import { ViewBridgeInspectionComponent } from '../../../app/pages/inspections/view-bridge-inspection/view-bridge-inspection.component';
import { ActionButtonsRendererComponent } from '../../../app/pages/inspections/shared/components/action-buttons-renderer.component';
import { DefectTableCheckboxRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-checkbox-renderer.component';
import { DefectTableFileUploadRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-file-upload-renderer.component';
import { DefectTableHeaderRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-header-renderer.component';
import { DefectTableNoteRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-note-renderer.component';
import { DefectTableRadioButtonsRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-radio-buttons-renderer.component';
import { HeaderRendererComponent } from '../../../app/pages/inspections/shared/components/header-renderer.component';
import { TextOnlyRendererComponent } from '../../../app/pages/inspections/shared/components/text-only-renderer.component';
import { CdAState as CdAStateConfig } from '../../../app/shared/configs/cda-state.config';
import { CdAStatus as CdAStatusConfig } from '../../../app/shared/configs/cda-status.config';
import { DefectNoteMode as DefectNoteModeConfig } from '../../../app/shared/configs/defect-note-mode.config';
import { UserRole as UserRoleConfig } from '../../../app/shared/configs/user-role.config';
import { environment as env } from '../../../environments/environment';
import { StructureConstants as StructureConstantsConfig } from '../constants';
import { ComponentMaterialMappingObj as ComponentMaterialMappingObjConfig } from './component-material-mapping';
import { BridgeConstants as BridgeConstantsConfig } from './constants';

const actionButtonsRendererComponent = ActionButtonsRendererComponent;
const BridgeConstants = BridgeConstantsConfig;
const CdAState = CdAStateConfig;
const CdAStatus = CdAStatusConfig;
const ComponentMaterialMappingObj = ComponentMaterialMappingObjConfig;
const DefectNoteMode = DefectNoteModeConfig;
const defectTableCheckboxRendererComponent =
  DefectTableCheckboxRendererComponent;
const defectTableFileUploadRendererComponent =
  DefectTableFileUploadRendererComponent;
const defectTableHeaderRendererComponent = DefectTableHeaderRendererComponent;
const defectTableNoteRendererComponent = DefectTableNoteRendererComponent;
const defectTableRadioButtonsRendererComponent =
  DefectTableRadioButtonsRendererComponent;
const environment = env;
const headerRendererComponent = HeaderRendererComponent;
const StructureConstants = StructureConstantsConfig;
const textOnlyRendererComponent = TextOnlyRendererComponent;
const UserRole = UserRoleConfig;

export function BridgeInputTemplate(
  bridgeInputTemplateComponent: ViewBridgeInspectionComponent,
  isDisabled: boolean
) {
  bridgeInputTemplateComponent.token =
    'se=2024-05-24T18%3A30%3A12Z&sp=r&sv=2022-11-02&sr=c&sig=FPh7XdGqZbfux83ee3tmy0PIkm0xsbKb7Gp1uS6ZjXc%3D';

  return {
    token: bridgeInputTemplateComponent.token,
    schema_json: [
      // INFO: Version 8.5.0
      {
        model: {
          [BridgeConstants.levels.l0]: {
            [BridgeConstants.levels.level2]: {
              level2List: [
                {
                  'N° campate': 0,
                  'Cda Status': 'Da Completare',
                },
              ],
            },
            Year: '',
            'Census date': [
              {
                'Tecnico Ispettore': '',
                'Data del censimento': '',
              },
            ],
            'Tipo di manutenzione section': [
              {
                'Intervento n': {
                  'Intervento n Data': '',
                  'Intervento n Data text': '',
                },
                'Allegato n': '',
              },
              {
                'Intervento n': {
                  'Intervento n Data': '',
                  'Intervento n Data text': '',
                },
                'Allegato n': '',
              },
              {
                'Intervento n': {
                  'Intervento n Data': '',
                  'Intervento n Data text': '',
                },
                'Allegato n': '',
              },
            ],
            'Ispezioni pregresse': {
              'Metodologia ispezioni section': [
                {
                  'Ispezione n': {
                    'Ispezione n Data': '',
                    'Ispezione n Data text': '',
                  },
                  'Allegato n': '',
                },
                {
                  'Ispezione n': {
                    'Ispezione n Data': '',
                    'Ispezione n Data text': '',
                  },
                  'Allegato n': '',
                },
                {
                  'Ispezione n': {
                    'Ispezione n Data': '',
                    'Ispezione n Data text': '',
                  },
                  'Allegato n': '',
                },
              ],
            },
            'Documenti progettuali disponibili': {
              'Varianti in corso d’opera': {
                Varianti: [
                  {
                    'Documenti descrittivi Variante': '',
                    'Documenti grafici Variante': '',
                    'Documenti contrattuali e contabili Variante': '',
                  },
                ],
              },
              'Interventi di manutenzione': {
                Manutenzione: [
                  {
                    'Documenti descrittivi Manutenzione': '',
                    'Documenti grafici Manutenzione': '',
                    'Documenti contrattuali e contabili Manutenzione': '',
                  },
                ],
              },
              Segnalazioni: {
                Segnalazione: [
                  {
                    'Documenti descrittivi con date Segnalazione': '',
                    'Documenti grafici con date Segnalazione': '',
                  },
                ],
              },
              'Progetto di incremento del grado di sicurezza': {
                Progetto: [
                  {
                    'Documenti descrittivi Progetto': '',
                    'Documenti grafici Progetto': '',
                  },
                ],
              },
              'Interventi di incremento del grado di sicurezza': {
                Intervento: [
                  {
                    'Documenti descrittivi Intervento': '',
                    'Documenti grafici Intervento': '',
                  },
                ],
              },
            },
          },
          [BridgeConstants.levels.l1]: {
            'Tipologia elementi strutturali': [],
            'Tipologia elementi strutturali impalcato': [],
            'Schede descrittive di ispezione ponti di Livello 1': {
              'Schede descrittive di ispezione ponti di Livello 1 section': [
                {
                  'Tecnico rilevatore': '',
                  'Data ispezione': '',
                },
              ],
            },
            'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici':
              {
                'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici section':
                  [
                    {
                      'Tecnico rilevatore': '',
                      Data: '',
                    },
                  ],
              },
            'Scheda Ispezione Ponti di Livello 1': {
              InspectionDate: [
                {
                  'Tecnico rilevatore': '',
                  'Data ispezione': '',
                },
              ],
            },
          },
          sharedData: {
            /* // INFO: Tecnico syncing to defect level
            defectCensusDate: [
              // Defect repeat section
              {
                list: [BridgeConstants.levels.l2, `${BridgeConstants.levels.spalle}list`],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'InspectionDate',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
              },
              {
                list: [BridgeConstants.levels.l2, `${BridgeConstants.levels.pile}list`],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'InspectionDate',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
              },
              {
                list: [BridgeConstants.levels.l2, `${BridgeConstants.levels.archi}list`],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'InspectionDate',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
              },
              {
                list: [BridgeConstants.levels.l2, `${BridgeConstants.levels.impalcati}list`],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'InspectionDate',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
              },
              {
                list: [BridgeConstants.levels.l2, `${BridgeConstants.levels.fileDiAppoggi}list`],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'InspectionDate',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
              },
              {
                list: [BridgeConstants.levels.l2, `${BridgeConstants.levels.giunti}list`],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'InspectionDate',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
              },
              {
                list: [BridgeConstants.levels.l2, `${BridgeConstants.levels.apparecchiSismici}list`],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'InspectionDate',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
              },
            ], */
            censusDate: [
              // Repeat section
              {
                pathNo: `${StructureConstants.syncPathNo}1`,
                path: [
                  BridgeConstants.levels.l0,
                  'Census date',
                  {
                    'Tecnico Ispettore': '',
                    'Data del censimento': '',
                  },
                ],
                syncCondition: 'false',
              },
              {
                pathNo: `${StructureConstants.syncPathNo}2`,
                path: [
                  BridgeConstants.levels.l1,
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Schede descrittive di ispezione ponti di Livello 1 section',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
                syncCondition:
                  'this.model.l0.opereparzialilist.length ? false : true',
              },
              /* // INFO: Tecnico syncing to defect level
              [
                BridgeConstants.levels.l1,
                'Scheda Ispezione Ponti di Livello 1',
                'InspectionDate',
                {
                  'Tecnico rilevatore': '',
                  'Data ispezione': '',
                },
              ], */
              {
                pathNo: `${StructureConstants.syncPathNo}3`,
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Schede descrittive di ispezione ponti di Livello 1 section',
                  {
                    'Tecnico rilevatore': '',
                    'Data ispezione': '',
                  },
                ],
                syncCondition:
                  'this.model.l0.opereparzialilist.length ? false : true',
              },
              /* // INFO: Tecnico syncing to defect level
              [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Scheda Ispezione Ponti di Livello 1',
                'InspectionDate',
                {
                  'Tecnico rilevatore': '',
                  'Data ispezione': '',
                },
              ], */
            ],
            censusDateTecnico: [
              {
                path: `(index) => ['${BridgeConstants.levels.l0}', 'Census date', index, 'Tecnico Ispettore']`,
                syncCondition: 'false',
              },
              {
                path: `(index) => [
                  '${BridgeConstants.levels.l1}',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Schede descrittive di ispezione ponti di Livello 1 section',
                  index,
                  'Tecnico rilevatore',
                ]`,
                syncCondition:
                  'this.model.l0.opereparzialilist.length ? false : true',
              },
              /* // INFO: Tecnico syncing to defect level
              `(index) => [
                '${BridgeConstants.levels.l1}',
                'Scheda Ispezione Ponti di Livello 1',
                'InspectionDate',
                index,
                'Tecnico rilevatore',
              ]`, */
              {
                path: `(index) => [
                  '${BridgeConstants.levels.l0}',
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Schede descrittive di ispezione ponti di Livello 1 section',
                  index,
                  'Tecnico rilevatore',
                ]`,
                syncCondition:
                  'this.model.l0.opereparzialilist.length ? false : true',
              },
              /* // INFO: Tecnico syncing to defect level
              `(index) => [
                '${BridgeConstants.levels.l0}',
                'opereparzialilist',
                0,
                'modelstructure',
                'Scheda Ispezione Ponti di Livello 1',
                'InspectionDate',
                index,
                'Tecnico rilevatore',
              ]`, */
            ],
            censusDateData: [
              {
                path: `(index) => ['${BridgeConstants.levels.l0}', 'Census date', index, 'Data del censimento']`,
                syncCondition: 'false',
              },
              {
                path: `(index) => [
                  '${BridgeConstants.levels.l1}',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Schede descrittive di ispezione ponti di Livello 1 section',
                  index,
                  'Data ispezione',
                ]`,
                syncCondition:
                  'this.model.l0.opereparzialilist.length ? false : true',
              },
              /* // INFO: Tecnico syncing to defect level
              `(index) => [
                '${BridgeConstants.levels.l1}',
                'Scheda Ispezione Ponti di Livello 1',
                'InspectionDate',
                index,
                'Data ispezione',
              ]`, */
              {
                path: `(index) => [
                  '${BridgeConstants.levels.l0}',
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Schede descrittive di ispezione ponti di Livello 1 section',
                  index,
                  'Data ispezione',
                ]`,
                syncCondition:
                  'this.model.l0.opereparzialilist.length ? false : true',
              },
              /* // INFO: Tecnico syncing to defect level
              `(index) => [
                '${BridgeConstants.levels.l0}',
                'opereparzialilist',
                0,
                'modelstructure',
                'Scheda Ispezione Ponti di Livello 1',
                'InspectionDate',
                index,
                'Data ispezione',
              ]`, */
            ],
            centroLatitudine: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Centro',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Centro',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Centro',
                  'Latitudine',
                ],
              },
            ],
            centroLongitudine: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Centro',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Centro',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Centro',
                  'Longitudine',
                ],
              },
            ],
            centroQuota: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Centro',
                  'Altitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Centro',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Centro',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Centro',
                  'Quota slm m',
                ],
              },
            ],
            codiceIop: [
              {
                path: [BridgeConstants.levels.l0, 'Codice IOP'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.spalle,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.pile,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.archi,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.impalcati,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.giunti,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.apparecchiSismici,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.fileDiAppoggi,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Codice IOP',
                ],
              },
            ],
            comune: [
              {
                path: [BridgeConstants.levels.l0, 'Localizzazione', 'Comune'],
              },
              {
                path: [BridgeConstants.levels.l1, 'Localizzazione', 'Comune'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Comune',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Comune',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Comune',
                ],
              },
            ],
            concessionario: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Informazioni generali',
                  'Concessionario',
                ],
              },
              {
                path: [BridgeConstants.levels.l0, 'Request', 'Concessionario'],
              },
            ],
            coordinateGeografiche: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Coordinate Geografiche',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'ETRF2000/WGS84',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'ETRF2000/WGS84',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'ETRF2000/WGS84',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'ETRF2000/WGS84',
                ],
              },
            ],
            enteVigilante: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Informazioni generali',
                  'Ente vigilante',
                ],
              },
              {
                path: [BridgeConstants.levels.l0, 'Request', 'Ente Vigilante'],
              },
            ],
            finaleLatitudine: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Punto Finale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Finale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Finale',
                  'Latitudine',
                ],
              },
            ],
            finaleLongitudine: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Punto Finale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Finale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Finale',
                  'Longitudine',
                ],
              },
            ],
            finaleQuota: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Punto Finale',
                  'Altitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Finale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Finale',
                  'Quota slm m',
                ],
              },
            ],
            inizialeLatitudine: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Punto Iniziale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Latitudine',
                ],
              },
            ],
            inizialeLongitudine: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Punto Iniziale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Longitudine',
                ],
              },
            ],
            inizialeQuota: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Coordinate',
                  'Punto Iniziale',
                  'Altitudine',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Quota slm m',
                ],
              },
            ],
            l0Images: [],
            l1Images: [],
            l2Images: [],
            l3Images: [],
            localita: [
              {
                path: [BridgeConstants.levels.l0, 'Localizzazione', 'Località'],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Indirizzo Principale',
                ],
              },
              {
                path: [BridgeConstants.levels.l1, 'Localizzazione', 'Località'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Località',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Località',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Località',
                ],
              },
            ],
            nomePonte: [
              {
                path: [BridgeConstants.levels.l0, 'Nome Ponte Viadotto'],
              },
              {
                path: [BridgeConstants.levels.l0, 'Request', 'Nome Opera'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Nome Opera',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Nome Ponte Viadotto',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Nome Ponte Viadotto',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Nome Opera',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Nome Ponte Viadotto',
                ],
              },
            ],
            progressivaKmFinale: [
              {
                path: [BridgeConstants.levels.l0, 'Progressiva km finale'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Progressiva km finale',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Progressiva km finale',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Progressiva km finale',
                ],
              },
            ],
            progressivaKmIniziale: [
              {
                path: [BridgeConstants.levels.l0, 'Progressiva km iniziale'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Progressiva km iniziale',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Progressiva km iniziale',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Progressiva km iniziale',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.spalle,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.pile,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.archi,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.impalcati,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.fileDiAppoggi,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.giunti,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.apparecchiSismici,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Progressiva km',
                ],
              },
            ],
            proprietario: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Informazioni generali',
                  'Proprietario',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Ente Concedente o Proprietario',
                ],
              },
            ],
            provincia: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Localizzazione',
                  'Provincia',
                ],
              },
              {
                path: [BridgeConstants.levels.l0, 'Request', 'Province'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione',
                  'Provincia',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Provincia',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Provincia',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Provincia',
                ],
              },
            ],
            regione: [
              {
                path: [BridgeConstants.levels.l0, 'Localizzazione', 'Regione'],
              },
              {
                path: [BridgeConstants.levels.l0, 'Request', 'Regioni'],
              },
              {
                path: [BridgeConstants.levels.l1, 'Localizzazione', 'Regione'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Localizzazione Fenomeni di frana',
                  'Regione',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Regione',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione Fenomeni di frana',
                  'Regione',
                ],
              },
            ],
            stradaDiAppartenenza: [
              {
                path: [BridgeConstants.levels.l0, 'Strada di appartenenza'],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l1,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Schede descrittive di ispezione ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.spalle,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.pile,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.archi,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.impalcati,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.fileDiAppoggi,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.giunti,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.apparecchiSismici,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Strada di appartenenza',
                ],
              },
            ],
            ultimazioneCostruzioneCollaudo: [
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Informazioni generali',
                  'Anno di costruzione / ristrutturazione',
                  'Ultimazione costruzione collaudo',
                  'year',
                ],
              },
              {
                path: [
                  BridgeConstants.levels.l0,
                  'Request',
                  'Anno Collaudo Finale',
                ],
              },
            ],
          },
          sharedDataForList: {
            codiceIop: [
              {
                list: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  BridgeConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${BridgeConstants.levels.spalle}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  BridgeConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${BridgeConstants.levels.pile}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  BridgeConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${BridgeConstants.levels.archi}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  BridgeConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${BridgeConstants.levels.impalcati}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  BridgeConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${BridgeConstants.levels.giunti}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  BridgeConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${BridgeConstants.levels.apparecchiSismici}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  BridgeConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  BridgeConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${BridgeConstants.levels.fileDiAppoggi}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
            ],
            accelerazioneDiPiccoAlSuolo: [
              {
                list: [
                  BridgeConstants.levels.l0,
                  'level2',
                  'level2List',
                  0,
                  'modelstructure',
                  'campateCdaStatus',
                  'campateList',
                ],
                path: [
                  'modelstructure',
                  BridgeConstants.level2RiskSheets.sismica,
                  'Valutazione del livello di pericolosità sismica',
                  'Accelerazione di picco al suolo',
                ],
                syncCondition: 'true',
              },
            ],
            alternativeStradali: [
              {
                list: [
                  BridgeConstants.levels.l0,
                  'level2',
                  'level2List',
                  0,
                  'modelstructure',
                  'campateCdaStatus',
                  'campateList',
                ],
                path: [
                  'modelstructure',
                  BridgeConstants.level2RiskSheets.strutturaleEFondazionale,
                  'Stima del livello di esposizione',
                  'Alternative stradali',
                ],
                syncCondition: 'true',
              },
            ],
            elementiDiVulnerabilitaSismica: [
              {
                list: [
                  BridgeConstants.levels.l0,
                  'level2',
                  'level2List',
                  0,
                  'modelstructure',
                  'campateCdaStatus',
                  'campateList',
                ],
                path: [
                  'modelstructure',
                  BridgeConstants.level2RiskSheets.sismica,
                  'Stima del livello di vulnerabilità sismica',
                  'Elementi di vulnerabilità sismica',
                ],
                syncCondition: 'true',
              },
            ],
            NCampate: [
              {
                list: [
                  BridgeConstants.levels.l0,
                  'level2',
                  'level2List',
                  0,
                  'modelstructure',
                  'campateCdaStatus',
                  'campateList',
                ],
                path: [
                  'modelstructure',
                  BridgeConstants.level2RiskSheets.strutturaleEFondazionale,
                  'Stima del livello di vulnerabilita',
                  'N° di campate',
                ],
                syncCondition: 'true',
              },
            ],
          },
          outputPaths: {
            output1: {
              keys: {
                defectsLists: [
                  `${BridgeConstants.levels.spalle}list`,
                  `${BridgeConstants.levels.pile}list`,
                  `${BridgeConstants.levels.archi}list`,
                  `${BridgeConstants.levels.impalcati}list`,
                  `${BridgeConstants.levels.fileDiAppoggi}list`,
                  `${BridgeConstants.levels.giunti}list`,
                  `${BridgeConstants.levels.apparecchiSismici}list`,
                ],
                modelStructure: 'modelstructure',
              },
              partialPaths: {
                campateNo: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  'modelstructure',
                  BridgeConstants.partNo,
                ],
                siglaIdElem: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Sigla ID Elem',
                ],
                defects: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Scheda Ispezione Ponti di Livello 1 List1',
                ],
                codiceElemento: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice Elemento',
                ],
                direzione: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'direzione',
                ],
                codiceComponento: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice Componento',
                ],
                localizzazione: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Localizzazione',
                ],
                codUnique: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Cod unique',
                ],
                codiceDifetto: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Codice difetto',
                ],
                component: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'component',
                ],
                descrizioneDifetto: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Descrizione difetto',
                ],
                g: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'G',
                ],
                k1: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Estensione K1',
                ],
                k2: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Intensità K2',
                ],
                PS: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'PS',
                ],
                NA: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NA',
                ],
                NP: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NP',
                ],
                NR: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NR',
                ],
                note: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Note',
                ],
              },
              codiceIop: [BridgeConstants.levels.l0, 'Codice IOP'],
              nomePonte: [BridgeConstants.levels.l0, 'Nome Ponte Viadotto'],
              opereParzialiList: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
              ],
              campateList: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'campatelist',
              ],
            },
            output5: {
              keys: {
                defectsLists: [
                  `${BridgeConstants.levels.spalle}list`,
                  `${BridgeConstants.levels.pile}list`,
                  `${BridgeConstants.levels.archi}list`,
                  `${BridgeConstants.levels.impalcati}list`,
                  `${BridgeConstants.levels.fileDiAppoggi}list`,
                  `${BridgeConstants.levels.giunti}list`,
                  `${BridgeConstants.levels.apparecchiSismici}list`,
                ],
                modelStructure: 'modelstructure',
              },
              partialPaths: {
                campateNo: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  BridgeConstants.partNo, // TODO discuss
                ],
                defects: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Scheda Ispezione Ponti di Livello 1 List1',
                ],
                siglaIdElem: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Sigla ID Elem',
                ],
                codiceElemento: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice Elemento',
                ],
                direzione: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'direzione',
                ],
                codiceComponento: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice Componento',
                ],
                g: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'G',
                ],
                k1: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Estensione K1',
                ],
                k2: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Intensità K2',
                ],
                PS: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'PS',
                ],
              },
              codiceIop: [BridgeConstants.levels.l0, 'Codice IOP'],
              annoDiCostruzioneRistrutturazione: [
                BridgeConstants.levels.l0,
                'Informazioni generali',
                'Anno di costruzione / ristrutturazione',
                'Ultimazione costruzione collaudo',
                'year',
              ],
              stradaDiAppartenenza: [
                BridgeConstants.levels.l0,
                'Strada di appartenenza',
              ],
              latitudine: [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Latitudine',
              ],
              longitudine: [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Longitudine',
              ],
              quotaSlmM: [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Quota slm m',
              ],
              opereParzialiList: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
              ],
              nCampate: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'N° campate',
              ],
              nSpalle: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'N° Spalle',
              ],
              nPile: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'N° Pile',
              ],
              nArchi: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'N° Archi',
              ],
              nImpalcati: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'N° Impalcati',
              ],
              nFileDiAppogi: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'N° File di Appoggi',
              ],
              nGiunti: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'N° Giunti',
              ],
              nApparecchiSismici: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'N° Apparecchi Sismici',
              ],
              campateList: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'campatelist',
              ],
              comune: [BridgeConstants.levels.l0, 'Localizzazione', 'Comune'],
              progressivaKmIniziale: [
                BridgeConstants.levels.l0,
                'Progressiva km iniziale',
              ],
              progressivaKmFinale: [
                BridgeConstants.levels.l0,
                'Progressiva km finale',
              ],
              enteProprietario: [
                BridgeConstants.levels.l0,
                'Informazioni generali',
                'Proprietario',
              ],
              nomePonteViadotto: [
                BridgeConstants.levels.l0,
                'Nome Ponte Viadotto',
              ],
              notes: [BridgeConstants.levels.l0, 'Notes'],
              tipoOpera: [BridgeConstants.levels.l0, 'Tipo Opera'],
              numeroDiCarreggiata: [
                BridgeConstants.levels.l0,
                'Rete stradale',
                'N° di carreggiate',
              ],
              mediaList: [BridgeConstants.levels.l0, 'MediaList'],
            },
            photoFolder: {
              keys: {
                defectsLists: [
                  `${BridgeConstants.levels.spalle}list`,
                  `${BridgeConstants.levels.pile}list`,
                  `${BridgeConstants.levels.archi}list`,
                  `${BridgeConstants.levels.impalcati}list`,
                  `${BridgeConstants.levels.fileDiAppoggi}list`,
                  `${BridgeConstants.levels.giunti}list`,
                  `${BridgeConstants.levels.apparecchiSismici}list`,
                ],
                elementiAccessoriLists: [
                  'campateList',
                  'schedaIspezionePontiDiLivello1List2',
                  'caso1Guardiavia',
                  'caso2Guardiavia',
                  'caso2Parapetto',
                  'caso3Guardiavia',
                  'caso3Parapetto',
                ],
                modelStructure: 'modelstructure',
                folderNames: {
                  campata: 'Campata',
                  spallelist: BridgeConstants.defectElements.spalle,
                  pilelist: BridgeConstants.defectElements.pile,
                  archilist: BridgeConstants.defectElements.archi,
                  impalcatilist: BridgeConstants.defectElements.impalcati,
                  filediappoggilist:
                    BridgeConstants.defectElements.fileDiAppoggi,
                  giuntilist: BridgeConstants.defectElements.giunti,
                  apparecchisismicilist:
                    BridgeConstants.defectElements.apparecchiSismici,
                  elementiAccessori: BridgeConstants.elementiAccessori,
                  caso1: 'Marciapiede assente',
                  caso2: 'Marciapiede sormontabile',
                  caso3: 'Marciapiede non sormontabile',
                },
              },
              partialPaths: {
                campateNo: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  BridgeConstants.partNo, // TODO discuss
                ],
                defects: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Scheda Ispezione Ponti di Livello 1 List1',
                ],
                nFoto: [
                  // Used for Every N° fotos
                  'N° foto',
                ],
                eventualiNote: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Note',
                ],
                uniquecode: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'campatelist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'uniquecode',
                ],
              },
              codiceIop: [BridgeConstants.levels.l0, 'Codice IOP'],
              campateList: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'campatelist',
              ],
              schedaIspezionePontiDiLivello1List2: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Scheda Ispezione Ponti di Livello 1',
                'Scheda Ispezione Ponti di Livello 1 List2',
              ],
              caso1Guardiavia: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Caso1',
                'GUARDIAVIA',
              ],
              caso2Guardiavia: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Caso2',
                'GUARDIAVIA',
              ],
              caso2Parapetto: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Caso2',
                'PARAPETTO',
              ],
              caso3Guardiavia: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Caso3',
                'GUARDIAVIA',
              ],
              caso3Parapetto: [
                BridgeConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Caso3',
                'PARAPETTO',
              ],
            },
            iopRequest: {
              tipoAnagrafica: [
                BridgeConstants.levels.l0,
                'Request',
                'Tipo Anagrafica',
              ],
              tipologiaInfrastruttura: [
                BridgeConstants.levels.l0,
                'Request',
                'Tipologia Infrastruttura',
              ],
              nomeInfrastruttura: [
                BridgeConstants.levels.l0,
                'Request',
                'Nome Infrastruttura',
              ],
              codiceInfrastruttura: [
                BridgeConstants.levels.l0,
                'Request',
                'Codice Infrastruttura',
              ],
              tipologiaOpera: [
                BridgeConstants.levels.l0,
                'Request',
                'Tipologia Opera',
              ],
              nomeOpera: [BridgeConstants.levels.l0, 'Request', 'Nome Opera'],
              codiceOpera: [
                BridgeConstants.levels.l0,
                'Request',
                'Codice Opera',
              ],
              idPerGestoreOProprietario: [
                BridgeConstants.levels.l0,
                'Request',
                'ID Gestore O Proprietario',
              ],
              'coordinateCentro.lat': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Centro',
                'Latitudine',
              ],
              'coordinateCentro.lon': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Centro',
                'Longitudine',
              ],
              'coordinateCentro.alt': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Centro',
                'Altitudine',
              ],
              'coordinatePuntoIniziale.lat': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Punto Iniziale',
                'Latitudine',
              ],
              'coordinatePuntoIniziale.lon': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Punto Iniziale',
                'Longitudine',
              ],
              'coordinatePuntoIniziale.alt': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Punto Iniziale',
                'Altitudine',
              ],
              'coordinatePuntoFinale.Lat': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Punto Finale',
                'Latitudine',
              ],
              'coordinatePuntoFinale.Lon': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Punto Finale',
                'Longitudine',
              ],
              'coordinatePuntoFinale.Alt': [
                BridgeConstants.levels.l0,
                'Request',
                'Coordinate',
                'Punto Finale',
                'Altitudine',
              ],
              comunePrincipale: [
                BridgeConstants.levels.l0,
                'Request',
                'Codice Catastale del Comune Principale',
              ],
              indirizzoPrincipale: [
                BridgeConstants.levels.l0,
                'Request',
                'Indirizzo Principale',
              ],
              comuni: [
                BridgeConstants.levels.l0,
                'Request',
                'Codice Catastale dei Comuni',
              ],
              province: [BridgeConstants.levels.l0, 'Request', 'Province'],
              regioni: [BridgeConstants.levels.l0, 'Request', 'Regioni'],
              annoEntrataEsercizio: [
                BridgeConstants.levels.l0,
                'Request',
                'Anno Entrata Esercizio',
              ],
              annoInizioLavori: [
                BridgeConstants.levels.l0,
                'Request',
                'Anno Inizio Lavori',
              ],
              annoFineLavori: [
                BridgeConstants.levels.l0,
                'Request',
                'Anno Fine Lavori',
              ],
              annoCollaudoFinale: [
                BridgeConstants.levels.l0,
                'Request',
                'Anno Collaudo Finale',
              ],
              enteConcedenteOProprietario: [
                BridgeConstants.levels.l0,
                'Request',
                'Ente Concedente o Proprietario',
              ],
              concessionario: [
                BridgeConstants.levels.l0,
                'Request',
                'Concessionario',
              ],
              enteVigilante: [
                BridgeConstants.levels.l0,
                'Request',
                'Ente Vigilante',
              ],
              statoOpera: [
                BridgeConstants.levels.l0,
                'Request',
                "Stato dell'Opera",
              ],
              codiceCup: [BridgeConstants.levels.l0, 'Request', 'Codice Cup'],
              note: [BridgeConstants.levels.l0, 'Request', 'Note'],
              idUnitaOrganizzativa: [
                BridgeConstants.levels.l0,
                'Request',
                'Id Unità Organizzativa',
              ],
              codiceIopMadre: [
                BridgeConstants.levels.l0,
                'Request',
                'Codice Iop Madre',
              ],
              codiceIop: [BridgeConstants.levels.l0, 'Codice IOP'],
            },
          },
          outputVersions: {
            output2: '2.8.5.0',
            output5: '5.8.5.0',
            output6: '6.8.5.0',
          },
          importStructure: {
            codiceIop: [
              [BridgeConstants.levels.l0, 'Codice IOP'],
              [
                BridgeConstants.levels.l1,
                'Schede descrittive di ispezione ponti di Livello 1',
                'Codice IOP',
              ],
              [
                BridgeConstants.levels.l1,
                'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                'Codice IOP',
              ],
              [
                BridgeConstants.levels.spalle,
                'Scheda Ispezione Ponti di Livello 1',
                'Codice IOP',
              ],
              [
                BridgeConstants.levels.pile,
                'Scheda Ispezione Ponti di Livello 1',
                'Codice IOP',
              ],
              [
                BridgeConstants.levels.archi,
                'Scheda Ispezione Ponti di Livello 1',
                'Codice IOP',
              ],
              [
                BridgeConstants.levels.impalcati,
                'Scheda Ispezione Ponti di Livello 1',
                'Codice IOP',
              ],
              [
                BridgeConstants.levels.giunti,
                'Scheda Ispezione Ponti di Livello 1',
                'Codice IOP',
              ],
              [
                BridgeConstants.levels.apparecchiSismici,
                'Scheda Ispezione Ponti di Livello 1',
                'Codice IOP',
              ],
              [
                BridgeConstants.levels.fileDiAppoggi,
                'Scheda Ispezione Ponti di Livello 1',
                'Codice IOP',
              ],
            ],
            nomePonte: [
              [BridgeConstants.levels.l0, 'Nome Ponte Viadotto'],
              [
                BridgeConstants.levels.l1,
                'Schede descrittive di ispezione ponti di Livello 1',
                'Nome Opera',
              ],
              [
                BridgeConstants.levels.l1,
                'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                'Nome Ponte Viadotto',
              ],
            ],
            stradaDiAppartenenza: [
              [BridgeConstants.levels.l0, 'Strada di appartenenza'],
              [
                BridgeConstants.levels.l1,
                'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                'Strada di appartenenza',
              ],
              [
                BridgeConstants.levels.l1,
                'Schede descrittive di ispezione ponti di Livello 1',
                'Strada di appartenenza',
              ],
              [
                BridgeConstants.levels.l1,
                'Scheda Ispezione Ponti di Livello 1',
                'Strada di appartenenza',
              ],
            ],
            progressivaKmIniziale: [
              [BridgeConstants.levels.l0, 'Progressiva km iniziale'],
              [
                BridgeConstants.levels.l1,
                'Schede descrittive di ispezione ponti di Livello 1',
                'Progressiva km',
              ],
              [
                BridgeConstants.levels.l1,
                'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                'Progressiva km iniziale',
              ],
              [
                BridgeConstants.levels.l1,
                'Scheda Ispezione Ponti di Livello 1',
                'Progressiva km',
              ],
            ],
            progressivaKmFinale: [
              [BridgeConstants.levels.l0, 'Progressiva km finale'],
              [
                BridgeConstants.levels.l1,
                'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                'Progressiva km finale',
              ],
            ],
            ispezioniProgrammate: [[BridgeConstants.levels.l0, 'quarter']],
            anno: [[BridgeConstants.levels.l0, 'Year']],
            regione: [
              [BridgeConstants.levels.l0, 'Localizzazione', 'Regione'],
              [BridgeConstants.levels.l1, 'Localizzazione', 'Regione'],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Regione',
              ],
            ],
            provincia: [
              [BridgeConstants.levels.l0, 'Localizzazione', 'Provincia'],
              [BridgeConstants.levels.l1, 'Localizzazione', 'Provincia'],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Provincia',
              ],
            ],
            comune: [
              [BridgeConstants.levels.l0, 'Localizzazione', 'Comune'],
              [BridgeConstants.levels.l1, 'Localizzazione', 'Comune'],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Comune',
              ],
            ],
            localita: [
              [BridgeConstants.levels.l0, 'Localizzazione', 'Località'],
              [BridgeConstants.levels.l1, 'Localizzazione', 'Località'],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Località',
              ],
            ],
            'Sismicità dell’area': [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Sismicità dell’area',
              ],
            ],
            luceComplessiva: [
              [
                BridgeConstants.levels.l0,
                'Caratteristiche geometriche',
                'Luce complessiva',
              ],
            ],
            'N° campate': [
              [
                BridgeConstants.levels.l0,
                'Caratteristiche geometriche',
                'N° campate',
              ],
              [
                BridgeConstants.levels.l1,
                'Rilievo caratteristiche geometriche',
                'N° campate',
              ],
            ],
            proprietario: [
              [
                BridgeConstants.levels.l0,
                'Informazioni generali',
                'Proprietario',
              ],
            ],
            concessionario: [
              [
                BridgeConstants.levels.l0,
                'Informazioni generali',
                'Concessionario',
              ],
            ],
            inizialeQuota: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Quota slm m',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Quota slm m',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Iniziale',
                'Quota slm m',
              ],
            ],
            inizialeLongitudine: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Longitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Longitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Iniziale',
                'Longitudine',
              ],
            ],
            inizialeLatitudine: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Latitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Latitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Iniziale',
                'Latitudine',
              ],
            ],
            centroQuota: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Quota slm m',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Quota slm m',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Centro',
                'Quota slm m',
              ],
            ],
            centroLongitudine: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Longitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Longitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Centro',
                'Longitudine',
              ],
            ],
            centroLatitudine: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Latitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Centro',
                'Latitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Centro',
                'Latitudine',
              ],
            ],
            finaleQuota: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Finale',
                'Quota slm m',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Finale',
                'Quota slm m',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Finale',
                'Quota slm m',
              ],
            ],
            finaleLongitudine: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Finale',
                'Longitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Finale',
                'Longitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Finale',
                'Longitudine',
              ],
            ],
            finaleLatitudine: [
              [
                BridgeConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Finale',
                'Latitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione',
                'Coordinate Geografiche',
                'Finale',
                'Latitudine',
              ],
              [
                BridgeConstants.levels.l1,
                'Localizzazione Fenomeni di frana',
                'Coordinate Geografiche',
                'Finale',
                'Latitudine',
              ],
            ],
          },
          version: '8.5.0',
        },
      },
      {
        key: 'activeLevel',
        defaultValue: BridgeConstants.levels.l0,
      },
      {
        key: BridgeConstants.levels.l0,
        type: 'cube-tabs-type',
        hooks: {
          afterViewInit: () => {
            bridgeInputTemplateComponent.saveModelToIndexedDbForPlannedInspection();
          },
        },
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: { label: 'Level 0' },
            fieldGroup: [
              {
                fieldGroupClassName: 'page-heading',
                fieldGroup: [
                  {
                    className: 'width-100',
                    template: `<div class="page-heading-text">Schede di censimento ponti di Livello 0</div>`,
                  },
                  {
                    className: 'page-heading-image',
                    template: `<img src="assets/rina/RINA-dark.png"/>`,
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Codice IOP',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Codice IOP',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          required: true,
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'codiceIop',
                              e.target.value
                            );
                            bridgeInputTemplateComponent.syncSharedModelDataForList(
                              'codiceIop',
                              e.target.value
                            );
                          },
                        },
                      },
                      {
                        className: 'remove-underline',
                        key: 'Official IOP Code',
                        type: 'checkbox',
                        defaultValue: false,
                        templateOptions: {
                          key: 'Official IOP Code',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspectionManager,
                                UserRole.bmsInspector,
                              ],
                              isDisabled
                            ),
                          name: 'Official IOP Code',
                          options: [
                            { value: true, label: 'Official IOP Code' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-2',
                    key: 'Nome Ponte Viadotto',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Nome Ponte/Viadotto',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      required: true,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.syncSharedModelData(
                          'nomePonte',
                          e.target.value
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'Strada di appartenenza',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Strada di appartenenza',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      required: true,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.syncSharedModelData(
                          'stradaDiAppartenenza',
                          e.target.value
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Progressiva km iniziale',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Progressiva km iniziale',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      required: true,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.syncSharedModelData(
                          'progressivaKmIniziale',
                          e.target.value
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Progressiva km finale',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Progressiva km finale',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      required: true,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.syncSharedModelData(
                          'progressivaKmFinale',
                          e.target.value
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    key: 'Year',
                    type: 'cube-year-picker-type',
                    defaultValue: '',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Anno',
                      required: true,
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'quarter',
                    type: 'select',
                    defaultValue: 'T1',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Ispezioni Programmate',
                      required: true,
                      options: [
                        { value: 'T1', label: 'T1' },
                        { value: 'T2', label: 'T2' },
                        { value: 'T3', label: 'T3' },
                        { value: 'T4', label: 'T4' },
                      ],
                    },
                  },
                ],
              },
              {
                className: 'flex-2',
                fieldGroup: [
                  {
                    key: 'Census date',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      onAddRow: () => {
                        bridgeInputTemplateComponent.syncRepeatSections(
                          'censusDate',
                          `${StructureConstants.syncPathNo}1`
                        );
                      },
                      onDeleteRow: (index) => {
                        bridgeInputTemplateComponent.syncRepeatSections(
                          'censusDate',
                          `${StructureConstants.syncPathNo}1`,
                          index
                        );
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex-wrap grid-gap',
                      fieldGroup: [
                        {
                          className: 'flex-1',
                          key: 'Tecnico Ispettore',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            disabled:
                              bridgeInputTemplateComponent.isFieldDisabled(
                                [UserRole.hydraulicTechnician],
                                isDisabled,
                                true
                              ),
                            required: true,
                            change: (field, e) => {
                              bridgeInputTemplateComponent.syncSharedModelData(
                                'censusDateTecnico',
                                e.target.value,
                                field.parent.key
                              );
                            },
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'Tecnico Ispettore n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className: 'flex-1',
                          key: 'Data del censimento',
                          type: 'cube-date-range-picker-type',
                          defaultValue: '',
                          templateOptions: {
                            disabled:
                              bridgeInputTemplateComponent.isFieldDisabled(
                                [UserRole.hydraulicTechnician],
                                isDisabled,
                                true
                              ),
                            required: true,
                            label: 'Data del censimento',
                            max: (() => {
                              const today = new Date();
                              const maxDate = new Date(today);
                              maxDate.setDate(today.getDate() + 7);

                              return maxDate;
                            })(),
                            onClearDate: (field, value) => {
                              bridgeInputTemplateComponent.syncSharedModelData(
                                'censusDateData',
                                value,
                                field.parent.key
                              );
                            },
                            onDateChange: (field, e) => {
                              bridgeInputTemplateComponent.syncSharedModelData(
                                'censusDateData',
                                e,
                                field.parent.key
                              );
                            },
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'Data del censimento n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                ],
              },
              {
                key: 'Localizzazione',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: { title: 'Localizzazione' },
                  },
                  {
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroupClassName: 'display-flex-column',
                            fieldGroup: [
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Provincia',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  required: true,
                                  label: 'Provincia',
                                  change: (field, e) => {
                                    bridgeInputTemplateComponent.syncSharedModelData(
                                      'provincia',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Regione',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  required: true,
                                  label: 'Regione',
                                  change: (field, e) => {
                                    bridgeInputTemplateComponent.syncSharedModelData(
                                      'regione',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Comune',
                                templateOptions: {
                                  label: 'Comune',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  required: true,
                                  change: (field, e) => {
                                    bridgeInputTemplateComponent.syncSharedModelData(
                                      'comune',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Località',
                                templateOptions: {
                                  label: 'Località',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  required: true,
                                  change: (field, e) => {
                                    bridgeInputTemplateComponent.syncSharedModelData(
                                      'localita',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Sismicità dell’area',
                                templateOptions: {
                                  change: (field, e) => {
                                    bridgeInputTemplateComponent.syncSharedModelDataForList(
                                      'accelerazioneDiPiccoAlSuolo',
                                      e.target.value
                                    );
                                    bridgeInputTemplateComponent.resetAllCampataAttentionClass();
                                  },
                                  label:
                                    'Sismicità dell’area [ag/g] (Suolo A, TR = 475 anni)',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  required: true,
                                },
                                validators: {
                                  validation: ['numberPattern'],
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            key: 'Coordinate Geografiche',
                            type: 'cube-block-table-type',
                            templateOptions: {
                              grouptitle: 'Coordinate Geografiche',
                            },
                            fieldGroup: [
                              {
                                key: 'Coordinate Geografiche',
                                type: 'radio',
                                defaultValue: 'ETRF2000',
                                className:
                                  'sub-sec radio-sec text-align-center radio-sec-custom-aligment',
                                templateOptions: {
                                  key: 'Coordinate Geografiche',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  required: true,
                                  options: [
                                    {
                                      value: 'ETRF2000',
                                      label: 'ETRF2000',
                                    },
                                    {
                                      value: 'WGS84',
                                      label: 'WGS84',
                                    },
                                  ],
                                  change: (field, e) => {
                                    bridgeInputTemplateComponent.syncSharedModelData(
                                      'coordinateGeografiche',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                              {
                                fieldGroup: [
                                  {
                                    key: 'Centro',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Centro',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        fieldGroupClassName:
                                          'display-flex align-items-center',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Quota s.l.m. [m]',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              required: true,
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'centroQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            type: 'cube-custom-tooltip',
                                            templateOptions: {
                                              icon: 'info',
                                              toolTipMessage:
                                                'Altitude expressed in meters (e.g 78.00)',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Longitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Longitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              required: true,
                                              onChange: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'centroLongitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Latitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Latitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              required: true,
                                              onChange: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'centroLatitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Iniziale',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Iniziale',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        fieldGroupClassName:
                                          'display-flex align-items-center',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Quota s.l.m. [m]',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'inizialeQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            type: 'cube-custom-tooltip',
                                            templateOptions: {
                                              icon: 'info',
                                              toolTipMessage:
                                                'Altitude expressed in meters (e.g 78.00)',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Longitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Longitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              onChange: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'inizialeLongitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Latitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Latitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              onChange: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'inizialeLatitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Finale',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Finale',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        fieldGroupClassName:
                                          'display-flex align-items-center',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Quota s.l.m. [m]',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'finaleQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            type: 'cube-custom-tooltip',
                                            templateOptions: {
                                              icon: 'info',
                                              toolTipMessage:
                                                'Altitude expressed in meters (e.g 78.00)',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Longitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Longitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              onChange: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'finaleLongitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Latitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Latitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              onChange: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'finaleLatitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      { template: `<hr/>` },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Fenomeni erosivi e di alluvionamento',
                        },
                      },
                      {
                        key: 'Fenomeni erosivi e di alluvionamento',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-3',
                        templateOptions: {
                          key: 'Fenomeni erosivi e di alluvionamento',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          flex: true,
                          options: [
                            { value: 'Assenti', label: 'Assenti' },
                            { value: 'Già valutati', label: 'Già valutati' },
                            { value: 'Da verificare', label: 'Da verificare' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: { title: 'Fenomeni franosi' },
                      },
                      {
                        key: 'Fenomeni franosi',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-3',
                        templateOptions: {
                          key: 'Fenomeni franosi',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          flex: true,
                          options: [
                            { value: 'Assenti', label: 'Assenti' },
                            { value: 'Già valutati', label: 'Già valutati' },
                            { value: 'Da verificare', label: 'Da verificare' },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: { title: 'Informazioni generali' },
              },
              {
                key: 'Informazioni generali',
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Proprietario',
                        templateOptions: {
                          label: 'Proprietario',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'proprietario',
                              e.target.value
                            );
                          },
                        },
                      },
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Concessionario',
                        templateOptions: {
                          label: 'Concessionario',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'concessionario',
                              e.target.value
                            );
                          },
                        },
                      },
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Ente vigilante',
                        templateOptions: {
                          label: 'Ente vigilante',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'enteVigilante',
                              e.target.value
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-1',
                    key: 'Anno di costruzione / ristrutturazione',
                    type: 'cube-block-table-type',
                    templateOptions: {
                      grouptitle: 'Anno di costruzione / ristrutturazione',
                    },
                    fieldGroup: [
                      {
                        type: 'cube-block-table-content-type',
                        templateOptions: {
                          rowKeysList: ['year', 'type'],
                        },
                        fieldGroup: [
                          {
                            key: 'Ultimazione costruzione collaudo',
                            templateOptions: {
                              columnName: 'Ultimazione costruzione (collaudo)',
                            },
                            fieldGroup: [
                              {
                                key: 'year',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  change: (field, e) => {
                                    bridgeInputTemplateComponent.syncSharedModelData(
                                      'ultimazioneCostruzioneCollaudo',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                key: 'type',
                                fieldGroup: [
                                  {
                                    key: 'Ultimazione costruzione collaudo',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec',
                                    templateOptions: {
                                      key: 'Ultimazione costruzione collaudo',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: 'Effettivo',
                                          label: 'Effettivo',
                                        },
                                        {
                                          value: 'Presunto',
                                          label: 'Presunto',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            key: 'Eventuali interventi sostanziali',
                            templateOptions: {
                              columnName: 'Eventuali interventi sostanziali',
                            },
                            fieldGroup: [
                              {
                                key: 'year',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                key: 'type',
                                fieldGroup: [
                                  {
                                    key: 'Eventuali interventi sostanziali',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec',
                                    templateOptions: {
                                      key: 'Eventuali interventi sostanziali',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: 'Effettivo',
                                          label: 'Effettivo',
                                        },
                                        {
                                          value: 'Presunto',
                                          label: 'Presunto',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: { title: 'Dati di progetto' },
              },
              {
                key: 'Dati di progetto',
                fieldGroup: [
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex-column',
                        fieldGroup: [
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Progettista',
                            templateOptions: {
                              label: 'Progettista',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Norma di progetto',
                            templateOptions: {
                              label: 'Norma di progetto',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Ente approvatore',
                            templateOptions: {
                              label: 'Ente approvatore',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        key: 'Anno di progettazione',
                        type: 'cube-block-table-type',
                        templateOptions: {
                          grouptitle: 'Anno di progettazione',
                        },
                        fieldGroup: [
                          {
                            type: 'cube-block-table-content-type',
                            templateOptions: {
                              rowKeysList: ['year', 'type'],
                            },
                            fieldGroup: [
                              {
                                key: 'Data inizio',
                                templateOptions: {
                                  columnName: 'Data inizio',
                                },
                                fieldGroup: [
                                  {
                                    key: 'year',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    key: 'type',
                                    fieldGroup: [
                                      {
                                        key: 'Data inizio',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec',
                                        templateOptions: {
                                          key: 'Data inizio',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: 'Effettivo',
                                              label: 'Effettivo',
                                            },
                                            {
                                              value: 'Presunto',
                                              label: 'Presunto',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                key: 'Data fine',
                                templateOptions: {
                                  columnName: 'Data fine',
                                },
                                fieldGroup: [
                                  {
                                    key: 'year',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    key: 'type',
                                    fieldGroup: [
                                      {
                                        key: 'Data fine',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec',
                                        templateOptions: {
                                          key: 'Data fine',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: 'Effettivo',
                                              label: 'Effettivo',
                                            },
                                            {
                                              value: 'Presunto',
                                              label: 'Presunto',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                key: 'Data approvazione',
                                templateOptions: {
                                  columnName: 'Data approvazione',
                                },
                                fieldGroup: [
                                  {
                                    key: 'year',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    key: 'type',
                                    fieldGroup: [
                                      {
                                        key: 'Data approvazione',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec',
                                        templateOptions: {
                                          key: 'Data approvazione',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: 'Effettivo',
                                              label: 'Effettivo',
                                            },
                                            {
                                              value: 'Presunto',
                                              label: 'Presunto',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Categoria di progetto',
                    fieldGroupClassName: 'display-flex-wrap margin-top-20',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        type: 'cube-block-table-type',
                        templateOptions: {
                          grouptitle: 'Categoria di progetto',
                        },
                        fieldGroup: [
                          {
                            type: 'cube-block-table-content-type',
                            templateOptions: {
                              rowKeysList: ['Categoria'],
                            },
                            fieldGroup: [
                              {
                                fieldGroup: [
                                  {
                                    key: 'Categoria',
                                    fieldGroup: [
                                      {
                                        key: 'Categoria',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec',
                                        templateOptions: {
                                          key: 'Categoria',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: 'I° Categoria',
                                              label: 'I° Categoria',
                                            },
                                            {
                                              value: 'II° Categoria',
                                              label: 'II° Categoria',
                                            },
                                          ],
                                          change: (field) => {
                                            if (field.formControl.value) {
                                              field.formControl.parent
                                                ?.get(['Categoria type'])
                                                ?.enable();
                                            }
                                          },
                                          onReset: (field) => {
                                            field.formControl.parent
                                              ?.get(['Categoria type'])
                                              ?.disable();
                                          },
                                        },
                                        hooks: {
                                          onInit: (field) => {
                                            setTimeout(() => {
                                              if (field.formControl.value) {
                                                const isFieldDisabled =
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  );

                                                if (!isFieldDisabled) {
                                                  field.formControl.parent
                                                    ?.get(['Categoria type'])
                                                    ?.enable();
                                                }
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Categoria type'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Categoria type'])
                                                  ?.disable();
                                              }
                                            }, 0);
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                fieldGroup: [
                                  {
                                    key: 'Categoria',
                                    fieldGroup: [
                                      {
                                        key: 'Categoria type',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec',
                                        templateOptions: {
                                          key: 'Categoria type',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: 'Effettivo',
                                              label: 'Effettivo',
                                            },
                                            {
                                              value: 'Presunto',
                                              label: 'Presunto',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Tutela ai sensi del Decreto Legislativo 22 gennaio 2004 n42',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Tutela ai sensi del Decreto Legislativo 22 gennaio 2004, n. 42',
                    },
                  },
                  {
                    key: 'Provvedimenti di tutela',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Provvedimenti di tutela',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    key: 'Autore della progettazione',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Autore della progettazione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    key: 'Inserimento del ponte nell’ambito dei Piani Paesaggistici vigenti/adottati',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label:
                        'Inserimento del ponte nell’ambito dei Piani Paesaggistici vigenti/adottati',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                    },
                  },
                ],
              },
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: { title: 'Stato dell’opera' },
              },
              {
                key: 'Stato dell’opera',
                fieldGroup: [
                  {
                    key: 'Stato dell’opera',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Stato dell’opera',
                      flex: 'true',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      change: (field) => {
                        // Check if the 'IOP Request' field is false
                        if (
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ]['Request']['IOP Request']
                        ) {
                          // Set 'Stato dell'Opera' to empty if 'IOP Request' is false
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ]['Request']["Stato dell'Opera"] = '';

                          return;
                        }

                        // Map the selected value to the corresponding 'Stato dell'Opera' value
                        let updatedStatoDellOpera;
                        switch (field.formControl.value) {
                          case 'Pienamente agibile':
                            updatedStatoDellOpera = 'PIENAMENTE_AGIBILE';

                            break;

                          case 'Agibile ma con scadenze di lavori di manutenzione ordinaria':
                            updatedStatoDellOpera =
                              'AGIBILE_CON_SCADENZE_LAVORI_MANUT_ORD';

                            break;

                          case 'Agibile ma con scadenze di lavori di manutenzione straordinaria':
                            updatedStatoDellOpera =
                              'AGIBILE_CON_NECESSITA_MANUT_STRAORD';

                            break;

                          case 'Condizioni critiche e agibile parzialmente/ lavori di manutenzione urgenti':
                            updatedStatoDellOpera =
                              'CONDIZIONI_CRITICHE_LAVORI_MANUT_URGENTI';

                            break;

                          case 'Inagibile':
                            updatedStatoDellOpera = 'INAGIBILE';

                            break;

                          default:
                            updatedStatoDellOpera = '';
                        }

                        // Update the 'Stato dell'Opera' field with the selected value
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l0
                        ]['Request']["Stato dell'Opera"] =
                          updatedStatoDellOpera;
                      },
                      onReset: () => {
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l0
                        ]['Request']["Stato dell'Opera"] = '';
                        bridgeInputTemplateComponent.refreshFields();
                      },
                      options: [
                        {
                          value: 'Pienamente agibile',
                          label: 'A',
                          description: 'Pienamente agibile',
                        },
                        {
                          value:
                            'Agibile ma con scadenze di lavori di manutenzione ordinaria',
                          label: 'B ',
                          description:
                            'Agibile ma con scadenze di lavori di manutenzione ordinaria',
                        },
                        {
                          value:
                            'Agibile ma con scadenze di lavori di manutenzione straordinaria',
                          label: 'C',
                          description:
                            'Agibile ma con scadenze di lavori di manutenzione straordinaria',
                        },
                        {
                          value:
                            'Condizioni critiche e agibile parzialmente/ lavori di manutenzione urgenti',
                          label: 'D',
                          description:
                            'Condizioni critiche e agibile parzialmente/ lavori di manutenzione urgenti',
                        },
                        {
                          value: 'Inagibile',
                          label: 'E',
                          description: 'Inagibile',
                        },
                      ],
                    },
                  },
                ],
              },
              {
                key: 'Classificazione del collegamento e Classificazione d’uso stradale',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Classificazione del collegamento e Classificazione d’uso stradale',
                    },
                  },
                  {
                    key: 'Tipo di collegamento',
                    fieldGroup: [
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: { title: 'Tipo di collegamento' },
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Ponte su corso d’acqua',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom border-right width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Ponte su corso d’acqua',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Ponte su corso d’acqua',
                                },
                              ],
                            },
                          },
                          {
                            className: 'width-100 border-bottom flex-1',
                            fieldGroup: [
                              {
                                key: 'reticolo principale',
                                type: 'checkbox',
                                defaultValue: false,
                                className: 'radio-sec border-bottom ',
                                templateOptions: {
                                  key: 'reticolo principale',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'reticolo principale',
                                    },
                                  ],
                                },
                              },
                              {
                                key: 'reticolo secondario',
                                type: 'checkbox',
                                defaultValue: false,
                                className: 'radio-sec',
                                templateOptions: {
                                  key: 'reticolo secondario',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'reticolo secondario',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            key: 'Ponte su specchi d’acqua marini',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom border-left width-100 radio-sec flex-2',
                            templateOptions: {
                              key: 'Ponte su specchi d’acqua marini',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              name: 'gender',
                              options: [
                                {
                                  value: true,
                                  label: 'Ponte su specchi d’acqua marini',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Viadotto su zona edificata',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Viadotto su zona edificata',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              name: 'gender',
                              options: [
                                {
                                  value: true,
                                  label: 'Viadotto su zona edificata',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Viadotto su zona urbanizzata',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom border-left width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Viadotto su zona urbanizzata',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              name: 'gender',
                              options: [
                                {
                                  value: true,
                                  label: 'Viadotto su zona urbanizzata',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Viadotto su altra via di comunicazione',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Viadotto su altra via di comunicazione',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              name: 'gender',
                              options: [
                                {
                                  value: true,
                                  label:
                                    'Viadotto su altra via di comunicazione',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Ponte/Viadotto su discontinuità orografica',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom border-left width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Ponte/Viadotto su discontinuità orografica',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              name: 'gender',
                              options: [
                                {
                                  value: true,
                                  label:
                                    'Ponte/Viadotto su discontinuità orografica (vallata, piccoli canali, ecc.)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Ponte su ferrovia',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Ponte su ferrovia',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              name: 'gender',
                              options: [
                                { value: true, label: 'Ponte su ferrovia' },
                              ],
                            },
                          },
                          {
                            className: 'width-100 flex-1',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Classificazione d’uso stradale',
                    },
                  },
                  {
                    key: 'Classificazione d’uso stradale',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Autostrada o Ferrovia',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Autostrada o Ferrovia',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                { value: true, label: 'Autostrada o Ferrovia' },
                              ],
                            },
                          },
                          {
                            key: 'Strada extraurbana secondaria',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Strada extraurbana secondaria',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Strada extraurbana secondaria',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Strada urbana di quartiere',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-left border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Strada urbana di quartiere',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Strada urbana di quartiere',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Strada extraurbana principale',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Strada extraurbana principale',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Strada extraurbana principale',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Strada urbana di scorrimento',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Strada urbana di scorrimento',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Strada urbana di scorrimento',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Strada locale',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Strada locale',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                { value: true, label: 'Strada locale' },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Dati Geomorfologici',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Dati Geomorfologici',
                    },
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: { title: 'Morfologia del sito' },
                  },
                  {
                    key: 'Morfologia del sito',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Cresta',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Cresta',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              name: 'gender',
                              options: [{ value: true, label: 'Cresta' }],
                            },
                          },
                          {
                            key: 'Pendio dolce',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Pendio dolce',
                              name: 'gender',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Pendio dolce (0 - 10°)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Pendio moderato',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Pendio moderato',
                              name: 'gender',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Pendio moderato (10° - 25°)',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Pendio ripido',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Pendio ripido',
                              name: 'gender',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                { value: true, label: 'Pendio ripido (> 25°)' },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            key: 'Pianura',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Pianura',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              name: 'gender',
                              options: [{ value: true, label: 'Pianura' }],
                            },
                          },
                          {
                            key: 'Pianura alla base dei versanti',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Pianura alla base dei versanti',
                              name: 'gender',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Pianura alla base dei versanti',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Caratteristiche geometriche',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Caratteristiche geometriche',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className:
                          'border-right width-100 flex-1 padding-right-10',
                        fieldGroup: [
                          {
                            className: 'sub-sec',
                            type: 'input',
                            defaultValue: '',
                            key: 'Luce complessiva',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Luce complessiva (Estesa) [m]',
                            },
                          },
                          {
                            className: 'sub-sec',
                            type: 'input',
                            defaultValue: '',
                            key: 'Larghezza totale impalcato',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Larghezza totale impalcato [m]',
                            },
                          },
                        ],
                      },
                      {
                        className: 'width-100 flex-1 padding-left-10',
                        fieldGroup: [
                          {
                            className: 'sub-sec',
                            type: 'cube-number-type',
                            key: 'N° campate',
                            defaultValue: 0,
                            templateOptions: {
                              isInteger: true,
                              label: 'N° campate',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              required: true,
                              min: 1,
                              onBlur: (field, e) => {
                                if (field.formControl.invalid) return;

                                const campateNo = field.formControl.value;
                                // TODO add check if value has been changed

                                const campateList =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ]?.opereparzialilist?.[0]?.modelstructure
                                    ?.campatelist;

                                // If campates have already been added
                                if (campateList?.length) {
                                  const previousCampateNo =
                                    bridgeInputTemplateComponent.campateNoList
                                      .length;

                                  // If camapte no has been decreased
                                  if (campateNo < previousCampateNo) {
                                    // Finding highest campate number which has already been added
                                    const campataNosList = campateList.map(
                                      (campataListItem) =>
                                        campataListItem[BridgeConstants.partNo]
                                    );
                                    const highestCampataNo = Math.max(
                                      ...campataNosList
                                    );

                                    if (campateNo < highestCampataNo) {
                                      // If new camapte no is less than the highest campate number
                                      bridgeInputTemplateComponent.getConfirmation(
                                        {
                                          confirmButtonText: 'Ok',
                                          isCancelButtonVisible: false,
                                          message: `Details for campata no. ${highestCampataNo} have already been added. N° campate cannot be less than ${highestCampataNo}.`,
                                          title: 'Info',
                                        }
                                      );

                                      // Reverting the campate number to previous campate number
                                      field.formControl.setValue(
                                        previousCampateNo
                                      );

                                      return;
                                    }
                                  }
                                }

                                // Setting campate no list
                                bridgeInputTemplateComponent.campateNoList =
                                  Array(campateNo)
                                    .fill(0)
                                    .map((x, i) => ({
                                      value: i + 1,
                                      label: i + 1,
                                    }));

                                // Setting N campate to L1
                                const modelStructure =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ].opereparzialilist?.[0]?.modelstructure;
                                const basePath = modelStructure
                                  ? modelStructure
                                  : bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l1
                                    ];
                                basePath['Rilievo caratteristiche geometriche'][
                                  'N° campate'
                                ] = campateNo;

                                // Level 2 - setting campate list. TODO - risk status updates when campate list is updated
                                let level2List =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ][BridgeConstants.levels.level2].level2List;
                                if (campateNo > level2List[0]['N° campate']) {
                                  const existingCampateList =
                                    bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l0
                                    ][BridgeConstants.levels.level2]
                                      .level2List[0]?.modelstructure
                                      ?.campateCdaStatus?.campateList ?? [];
                                  const newCount =
                                    campateNo > level2List[0]['N° campate']
                                      ? campateNo - level2List[0]['N° campate']
                                      : level2List[0]['N° campate'] - campateNo;

                                  const newCampatelist = Array(newCount)
                                    .fill(0)
                                    .map((x, i) => ({
                                      [BridgeConstants.partNo]:
                                        existingCampateList.length + i + 1,
                                      [BridgeConstants.level2RiskSheets
                                        .strutturaleEFondazionale]: '',
                                      [BridgeConstants.level2RiskSheets
                                        .sismica]: '',
                                      [BridgeConstants.level2RiskSheets.frane]:
                                        '',
                                      [BridgeConstants.level2RiskSheets
                                        .idraulica]: '',
                                      CdA: '',
                                      modelstructure: {
                                        ...bridgeInputTemplateComponent.model[
                                          BridgeConstants.levels.level2Campata
                                        ],
                                        Campata: {
                                          [BridgeConstants.partNo]:
                                            existingCampateList.length + i + 1,
                                        },
                                      },
                                    }));
                                  level2List = [
                                    {
                                      'N° campate': campateNo,
                                      'Cda Status': CdAState.notCompleted,
                                      modelstructure: {
                                        campateCdaStatus: {
                                          'Data di ispezione': '',
                                          'Tecnico rilevatore': '',
                                          allCampataAreCalculated: false,
                                          rilevamento: '',
                                          "classeDiAttenzioneDell'Opera":
                                            CdAState.na,
                                          calculatedCdAValue: CdAState.na,
                                          campateList:
                                            existingCampateList.concat(
                                              newCampatelist
                                            ),
                                        },
                                      },
                                    },
                                  ];
                                } else {
                                  level2List[0]['N° campate'] = campateNo;
                                  level2List[0].modelstructure.campateCdaStatus.campateList =
                                    level2List[0].modelstructure.campateCdaStatus.campateList.splice(
                                      0,
                                      campateNo
                                    );
                                }
                                bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ][BridgeConstants.levels.level2].level2List =
                                  [].concat(level2List);
                                bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ][BridgeConstants.levels.level2].level2List =
                                  [].concat(level2List);
                                // TODO reset status if campate count has changed

                                bridgeInputTemplateComponent.syncSharedModelDataForList(
                                  'NCampate',
                                  e.target.value
                                );

                                bridgeInputTemplateComponent.resetAllCampataAttentionClass();
                              },
                            },
                          },
                          {
                            className: 'sub-sec ',
                            type: 'input',
                            defaultValue: '',
                            key: 'Luce campate',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Luce campate',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: { title: 'Tracciato' },
                      },
                      {
                        key: 'Tracciato',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-3',
                        templateOptions: {
                          key: 'Tracciato',
                          flex: true,
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            { value: 'Rettilineo', label: 'Rettilineo' },
                            {
                              value: 'In curva',
                              label: 'In curva',
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Tipologia strutturale',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Tipologia strutturale',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Arco in Muratura',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec border-bottom',
                            templateOptions: {
                              key: 'Arco in Muratura',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Arco in Muratura',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Arco in CA',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec border-bottom',
                            templateOptions: {
                              key: 'Arco in CA',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Arco in C.A.',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Strallato o sospeso',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec',
                            templateOptions: {
                              key: 'Strallato o sospeso',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Strallato o sospeso',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-2 border-right',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap ',
                            fieldGroup: [
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'Travate appoggiate',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom ',
                                    templateOptions: {
                                      key: 'Travate appoggiate',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Travate appoggiate',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    key: 'Travate continue',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom flex-1',
                                    templateOptions: {
                                      key: 'Travate continue',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Travate continue',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap ',
                            fieldGroup: [
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'Travate Gerber',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom ',
                                    templateOptions: {
                                      key: 'Travate Gerber',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Travate Gerber',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    key: 'Cassone in Precompresso',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom flex-1',
                                    templateOptions: {
                                      key: 'Cassone in Precompresso',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Cassone in Precompresso',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap padding-right-10',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    key: 'Altro',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'width-100 radio-sec ',
                                    templateOptions: {
                                      key: 'Altro',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Altro',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-6',
                                fieldGroup: [
                                  {
                                    className: 'sub-sec',
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Altro Input',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      label: 'Altro',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-2',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap ',
                            fieldGroup: [
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'Soletta in CA',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom ',
                                    templateOptions: {
                                      key: 'Soletta in CA',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Soletta in C.A.',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    key: 'Sezione tubolare in ca',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom flex-1',
                                    templateOptions: {
                                      key: 'Sezione tubolare in ca',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Sezione tubolare in c.a.',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap ',
                            fieldGroup: [
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'Sezione tubolare in acciaio',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom ',
                                    templateOptions: {
                                      key: 'Sezione tubolare in acciaio',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Sezione tubolare in acciaio',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    key: 'Arco in acciaio',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom flex-1',
                                    templateOptions: {
                                      key: 'Arco in acciaio',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Arco in acciaio',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            fieldGroup: [
                              {
                                fieldGroup: [
                                  {
                                    key: 'Travate in cap a cavi post-tesi',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec light-orange',
                                    templateOptions: {
                                      key: 'Travate in cap a cavi post-tesi',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Travate in c.a.p. a cavi post-tesi',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: BridgeConstants.defectElements.spalle,
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: BridgeConstants.defectElements.spalle,
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right padding-right-10',
                        fieldGroup: [
                          {
                            key: 'Tipologia spalla iniziale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Tipologia spalla iniziale',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            key: 'Fondazioni spalla iniziale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Fondazioni spalla iniziale',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        fieldGroup: [
                          {
                            key: 'Tipologia spalla finale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Tipologia spalla finale',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            key: 'Fondazioni spalla finale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Fondazioni spalla finale',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Pile - Materiale costruttivo',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Pile - Materiale costruttivo',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Muratura',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec border-bottom',
                            templateOptions: {
                              key: 'Muratura',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Muratura',
                                },
                              ],
                            },
                          },
                          ,
                          {
                            key: 'Legno',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec border-bottom',
                            templateOptions: {
                              key: 'Legno',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Legno',
                                },
                              ],
                            },
                          },
                          ,
                        ],
                      },
                      {
                        className: 'flex-3',
                        fieldGroup: [
                          {
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex-wrap ',
                                fieldGroup: [
                                  {
                                    className: 'flex-1 border-right',
                                    fieldGroup: [
                                      {
                                        key: 'Ca',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom ',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          key: 'Ca',
                                          options: [
                                            {
                                              value: true,
                                              label: 'C.a.',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1 border-right',
                                    fieldGroup: [
                                      {
                                        key: 'CAP',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom flex-1',
                                        templateOptions: {
                                          key: 'CAP.',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'C.A.P.',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1 border-right',
                                    fieldGroup: [
                                      {
                                        key: 'Acciaio',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom flex-1',
                                        templateOptions: {
                                          key: 'Acciaio',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'Acciaio',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'Misto Ca/acciaio',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom flex-1',
                                        templateOptions: {
                                          key: 'Misto Ca/acciaio',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'Misto (C.a./acciaio)',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    key: 'Altro',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'width-100 radio-sec ',
                                    templateOptions: {
                                      key: 'Altro',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Altro',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-6',
                                fieldGroup: [
                                  {
                                    className: 'sub-sec',
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Altro Input',
                                    templateOptions: {
                                      label: 'Altro',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: BridgeConstants.defectElements.pile,
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: BridgeConstants.defectElements.pile,
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Tipologia sezione',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Tipologia sezione',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            key: 'Tipologia fondazioni',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Tipologia fondazioni',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            key: 'Altezza pile m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Altezza pile [m]',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Geometria sezione',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label:
                                'Geometria sezione (circolare, rettangolare, etc.):',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            key: 'Numero fondazioni',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Numero fondazioni',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            key: 'Evoluzione eventuale rispetto al fondo alveo',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label:
                                'Evoluzione eventuale rispetto al fondo alveo',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Impalcato - Materiale costruttivo',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Impalcato - Materiale costruttivo',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Muratura',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec border-bottom',
                            templateOptions: {
                              key: 'Muratura',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Muratura',
                                },
                              ],
                            },
                          },
                          ,
                          {
                            key: 'Legno',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec border-bottom',
                            templateOptions: {
                              key: 'Legno',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Legno',
                                },
                              ],
                            },
                          },
                          ,
                        ],
                      },
                      {
                        className: 'flex-3',
                        fieldGroup: [
                          {
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex-wrap ',
                                fieldGroup: [
                                  {
                                    className: 'flex-1 border-right',
                                    fieldGroup: [
                                      {
                                        key: 'Ca',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom ',
                                        templateOptions: {
                                          key: 'Ca',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'C.a.',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1 border-right',
                                    fieldGroup: [
                                      {
                                        key: 'CAP',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom flex-1',
                                        templateOptions: {
                                          key: 'CAP',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'C.A.P.',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1 border-right',
                                    fieldGroup: [
                                      {
                                        key: 'Acciaio',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom flex-1',
                                        templateOptions: {
                                          key: 'Acciaio',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'Acciaio',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'Misto Ca/acciaio',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom flex-1',
                                        templateOptions: {
                                          key: 'Misto Ca/acciaio',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'Misto (C.a./acciaio)',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    key: 'Altro',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'width-100 radio-sec ',
                                    templateOptions: {
                                      key: 'Altro',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Altro',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-6',
                                fieldGroup: [
                                  {
                                    className: 'sub-sec',
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Altro Input',
                                    templateOptions: {
                                      label: 'Altro',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Impalcato - Tipologia soletta',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Impalcato - Tipologia Soletta',
                    },
                  },
                  {
                    fieldGroup: [
                      {
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap ',
                            fieldGroup: [
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'Ca',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom ',
                                    templateOptions: {
                                      key: 'Ca',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'C.a.',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'CAP',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom flex-1',
                                    templateOptions: {
                                      key: 'CAP',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'C.A.P.',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'Acciaio',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom flex-1',
                                    templateOptions: {
                                      key: 'Acciaio',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Acciaio',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    key: 'Misto Ca/acciaio',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom flex-1',
                                    templateOptions: {
                                      key: 'Misto Ca/acciaio',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Misto (C.a./acciaio)',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                key: 'Altro',
                                type: 'checkbox',
                                defaultValue: false,
                                className: 'width-100 radio-sec ',
                                templateOptions: {
                                  key: 'Altro',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'Altro',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-6',
                            fieldGroup: [
                              {
                                className: 'sub-sec',
                                type: 'input',
                                defaultValue: '',
                                key: 'Altro Input',
                                templateOptions: {
                                  label: 'Altro',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Sistemi di protezione ed apparecchi di appoggio',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Sistemi di protezione ed apparecchi di appoggio',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Tipo sistemi di protezione',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Tipo sistemi di protezione',
                            },
                          },
                          {
                            key: 'Tipologia apparecchi di appoggio',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Tipologia apparecchi di appoggio',
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Larghezza carreggiata m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Larghezza carreggiata [m]',
                            },
                          },
                          {
                            key: 'Tipologia dispositivi antisismici',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Tipologia dispositivi antisismici',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: BridgeConstants.defectElements.giunti,
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: BridgeConstants.defectElements.giunti,
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Tipologia giunti',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Tipologia giunti',
                            },
                          },
                          {
                            key: 'Lunghezza giunto spalla m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Lunghezza giunto spalla [m]',
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Numero totale giunti',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Numero totale giunti',
                            },
                          },
                          {
                            key: 'Lunghezza giunto pila m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Lunghezza giunto pila [m]',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Descrizione degli eventuali interventi strutturali eseguiti',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Descrizione degli eventuali interventi strutturali eseguiti',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    key: 'Volta in muratura',
                    fieldGroup: [
                      {
                        key: 'Volta in muratura',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          key: 'Volta in muratura',
                          options: [
                            {
                              value: true,
                              label: 'Volta in muratura',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Descrizione',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    key: 'Riparazione/Sostituzione di elementi strutturali',
                    fieldGroup: [
                      {
                        key: 'Riparazione/Sostituzione di elementi strutturali',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          key: 'Riparazione/Sostituzione di elementi strutturali',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            {
                              value: true,
                              label:
                                'Riparazione/Sostituzione di elementi strutturali',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom',
                        templateOptions: {
                          label: 'Descrizione',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    key: 'Ampliamento di carreggiata e delle strutture',
                    fieldGroup: [
                      {
                        key: 'Ampliamento di carreggiata e delle strutture',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          key: 'Riparazione/Sostituzione di elementi strutturali',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            {
                              value: true,
                              label:
                                'Ampliamento di carreggiata e delle strutture',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom',
                        templateOptions: {
                          label: 'Descrizione',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    key: 'Elementi strutturali aggiuntivi/consolidamento',
                    fieldGroup: [
                      {
                        key: 'Elementi strutturali aggiuntivi/consolidamento',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          key: 'Riparazione/Sostituzione di elementi strutturali',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            {
                              value: true,
                              label:
                                'Elementi strutturali aggiuntivi/consolidamento',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom',
                        templateOptions: {
                          label: 'Descrizione',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    key: 'Interventi di carattere geotecnico in fondazione e/o in corrispondenza delle spalle',
                    fieldGroup: [
                      {
                        key: 'Interventi di carattere geotecnico in fondazione e/o in corrispondenza delle spalle',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          key: 'Interventi di carattere geotecnico in fondazione e/o in corrispondenza delle spalle',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            {
                              value: true,
                              label:
                                'Interventi di carattere geotecnico in fondazione e/o in corrispondenza delle spalle',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom',
                        templateOptions: {
                          label: 'Descrizione',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    key: 'Interventi di mitigazione/protezione dall’erosione di spalle e pile',
                    fieldGroup: [
                      {
                        key: 'Interventi di mitigazione/protezione dall’erosione di spalle e pile',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          key: 'Interventi di mitigazione/protezione dall’erosione di spalle e pile',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            {
                              value: true,
                              label:
                                'Interventi di mitigazione/protezione dall’erosione di spalle e pile',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom',
                        templateOptions: {
                          label: 'Descrizione',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    key: 'Altro',
                    fieldGroup: [
                      {
                        key: 'Altro',
                        type: 'checkbox',
                        defaultValue: false,
                        className: 'width-100 radio-sec flex-1',
                        templateOptions: {
                          key: 'Altro',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            {
                              value: true,
                              label: 'Altro',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Altro text',
                        type: 'input',
                        defaultValue: '',
                        className: 'flex-6',
                        templateOptions: {
                          label: 'Altro',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                ],
              },
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: { title: 'Interventi di manutenzione' },
              },
              {
                key: 'Interventi di manutenzione',
                fieldGroup: [
                  {
                    key: 'Interventi di manutenzione',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Interventi di manutenzione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      flex: 'true',
                      options: [
                        {
                          value: 'Presenti',
                          label: 'Presenti',
                        },
                        {
                          value: 'Assenti',
                          label: 'Assenti',
                        },
                        {
                          value: 'Non noti',
                          label: 'Non noti',
                        },
                      ],
                    },
                  },
                  {
                    fieldGroupClassName: 'grid-gap',
                    fieldGroup: [
                      {
                        key: 'Piano di manutenzione',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Piano di manutenzione',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Numero di interventi manutentivi effettuati',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Numero di interventi manutentivi effettuati',
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Data ultimo intervento',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Data ultimo intervento',
                          max: new Date(),
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Periodicità se più di 1 intervento',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Periodicità (se più di 1 intervento)',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                wrappers: ['cube-repeat-section-wrapper'],
                templateOptions: {
                  minWidth: '1080px',
                },
                fieldGroup: [
                  {
                    fieldGroupClassName: 'display-flex-wrap repeat-header',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'n° intervento',
                          class: 'text-align-center light-grey',
                        },
                      },
                      {
                        className: 'flex-1 border-right',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Tipo di manutenzione',
                          class: 'text-align-center light-grey',
                        },
                      },
                      {
                        className: 'flex-1 border-right',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Documentazione relativa',
                          class: 'text-align-center light-grey',
                        },
                      },
                    ],
                  },
                  {
                    key: 'Tipo di manutenzione section',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      onDeleteRow: (index) => {
                        bridgeInputTemplateComponent.deleteUploadedFiles(
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ]['Tipo di manutenzione section'][index]['Allegato n']
                        );
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l0
                        ]['Tipo di manutenzione section'].splice(index, 1);
                        bridgeInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex-wrap border-bottom',
                      fieldGroup: [
                        {
                          className: 'flex-2 border-right',
                          key: 'Intervento n',
                          fieldGroupClassName: 'display-flex-wrap',
                          fieldGroup: [
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'Intervento n Data',
                              type: 'cube-datepicker-type',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                max: new Date(),
                              },
                              expressionProperties: {
                                'templateOptions.label':
                                  "'Intervento n.' + (+field.parent.parent.key + 1)+ ' (Data)'",
                              },
                            },
                            {
                              className: 'flex-1 repeat-cell remove-underline',
                              key: 'Intervento n Data text',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                        {
                          className:
                            'width-100 flex-1 border-right repeat-cell remove-underline',
                          key: 'Allegato n',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            disabled:
                              bridgeInputTemplateComponent.isFieldDisabled(
                                [UserRole.hydraulicTechnician],
                                isDisabled,
                                true
                              ),
                            token: bridgeInputTemplateComponent.token,
                            modelData: bridgeInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'Allegato n.' + (+field.parent.key + 1)+ '  (Data)'",
                          },
                        },
                      ],
                    },
                  },
                ],
              },
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: { title: 'Ispezioni pregresse' },
              },
              {
                key: 'Ispezioni pregresse',
                fieldGroup: [
                  {
                    key: 'Ispezioni pregresse',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Ispezioni pregresse',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      flex: 'true',
                      options: [
                        {
                          value: 'Presenti',
                          label: 'Presenti',
                        },
                        {
                          value: 'Assenti',
                          label: 'Assenti',
                        },
                        {
                          value: 'Non note',
                          label: 'Non note',
                        },
                      ],
                    },
                  },
                  {
                    fieldGroupClassName: 'grid-gap',
                    fieldGroup: [
                      {
                        key: 'Programma di ispezioni',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Programma di ispezioni',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Numero di ispezioni effettuate',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Numero di ispezioni effettuate',
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Data ultima ispezione',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Data ultima ispezione',
                          max: new Date(),
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Periodicità se più di 1 intervento',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Periodicità (se più di 1 intervento)',
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-repeat-section-wrapper'],
                    templateOptions: {
                      minWidth: '1080px',
                    },
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap repeat-header',
                        fieldGroup: [
                          {
                            className: 'flex-2 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Metodologia ispezioni',
                              class: 'text-align-center light-grey',
                            },
                          },
                          {
                            className: 'flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documentazione relativa',
                              class: 'text-align-center light-grey',
                            },
                          },
                          {
                            className: 'flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Ente ispettivo',
                              class: 'text-align-center light-grey',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Metodologia ispezioni section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onDeleteRow: (index) => {
                            bridgeInputTemplateComponent.deleteUploadedFiles(
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ]['Ispezioni pregresse'][
                                'Metodologia ispezioni section'
                              ][index]['Allegato n']
                            );
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l0
                            ]['Ispezioni pregresse'][
                              'Metodologia ispezioni section'
                            ].splice(index, 1);
                            bridgeInputTemplateComponent.refreshFields();
                          },
                        },
                        fieldArray: {
                          fieldGroupClassName: 'display-flex-wrap',
                          fieldGroup: [
                            {
                              className: 'flex-2 border-right',
                              key: 'Ispezione n',
                              fieldGroupClassName: 'display-flex-wrap grid-gap',
                              fieldGroup: [
                                {
                                  className:
                                    'flex-1 repeat-cell remove-underline border-bottom',
                                  key: 'Ispezione n Data',
                                  type: 'cube-datepicker-type',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    max: new Date(),
                                  },
                                  expressionProperties: {
                                    'templateOptions.label':
                                      "'Ispezione n.' + (+field.parent.parent.key + 1)+ ' (Data)'",
                                  },
                                },
                                {
                                  className:
                                    'flex-1 repeat-cell remove-underline border-bottom',
                                  key: 'Ispezione n Data text',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              className:
                                'width-100 flex-1 border-right repeat-cell remove-underline border-bottom',
                              key: 'Allegato n',
                              type: 'cube-file-upload-type',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                token: bridgeInputTemplateComponent.token,
                                modelData: bridgeInputTemplateComponent.model,
                              },
                              expressionProperties: {
                                'templateOptions.label':
                                  "'Allegato n.' + (+field.parent.key + 1)+ ' (Data)'",
                              },
                            },
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline border-bottom',
                              key: 'Ente ispettivo',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  { template: `<hr>` },
                  {
                    key: 'Risultati significativi',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Risultati significativi',
                    },
                  },
                ],
              },
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: {
                  title: 'Attività di monitoraggio pregresse o in corso',
                },
              },
              {
                key: 'Attività di monitoraggio pregresse o in corso',
                fieldGroup: [
                  {
                    key: 'Attività di monitoraggio pregresse o in corso',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Attività di monitoraggio pregresse o in corso',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      flex: 'true',
                      options: [
                        {
                          value: 'Presenti',
                          label: 'Presenti',
                        },
                        {
                          value: 'Assenti',
                          label: 'Assenti',
                        },
                        {
                          value: 'Non note',
                          label: 'Non note',
                        },
                      ],
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Tipo rilevamento',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Tipo rilevamento',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            key: 'Metodologia monitoraggio',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Metodologia monitoraggio',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            key: 'text',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Data inizio',
                            type: 'cube-datepicker-type',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Data inizio',
                              max: new Date(),
                            },
                          },
                          {
                            key: 'Data ultimo aggiornamento',
                            type: 'cube-datepicker-type',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Data ultimo aggiornamento',
                              max: new Date(),
                            },
                          },
                          {
                            key: 'Data fine',
                            type: 'cube-datepicker-type',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Data fine',
                              max: new Date(),
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Tipologia strumentazione',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Tipologia strumentazione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    key: 'Grandezze misurate',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Grandezze misurate',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  { template: `<hr>` },
                  {
                    key: 'Risultati significativi',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Risultati significativi',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  { template: `<hr>` },
                  {
                    key: 'Livello Allerta',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Livello Allerta',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Documentazione relativa',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Documentazione relativa',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                      {
                        className: 'width-100 flex-1 input-line',
                        key: 'Allegato n',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Allegato n',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          token: bridgeInputTemplateComponent.token,
                          modelData: bridgeInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: {
                  title: 'Rete stradale',
                },
              },
              {
                key: 'Rete stradale',
                fieldGroup: [
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            fieldGroupClassName:
                              'display-flex-wrap border-bottom height-set',
                            fieldGroup: [
                              {
                                className: 'width-100 flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Itinerario Internazionale',
                                },
                              },
                              {
                                key: 'Itinerario Internazionale',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-3',
                                templateOptions: {
                                  key: 'Itinerario Internazionale',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap border-bottom height-set',
                            fieldGroup: [
                              {
                                className: 'width-100 flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Rete emergenza',
                                },
                              },
                              {
                                key: 'Rete emergenza',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-3',
                                templateOptions: {
                                  key: 'Rete emergenza',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap border-bottom height-set',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'N° di carreggiate',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'N° di carreggiate',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap border-bottom height-set',
                            fieldGroup: [
                              {
                                className: 'width-100 flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Presenza di curve',
                                },
                              },
                              {
                                key: 'Presenza di curve',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-3',
                                templateOptions: {
                                  key: 'Presenza di curve',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName:
                              'display-flex-wrap border-bottom height-set',
                            fieldGroup: [
                              {
                                className: 'width-100 flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Rete TEN',
                                },
                              },
                              {
                                key: 'Rete TEN',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-3',
                                templateOptions: {
                                  key: 'Rete TEN',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap border-bottom height-set',
                            fieldGroup: [],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap border-bottom height-set',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'N° corsie carreggiata',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'N° corsie/carreggiata',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap border-bottom height-set',
                            fieldGroup: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-bottom',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Traffico Medio Giornaliero',
                        },
                      },
                      {
                        className: 'width-100 flex-2 border-left',
                        key: 'Traffico Medio Giornaliero',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'veicoli/giorno (sull’intera carreggiata)',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-bottom',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Traffico Medio Giornaliero - Veicoli commerciali*',
                        },
                      },
                      {
                        className: 'flex-2 border-left',
                        key: 'Traffico Medio Giornaliero - Veicoli commerciali',
                        fieldGroup: [
                          {
                            className: 'width-100',
                            key: 'veicoli/giorno sull’intera carreggiata',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'veicoli/giorno (sull’intera carreggiata)',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            className: 'width-100',
                            key: 'veicoli/giorno per singola corsia di marcia',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label:
                                'veicoli/giorno (per singola corsia di marcia)',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<div>*veicoli la cui sagoma corrisponde a tipologie con portata superiore a 3,5 t</div>`,
                  },
                  {
                    fieldGroupClassName:
                      'display-flex-wrap border-bottom border-top',
                    key: 'Limitazione di carico',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Limitazione di carico',
                        },
                      },
                      {
                        key: 'Limitazione di carico',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-2',
                        templateOptions: {
                          key: 'Limitazione di carico',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          flex: true,
                          options: [
                            {
                              value: 'Nessuna limitazione presente',
                              label: 'Nessuna limitazione presente',
                            },
                            {
                              value: 'Massima massa consentita',
                              label: 'Massima massa consentita',
                            },
                          ],
                          change: (field) => {
                            if (
                              field.formControl.value !==
                              'Massima massa consentita'
                            ) {
                              field.formControl.parent
                                ?.get(['ton'])
                                ?.setValue(null);
                              field.formControl.parent?.get(['ton'])?.disable();
                            } else {
                              field.formControl.parent?.get(['ton'])?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (
                              field.formControl.value !==
                              'Massima massa consentita'
                            ) {
                              field.formControl.parent
                                ?.get(['ton'])
                                ?.setValue(null);
                              field.formControl.parent?.get(['ton'])?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (
                                field.formControl.value !==
                                'Massima massa consentita'
                              ) {
                                field.formControl.parent
                                  ?.get(['ton'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['ton'])
                                  ?.disable();
                              } else {
                                const isFieldDisabled =
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  );
                                if (!isFieldDisabled) {
                                  field.formControl.parent
                                    ?.get(['ton'])
                                    ?.enable();
                                }
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        className: 'width-100 flex-1',
                        key: 'ton',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'ton',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-bottom',
                    key: 'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Presenza di alternative stradali (in caso di chiusure/limitazioni di traffico)',
                        },
                      },
                      {
                        key: 'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-2',
                        templateOptions: {
                          key: 'Presenza di alternative stradali in caso di chiusure/limitazioni di traffico',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          flex: true,
                          options: [
                            {
                              value: 'SI',
                              label: 'SÌ',
                            },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelDataForList(
                              'alternativeStradali',
                              field.formControl.value
                            );
                            bridgeInputTemplateComponent.resetAllCampataAttentionClass();
                          },
                          onReset: (field) => {
                            bridgeInputTemplateComponent.syncSharedModelDataForList(
                              'alternativeStradali',
                              field.formControl.value
                            );
                            bridgeInputTemplateComponent.resetAllCampataAttentionClass();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-bottom',
                    key: 'Durata deviazione',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-2 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Durata deviazione',
                        },
                      },
                      {
                        className: 'width-100 flex-1 border-left',
                        key: 'Km',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Km',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                      {
                        className: 'width-100 flex-1 border-left',
                        key: 'Minuti',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Minuti',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    className: 'width-100',
                    key: 'Categoria del percorso alternativo individuato',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Categoria del percorso alternativo individuato',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-bottom',
                    key: 'Disponibilità di studi trasportistici specifici',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-2 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Disponibilità di studi trasportistici specifici',
                        },
                      },
                      {
                        key: 'Disponibilità di studi trasportistici specifici',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-2',
                        templateOptions: {
                          key: 'Disponibilità di studi trasportistici specifici',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          flex: true,
                          options: [
                            {
                              value: 'SÌ',
                              label: 'SÌ',
                            },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== 'SÌ') {
                              bridgeInputTemplateComponent.deleteUploadedFiles(
                                field.formControl.parent?.get(['Allegato n'])
                                  ?.value
                              );
                              field.formControl.parent
                                ?.get(['Allegato n'])
                                ?.setValue('');
                              field.formControl.parent
                                ?.get(['Allegato n'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Allegato n'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== 'SÌ') {
                              bridgeInputTemplateComponent.deleteUploadedFiles(
                                field.formControl.parent?.get(['Allegato n'])
                                  ?.value
                              );
                              field.formControl.parent
                                ?.get(['Allegato n'])
                                ?.setValue('');
                              field.formControl.parent
                                ?.get(['Allegato n'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Allegato n'])
                                ?.enable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== 'SÌ') {
                                field.formControl.parent
                                  ?.get(['Allegato n'])
                                  ?.setValue('');
                                field.formControl.parent
                                  ?.get(['Allegato n'])
                                  ?.disable();
                              } else {
                                const isFieldDisabled =
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  );
                                if (!isFieldDisabled) {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        className: 'width-100 flex-1 input-line',
                        key: 'Allegato n',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Allegato n',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          token: bridgeInputTemplateComponent.token,
                          modelData: bridgeInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Classi di conseguenza (secondo definizione EN 1990:2002)',
                    },
                  },
                  {
                    key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                    fieldGroup: [
                      {
                        key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                        className: 'remove-underline',
                        type: 'radio',
                        defaultValue: '',
                        templateOptions: {
                          key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            {
                              label:
                                'CC3 (Elevate conseguenze per perdita di vite umane, o con conseguenze molto gravi in termini economici, sociali o ambientali)',
                              value: 'CC3',
                            },
                          ],
                          change: (field, e) => {
                            if (field.formControl.value !== 'Minore di CC3') {
                              field.formControl.parent
                                ?.get(['Minore di CC3'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Minore di CC3'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Minore di CC3'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            field.formControl.parent
                              ?.get(['Minore di CC3'])
                              ?.disable();
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== 'Minore di CC3') {
                                field.formControl.parent
                                  ?.get(['Minore di CC3'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Minore di CC3'])
                                  ?.disable();
                              } else {
                                const isFieldDisabled =
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  );
                                if (!isFieldDisabled) {
                                  field.formControl.parent
                                    ?.get(['Minore di CC3'])
                                    ?.enable();
                                }
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                            className: 'remove-underline',
                            type: 'radio',
                            defaultValue: '',
                            templateOptions: {
                              key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  label: 'Minore di CC3',
                                  value: 'Minore di CC3',
                                },
                              ],
                              change: (field, e) => {
                                if (
                                  field.formControl.value !== 'Minore di CC3'
                                ) {
                                  field.formControl.parent
                                    ?.get(['Minore di CC3'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Minore di CC3'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Minore di CC3'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                field.formControl.parent
                                  ?.get(['Minore di CC3'])
                                  ?.disable();
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.value !== 'Minore di CC3'
                                  ) {
                                    field.formControl.parent
                                      ?.get(['Minore di CC3'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Minore di CC3'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl.parent
                                        ?.get(['Minore di CC3'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            key: 'Minore di CC3',
                            type: 'radio',
                            className: 'width-100 sub-sec radio-sec',
                            templateOptions: {
                              key: 'Minore di CC3',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  value:
                                    'CC2 (Conseguenze medie per perdita di vite umane, o con conseguenze considerevoli in termini economici, sociali o ambientali considerevoli)',
                                  label:
                                    'CC2 (Conseguenze medie per perdita di vite umane, o con conseguenze considerevoli in termini economici, sociali o ambientali considerevoli)',
                                },
                                {
                                  value:
                                    'CC1 (Conseguenze basse per perdita di vite umane, o con conseguenze modeste o trascurabili in termini economici, sociali o ambientali)',
                                  label:
                                    'CC1 (Conseguenze basse per perdita di vite umane, o con conseguenze modeste o trascurabili in termini economici, sociali o ambientali)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className: 'width-100 flex-1',
                        key: 'In caso di classe di conseguenza minore di CC3, fornire adeguata giustificazione',
                        type: 'textarea',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label:
                            'In caso di classe di conseguenza minore di CC3, fornire adeguata giustificazione:',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Documenti progettuali disponibili',
                fieldGroup: [
                  {
                    key: 'Documentazione iniziale',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Documenti progettuali disponibili',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documentazione iniziale',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Strumenti del finanziamento',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Strumenti del finanziamento',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Strumenti del finanziamento',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Strumenti del finanziamento',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Piani e strumento di programmazione',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Piani e strumento di programmazione',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Piani e strumento di programmazione',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Piani e strumento di programmazione',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Progetto preliminare/di massima',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Progetto preliminare/di massima',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti descrittivi',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti descrittivi',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti descrittivi',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti descrittivi',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti grafici',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti grafici',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti grafici',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti grafici',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti inerenti l’iter di approvazione',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Documenti inerenti l’iter di approvazione',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Progetto definitivo/esecutivo',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Progetto definitivo/esecutivo',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti descrittivi',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti descrittivi',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti descrittivi 2',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti descrittivi 2',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti grafici',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti grafici',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti grafici 2',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti grafici 2',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti inerenti l’iter di approvazione',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Documenti inerenti l’iter di approvazione',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione 2',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione 2',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Progetto esecutivo/cantierabile',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Progetto esecutivo/cantierabile',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti descrittivi',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti descrittivi',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti descrittivi 3',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti descrittivi 3',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti grafici',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti grafici',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti grafici 3',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti grafici 3',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti inerenti l’iter di approvazione',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Documenti inerenti l’iter di approvazione',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione 3',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione 3',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Documenti inerenti la direzione dei lavori',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Documenti inerenti la direzione dei lavori',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className: 'width-100 flex-3 text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti contrattuali',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti contrattuali',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti contrattuali',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti contrattuali',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Grafici allegati alla contabilità dell’appalto',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Grafici allegati alla contabilità dell’appalto',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Grafici allegati alla contabilità dell’appalto',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Grafici allegati alla contabilità dell’appalto',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Documenti inerenti la realizzazione',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti inerenti la realizzazione',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className: 'width-100 flex-3 text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti contrattuali',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti contrattuali',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti contrattuali 2',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti contrattuali 2',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti contabili',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti contabili',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti contabili',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Documenti contabili',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Varianti in corso d’opera',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Varianti in corso d’opera',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Varianti',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Variante',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Variante ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi Variante',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici Variante',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti contrattuali e contabili Variante',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title:
                                          'Documenti contrattuali e contabili',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti contrattuali e contabili Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti contrattuali e contabili Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Documenti inerenti il collaudo',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti inerenti il collaudo',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Relazione di collaudo',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Relazione di collaudo',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Relazione di collaudo',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Relazione di collaudo',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Allegati alla relazione di collaudo',
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegati alla relazione di collaudo',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment  padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl
                                        .get(['Fonte'])
                                        ?.enable();
                                      field.formControl
                                        .get(['Allegato n'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Allegati alla relazione di collaudo',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  token: bridgeInputTemplateComponent.token,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Allegati alla relazione di collaudo',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      bridgeInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  multiple: true,
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Interventi di manutenzione',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Interventi di manutenzione',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Manutenzione',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Manutenzione',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Manutenzione ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi Manutenzione',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici Manutenzione',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti contrattuali e contabili Manutenzione',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title:
                                          'Documenti contrattuali e contabili',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti contrattuali e contabili Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti contrattuali e contabili Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Segnalazioni',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Segnalazioni',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Segnalazione',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Segnalazione',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Segnalazione ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi con date Segnalazione',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi con date',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi con date Segnalazione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi con date Segnalazione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici con date Segnalazione',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici con date',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici con date Segnalazione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici con date Segnalazione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Progetto di incremento del grado di sicurezza',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Progetto di incremento del grado di sicurezza',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className: 'width-100 flex-3 text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Progetto',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Progetto',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Progetto ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi Progetto',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Progetto ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Progetto ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici Progetto',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Progetto ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Progetto ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Interventi di incremento del grado di sicurezza',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Interventi di incremento del grado di sicurezza',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className: 'width-100 flex-3 text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Intervento',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Intervento',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Intervento ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi Intervento',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Intervento ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Intervento ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici Intervento',
                                  fieldGroupClassName:
                                    'display-flex-wrap border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment  padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              const isFieldDisabled =
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                );
                                              if (!isFieldDisabled) {
                                                field.formControl
                                                  .get(['Fonte'])
                                                  ?.enable();
                                                field.formControl
                                                  .get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Intervento ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          defaultValue: '',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Intervento ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                bridgeInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                [UserRole.hydraulicTechnician],
                                                isDisabled,
                                                true
                                              ),
                                            multiple: true,
                                            token:
                                              bridgeInputTemplateComponent.token,
                                            modelData:
                                              bridgeInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Documenti disponibili inerenti alle condizioni di rischio idrogeologico',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Documenti disponibili inerenti alle condizioni di rischio idrogeologico',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Rischio idraulico',
                        },
                      },
                      {
                        key: 'Rischio idraulico',
                        type: 'input',
                        defaultValue: '',
                        className: 'flex-3 border-bottom',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Rischio frane',
                        },
                      },
                      {
                        key: 'Rischio frane',
                        type: 'input',
                        defaultValue: '',
                        className: 'flex-3 border-bottom',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    key: 'Altre documentazioni es zonazioni da Enti locali',
                    type: 'textarea',
                    defaultValue: '',
                    className: 'width-100',
                    templateOptions: {
                      label:
                        'Altra documentazione (ad es. suddivisione in zone da parte delle autorità locali)',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'page-heading',
                        fieldGroup: [
                          {
                            className: 'width-100',
                            template: `<div class="page-heading-text">Schede di ispezione speciale - Ponti in c.a.p. a cavi post-tesi</div>`,
                          },
                          {
                            className: 'page-heading-image',
                            template: `<img src="assets/rina/RINA-dark.png"/>`,
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Codice IOP',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Codice IOP',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              change: (field, e) => {
                                bridgeInputTemplateComponent.syncSharedModelData(
                                  'codiceIop',
                                  e.target.value
                                );
                                bridgeInputTemplateComponent.syncSharedModelDataForList(
                                  'codiceIop',
                                  e.target.value
                                );
                              },
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Nome Ponte Viadotto',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Nome Ponte/Viadotto',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            key: 'Strada di appartenenza',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Strada di appartenenza',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Progressiva km iniziale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Progressiva km iniziale',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Progressiva km finale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Progressiva km finale',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Tracciato dei cavi e localizzazione dei difetti',
                        },
                      },
                      {
                        key: 'Tracciato dei cavi e localizzazione dei difetti',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap ',
                            fieldGroup: [
                              {
                                className: 'width-100 flex-4 margin-auto',
                                template: `<div>Tracciato dei cavi reperibile da documenti di progetto originale</div>`,
                              },
                              {
                                key: 'Tracciato dei cavi reperibile da documenti di progetto originale',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-2 ',
                                templateOptions: {
                                  key: 'Tracciato dei cavi reperibile da documenti di progetto originale',
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap grid-gap',
                            fieldGroup: [
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .pdf,
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                              {
                                className: 'flex-1',
                                template: `<div class="input-text-align">( se SI, indicare i documenti di riferimento da allegare alla presente scheda)</div>`,
                              },
                            ],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap margin-bottom-30',
                            fieldGroup: [
                              {
                                className:
                                  'flex-1 border-bottom border-right border-top height text-box content-center',
                                fieldGroup: [
                                  {
                                    template: `<div>
                                          <div>Schema da progetto del tracciato dei cavi</div>
                                          <div>(tracciare uno schema per ogni tipologia di elemento strutturale)</div>
                                      </div>`,
                                  },
                                ],
                              },
                              {
                                className:
                                  'flex-2 border-bottom border-top height-auto',
                                fieldGroup: [
                                  {
                                    key: 'Schema da progetto del tracciato dei cavi',
                                    type: 'cube-file-upload-with-preview-type',
                                    defaultValue: '',
                                    className:
                                      'width-100 flex-1 input-line remove-underline',
                                    templateOptions: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      modelData:
                                        bridgeInputTemplateComponent.model,
                                      token: bridgeInputTemplateComponent.token,
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            key: 'Indagini non distruttive/semi-distruttive',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex margin-bottom-30',
                                fieldGroup: [
                                  {
                                    className: 'flex-2 border-right',
                                    key: 'Indagini non distruttive/semi-distruttive ',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'Indagini non distruttive/semi-distruttive ',
                                          class: 'light-orange-background',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap',
                                        fieldGroup: [
                                          {
                                            className:
                                              'flex-1  border-right border-top  text-box content-center ',
                                            fieldGroup: [
                                              {
                                                template: `<div>
                                                      <div class="font-weight-bolder">Tipologie di indagini </div>
                                                      <div>(indagine pacometriche, georadar, tomografie ultrasoniche, Impact-Echo, metodi MFL, misure di ponteziale di corrosione)</div>
                                                  </div>`,
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-2  border-top',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1 ',
                                                key: 'Tipologie di indagini',
                                                fieldGroup: [
                                                  {
                                                    fieldGroupClassName:
                                                      'display-flex-wrap grid-gap',
                                                    fieldGroup: [
                                                      {
                                                        template: `<div class="input-text-align">1)</div>`,
                                                      },
                                                      {
                                                        className: 'flex-1',
                                                        key: 'Tipologie di indagini 1',
                                                        type: 'input',
                                                        defaultValue: '',
                                                        templateOptions: {
                                                          disabled:
                                                            bridgeInputTemplateComponent.isFieldDisabled(
                                                              [
                                                                UserRole.hydraulicTechnician,
                                                              ],
                                                              isDisabled,
                                                              true
                                                            ),
                                                        },
                                                      },
                                                    ],
                                                  },
                                                  {
                                                    fieldGroupClassName:
                                                      'display-flex-wrap grid-gap',
                                                    fieldGroup: [
                                                      {
                                                        template: `<div class="input-text-align">2)</div>`,
                                                      },
                                                      {
                                                        className: 'flex-1',
                                                        key: 'Tipologie di indagini 2',
                                                        type: 'input',
                                                        defaultValue: '',
                                                        templateOptions: {
                                                          disabled:
                                                            bridgeInputTemplateComponent.isFieldDisabled(
                                                              [
                                                                UserRole.hydraulicTechnician,
                                                              ],
                                                              isDisabled,
                                                              true
                                                            ),
                                                        },
                                                      },
                                                    ],
                                                  },
                                                  {
                                                    fieldGroupClassName:
                                                      'display-flex-wrap grid-gap',
                                                    fieldGroup: [
                                                      {
                                                        template: `<div class="input-text-align">3)</div>`,
                                                      },
                                                      {
                                                        className: 'flex-1',
                                                        key: 'Tipologie di indagini 3',
                                                        type: 'input',
                                                        defaultValue: '',
                                                        templateOptions: {
                                                          disabled:
                                                            bridgeInputTemplateComponent.isFieldDisabled(
                                                              [
                                                                UserRole.hydraulicTechnician,
                                                              ],
                                                              isDisabled,
                                                              true
                                                            ),
                                                        },
                                                      },
                                                    ],
                                                  },
                                                  {
                                                    fieldGroupClassName:
                                                      'display-flex-wrap grid-gap',
                                                    fieldGroup: [
                                                      {
                                                        template: `<div class="input-text-align">4)</div>`,
                                                      },
                                                      {
                                                        className: 'flex-1',
                                                        key: 'Tipologie di indagini 4',
                                                        type: 'input',
                                                        defaultValue: '',
                                                        templateOptions: {
                                                          disabled:
                                                            bridgeInputTemplateComponent.isFieldDisabled(
                                                              [
                                                                UserRole.hydraulicTechnician,
                                                              ],
                                                              isDisabled,
                                                              true
                                                            ),
                                                        },
                                                      },
                                                    ],
                                                  },
                                                  {
                                                    fieldGroupClassName:
                                                      'display-flex-wrap grid-gap',
                                                    fieldGroup: [
                                                      {
                                                        template: `<div class="input-text-align">5)</div>`,
                                                      },
                                                      {
                                                        className: 'flex-1',
                                                        key: 'Tipologie di indagini 5',
                                                        type: 'input',
                                                        defaultValue: '',
                                                        templateOptions: {
                                                          disabled:
                                                            bridgeInputTemplateComponent.isFieldDisabled(
                                                              [
                                                                UserRole.hydraulicTechnician,
                                                              ],
                                                              isDisabled,
                                                              true
                                                            ),
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Elementi indagati tipologia e numero',
                                    fieldGroupClassName: 'display-flex',
                                    className: 'flex-1',
                                    fieldGroup: [
                                      {
                                        className: 'flex-1 border-right ',
                                        key: 'Elementi indagati tipologia e numero',
                                        fieldGroup: [
                                          {
                                            wrappers: [
                                              'cube-sub-title-wrapper',
                                            ],
                                            templateOptions: {
                                              title:
                                                'Elementi indagati (tipologia e numero)',
                                              class:
                                                'text-align-center light-grey',
                                              isHeight70: true,
                                            },
                                          },
                                          {
                                            key: 'Elementi indagati tipologia e numero 1',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Elementi indagati tipologia e numero 2',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Elementi indagati tipologia e numero 3',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Elementi indagati tipologia e numero 4',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Elementi indagati tipologia e numero 5',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        className: 'flex-1',
                                        key: 'N° totale di indagini eseguite',
                                        fieldGroup: [
                                          {
                                            wrappers: [
                                              'cube-sub-title-wrapper',
                                            ],
                                            templateOptions: {
                                              title:
                                                'N° totale di indagini eseguite',
                                              class:
                                                'text-align-center light-grey',
                                              isHeight70: true,
                                            },
                                          },
                                          {
                                            key: 'N° totale di indagini eseguite 1',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'N° totale di indagini eseguite 2',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'N° totale di indagini eseguite 3',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'N° totale di indagini eseguite 4',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'N° totale di indagini eseguite 5',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            template: `<div class=" border-bottom text-align-left light-grey-background">
                                  <span class="font-weight-bolder">Risultati delle indagini </span>
                                  <span>(compilare una tabella per ogni elemento indagato e tipologia di indagine)</span>
                              </div>`,
                          },
                          {
                            key: 'Risultati delle indagini',
                            fieldGroup: [
                              {
                                key: 'Tracciato dei cavi',
                                fieldGroupClassName: 'display-flex-wrap ',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right border-top height-auto content-center',
                                    fieldGroup: [
                                      {
                                        template: `<div class="font-weight-bolder">Tracciato dei cavi</div><span>Indicare la localizzazione delle indagini </span>  `,
                                      },
                                    ],
                                  },
                                  {
                                    className:
                                      'flex-3 border-bottom border-top height-auto',
                                    fieldGroup: [
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap border-bottom',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Tipologia elemento indagato',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              label:
                                                'Tipologia elemento indagato',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Tipologia di indagine',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Tipologia di indagine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'Schema del tracciato dei cavi:',
                                          class: 'padding-10',
                                        },
                                      },
                                      {
                                        className:
                                          'border-bottom width-100 remove-underline upload-section',
                                        key: 'Schema del tracciato dei cavi',
                                        type: 'cube-file-upload-with-preview-type',
                                        defaultValue: '',
                                        templateOptions: {
                                          acceptedFileTypes:
                                            environment.file_upload
                                              .accepted_file_types.images,
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          modelData:
                                            bridgeInputTemplateComponent.model,
                                          token:
                                            bridgeInputTemplateComponent.token,
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                key: 'Rispondenza con il materiale di progetto originale',
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    wrappers: ['cube-sub-title-wrapper'],
                                    templateOptions: {
                                      title:
                                        'Rispondenza con il materiale di progetto originale ',
                                    },
                                  },
                                  {
                                    key: 'Rispondenza con il materiale di progetto originale radio',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-3',
                                    templateOptions: {
                                      key: 'Rispondenza con il materiale di progetto originale radio',
                                      flex: true,
                                      options: [
                                        {
                                          value: 'Completa',
                                          label: 'Completa',
                                        },
                                        {
                                          value: 'Parziale',
                                          label: 'Parziale',
                                        },
                                        {
                                          value: 'Assente',
                                          label: 'Assente',
                                        },
                                      ],
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                key: 'Localizzazione dei difetti',
                                fieldGroupClassName:
                                  'display-flex-wrap file-upload-sub-header',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right border-top  text-box height-auto content-center',
                                    fieldGroup: [
                                      {
                                        template: `<div class="font-weight-bolder">Localizzazione dei difetti</div>
                                          <div>(discontinuità, fessure, ecc.)</div>
                                          <div>Indicare la localizzazione delle indagini</div>`,
                                      },
                                    ],
                                  },
                                  {
                                    className:
                                      'flex-3 border-bottom border-top text-box   ',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        class: 'height-auto',
                                        templateOptions: {
                                          title:
                                            'Schema di localizzazione dei difetti: ',
                                          class: 'padding-10',
                                        },
                                      },
                                      {
                                        className:
                                          'border-bottom width-100 remove-underline upload-section',
                                        key: 'Schema di localizzazione dei difetti',
                                        type: 'cube-file-upload-with-preview-type',
                                        defaultValue: '',
                                        templateOptions: {
                                          acceptedFileTypes:
                                            environment.file_upload
                                              .accepted_file_types.images,
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          modelData:
                                            bridgeInputTemplateComponent.model,
                                          token:
                                            bridgeInputTemplateComponent.token,
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Campagna di indagini per la valutazione dei difetti',
                        },
                      },
                      {
                        key: 'Campagna di indagini per la valutazione dei difetti',
                        fieldGroup: [
                          {
                            fieldGroupClassName:
                              'display-flex-wrap margin-bottom-30',
                            fieldGroup: [
                              {
                                className: 'flex-2 border-right',
                                key: 'Indagini semi-distruttive/distruttive',
                                fieldGroup: [
                                  {
                                    wrappers: ['cube-sub-title-wrapper'],
                                    templateOptions: {
                                      title:
                                        'Indagini semi-distruttive/distruttive ',
                                      class: 'light-orange-background',
                                      isHeight70: true,
                                    },
                                  },
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    fieldGroup: [
                                      {
                                        className:
                                          'flex-1  border-right border-top  text-box content-center ',
                                        fieldGroup: [
                                          {
                                            template: `<div>
                                                  <div class="font-weight-bolder">Tipologie di indagini </div>
                                                  <div>(endoscopie, indagini vacuometriche, saggi localmente  distruttivi, ecc.)</div>
                                              </div>`,
                                          },
                                        ],
                                      },
                                      {
                                        className: 'flex-2  border-top   ',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1 ',
                                            key: 'Tipologie di indagini',
                                            fieldGroup: [
                                              {
                                                fieldGroupClassName:
                                                  'display-flex-wrap grid-gap',
                                                fieldGroup: [
                                                  {
                                                    template: `<div class="input-text-align">1)</div>`,
                                                  },
                                                  {
                                                    className: 'flex-1',
                                                    key: 'Tipologie di indagini 1',
                                                    type: 'input',
                                                    defaultValue: '',
                                                    templateOptions: {
                                                      disabled:
                                                        bridgeInputTemplateComponent.isFieldDisabled(
                                                          [
                                                            UserRole.hydraulicTechnician,
                                                          ],
                                                          isDisabled,
                                                          true
                                                        ),
                                                    },
                                                  },
                                                ],
                                              },
                                              {
                                                fieldGroupClassName:
                                                  'display-flex-wrap grid-gap',
                                                fieldGroup: [
                                                  {
                                                    template: `<div class="input-text-align">2)</div>`,
                                                  },
                                                  {
                                                    className: 'flex-1',
                                                    key: 'Tipologie di indagini 2',
                                                    type: 'input',
                                                    defaultValue: '',
                                                    templateOptions: {
                                                      disabled:
                                                        bridgeInputTemplateComponent.isFieldDisabled(
                                                          [
                                                            UserRole.hydraulicTechnician,
                                                          ],
                                                          isDisabled,
                                                          true
                                                        ),
                                                    },
                                                  },
                                                ],
                                              },
                                              {
                                                fieldGroupClassName:
                                                  'display-flex-wrap grid-gap',
                                                fieldGroup: [
                                                  {
                                                    template: `<div class="input-text-align">3)</div>`,
                                                  },
                                                  {
                                                    className: 'flex-1',
                                                    key: 'Tipologie di indagini 3',
                                                    type: 'input',
                                                    defaultValue: '',
                                                    templateOptions: {
                                                      disabled:
                                                        bridgeInputTemplateComponent.isFieldDisabled(
                                                          [
                                                            UserRole.hydraulicTechnician,
                                                          ],
                                                          isDisabled,
                                                          true
                                                        ),
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    className: 'flex-1 border-right ',
                                    key: 'Elementi indagati tipologia e numero',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'Elementi indagati (tipologia e numero) ',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'Elementi indagati tipologia e numero 1',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Elementi indagati tipologia e numero 2',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Elementi indagati tipologia e numero 3',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'N° totale di indagini eseguite',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'N° totale di indagini eseguite',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'N° totale di indagini eseguite 1',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'N° totale di indagini eseguite 2',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'N° totale di indagini eseguite 3',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        template: `<div class=" border-bottom text-align-left light-grey-background">
                                      <span class="font-weight-bolder ">Risultati delle indagini </span>
                                      <span>(compilare una tabella per ogni elemento indagato e tipologia di indagine)</span>
                                  </div>`,
                      },
                      {
                        key: 'Localizzazione dei difetti',
                        fieldGroupClassName: 'display-flex-wrap ',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right border-top text-box content-center height-auto',
                            fieldGroup: [
                              {
                                template: `<div class="font-weight-bolder">Localizzazione dei difetti </div>
                                  <div>(vuotiall’interno delle guaine, fenomeni di ossidazione  dei cavi, ecc.)</div>
                                  <div>Indicare la localizzazione delle indagini</div>  `,
                              },
                            ],
                          },
                          {
                            className: 'flex-3 border-bottom border-top ',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex grid-gap border-bottom',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Tipologia elemento indagato',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Tipologia elemento indagato',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Tipologia di indagine',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Tipologia di indagine',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1',
                                key: 'Tipologie di difetti individuati',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Tipologie di difetti individuati',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title:
                                    'Schema di localizzazione dei difetti:',
                                  class: 'padding-left-10',
                                },
                              },
                              {
                                className:
                                  'border-bottom width-100 remove-underline upload-section',
                                key: 'Schema di localizzazione dei difetti',
                                type: 'cube-file-upload-with-preview-type',
                                defaultValue: '',
                                templateOptions: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  modelData: bridgeInputTemplateComponent.model,
                                  token: bridgeInputTemplateComponent.token,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Valutazione dello stato di tensione del filo o del calcestruzzo',
                        fieldGroupClassName: 'display-flex-wrap ',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right border-top  content-center',
                            fieldGroup: [
                              {
                                template: `<div class="font-weight-bolder">Valutazione dello stato di tensione del filo o del calcestruzzo</div>  `,
                              },
                            ],
                          },
                          {
                            className: 'flex-3 border-bottom border-top   ',
                            fieldGroup: [
                              {
                                key: 'Descrizione',
                                fieldGroupClassName: 'display-flex',
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right border-bottom',
                                    key: 'Descrizione',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title: 'Descrizione ',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'Descrizione 1',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Descrizione 2',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className:
                                      'flex-1 border-right  border-bottom',
                                    key: 'Elementi indagati tipologia e numero',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'Elementi indagati (tipologia e numero)',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'Elementi indagati (tipologia e numero) 1',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Elementi indagati (tipologia e numero) 2',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1 border-bottom ',
                                    key: 'N° totale di misurazioni eseguite',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'N° totale di misurazioni eseguite',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'N° totale di misurazioni eseguite 1',
                                        type: 'input',
                                        defaultValue: '',
                                        class: 'text-box content-center',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title:
                                    'Schema di localizzazione delle indagini:',
                                  class:
                                    'padding-10 height-auto border-bottom ',
                                },
                              },
                              {
                                className:
                                  'border-bottom width-100 remove-underline upload-section',
                                key: 'Schema di localizzazione delle indagini',
                                type: 'cube-file-upload-with-preview-type',
                                defaultValue: '',
                                templateOptions: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  modelData: bridgeInputTemplateComponent.model,
                                  token: bridgeInputTemplateComponent.token,
                                },
                              },
                              {
                                className: 'flex-1 ',
                                key: 'Risultati:',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Risultati:',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap  border-top border-bottom',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-4 margin-auto',
                                    template: `<div class="padding-left-10">Rispondenza con il materiale di progetto originale </div>`,
                                  },
                                  {
                                    key: 'Rispondenza con il materiale di progetto originale',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2 ',
                                    templateOptions: {
                                      key: 'Rispondenza con il materiale di progetto originale',
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1 ',
                                key: 'Informazioni reperibili dal progetto originale:',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label:
                                    'Informazioni reperibili dal progetto originale:',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Prelievo di materiale di iniezione',
                        fieldGroupClassName: 'display-flex-wrap ',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right border-top  content-center',
                            fieldGroup: [
                              {
                                template: `<div class="font-weight-bolder">Prelievo di materiale di iniezione </div>  `,
                              },
                            ],
                          },
                          {
                            className: 'flex-3 border-bottom border-top   ',
                            fieldGroup: [
                              {
                                key: 'Descrizione',
                                fieldGroupClassName: 'display-flex',
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right border-bottom',
                                    key: 'Descrizione',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title: 'Descrizione ',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'Descrizione 1',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Descrizione 2',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className:
                                      'flex-1 border-right  border-bottom',
                                    key: 'Elementi indagati tipologia e numero',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'Elementi indagati (tipologia e numero)',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'Elementi indagati tipologia e numero 1',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Elementi indagati tipologia e numero 2',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1 border-bottom ',
                                    key: 'N° totale di prelievi eseguiti',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'N° totale di prelievi eseguiti ',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'N° totale di prelievi eseguiti 1',
                                        type: 'input',
                                        defaultValue: '',
                                        class: 'text-box content-center',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title:
                                    'Schema di localizzazione delle indagini:',
                                  class: 'padding-10 height-auto ',
                                },
                              },
                              {
                                className:
                                  'border-bottom width-100 remove-underline upload-section',
                                key: 'Schema di localizzazione delle indagini',
                                type: 'cube-file-upload-with-preview-type',
                                defaultValue: '',
                                templateOptions: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  modelData: bridgeInputTemplateComponent.model,
                                  token: bridgeInputTemplateComponent.token,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Prove chimiche',
                        fieldGroupClassName: 'display-flex-wrap ',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right border-top  content-center',
                            fieldGroup: [
                              {
                                template: `<div class="font-weight-bolder">Prove chimiche</div>  `,
                              },
                            ],
                          },
                          {
                            className: 'flex-3 border-bottom border-top   ',
                            fieldGroup: [
                              {
                                key: 'Prove chimiche',
                                fieldGroupClassName: 'display-flex',
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right border-bottom',
                                    key: 'Tipologia',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title: 'Tipologia  ',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap',
                                        fieldGroup: [
                                          {
                                            template: `<div class="input-text-align">1)</div>`,
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Tipologia 1',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap',
                                        fieldGroup: [
                                          {
                                            template: `<div class="input-text-align">2)</div>`,
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Tipologia 2',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap',
                                        fieldGroup: [
                                          {
                                            template: `<div class="input-text-align">3)</div>`,
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Tipologia 3',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap',
                                        fieldGroup: [
                                          {
                                            template: `<div class="input-text-align">4)</div>`,
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Tipologia 4',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    className:
                                      'flex-1 border-right  border-bottom',
                                    key: 'N° totale di prove eseguite',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'N° totale di prove eseguite  ',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'N° totale di prove eseguite 1',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'N° totale di prove eseguite 2',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'N° totale di prove eseguite 3',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'N° totale di prove eseguite 4',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'flex-1 border-bottom ',
                                    key: 'Risultati composizione chimica e presenza di cloruri e umidità',
                                    fieldGroup: [
                                      {
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'Risultati (composizione chimica e presenza di cloruri e umidità) ',
                                          class: 'text-align-center light-grey',
                                          isHeight70: true,
                                        },
                                      },
                                      {
                                        key: 'Risultati composizione chimica e presenza di cloruri e umidità 1',
                                        type: 'input',
                                        defaultValue: '',
                                        class: 'text-box content-center',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Risultati composizione chimica e presenza di cloruri e umidità 2',
                                        type: 'input',
                                        defaultValue: '',
                                        class: 'text-box content-center',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Risultati composizione chimica e presenza di cloruri e umidità 3',
                                        type: 'input',
                                        defaultValue: '',
                                        class: 'text-box content-center',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                      {
                                        key: 'Risultati composizione chimica e presenza di cloruri e umidità 4',
                                        type: 'input',
                                        defaultValue: '',
                                        class: 'text-box content-center',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Analisi dei difetti rilevati',
                        fieldGroupClassName: 'display-flex-wrap margin-top-30',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right border-top text-box content-center left light-orange-background',
                            fieldGroup: [
                              {
                                template: `<div class="font-weight-bolder ">Analisi dei difetti rilevati</div>`,
                              },
                            ],
                          },
                          {
                            className: 'flex-2 border-bottom border-top ',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-column border-right',
                                className: 'flex-1',
                                fieldGroup: [
                                  {
                                    className: 'remove-underline radio-margin',
                                    key: 'Assenti/Presenti',
                                    type: 'radio',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          value:
                                            'I difetti rilevati indicano la necessità di eseguire valutazioni di sicurezza approfondite di Livello 4.',
                                          label:
                                            'I difetti rilevati indicano la necessità di eseguire valutazioni di sicurezza approfondite di Livello 4.',
                                        },
                                        {
                                          value:
                                            'Le indagini effettuate non consentono di stimare con adeguata accuratezza l’entità dei difetti presenti.Sono necessarie, pertanto, valutazioni approfondite di Livello 4.',
                                          label:
                                            'Le indagini effettuate non consentono di stimare con adeguata accuratezza l’entità dei difetti presenti.Sono necessarie, pertanto, valutazioni approfondite di Livello 4.',
                                        },
                                        {
                                          value:
                                            'Le indagini effettuate hanno dimostrato la presenza di difetti di entità modesta, tali da non richiedere provvedimenti urgenti. Si procede pertanto ad ispezioni visive e compilazione di schede di difettosità di Livello 1 e classificazione di Livello 2.',
                                          label:
                                            'Le indagini effettuate hanno dimostrato la presenza di difetti di entità modesta, tali da non richiedere provvedimenti urgenti. Si procede pertanto ad ispezioni visive e compilazione di schede di difettosità di Livello 1 e classificazione di Livello 2.',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
                hideExpression: () =>
                  bridgeInputTemplateComponent.model[BridgeConstants.levels.l0][
                    'Tipologia strutturale'
                  ]['Travate in cap a cavi post-tesi'] !== true,
              },
            ],
          },
          {
            templateOptions: { label: 'Level 1' },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: 'opereparzialilist',
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    defaultColDef: {
                      flex: 1,
                      minWidth: 130,
                    },
                    columnDefs: [
                      {
                        headerName: 'N° CAMPATE',
                        headerClass: 'header-no-child',
                        field: 'N° campate',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° SPALLE',
                        headerClass: 'header-no-child',
                        field: 'N° Spalle',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° PILE',
                        headerClass: 'header-no-child',
                        field: 'N° Pile',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° ARCHI',
                        headerClass: 'header-no-child',
                        field: 'N° Archi',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° IMPALCATI',
                        headerClass: 'header-no-child',
                        field: 'N° Impalcati',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° FILE DI APPOGGI',
                        headerClass: 'header-no-child',
                        field: 'N° File di Appoggi',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        minWidth: 175,
                      },
                      {
                        headerName: 'N° GIUNTI',
                        headerClass: 'header-no-child',
                        field: 'N° Giunti',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° APPARECCHI SISMICI',
                        headerClass: 'header-no-child',
                        field: 'N° Apparecchi Sismici',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        minWidth: 200,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.l1;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.l1,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ]['opereparzialilist'],
                              ''
                            );
                          },
                        },
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'N° campate',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° CAMPATE',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° Spalle',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° SPALLE',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° Pile',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° PILE',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° Archi',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° ARCHI',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° Impalcati',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° IMPALCATI',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° File di Appoggi',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° FILE DI APPOGGI',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° Giunti',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° GIUNTI',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° Apparecchi Sismici',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° APPARECCHI SISMICI',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New L1
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  onClick: (e) => {
                    bridgeInputTemplateComponent.l0BackupData =
                      bridgeInputTemplateComponent.getDeepCopy(
                        bridgeInputTemplateComponent.model.l0
                      );
                    bridgeInputTemplateComponent.l1InitBackupData =
                      bridgeInputTemplateComponent.getDeepCopy(
                        bridgeInputTemplateComponent.model.l1
                      );
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l0
                    );
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.l1;
                  },
                },
                hideExpression: () => {
                  const isFieldDisabled =
                    bridgeInputTemplateComponent.isFieldDisabled(
                      [UserRole.hydraulicTechnician],
                      isDisabled,
                      true
                    );
                  const isLevel1Added =
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.l0
                    ].opereparzialilist.length > 0;

                  return isLevel1Added || isFieldDisabled;
                },
              },
            ],
            expressionProperties: {
              'templateOptions.isHidden': () =>
                bridgeInputTemplateComponent.isShortcutView,
            },
          },
          {
            key: BridgeConstants.levels.level2,
            templateOptions: { label: 'Level 2' },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: 'level2List',
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    defaultColDef: {
                      flex: 1,
                      minWidth: 130,
                    },
                    columnDefs: [
                      {
                        headerName: 'N° campate',
                        headerClass: 'header-no-child',
                        field: 'N° campate',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'CdA Status',
                        headerClass: 'header-no-child',
                        field: 'Cda Status',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onEdit: async (index) => {
                            // Checking if N° campate has been added in L0
                            const nCampate =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ]['Caratteristiche geometriche']['N° campate'];

                            // If N° campate has not been added in L0
                            if (!nCampate) {
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Ok',
                                  isCancelButtonVisible: false,
                                  message: `Please fill N° campate in L0 first`,
                                  title: 'Info',
                                }
                              );

                              return;
                            }

                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.level2;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.level2,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ][BridgeConstants.levels.level2].level2List,
                              ''
                            );
                          },
                        },
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'N° campate',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° campate',
                        readonly: true,
                      },
                    },
                    {
                      key: 'Cda Status',
                      type: 'input',
                      defaultValue: 'Da Completare',
                      templateOptions: {
                        label: 'CdA Status',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
            ],
            expressionProperties: {
              'templateOptions.isHidden': () =>
                bridgeInputTemplateComponent.isShortcutView,
            },
          },
          {
            templateOptions: { label: 'Media' },
            key: 'MediaList',
            fieldGroup: [
              {
                key: 'Media',
                type: 'cube-file-upload-media-type',
                templateOptions: {
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  multiple: true,
                  modelData: bridgeInputTemplateComponent.model,
                  token: bridgeInputTemplateComponent.token,
                },
                className: 'width-100 remove-underline upload-section',
              },
            ],
            expressionProperties: {
              'templateOptions.isHidden': () =>
                bridgeInputTemplateComponent.isShortcutView,
            },
          },
          {
            templateOptions: { label: 'Notes' },
            fieldGroup: [
              {
                type: 'textarea',
                defaultValue: '',
                key: 'Notes',
                templateOptions: {
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  label: 'Notes',
                  theme: 'custom',
                  rows: 10,
                },
              },
            ],
            expressionProperties: {
              'templateOptions.isHidden': () =>
                bridgeInputTemplateComponent.isShortcutView,
            },
          },
          {
            key: 'Request',
            templateOptions: { label: 'Request' },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1 remove-underline',
                    key: 'IOP Request',
                    type: 'checkbox',
                    defaultValue: false,
                    templateOptions: {
                      key: 'IOP Request',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.bmsInspectionManager, UserRole.bmsInspector],
                        isDisabled
                      ),
                      name: 'IOP Request',
                      options: [{ value: true, label: 'IOP Request' }],
                      change: async (field) => {
                        if (field.formControl.value) {
                          // Populate values from L0
                          const l0Model =
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l0
                            ];

                          // Mapping the value of 'Stato dell’opera' to the corresponding enum value
                          let updatedStatoDellOpera;
                          switch (
                            l0Model['Stato dell’opera']['Stato dell’opera']
                          ) {
                            case 'Pienamente agibile':
                              updatedStatoDellOpera = 'PIENAMENTE_AGIBILE';

                              break;

                            case 'Agibile ma con scadenze di lavori di manutenzione ordinaria':
                              updatedStatoDellOpera =
                                'AGIBILE_CON_SCADENZE_LAVORI_MANUT_ORD';

                              break;

                            case 'Agibile ma con scadenze di lavori di manutenzione straordinaria':
                              updatedStatoDellOpera =
                                'AGIBILE_CON_NECESSITA_MANUT_STRAORD';

                              break;

                            case 'Condizioni critiche e agibile parzialmente/ lavori di manutenzione urgenti':
                              updatedStatoDellOpera =
                                'CONDIZIONI_CRITICHE_LAVORI_MANUT_URGENTI';

                              break;

                            case 'Inagibile':
                              updatedStatoDellOpera = 'INAGIBILE';

                              break;

                            default:
                              updatedStatoDellOpera = '';
                          }

                          field.parent.formControl.patchValue({
                            'Nome Opera': l0Model['Nome Ponte Viadotto'],
                            'Indirizzo Principale':
                              l0Model.Localizzazione.Località,
                            Province: l0Model.Localizzazione.Provincia,
                            Regioni: l0Model.Localizzazione.Regione,
                            Coordinate: {
                              Centro: {
                                Altitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Centro['Quota slm m'],
                                Longitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Centro.Longitudine,
                                Latitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Centro.Latitudine,
                              },
                              ['Punto Iniziale']: {
                                Altitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Iniziale['Quota slm m'],
                                Longitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Iniziale.Longitudine,
                                Latitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Iniziale.Latitudine,
                              },
                              ['Punto Finale']: {
                                Altitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Finale['Quota slm m'],
                                Longitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Finale.Longitudine,
                                Latitudine:
                                  l0Model.Localizzazione[
                                    'Coordinate Geografiche'
                                  ].Finale.Latitudine,
                              },
                            },
                            'Anno Collaudo Finale':
                              l0Model['Informazioni generali'][
                                'Anno di costruzione / ristrutturazione'
                              ]['Ultimazione costruzione collaudo'].year,
                            'Ente Concedente o Proprietario':
                              l0Model['Informazioni generali'].Proprietario,
                            Concessionario:
                              l0Model['Informazioni generali'].Concessionario,
                            'Ente Vigilante':
                              l0Model['Informazioni generali'][
                                'Ente vigilante'
                              ],
                            "Stato dell'Opera": updatedStatoDellOpera,
                          });

                          return;
                        }

                        const isConfirmed =
                          await bridgeInputTemplateComponent.getConfirmation({
                            confirmButtonText: 'Ok',
                            message:
                              'Do you want to uncheck IOP Request? If you uncheck any inputed data will be lost.',
                            title: 'Alert',
                          });

                        if (!isConfirmed) {
                          // Set the checkbox value back to checked
                          field.formControl.setValue(true);

                          return;
                        }

                        // Reset the values of all nested fields
                        field.formControl.parent.reset();
                        bridgeInputTemplateComponent.refreshFields();
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    key: 'Tipo Anagrafica',
                    type: 'select',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Tipo Anagrafica',
                      required: true,
                      options: [
                        {
                          label: 'Ponteferroviario',
                          value: 'Ponteferroviario',
                        },
                        {
                          label: 'PonteViadottoStradale',
                          value: 'PonteViadottoStradale',
                        },
                      ],
                      change: (field, e) => {
                        field.parent.formControl
                          .get(['Tipologia Infrastruttura'])
                          ?.reset();
                      },
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Tipologia Infrastruttura',
                    type: 'select',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Tipologia Infrastruttura',
                      required: true,
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                      'templateOptions.options': () => {
                        const tipoAnagrafica =
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['Tipo Anagrafica'];

                        switch (tipoAnagrafica) {
                          case 'Ponteferroviario':
                            return [
                              {
                                label: 'FERROVIA_ALTA_VELOCITA',
                                value: 'FERROVIA_ALTA_VELOCITA',
                              },
                              {
                                label: 'FERROVIA_FONDAMENTALE',
                                value: 'FERROVIA_FONDAMENTALE',
                              },
                              {
                                label: 'FERROVIA_COMPLEMENTARE',
                                value: 'FERROVIA_COMPLEMENTARE',
                              },
                              {
                                label: 'FERROVIA_NODI',
                                value: 'FERROVIA_NODI',
                              },
                              {
                                label: 'FERROVIA_A_CREMAGLIERA',
                                value: 'FERROVIA_A_CREMAGLIERA',
                              },
                              {
                                label: 'FERROVIA_METROPOLITANA',
                                value: 'FERROVIA_METROPOLITANA',
                              },
                              {
                                label: 'ALTRA_INFRASTRUTTURA_FERROVIARIA',
                                value: 'ALTRA_INFRASTRUTTURA_FERROVIARIA',
                              },
                            ];

                          case 'PonteViadottoStradale':
                            return [
                              {
                                label: 'AUTOSTRADA',
                                value: 'AUTOSTRADA',
                              },
                              {
                                label: 'STRADA_STATALE',
                                value: 'STRADA_STATALE',
                              },
                              {
                                label: 'STRADA_PROVINCIALE',
                                value: 'STRADA_PROVINCIALE',
                              },
                              {
                                label: 'STRADA_COMUNALE',
                                value: 'STRADA_COMUNALE',
                              },
                              {
                                label: 'STRADA_VICINALE',
                                value: 'STRADA_VICINALE',
                              },
                              {
                                label: 'ALTRA_INFRASTRUTTURA_STRADALE',
                                value: 'ALTRA_INFRASTRUTTURA_STRADALE',
                              },
                            ];

                          default:
                            return [];
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    key: 'Nome Infrastruttura',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Nome Infrastruttura',
                      required: true,
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Codice Infrastruttura',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Codice Infrastruttura',
                          maxLength: 50,
                          required: true,
                        },
                        validators: {
                          validation: ['alphaNumericPattern'],
                        },
                        expressionProperties: {
                          'templateOptions.disabled': () => {
                            const isIopRequestDisabled =
                              !bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ].Request['IOP Request'];

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              isIopRequestDisabled || isDisabled
                            );
                          },
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: `Letters: A - Z (no case sensitive) 
                          Numbers: 0 to 9 
                          Special characters allowed: - and / 
                          Length: Maximum 50 digits`,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    key: 'Tipologia Opera',
                    type: 'select',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Tipologia Opera',
                      required: true,
                      options: [
                        { label: 'PONTE', value: 'PONTE' },
                        { label: 'VIADOTTO', value: 'VIADOTTO' },
                        { label: 'SOTTOVIA', value: 'SOTTOVIA' },
                        { label: 'CAVALCAVIA', value: 'CAVALCAVIA' },
                        { label: 'SOVRAPPASSO', value: 'SOVRAPPASSO' },
                        { label: 'SOTTOPASSO', value: 'SOTTOPASSO' },
                        { label: 'SOPRAELEVATA', value: 'SOPRAELEVATA' },
                        { label: 'TOMBINO', value: 'TOMBINO' },
                      ],
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Nome Opera',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Nome Opera',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              true
                            ),
                          required: true,
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: 'L0 - Nome Ponte/Viadotto',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Codice Opera',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Codice Opera',
                          maxLength: 50,
                          required: true,
                        },
                        validators: {
                          validation: ['alphaNumericPattern'],
                        },
                        expressionProperties: {
                          'templateOptions.disabled': () => {
                            const isIopRequestDisabled =
                              !bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ].Request['IOP Request'];

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              isIopRequestDisabled || isDisabled
                            );
                          },
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: `Letters: A - Z (no case sensitive)
                          Numbers: 0 to 9
                          Special characters allowed: - and /
                          Length: Maximum 50 digits`,
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'ID Gestore O Proprietario',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'ID Gestore O Proprietario',
                          maxLength: 50,
                        },
                        expressionProperties: {
                          'templateOptions.disabled': () => {
                            const isIopRequestDisabled =
                              !bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ].Request['IOP Request'];

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              isIopRequestDisabled || isDisabled
                            );
                          },
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: `Length: Maximum 50 digits`,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroupClassName: 'display-flex-column',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex align-items-center',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Codice Catastale del Comune Principale',
                                    templateOptions: {
                                      label:
                                        'Codice Catastale del Comune Principale',
                                      maxLength: 50,
                                      required: true,
                                    },
                                    validators: {
                                      validation: ['alphaNumericPattern'],
                                    },
                                    expressionProperties: {
                                      'templateOptions.disabled': () => {
                                        const isIopRequestDisabled =
                                          !bridgeInputTemplateComponent.model[
                                            BridgeConstants.levels.l0
                                          ].Request['IOP Request'];

                                        return bridgeInputTemplateComponent.isFieldDisabled(
                                          [
                                            UserRole.bmsInspector,
                                            UserRole.bmsInspectionManager,
                                          ],
                                          isIopRequestDisabled || isDisabled
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'cube-custom-tooltip',
                                    templateOptions: {
                                      icon: 'info',
                                      toolTipMessage:
                                        'L0 - Localizzazione - Comune',
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex align-items-center',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Indirizzo Principale',
                                    templateOptions: {
                                      label: 'Indirizzo Principale',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [
                                            UserRole.bmsInspector,
                                            UserRole.bmsInspectionManager,
                                          ],
                                          true
                                        ),
                                      required: true,
                                    },
                                  },
                                  {
                                    type: 'cube-custom-tooltip',
                                    templateOptions: {
                                      icon: 'info',
                                      toolTipMessage: `L0 - Localizzazione - Località`,
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex align-items-center',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Province',
                                    templateOptions: {
                                      label: 'Province',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [
                                            UserRole.bmsInspector,
                                            UserRole.bmsInspectionManager,
                                          ],
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    type: 'cube-custom-tooltip',
                                    templateOptions: {
                                      icon: 'info',
                                      toolTipMessage: `Enter the 2-digit acronym; if you enter a list, separate the values with a comma.
                                      L0 - Localizzazione - Provincia`,
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex align-items-center',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Regioni',
                                    templateOptions: {
                                      label: 'Regioni',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [
                                            UserRole.bmsInspector,
                                            UserRole.bmsInspectionManager,
                                          ],
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    type: 'cube-custom-tooltip',
                                    templateOptions: {
                                      icon: 'info',
                                      toolTipMessage: `L0 - Localizzazione - Regione`,
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex align-items-center',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Codice Catastale dei Comuni',
                                    templateOptions: {
                                      label: 'Codice Catastale dei Comuni',
                                      maxLength: 50,
                                    },
                                    validators: {
                                      validation: ['alphaNumericPattern'],
                                    },
                                    expressionProperties: {
                                      'templateOptions.disabled': () => {
                                        const isIopRequestDisabled =
                                          !bridgeInputTemplateComponent.model[
                                            BridgeConstants.levels.l0
                                          ].Request['IOP Request'];

                                        return bridgeInputTemplateComponent.isFieldDisabled(
                                          [
                                            UserRole.bmsInspector,
                                            UserRole.bmsInspectionManager,
                                          ],
                                          isIopRequestDisabled || isDisabled
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'cube-custom-tooltip',
                                    templateOptions: {
                                      icon: 'info',
                                      toolTipMessage:
                                        'L0 - Localizzazione - Comune',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            key: 'Coordinate',
                            type: 'cube-block-table-type',
                            templateOptions: {
                              grouptitle: 'Coordinate Geografiche',
                              subtitle: 'WGS84',
                            },
                            fieldGroup: [
                              {
                                fieldGroup: [
                                  {
                                    key: 'Centro',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Centro',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        fieldGroupClassName:
                                          'display-flex align-items-center',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Altitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Altitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.bmsInspector,
                                                    UserRole.bmsInspectionManager,
                                                  ],
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            type: 'cube-custom-tooltip',
                                            templateOptions: {
                                              icon: 'info',
                                              toolTipMessage: `Altitude expressed in meters (e.g 78.00).
                                              L0 - Localizzazione - Coordinate geografiche - Centro - Quota s.l.m [m]`,
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            fieldGroupClassName:
                                              'display-flex align-items-center',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Longitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.bmsInspector,
                                                        UserRole.bmsInspectionManager,
                                                      ],
                                                      true
                                                    ),
                                                },
                                              },
                                              {
                                                type: 'cube-custom-tooltip',
                                                templateOptions: {
                                                  icon: 'info',
                                                  toolTipMessage: `The admitted format is EPSG:4326 (WGS84).
                                                  L0 - Localizzazione - Coordinate geografiche - Centro - Longitudine`,
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1',
                                            fieldGroupClassName:
                                              'display-flex align-items-center',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Latitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.bmsInspector,
                                                        UserRole.bmsInspectionManager,
                                                      ],
                                                      true
                                                    ),
                                                },
                                              },
                                              {
                                                type: 'cube-custom-tooltip',
                                                templateOptions: {
                                                  icon: 'info',
                                                  toolTipMessage: `The admitted format is EPSG:4326 (WGS84).
                                                  L0 - Localizzazione - Coordinate geografiche - Centro - Latitudine`,
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Punto Iniziale',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Iniziale',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        fieldGroupClassName:
                                          'display-flex align-items-center',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Altitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Altitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.bmsInspector,
                                                    UserRole.bmsInspectionManager,
                                                  ],
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            type: 'cube-custom-tooltip',
                                            templateOptions: {
                                              icon: 'info',
                                              toolTipMessage: `Altitude expressed in meters (e.g 78.00).
                                              L0 - Localizzazione - Coordinate geografiche - Centro - Quota s.l.m [m]`,
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            fieldGroupClassName:
                                              'display-flex align-items-center',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Longitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.bmsInspector,
                                                        UserRole.bmsInspectionManager,
                                                      ],
                                                      true
                                                    ),
                                                },
                                              },
                                              {
                                                type: 'cube-custom-tooltip',
                                                templateOptions: {
                                                  icon: 'info',
                                                  toolTipMessage: `The admitted format is EPSG:4326 (WGS84).
                                                  L0 - Localizzazione - Coordinate geografiche - Iniziale - Longitudine`,
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1',
                                            fieldGroupClassName:
                                              'display-flex align-items-center',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Latitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.bmsInspector,
                                                        UserRole.bmsInspectionManager,
                                                      ],
                                                      true
                                                    ),
                                                },
                                              },
                                              {
                                                type: 'cube-custom-tooltip',
                                                templateOptions: {
                                                  icon: 'info',
                                                  toolTipMessage: `The admitted format is EPSG:4326 (WGS84).
                                                  L0 - Localizzazione - Coordinate geografiche - Iniziale - Latitudine `,
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Punto Finale',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Finale',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        className: 'flex-1',
                                        fieldGroupClassName:
                                          'display-flex align-items-center',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Altitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Altitudine',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.bmsInspector,
                                                    UserRole.bmsInspectionManager,
                                                  ],
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            type: 'cube-custom-tooltip',
                                            templateOptions: {
                                              icon: 'info',
                                              toolTipMessage: `Altitude expressed in meters (e.g 78.00).
                                              L0 - Localizzazione - Coordinate geografiche - Centro - Quota s.l.m [m]`,
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            fieldGroupClassName:
                                              'display-flex align-items-center',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Longitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.bmsInspector,
                                                        UserRole.bmsInspectionManager,
                                                      ],
                                                      true
                                                    ),
                                                },
                                              },
                                              {
                                                type: 'cube-custom-tooltip',
                                                templateOptions: {
                                                  icon: 'info',
                                                  toolTipMessage: `The admitted format is EPSG:4326 (WGS84).
                                                  L0 - Localizzazione - Coordinate geografiche - Finale - Longitudine`,
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1',
                                            fieldGroupClassName:
                                              'display-flex align-items-center',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Latitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.bmsInspector,
                                                        UserRole.bmsInspectionManager,
                                                      ],
                                                      true
                                                    ),
                                                },
                                              },
                                              {
                                                type: 'cube-custom-tooltip',
                                                templateOptions: {
                                                  icon: 'info',
                                                  toolTipMessage: `The admitted format is EPSG:4326 (WGS84).
                                                  L0 - Localizzazione - Coordinate geografiche - Finale - Latitudine`,
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    key: 'Anno Entrata Esercizio',
                    type: 'cube-year-picker-type',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Anno Entrata Esercizio',
                      maxDate: (() => {
                        const maxDate = new Date();
                        maxDate.setFullYear(maxDate.getFullYear() + 5);

                        return maxDate;
                      })(),
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Anno Inizio Lavori',
                    type: 'cube-year-picker-type',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Anno Inizio Lavori',
                      maxDate: (() => {
                        let maxDate = new Date();
                        maxDate.setFullYear(maxDate.getFullYear() + 5);
                        return maxDate;
                      })(),
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Anno Fine Lavori',
                    type: 'cube-year-picker-type',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Anno Fine Lavori',
                      maxDate: (() => {
                        let maxDate = new Date();
                        maxDate.setFullYear(maxDate.getFullYear() + 5);
                        return maxDate;
                      })(),
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Anno Collaudo Finale',
                        type: 'cube-year-picker-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Anno Collaudo Finale',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              true
                            ),
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: `Year expressed with 4 digits (e.g. 2024).
                          L0 - Informazioni generali - Ultimazione costruzione /collaudo`,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Ente Concedente o Proprietario',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Ente Concedente o Proprietario',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              true
                            ),
                          required: true,
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: `L0 - Informazioni generali - Proprietario`,
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Concessionario',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Concessionario',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              true
                            ),
                          required: true,
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: `L0 - Informazioni generali - Concessionario`,
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Ente Vigilante',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Ente Vigilante',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              true
                            ),
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: `L0 - Informazioni generali - Ente vigilante`,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: "Stato dell'Opera",
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: "Stato dell'Opera",
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [
                                UserRole.bmsInspector,
                                UserRole.bmsInspectionManager,
                              ],
                              true
                            ),
                          required: true,
                        },
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'info',
                          toolTipMessage: `L0 - Stato dell'opera`,
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-1',
                    key: 'Codice Cup',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Codice Cup',
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    key: 'Note',
                    type: 'textarea',
                    defaultValue: '',
                    className: 'width-100',
                    templateOptions: {
                      label: 'Note',
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    key: 'Id Unità Organizzativa',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Id Unità Organizzativa',
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Codice Iop Madre',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Codice Iop Madre',
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        const isIopRequestDisabled =
                          !bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l0
                          ].Request['IOP Request'];

                        return bridgeInputTemplateComponent.isFieldDisabled(
                          [
                            UserRole.bmsInspector,
                            UserRole.bmsInspectionManager,
                          ],
                          isIopRequestDisabled || isDisabled
                        );
                      },
                    },
                  },
                ],
              },
            ],
            expressionProperties: {
              'templateOptions.isHidden': () =>
                bridgeInputTemplateComponent.isShortcutView,
            },
            hooks: {
              onInit: (field) => {
                if (!field.formControl.get(['IOP Request'])?.value)
                  field.formControl.reset();
              },
            },
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.l0,
      },
      {
        key: BridgeConstants.levels.l1,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: { label: 'Level 1' },
            hooks: {
              onInit: (field) => {
                bridgeInputTemplateComponent.getLevel1TipologiaElementiStructures();
              },
            },
            fieldGroup: [
              {
                resetOnHide: true,
                fieldGroup: [
                  {
                    fieldGroupClassName: 'page-heading',
                    fieldGroup: [
                      {
                        className: 'width-100',
                        template: `<div class="page-heading-text" style="margin: auto 0px;">Schede descrittive di ispezione ponti di Livello 1 </div>`,
                      },
                      {
                        // Switch to L1 - Frane/Idraulica
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Frane/Idraulica',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.options.formState.l1Descrizione =
                              false;
                            bridgeInputTemplateComponent.options.formState.l1Frane =
                              true;
                          },
                        },
                      },
                      {
                        className: 'page-heading-image',
                        template: `<img src="assets/rina/RINA-dark.png"/>`,
                      },
                    ],
                  },
                  {
                    key: 'Schede descrittive di ispezione ponti di Livello 1',
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Codice IOP',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Codice IOP',
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'codiceIop',
                              e.target.value
                            );
                            bridgeInputTemplateComponent.syncSharedModelDataForList(
                              'codiceIop',
                              e.target.value
                            );
                          },
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Nome Opera',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Nome Opera',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'nomePonte',
                              e.target.value
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    key: 'Schede descrittive di ispezione ponti di Livello 1',
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Strada di appartenenza',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Strada di appartenenza',
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'stradaDiAppartenenza',
                              e.target.value
                            );
                          },
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Progressiva km',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Progressiva km',
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'progressivaKmIniziale',
                              e.target.value
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    key: 'Schede descrittive di ispezione ponti di Livello 1',
                    className: 'flex-2',
                    fieldGroup: [
                      {
                        key: 'Schede descrittive di ispezione ponti di Livello 1 section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onAddRow: () => {
                            bridgeInputTemplateComponent.syncRepeatSections(
                              'censusDate',
                              `${StructureConstants.syncPathNo}2`
                            );
                          },
                          onDeleteRow: (index) => {
                            bridgeInputTemplateComponent.syncRepeatSections(
                              'censusDate',
                              `${StructureConstants.syncPathNo}2`,
                              index
                            );
                          },
                        },
                        fieldArray: {
                          fieldGroupClassName: 'display-flex-wrap grid-gap',
                          fieldGroup: [
                            {
                              className: 'flex-1',
                              key: 'Tecnico rilevatore',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                change: (field, e) => {
                                  bridgeInputTemplateComponent.syncSharedModelData(
                                    'censusDateTecnico',
                                    e.target.value,
                                    field.parent.key
                                  );
                                },
                              },
                              expressionProperties: {
                                'templateOptions.label':
                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                              },
                            },
                            {
                              className: 'flex-1',
                              key: 'Data ispezione',
                              type: 'cube-date-range-picker-type',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                max: (() => {
                                  const today = new Date();
                                  const maxDate = new Date(today);
                                  maxDate.setDate(today.getDate() + 7);

                                  return maxDate;
                                })(),
                                onClearDate: (field, value) => {
                                  bridgeInputTemplateComponent.syncSharedModelData(
                                    'censusDateData',
                                    value,
                                    field.parent.key
                                  );
                                },
                                onDateChange: (field, e) => {
                                  bridgeInputTemplateComponent.syncSharedModelData(
                                    'censusDateData',
                                    e,
                                    field.parent.key
                                  );
                                },
                              },
                              expressionProperties: {
                                'templateOptions.label':
                                  "'Data ispezione n.' + (+field.parent.key + 1)",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    key: 'Localizzazione',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: { title: 'Localizzazione' },
                      },
                      {
                        fieldGroupClassName: 'display-flex-column',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap grid-gap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                fieldGroupClassName: 'display-flex-column',
                                fieldGroup: [
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Provincia',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      label: 'Provincia',
                                      change: (field, e) => {
                                        bridgeInputTemplateComponent.syncSharedModelData(
                                          'provincia',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Regione',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      label: 'Regione',
                                      change: (field, e) => {
                                        bridgeInputTemplateComponent.syncSharedModelData(
                                          'regione',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Comune',
                                    templateOptions: {
                                      label: 'Comune',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      change: (field, e) => {
                                        bridgeInputTemplateComponent.syncSharedModelData(
                                          'comune',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Località',
                                    templateOptions: {
                                      label: 'Località',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      change: (field, e) => {
                                        bridgeInputTemplateComponent.syncSharedModelData(
                                          'localita',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-2 font-size-18',
                                key: 'Coordinate Geografiche',
                                type: 'cube-block-table-type',
                                templateOptions: {
                                  grouptitle: 'Coordinate Geografiche',
                                },
                                fieldGroup: [
                                  {
                                    fieldGroup: [
                                      {
                                        className: 'sub-sec remove-underline',
                                        key: 'ETRF2000/WGS84',
                                        type: 'radio',
                                        defaultValue: 'ETRF2000',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          options: [
                                            {
                                              value: 'ETRF2000',
                                              label: 'ETRF2000',
                                            },
                                            {
                                              value: 'WGS84',
                                              label: 'WGS84',
                                            },
                                          ],
                                          change: (field, e) => {
                                            bridgeInputTemplateComponent.syncSharedModelData(
                                              'coordinateGeografiche',
                                              field.formControl.value
                                            );
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    fieldGroup: [
                                      {
                                        key: 'Centro',
                                        wrappers: [
                                          'cube-coordinate-section-row-wrapper',
                                        ],
                                        templateOptions: {
                                          rowTitle: 'Centro',
                                        },
                                        fieldGroupClassName:
                                          'display-flex-column',
                                        fieldGroup: [
                                          {
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              label: 'Quota s.l.m. [m]',
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'centroQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            fieldGroupClassName:
                                              'display-flex grid-gap',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  label: 'Longitudine',
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'centroLongitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  label: 'Latitudine',
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'centroLatitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        key: 'Iniziale',
                                        wrappers: [
                                          'cube-coordinate-section-row-wrapper',
                                        ],
                                        templateOptions: {
                                          rowTitle: 'Iniziale',
                                        },
                                        fieldGroupClassName:
                                          'display-flex-column',
                                        fieldGroup: [
                                          {
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              label: 'Quota s.l.m. [m]',
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'inizialeQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            fieldGroupClassName:
                                              'display-flex grid-gap',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  label: 'Longitudine',
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'inizialeLongitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  label: 'Latitudine',
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'inizialeLatitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        key: 'Finale',
                                        wrappers: [
                                          'cube-coordinate-section-row-wrapper',
                                        ],
                                        templateOptions: {
                                          rowTitle: 'Finale',
                                        },
                                        fieldGroupClassName:
                                          'display-flex-column',
                                        fieldGroup: [
                                          {
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                              label: 'Quota s.l.m. [m]',
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'finaleQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            fieldGroupClassName:
                                              'display-flex grid-gap',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  label: 'Longitudine',
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'finaleLongitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  label: 'Latitudine',
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'finaleLatitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    className: 'light-green',
                    key: 'Tipologia strutturale',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Tipologia strutturale',
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap light-green',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-column flex-1',
                            fieldGroup: [
                              {
                                className: 'border-bottom remove-underline',
                                key: 'Arco Massiccio',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Arco Massiccio ',
                                    },
                                  ],
                                },
                              },
                              {
                                className: 'border-bottom  remove-underline',
                                key: 'Arco sottile',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Arco sottile' }],
                                },
                              },
                              {
                                className: 'border-bottom remove-underline',
                                key: 'Strallato o sospeso',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Strallato o sospeso',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            className: 'display-flex-column  flex-2',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap border-left',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-right flex-1 remove-underline  border-bottom',
                                    key: 'Travate appoggiate',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Travate appoggiate',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 remove-underline border-bottom ',
                                    key: 'Travate continue/Telaio',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Travate continue/Telaio',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap border-left',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-right flex-1 remove-underline border-bottom ',
                                    key: 'Travate Gerber Ponti a stampella con travitampone',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label:
                                            'Travate Gerber/Ponti a stampella con travi tampone',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 remove-underline border-bottom light-green',
                                    key: 'empty-box',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap border-left',
                                fieldGroup: [
                                  {
                                    className:
                                      'width-100  border-bottom remove-underline flex-1',
                                    key: 'Altro',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Altro',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-bottom width-100 input flex-2 light-green',
                                    key: 'Altro-text',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className:
                              'display-flex-column  flex-2 border-left',
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex-wrap ',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-right flex-1 remove-underline border-bottom',
                                    key: 'Soletta appoggiata',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Soletta appoggiata ',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-right flex-1 remove-underline border-bottom',
                                    key: 'Soletta incastrata',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Soletta incastrata',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap  flex-1',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-right border-bottom  flex-1 remove-underline',
                                    key: 'Sezione tubolare',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Sezione tubolare',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-right border-bottom  flex-1 remove-underline',
                                    key: 'Arco in acciaio',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Arco in acciaio',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap flex-2',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-right border-bottom width-100 remove-underline',
                                    key: 'Travate in cap a cavi post tesi',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label:
                                            'Travate in c.a.p. a cavi post-tesi',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap center-align light-green',
                        fieldGroup: [
                          {
                            className: 'remove-underline',
                            key: 'Schema isostatico',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  label: 'Schema isostatico',
                                },
                              ],
                            },
                          },
                          {
                            className:
                              'remove-underline border-left padding-left-40',
                            key: 'Schema iperstatico',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  label: 'Schema iperstatico ',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'light-green',
                    key: 'Impalcato Materiale costruttivo',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Impalcato - Materiale costruttivo',
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap light-green',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-column flex-1',
                            fieldGroup: [
                              {
                                className: 'border-bottom remove-underline',
                                key: 'Muratura',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Muratura' }],
                                },
                              },
                              {
                                className: 'border-bottom  remove-underline',
                                key: 'Legno',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Legno' }],
                                },
                              },
                            ],
                          },
                          {
                            className:
                              'display-flex-column  flex-4 border-left',
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-right flex-1 remove-underline  border-bottom',
                                    key: 'Ca',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'C.a.',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-right flex-1 remove-underline  border-bottom',
                                    key: 'CAP',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'C.A.P.',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-right flex-1 remove-underline  border-bottom',
                                    key: 'Acciaio',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Acciaio',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-right flex-1 remove-underline  border-bottom',
                                    key: 'Misto Ca acciaio',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Misto (C.a./acciaio)',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className:
                                      'width-100  border-bottom remove-underline flex-1',
                                    key: 'Altro-impalcato',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Altro',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-right border-bottom width-100 input flex-3',
                                    key: 'Altro-text-impalcato',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Tipologia delle Fondazioni/Classificazione delle vie di attacco',
                    fieldGroupClassName: 'display-flex-wrap gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            className: 'text-align-center',
                            wrappers: ['cube-section-title-wrapper'],
                            templateOptions: {
                              title: 'Tipologia delle Fondazioni',
                            },
                          },
                          {
                            key: 'Tipologia delle Fondazioni',
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-bottom border-right width-100 flex-1 remove-underline display-flex justify-content-center',
                                    key: 'Fondazioni dirette',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        { label: 'Fondazioni dirette ' },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-bottom width-100 flex-1 remove-underline display-flex justify-content-center',
                                    key: 'Fondazioni Indirette',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        { label: 'Fondazioni Indirette' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-column',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-bottom width-100 remove-underline',
                                    key: 'Murature',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [{ label: 'Murature' }],
                                    },
                                  },
                                  {
                                    className:
                                      'border-bottom width-100  remove-underline',
                                    key: 'Pile in alveo',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [{ label: 'Pile in alveo ' }],
                                    },
                                  },
                                  {
                                    className:
                                      'border-bottom width-100 remove-underline',
                                    key: 'Non nota',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [{ label: 'Non nota' }],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Classificazione delle vie di attacco',
                        className: 'flex-1 border-left padding-left-35',
                        fieldGroup: [
                          {
                            className: 'text-align-center',
                            wrappers: ['cube-section-title-wrapper'],
                            templateOptions: {
                              title: 'Classificazione delle vie di attacco',
                            },
                          },
                          {
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex-column',
                                fieldGroup: [
                                  {
                                    className:
                                      'border-bottom width-100 remove-underline',
                                    key: 'Rilevato in terra',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [{ label: 'Rilevato in terra' }],
                                    },
                                  },
                                  {
                                    className:
                                      'border-bottom width-100  remove-underline',
                                    key: 'Rilevato in terra rinforzata armata',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label:
                                            'Rilevato in terra rinforzata/armata ',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-bottom width-100 remove-underline',
                                    key: 'Rilevato in golena',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        { label: 'Rilevato in golena' },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'border-bottom width-100  remove-underline',
                                    key: 'Su roccia',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [{ label: 'Su roccia' }],
                                    },
                                  },
                                  {
                                    className:
                                      'border-bottom width-100  remove-underline',
                                    key: 'Viadotto in pendenza curva',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Viadotto in pendenza/curva',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 width-100 remove-underline altro-width',
                                    key: 'Altro',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [{ label: 'Altro :' }],
                                    },
                                  },
                                  {
                                    className: 'flex-3',
                                    key: 'Altro-text-classificazione',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Dati Geomorfologici',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Dati Geomorfologici',
                        },
                      },
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: { title: 'Morfologia del sito' },
                      },
                      {
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'main-section',
                            fieldGroup: [
                              {
                                className:
                                  'border-right border-bottom width-100 remove-underline',
                                key: 'Cresta',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Cresta' }],
                                },
                              },
                              {
                                className:
                                  'border-bottom width-100 remove-underline',
                                key: 'Pendio dolce',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    { label: 'Pendio dolce (0 - 10°)' },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'main-section',
                            fieldGroup: [
                              {
                                className:
                                  'border-right border-bottom width-100 remove-underline',
                                key: 'Pendio moderato',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Pendio moderato (10° - 25°)',
                                    },
                                  ],
                                },
                              },
                              {
                                className:
                                  'border-bottom width-100 remove-underline',
                                key: 'Pendio ripido',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Pendio ripido (> 25°)' }],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'main-section',
                            fieldGroup: [
                              {
                                className:
                                  'border-right width-100 remove-underline',
                                key: 'Pianura',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Pianura' }],
                                },
                              },
                              {
                                className: 'width-100 remove-underline',
                                key: 'Pianura alla base dei versanti',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Pianura alla base dei versanti',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'light-green',
                    key: 'Tipologia di ente scavalcato',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Tipologia di ente scavalcato ',
                        },
                      },
                      {
                        fieldGroupClassName: 'light-green',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'main-section',
                            fieldGroup: [
                              {
                                className:
                                  'border-right border-bottom width-100 remove-underline',
                                key: 'Corso d’acqua',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Corso d’acqua ' }],
                                },
                              },
                              {
                                className:
                                  'border-bottom width-100 remove-underline',
                                key: 'Zona edificata',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Zona edificata' }],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'main-section',
                            fieldGroup: [
                              {
                                className:
                                  'border-right border-bottom width-100 remove-underline',
                                key: 'Specchio d’acqua marina',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Specchio d’acqua marina',
                                    },
                                  ],
                                },
                              },
                              {
                                className:
                                  'border-bottom width-100 remove-underline',
                                key: 'Zona urbanizzata',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [{ label: 'Zona urbanizzata ' }],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'main-section light-green',
                            fieldGroup: [
                              {
                                className:
                                  'border-right border-bottom width-100 remove-underline',
                                key: 'Discontinuità orografica',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label:
                                        'Discontinuità orografica (vallata, piccoli canali, ecc.)',
                                    },
                                  ],
                                },
                              },
                              {
                                className:
                                  'width-100 remove-underline border-bottom',
                                key: 'Altra via di comunicazione',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Altra via di comunicazione',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'main-section light-green',
                            fieldGroup: [
                              {
                                className:
                                  'border-right width-50 remove-underline',
                                key: 'Ferrovia',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Ferrovia',
                                    },
                                  ],
                                },
                              },
                              {
                                fieldGroupClassName: 'main-section',
                                fieldGroup: [
                                  {
                                    className:
                                      'width-100 remove-underline altro-width',
                                    key: 'Altro-tipologia',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                      options: [
                                        {
                                          label: 'Altro :',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className: 'width-100',
                                    key: 'Altro-text-tipologia',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Indicare, ai fini della classificazione',
                    fieldGroupClassName: 'display-flex-column light-green',
                    fieldGroup: [
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Indicare, ai fini della classificazione, se l’ente scavalcato:',
                        },
                      },
                      {
                        className: 'remove-underline radio-margin',
                        key: 'Indicare, ai fini della classificazione - radio',
                        type: 'radio',
                        defaultValue: '',
                        templateOptions: {
                          key: 'Indicare, ai fini della classificazione - radio',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          options: [
                            {
                              value:
                                'Prevede affollamenti significativi e/o ha funzioni pubbliche e sociali essenziali e/o la cui interruzione provochi situazioni di emergenza e/o ha elevato valore naturalistico, economico e sociale (Ferrovia, zona edificata/antropizzata, strade a viabilità primaria, etc.)',
                              label:
                                'Prevede affollamenti significativi e/o ha funzioni pubbliche e sociali essenziali e/o la cui interruzione provochi situazioni di emergenza e/o ha elevato valore naturalistico, economico e sociale (Ferrovia, zona edificata/antropizzata, strade a viabilità primaria, etc.)',
                            },
                            {
                              value:
                                'Prevede normali affollamenti, senza funzioni pubbliche e sociali essenziali, la cui interruzione non provochi situazioni di emergenza e/o ha limitato valore naturalistico, economico e sociale (strade a viabilità secondaria, corsi d’acqua, laghi, specchi d’acqua marini, etc.)',
                              label:
                                'Prevede normali affollamenti, senza funzioni pubbliche e sociali essenziali, la cui interruzione non provochi situazioni di emergenza e/o ha limitato valore naturalistico, economico e sociale (strade a viabilità secondaria, corsi d’acqua, laghi, specchi d’acqua marini, etc.)',
                            },
                            {
                              value:
                                'Prevede presenza occasionale di persone e privo di valore naturalistico, economico e sociale (discontinuità naturali, depressioni del terreno, etc.)',
                              label:
                                'Prevede presenza occasionale di persone e privo di valore naturalistico, economico e sociale (discontinuità naturali, depressioni del terreno, etc.)',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  ,
                  {
                    fieldGroupClassName: 'file-upload-header',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Schemi geometrici',
                        },
                      },
                    ],
                  },
                  {
                    key: 'Schemi geometrici',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex-wrap file-upload-sub-header',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                className:
                                  'text-align-center file-upload-sub-header ',
                                wrappers: ['cube-section-title-wrapper'],
                                templateOptions: {
                                  title: 'Sezione trasversale',
                                },
                              },
                              {
                                key: 'Sezione trasversale',
                                type: 'cube-file-upload-with-preview-type',
                                className:
                                  'border-bottom width-100 remove-underline upload-section',
                                templateOptions: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .imagesAndPdf,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  modelData: bridgeInputTemplateComponent.model,
                                  token: bridgeInputTemplateComponent.token,
                                },
                              },
                            ],
                          },
                          {
                            key: 'Sezione longitudinale',
                            className: 'flex-1 border-left ',
                            fieldGroup: [
                              {
                                className:
                                  'text-align-center file-upload-sub-header',
                                wrappers: ['cube-section-title-wrapper'],
                                templateOptions: {
                                  title: 'Sezione longitudinale',
                                },
                              },
                              {
                                key: 'Sezione longitudinale',
                                type: 'cube-file-upload-with-preview-type',
                                className:
                                  'border-bottom width-100 remove-underline upload-section',
                                templateOptions: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .imagesAndPdf,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  modelData: bridgeInputTemplateComponent.model,
                                  token: bridgeInputTemplateComponent.token,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'file-upload-sub-header',
                        fieldGroup: [
                          {
                            fieldGroup: [
                              {
                                className:
                                  'text-align-center file-upload-sub-header ',
                                wrappers: ['cube-section-title-wrapper'],
                                templateOptions: {
                                  title: 'Pianta',
                                },
                              },
                              {
                                key: 'Pianta',
                                type: 'cube-file-upload-with-preview-type',
                                className:
                                  'border-bottom width-100 remove-underline upload-section',
                                templateOptions: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .imagesAndPdf,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  modelData: bridgeInputTemplateComponent.model,
                                  token: bridgeInputTemplateComponent.token,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Rilievo caratteristiche geometriche',
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Rilievo caratteristiche geometriche',
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                fieldGroup: [
                                  {
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        key: 'Luce complessiva spalla - spalla m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label:
                                            'Luce complessiva (spalla - spalla) [m]',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        className: 'light-green',
                                        key: 'Luce media campata m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'Luce media campata [m]',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        className: 'light-green',
                                        key: 'Luce massima campata m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'Luce massima campata [m]',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Lunghezza sbalzo soletta sx m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label:
                                            'Lunghezza sbalzo soletta sx [m]',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Lunghezza sbalzo soletta dx m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label:
                                            'Lunghezza sbalzo soletta dx [m]',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-1 border-left padding-left-35',
                            fieldGroup: [
                              {
                                fieldGroup: [
                                  {
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        key: 'N° campate',
                                        type: 'cube-number-type',
                                        defaultValue: '',
                                        templateOptions: {
                                          isInteger: true,
                                          min: 1,
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'N° campate',
                                        },
                                      },
                                      {
                                        key: 'Altezza max pile m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'Altezza max pile [m]',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Larghezza impalcato m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label:
                                            'Larghezza impalcato [m] (dalla sede stradale)',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Presenza di curve',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'Presenza di curve',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Tipologia elementi strutturali',
                    type: 'cube-ag-grid-table-type',
                    className: 'ag-theme-balham',
                    templateOptions: {
                      hasModelData: false,
                      height: '100%',
                      width: '100%',
                      gridOptions: {
                        columnDefs: [
                          {
                            headerName: 'Materiale',
                            field: 'materiale',
                            headerClass: 'header',
                            sortable:
                              !bridgeInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            minWidth: 100,
                            flex: 1,
                            // readonly: true, // TODO ???
                          },
                          {
                            headerName: 'Tipologia elementi strutturali',
                            headerClass: 'header',
                            children: [
                              {
                                field: 'spalle',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                headerComponent: headerRendererComponent,
                                headerComponentParams: {
                                  title: 'Spalle',
                                  subTitle: 'n° elementi',
                                  sortable:
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                                flex: 1.5,
                                minWidth: 150,
                                singleClickEdit: true,
                              },
                              {
                                field: 'pile',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                headerComponent: headerRendererComponent,
                                headerComponentParams: {
                                  title: 'Pile',
                                  subTitle: 'n° elementi',
                                  sortable:
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                                flex: 1.5,
                                minWidth: 200,
                                singleClickEdit: true,
                              },
                              {
                                field: 'impalcato',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                headerComponent: headerRendererComponent,
                                headerComponentParams: {
                                  title: 'Impalcato',
                                  subTitle: 'n° campate',
                                  sortable:
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                                flex: 1.5,
                                minWidth: 200,
                                singleClickEdit: true,
                              },
                            ],
                          },
                        ],
                      },
                    },
                    fieldArray: {
                      fieldGroup: [
                        {
                          type: 'input',
                          key: 'materiale',
                          templateOptions: {
                            // required: true, // TODO ???
                            readonly: true,
                          },
                        },
                        {
                          fieldGroup: [
                            {
                              type: 'input',
                              key: 'spalle',
                            },
                            {
                              type: 'input',
                              key: 'pile',
                            },
                            {
                              type: 'input',
                              key: 'impalcato',
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    key: 'Tipologia elementi strutturali impalcato',
                    type: 'cube-ag-grid-table-type',
                    className: 'ag-theme-balham padding-left-35',
                    templateOptions: {
                      hasModelData: false,
                      height: '100%',
                      width: '100%',
                      gridOptions: {
                        columnDefs: [
                          {
                            headerName: 'Elemento strutturale',
                            field: 'Elemento strutturale',
                            headerClass: 'header',
                            sortable:
                              !bridgeInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            minWidth: 200,
                            // readonly: true, // TODO ???
                          },
                          {
                            headerName:
                              'Tipologia elementi strutturali IMPALCATO',
                            headerClass: 'header',
                            children: [
                              {
                                field: 'n° elementi totali',
                                flex: 1,
                                minWidth: 150,
                              },
                              {
                                field: 'C A',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                valueSetter: (params) => {
                                  params.data['n° elementi totali'] =
                                    +params.data['n° elementi totali'] +
                                    +params.newValue -
                                    +params.oldValue;
                                  params.data['C A'] = params.newValue;
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l1
                                  ][
                                    'Tipologia elementi strutturali impalcato'
                                  ] = [].concat(
                                    bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l1
                                    ][
                                      'Tipologia elementi strutturali impalcato'
                                    ]
                                  );

                                  return true;
                                },
                                flex: 1,
                                minWidth: 150,
                                singleClickEdit: true,
                              },
                              {
                                field: 'C A P',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                valueSetter: (params) => {
                                  params.data['n° elementi totali'] =
                                    +params.data['n° elementi totali'] +
                                    +params.newValue -
                                    +params.oldValue;
                                  params.data['C A P'] = params.newValue;
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l1
                                  ][
                                    'Tipologia elementi strutturali impalcato'
                                  ] = [].concat(
                                    bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l1
                                    ][
                                      'Tipologia elementi strutturali impalcato'
                                    ]
                                  );

                                  return true;
                                },
                                flex: 1,
                                minWidth: 150,
                                singleClickEdit: true,
                              },
                              {
                                field: 'Acciaio',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                valueSetter: (params) => {
                                  params.data['n° elementi totali'] =
                                    +params.data['n° elementi totali'] +
                                    +params.newValue -
                                    +params.oldValue;
                                  params.data['Acciaio'] = params.newValue;
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l1
                                  ][
                                    'Tipologia elementi strutturali impalcato'
                                  ] = [].concat(
                                    bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l1
                                    ][
                                      'Tipologia elementi strutturali impalcato'
                                    ]
                                  );

                                  return true;
                                },
                                flex: 1,
                                minWidth: 150,
                                singleClickEdit: true,
                              },
                              {
                                field: 'Acciaio Calcestruzzo',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                valueSetter: (params) => {
                                  params.data['n° elementi totali'] =
                                    +params.data['n° elementi totali'] +
                                    +params.newValue -
                                    +params.oldValue;
                                  params.data['Acciaio Calcestruzzo'] =
                                    params.newValue;
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l1
                                  ][
                                    'Tipologia elementi strutturali impalcato'
                                  ] = [].concat(
                                    bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l1
                                    ][
                                      'Tipologia elementi strutturali impalcato'
                                    ]
                                  );

                                  return true;
                                },
                                flex: 1,
                                minWidth: 150,
                                singleClickEdit: true,
                              },
                              {
                                field: 'Muratura',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                valueSetter: (params) => {
                                  params.data['n° elementi totali'] =
                                    +params.data['n° elementi totali'] +
                                    +params.newValue -
                                    +params.oldValue;
                                  params.data['Muratura'] = params.newValue;
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l1
                                  ][
                                    'Tipologia elementi strutturali impalcato'
                                  ] = [].concat(
                                    bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l1
                                    ][
                                      'Tipologia elementi strutturali impalcato'
                                    ]
                                  );

                                  return true;
                                },
                                flex: 1,
                                minWidth: 150,
                                singleClickEdit: true,
                              },
                              {
                                field: 'Legno',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                valueSetter: (params) => {
                                  params.data['n° elementi totali'] =
                                    +params.data['n° elementi totali'] +
                                    +params.newValue -
                                    +params.oldValue;
                                  params.data['Legno'] = params.newValue;
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l1
                                  ][
                                    'Tipologia elementi strutturali impalcato'
                                  ] = [].concat(
                                    bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l1
                                    ][
                                      'Tipologia elementi strutturali impalcato'
                                    ]
                                  );

                                  return true;
                                },
                                flex: 1,
                                minWidth: 150,
                                singleClickEdit: true,
                              },
                              {
                                field: 'Altro',
                                cellClass: ['ag-cell-edit'],
                                cellClassRules: {
                                  disabled: () =>
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                editable:
                                  !bridgeInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                valueParser: (params) => {
                                  if (Number(params.newValue))
                                    return Number(params.newValue);

                                  const newValue = params.newValue.replace(
                                    /[^0-9\.]+/g,
                                    ''
                                  );

                                  return newValue ? Number(newValue) : '';
                                },
                                valueSetter: (params) => {
                                  params.data['n° elementi totali'] =
                                    +params.data['n° elementi totali'] +
                                    +params.newValue -
                                    +params.oldValue;
                                  params.data['Altro'] = params.newValue;
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l1
                                  ][
                                    'Tipologia elementi strutturali impalcato'
                                  ] = [].concat(
                                    bridgeInputTemplateComponent.model[
                                      BridgeConstants.levels.l1
                                    ][
                                      'Tipologia elementi strutturali impalcato'
                                    ]
                                  );

                                  return true;
                                },
                                flex: 1,
                                minWidth: 150,
                                singleClickEdit: true,
                              },
                            ],
                          },
                        ],
                      },
                    },
                    fieldArray: {
                      fieldGroup: [
                        {
                          type: 'input',
                          key: 'Elemento strutturale',
                          templateOptions: {
                            // required: true, // TODO ???
                            readonly: true,
                          },
                        },
                        {
                          fieldGroup: [
                            {
                              type: 'input',
                              key: 'n° elementi totali',
                            },
                            {
                              type: 'input',
                              key: 'C A',
                            },
                            {
                              type: 'input',
                              key: 'C A P',
                            },
                            {
                              type: 'input',
                              key: 'Acciaio',
                            },
                            {
                              type: 'input',
                              key: 'Acciaio Calcestruzzo',
                            },
                            {
                              type: 'input',
                              key: 'Muratura',
                            },
                            {
                              type: 'input',
                              key: 'Legno',
                            },
                            {
                              type: 'input',
                              key: 'Altro',
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Apparecchi di appoggio',
                    },
                  },
                  {
                    fieldGroup: [
                      {
                        key: 'Apparecchi di appoggio',
                        fieldGroup: [
                          {
                            key: 'Apparecchi di appoggio - radio',
                            className: 'remove-underline',
                            type: 'radio',
                            defaultValue: '',
                            templateOptions: {
                              key: 'Apparecchi di appoggio - radio',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  label: 'Assenti',
                                  value: 'Assenti',
                                  class: true,
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'Presenti') {
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'Presenti') {
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'Presenti') {
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'Tipo di apparecchi',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'Tipo di apparecchi',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'n apparecchi rilevabili',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'n apparecchi rilevabili',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'n apparecchi tot',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'n apparecchi tot',
                                      ])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                key: 'Apparecchi di appoggio - radio',
                                className: 'remove-underline',
                                type: 'radio',
                                defaultValue: '',
                                templateOptions: {
                                  key: 'Apparecchi di appoggio - radio',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Presenti',
                                      value: 'Presenti',
                                    },
                                  ],
                                  change: (field) => {
                                    if (
                                      field.formControl.value !== 'Presenti'
                                    ) {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (
                                      field.formControl.value !== 'Presenti'
                                    ) {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !== 'Presenti'
                                      ) {
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'Tipo di apparecchi',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'Tipo di apparecchi',
                                          ])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'n apparecchi rilevabili',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'n apparecchi rilevabili',
                                          ])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'n apparecchi tot',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'n apparecchi tot',
                                          ])
                                          ?.disable();
                                      } else {
                                        const isFieldDisabled =
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          );
                                        if (!isFieldDisabled) {
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti - input',
                                              'Tipo di apparecchi',
                                            ])
                                            ?.enable();
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti - input',
                                              'n apparecchi rilevabili',
                                            ])
                                            ?.enable();
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti - input',
                                              'n apparecchi tot',
                                            ])
                                            ?.enable();
                                        }
                                      }
                                    }, 0);
                                  },
                                },
                              },
                              {
                                key: 'Presenti - input',
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'width-100 border-right  flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'Tipo di apparecchi',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'Tipo di apparecchi',
                                        },
                                      },
                                      {
                                        key: 'n apparecchi rilevabili',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'n° apparecchi rilevabili',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'width-100 flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'n apparecchi tot',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'n° apparecchi tot ',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Nessun apparecchio visibile',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          key: 'Nessun apparecchio visibile',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label:
                                                'Nessun apparecchio visibile',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Apparecchi sismici',
                    },
                  },
                  {
                    fieldGroup: [
                      {
                        key: 'Apparecchi sismici',
                        fieldGroup: [
                          {
                            key: 'Apparecchi sismici - radio',
                            className: 'remove-underline',
                            type: 'radio',
                            defaultValue: '',
                            templateOptions: {
                              key: 'Apparecchi sismici - radio',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  label: 'Assenti',
                                  value: 'Assenti',
                                  class: true,
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'Presenti') {
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'Presenti') {
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'Tipo di apparecchi',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi rilevabili',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti - input',
                                      'n apparecchi tot',
                                    ])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'Presenti') {
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'Tipo di apparecchi',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'Tipo di apparecchi',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'n apparecchi rilevabili',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'n apparecchi rilevabili',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'n apparecchi tot',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti - input',
                                        'n apparecchi tot',
                                      ])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                key: 'Apparecchi sismici - radio',
                                className: 'remove-underline',
                                type: 'radio',
                                defaultValue: '',
                                templateOptions: {
                                  key: 'Apparecchi sismici - radio',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Presenti',
                                      value: 'Presenti',
                                    },
                                  ],
                                  change: (field) => {
                                    if (
                                      field.formControl.value !== 'Presenti'
                                    ) {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (
                                      field.formControl.value !== 'Presenti'
                                    ) {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'Tipo di apparecchi',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi rilevabili',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti - input',
                                          'n apparecchi tot',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !== 'Presenti'
                                      ) {
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'Tipo di apparecchi',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'Tipo di apparecchi',
                                          ])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'n apparecchi rilevabili',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'n apparecchi rilevabili',
                                          ])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'n apparecchi tot',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti - input',
                                            'n apparecchi tot',
                                          ])
                                          ?.disable();
                                      } else {
                                        const isFieldDisabled =
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          );
                                        if (!isFieldDisabled) {
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti - input',
                                              'Tipo di apparecchi',
                                            ])
                                            ?.enable();
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti - input',
                                              'n apparecchi rilevabili',
                                            ])
                                            ?.enable();
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti - input',
                                              'n apparecchi tot',
                                            ])
                                            ?.enable();
                                        }
                                      }
                                    }, 0);
                                  },
                                },
                              },
                              {
                                key: 'Presenti - input',
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'width-100 border-right  flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'Tipo di apparecchi',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'Tipo di apparecchi',
                                        },
                                      },
                                      {
                                        key: 'n apparecchi rilevabili',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'n° apparecchi rilevabili',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'width-100 flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'n apparecchi tot',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'n° apparecchi tot ',
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Nessun apparecchio visibile',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          key: 'Nessun apparecchio visibile',
                                          name: 'Nessun apparecchio visibile',
                                          options: [
                                            {
                                              value: true,
                                              label:
                                                'Nessun apparecchio visibile',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Elementi critici - Vulnerabilità strutturale e fondazionale (vedi § 3.3 delle Linee Guida)',
                    },
                  },
                  {
                    fieldGroup: [
                      {
                        key: 'Elementi critici - Vulnerabilità strutturale e fondazionale vedi delle Linee Guida',
                        fieldGroup: [
                          {
                            key: 'Elementi critici - Vulnerabilità strutturale e fondazionale vedi delle Linee Guida',
                            className: 'remove-underline',
                            type: 'radio',
                            defaultValue: '',
                            templateOptions: {
                              key: 'Elementi critici - Vulnerabilità strutturale e fondazionale vedi delle Linee Guida',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  label: 'Assenti',
                                  value: 'Assenti',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'Presenti') {
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio1'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio1'])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Tipologia di elemento'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Tipologia di elemento'])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti',
                                      'Stato di degrado descrizione sintetica',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti',
                                      'Stato di degrado descrizione sintetica',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio1'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Tipologia di elemento'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti',
                                      'Stato di degrado descrizione sintetica',
                                    ])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'Presenti') {
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio1'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio1'])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Tipologia di elemento'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Tipologia di elemento'])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti',
                                      'Stato di degrado descrizione sintetica',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti',
                                      'Stato di degrado descrizione sintetica',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio1'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Tipologia di elemento'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'Presenti',
                                      'Stato di degrado descrizione sintetica',
                                    ])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'Presenti') {
                                    field.formControl.parent
                                      ?.get(['Presenti', 'Presenti - radio1'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Presenti', 'Presenti - radio1'])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti',
                                        'Tipologia di elemento',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti',
                                        'Tipologia di elemento',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti',
                                        'Stato di degrado descrizione sintetica',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'Presenti',
                                        'Stato di degrado descrizione sintetica',
                                      ])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio1'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Tipologia di elemento',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Stato di degrado descrizione sintetica',
                                        ])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                key: 'Elementi critici - Vulnerabilità strutturale e fondazionale vedi delle Linee Guida',
                                className: 'remove-underline',
                                type: 'radio',
                                defaultValue: '',
                                templateOptions: {
                                  key: 'Elementi critici - Vulnerabilità strutturale e fondazionale vedi delle Linee Guida',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Presenti',
                                      value: 'Presenti',
                                    },
                                  ],
                                  change: (field) => {
                                    if (
                                      field.formControl.value !== 'Presenti'
                                    ) {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio1'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio1'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Tipologia di elemento',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Tipologia di elemento',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Stato di degrado descrizione sintetica',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Stato di degrado descrizione sintetica',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio1'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Tipologia di elemento',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Stato di degrado descrizione sintetica',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (
                                      field.formControl.value !== 'Presenti'
                                    ) {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio1'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio1'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Tipologia di elemento',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Tipologia di elemento',
                                        ])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Stato di degrado descrizione sintetica',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Stato di degrado descrizione sintetica',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio1'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Tipologia di elemento',
                                        ])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get([
                                          'Presenti',
                                          'Stato di degrado descrizione sintetica',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !== 'Presenti'
                                      ) {
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti',
                                            'Presenti - radio1',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti',
                                            'Presenti - radio1',
                                          ])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti',
                                            'Tipologia di elemento',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti',
                                            'Tipologia di elemento',
                                          ])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti',
                                            'Stato di degrado descrizione sintetica',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti',
                                            'Stato di degrado descrizione sintetica',
                                          ])
                                          ?.disable();
                                      } else {
                                        const isFieldDisabled =
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          );
                                        if (!isFieldDisabled) {
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti',
                                              'Presenti - radio1',
                                            ])
                                            ?.enable();
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti',
                                              'Tipologia di elemento',
                                            ])
                                            ?.enable();
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti',
                                              'Stato di degrado descrizione sintetica',
                                            ])
                                            ?.enable();
                                        }
                                      }
                                    }, 0);
                                  },
                                },
                              },
                              {
                                key: 'Presenti',
                                className: 'width-100',
                                fieldGroupClassName:
                                  'display-flex-wrap light-green',
                                fieldGroup: [
                                  {
                                    className:
                                      'width-100 border-right flex-1 light-green',
                                    fieldGroup: [
                                      {
                                        key: 'Presenti - radio1',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec remove-underline',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          key: 'Presenti - radio1',
                                          options: [
                                            {
                                              value:
                                                'Condizioni tali da non doverli segnalare immediatamente assenza di uno stato di degrado avanzato',
                                              label:
                                                'Condizioni tali da non doverli segnalare immediatamente (assenza di uno stato di degrado avanzato)',
                                              key: 'Presenti - radio1',
                                            },
                                            {
                                              value:
                                                'Condizioni tali da doverli segnalare immediatamente presenza di uno stato di degrado avanzato',
                                              label:
                                                'Condizioni tali da doverli segnalare immediatamente (presenza di uno stato di degrado avanzato)',
                                              key: 'Presenti - radio1',
                                            },
                                            {
                                              value: 'Non ispezionabili',
                                              label: 'Non ispezionabili',
                                              key: 'Presenti - radio1',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'width-100 flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'Tipologia di elemento',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'Tipologia di elemento',
                                        },
                                      },
                                      {
                                        key: 'Stato di degrado descrizione sintetica',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label:
                                            'Stato di degrado (descrizione sintetica)',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'light-green',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Elementi critici - Vulnerabilità sismica (vedi § 3.3 delle Linee Guida)',
                        },
                      },
                      {
                        key: 'Elementi critici - Vulnerabilità sismica vedi delle Linee Guida',
                        fieldGroupClassName: 'light-green',
                        fieldGroup: [
                          {
                            key: 'Elementi critici - Vulnerabilità sismica vedi delle Linee Guida - radio',
                            className: 'remove-underline',
                            type: 'radio',
                            defaultValue: '',
                            templateOptions: {
                              key: 'Elementi critici - Vulnerabilità sismica vedi delle Linee Guida - radio',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              options: [
                                {
                                  label: 'Assenti',
                                  value: 'Assenti',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'Presenti') {
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'Presenti') {
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Presenti', 'Presenti - radio'])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'Presenti') {
                                    field.formControl.parent
                                      ?.get(['Presenti', 'Presenti - radio'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Presenti', 'Presenti - radio'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      );
                                    if (!isFieldDisabled) {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            fieldGroupClassName: 'display-flex light-green',
                            fieldGroup: [
                              {
                                key: 'Elementi critici - Vulnerabilità sismica vedi delle Linee Guida - radio',
                                className: 'remove-underline',
                                type: 'radio',
                                defaultValue: '',
                                templateOptions: {
                                  key: 'Elementi critici - Vulnerabilità sismica vedi delle Linee Guida - radio',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      label: 'Presenti',
                                      value: 'Presenti',
                                    },
                                  ],
                                  change: (field) => {
                                    if (
                                      field.formControl.value !== 'Presenti'
                                    ) {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (
                                      field.formControl.value !== 'Presenti'
                                    ) {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Presenti', 'Presenti - radio'])
                                        ?.enable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !== 'Presenti'
                                      ) {
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti',
                                            'Presenti - radio',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Presenti',
                                            'Presenti - radio',
                                          ])
                                          ?.disable();
                                      } else {
                                        const isFieldDisabled =
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          );
                                        if (!isFieldDisabled) {
                                          field.formControl.parent
                                            ?.get([
                                              'Presenti',
                                              'Presenti - radio',
                                            ])
                                            ?.enable();
                                        }
                                      }
                                    }, 0);
                                  },
                                },
                              },
                              {
                                key: 'Presenti',
                                className: 'width-100',
                                fieldGroupClassName:
                                  'display-flex-wrap light-green',
                                fieldGroup: [
                                  {
                                    className: 'width-100 border-right flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'Presenti - radio',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec remove-underline',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          key: 'Presenti - radio',
                                          options: [
                                            {
                                              value:
                                                'Condizioni tali da non doverli segnalare immediatamente assenza di uno stato di degrado avanzato',
                                              label:
                                                'Condizioni tali da non doverli segnalare immediatamente (assenza di uno stato di degrado avanzato)',
                                              key: 'Presenti - radio',
                                            },
                                            {
                                              value:
                                                'Condizioni tali da doverli segnalare immediatamente presenza di uno stato di degrado avanzato',
                                              label:
                                                'Condizioni tali da doverli segnalare immediatamente (presenza di uno stato di degrado avanzato)',
                                              key: 'Presenti - radio',
                                            },
                                            {
                                              value: 'Non ispezionabili',
                                              label: 'Non ispezionabili',
                                              key: 'Presenti - radio',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    className: 'width-100 flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'Tipologia di elemento',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label: 'Tipologia di elemento',
                                        },
                                      },
                                      {
                                        key: 'Stato di degrado descrizione sintetica',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                          label:
                                            'Stato di degrado (descrizione sintetica)',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            template: `<h4 class="light-green">Nota: <span class="font-weight-normal"> Si definiscono elementi critici gli elementi particolarmente soggetti ai fenomeni di degrado e i cui eventuali malfunzionamenti possono incidere significativamente sul comportamento strutturale o sismico globale del ponte, ovvero gli elementi o le condizioni per i quali la presenza di uno stato di degrado avanzato è da segnalare immediatamente. La presenza di elementi critici con stato di degrado avanzato comporta un livello di difettosità attuale alto. </span></h4>`,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Elementi di vulnerabilità sismica',
                    fieldGroupClassName: 'display-flex-column light-green',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Elementi di vulnerabilità sismica (vedi § 4.3.3 delle Linee Guida)',
                        },
                      },
                      {
                        key: 'Elementi di vulnerabilità sismica vedi 4 3 3 delle Linee Guida',
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            fieldGroupClassName:
                              'display-flex-column border-right',
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                className: 'remove-underline radio-margin',
                                key: 'Assenti/Presenti',
                                type: 'radio',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Assenti/Presenti',
                                  options: [
                                    {
                                      value: 'Assenti',
                                      label: 'Assenti',
                                    },
                                    {
                                      value:
                                        'Presenti (influenti sul comportamento sismico)',
                                      label:
                                        'Presenti (influenti sul comportamento sismico)',
                                    },
                                  ],
                                  change: (field, e) => {
                                    bridgeInputTemplateComponent.syncSharedModelDataForList(
                                      'elementiDiVulnerabilitaSismica',
                                      field.formControl.value
                                    );
                                  },
                                  onReset: (field) => {
                                    bridgeInputTemplateComponent.syncSharedModelDataForList(
                                      'elementiDiVulnerabilitaSismica',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'Descrizione',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'Descrizione',
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<h4 class="font-weight-normal">L’eventuale esposizione dell’opera a correnti di vento marine (“aerosol marini”) o all’azione aggressiva dei sali antigelo è influente ai fini della classificazione in quanto può determinare una maggiore rapidità di evoluzione del degrado.</h4>`,
                  },
                  {
                    key: 'Esposizione a correnti di vento marini',
                    fieldGroupClassName: 'display-flex-column light-green',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Esposizione a correnti di vento marini (aerosol marini) o all’azione aggressiva di sali antigelo ',
                        },
                      },
                      {
                        key: 'Esposizione a correnti di vento marini (aerosol marini) o all azione aggressiva di sali antigelo',
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            fieldGroupClassName:
                              'display-flex-column border-right',
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                key: 'SI/NO',
                                type: 'radio',
                                defaultValue: '',
                                className: 'remove-underline radio-margin',
                                templateOptions: {
                                  key: 'SI/NO',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      className:
                                        'remove-underline radio-margin',
                                      value: 'SI-Esposizione',
                                      label: 'SI',
                                    },
                                    {
                                      value: 'NO-Esposizione',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field, e) => {
                                    if (
                                      field.formControl.value !==
                                      'SI-Esposizione'
                                    ) {
                                      field.parent.formControl
                                        .get([
                                          'Tale da determinare una maggiore rapidità del degrado',
                                        ])
                                        ?.setValue(null);
                                      field.parent.formControl
                                        .get([
                                          'Tale da determinare una maggiore rapidità del degrado',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.parent.formControl
                                        .get([
                                          'Tale da determinare una maggiore rapidità del degrado',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    field.parent.formControl
                                      .get([
                                        'Tale da determinare una maggiore rapidità del degrado',
                                      ])
                                      ?.disable();
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !==
                                        'SI-Esposizione'
                                      ) {
                                        field.parent.formControl
                                          .get([
                                            'Tale da determinare una maggiore rapidità del degrado',
                                          ])
                                          ?.setValue(null);
                                        field.parent.formControl
                                          .get([
                                            'Tale da determinare una maggiore rapidità del degrado',
                                          ])
                                          ?.disable();
                                      } else {
                                        const isFieldDisabled =
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          );
                                        if (!isFieldDisabled) {
                                          field.parent.formControl
                                            .get([
                                              'Tale da determinare una maggiore rapidità del degrado',
                                            ])
                                            ?.enable();
                                        }
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                key: 'Tale da determinare una maggiore rapidità del degrado',
                                type: 'radio',
                                defaultValue: '',
                                className: 'remove-underline flex-1',
                                templateOptions: {
                                  key: 'Tale da determinare una maggiore rapidità del degrado',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      value:
                                        'Tale da determinare una maggiore rapidità del degrado',
                                      label:
                                        'Tale da determinare una maggiore rapidità del degrado',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Informazioni Ispezione',
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Informazioni Ispezione',
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex-column',
                        fieldGroup: [
                          {
                            key: 'Possibilità di accedere al di sotto del ponte',
                            fieldGroupClassName: 'display-flex-wrap',
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                className: 'flex-1 ',
                                template: `<h4>Possibilità di accedere al di sotto del ponte</h4>`,
                              },
                              {
                                key: 'Possibilità di accedere - radio',
                                type: 'radio',
                                defaultValue: '',
                                className:
                                  'remove-underline radio-padding flex-2',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Possibilità di accedere - radio',
                                  flex: true,
                                  options: [
                                    {
                                      value: 'SI-Possibilità',
                                      label: 'SI',
                                    },
                                    {
                                      value: 'NO-Possibilità',
                                      label: 'NO',
                                    },
                                    {
                                      value: 'PARZIALE-Possibilità',
                                      label: 'PARZIALE',
                                    },
                                  ],
                                  change: (field) => {
                                    if (
                                      field.formControl.value !==
                                      'PARZIALE-Possibilità'
                                    ) {
                                      field.formControl.parent
                                        ?.get([
                                          'n campate completamente ispezionate',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'n campate completamente ispezionate',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get([
                                          'n campate completamente ispezionate',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (
                                      field.formControl.value !==
                                      'PARZIALE-Possibilità'
                                    ) {
                                      field.formControl.parent
                                        ?.get([
                                          'n campate completamente ispezionate',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'n campate completamente ispezionate',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get([
                                          'n campate completamente ispezionate',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !==
                                        'PARZIALE-Possibilità'
                                      ) {
                                        field.formControl.parent
                                          ?.get([
                                            'n campate completamente ispezionate',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'n campate completamente ispezionate',
                                          ])
                                          ?.disable();
                                      } else {
                                        const isFieldDisabled =
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          );
                                        if (!isFieldDisabled) {
                                          field.formControl.parent
                                            ?.get([
                                              'n campate completamente ispezionate',
                                            ])
                                            ?.enable();
                                        }
                                      }
                                    }, 0);
                                  },
                                },
                              },
                              {
                                className: 'flex-1',
                                key: 'n campate completamente ispezionate',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  label: 'n° campate completamente ispezionate',
                                },
                                validators: {
                                  validation: ['numberPattern'],
                                },
                              },
                            ],
                          },
                          {
                            key: 'Ispezionati entrambi i prospetti',
                            fieldGroupClassName: 'display-flex-wrap',
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                template: `<h4>Ispezionati entrambi i prospetti</h4>`,
                              },
                              {
                                key: 'Ispezionati entrambi - radio',
                                type: 'radio',
                                defaultValue: '',
                                className:
                                  'remove-underline radio-padding flex-2',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  key: 'Ispezionati entrambi - radio',
                                  flex: true,
                                  options: [
                                    {
                                      value: 'SI-Ispezionati',
                                      label: 'SI',
                                    },
                                    {
                                      value: 'NO-Ispezionati',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (
                                      field.formControl.value !==
                                      'NO-Ispezionati'
                                    ) {
                                      field.formControl.parent.parent
                                        ?.get(['Prospetto Ispezionato'])
                                        ?.setValue(null);
                                      field.formControl.parent.parent
                                        ?.get(['Prospetto Ispezionato'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent.parent
                                        ?.get(['Prospetto Ispezionato'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (
                                      field.formControl.value !==
                                      'NO-Ispezionati'
                                    ) {
                                      field.formControl.parent.parent
                                        ?.get(['Prospetto Ispezionato'])
                                        ?.setValue(null);
                                      field.formControl.parent.parent
                                        ?.get(['Prospetto Ispezionato'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent.parent
                                        ?.get(['Prospetto Ispezionato'])
                                        ?.enable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !==
                                        'NO-Ispezionati'
                                      ) {
                                        field.formControl.parent.parent
                                          ?.get(['Prospetto Ispezionato'])
                                          ?.setValue(null);
                                        field.formControl.parent.parent
                                          ?.get(['Prospetto Ispezionato'])
                                          ?.disable();
                                      } else {
                                        const isFieldDisabled =
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          );
                                        if (!isFieldDisabled) {
                                          field.formControl.parent.parent
                                            ?.get(['Prospetto Ispezionato'])
                                            ?.enable();
                                        }
                                      }
                                    }, 0);
                                  },
                                },
                              },
                              // Empty field for design purpose
                              {
                                className: 'flex-1',
                              },
                            ],
                          },
                          {
                            key: 'Prospetto Ispezionato',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled,
                                  true
                                ),
                              label: 'Prospetto Ispezionato',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
                hideExpression: '!formState.l1Descrizione',
              },
              {
                resetOnHide: true,
                fieldGroup: [
                  {
                    fieldGroupClassName: 'page-heading',
                    fieldGroup: [
                      {
                        className: 'width-100',
                        template: `<div class="page-heading-text" style="margin: auto 0px;">Schede di ispezione ponti di Livello 1 - Fenomeni di frana e fenomeni idraulici</div>`,
                      },
                      {
                        // Switch to L1 - Descrizione Ispezione
                        type: 'cube-button-type',
                        defaultValue: true,
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Descrizione Ispezione',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.options.formState.l1Descrizione =
                              true;
                            bridgeInputTemplateComponent.options.formState.l1Frane =
                              false;
                          },
                        },
                      },
                      {
                        className: 'page-heading-image',
                        template: `<img src="assets/rina/RINA-dark.png"/>`,
                      },
                    ],
                  },
                  {
                    key: 'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Codice IOP',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Codice IOP',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Nome Ponte Viadotto',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Nome Ponte/Viadotto',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'nomePonte',
                              e.target.value
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    key: 'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-2',
                        key: 'Strada di appartenenza',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Strada di appartenenza',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Progressiva km iniziale',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Progressiva km iniziale',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'progressivaKmIniziale',
                              e.target.value
                            );
                          },
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Progressiva km finale',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Progressiva km finale',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          change: (field, e) => {
                            bridgeInputTemplateComponent.syncSharedModelData(
                              'progressivaKmFinale',
                              e.target.value
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    key: 'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici',
                    fieldGroupClassName: 'flex-2',
                    fieldGroup: [
                      {
                        key: 'Schede di ispezione ponti di Livello 1 Fenomeni di frana e fenomeni idraulici section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                        },
                        fieldArray: {
                          fieldGroupClassName: 'display-flex-wrap grid-gap',
                          fieldGroup: [
                            {
                              className: 'flex-1',
                              key: 'Tecnico rilevatore',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled
                                  ),
                              },
                              expressionProperties: {
                                'templateOptions.label':
                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                              },
                            },
                            {
                              className: 'flex-1',
                              key: 'Data',
                              type: 'cube-date-range-picker-type',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled
                                  ),
                                max: (() => {
                                  const today = new Date();
                                  const maxDate = new Date(today);
                                  maxDate.setDate(today.getDate() + 7);

                                  return maxDate;
                                })(),
                              },
                              expressionProperties: {
                                'templateOptions.label':
                                  "'Data n.' + (+field.parent.key + 1)",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    key: 'Localizzazione Fenomeni di frana',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: { title: 'Localizzazione' },
                      },
                      {
                        fieldGroupClassName: 'display-flex-column',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap grid-gap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                fieldGroupClassName: 'display-flex-column',
                                fieldGroup: [
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Provincia',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled,
                                          true
                                        ),
                                      label: 'Provincia',
                                      change: (field, e) => {
                                        bridgeInputTemplateComponent.syncSharedModelData(
                                          'provincia',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Regione',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled,
                                          true
                                        ),
                                      label: 'Regione',
                                      change: (field, e) => {
                                        bridgeInputTemplateComponent.syncSharedModelData(
                                          'regione',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Comune',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled,
                                          true
                                        ),
                                      label: 'Comune',
                                      change: (field, e) => {
                                        bridgeInputTemplateComponent.syncSharedModelData(
                                          'comune',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Località',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled,
                                          true
                                        ),
                                      label: 'Località',
                                      change: (field, e) => {
                                        bridgeInputTemplateComponent.syncSharedModelData(
                                          'localita',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Coordinate CTR',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label: 'Coordinate CTR ',
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Scala',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label: 'Scala',
                                    },
                                  },
                                  {
                                    type: 'input',
                                    defaultValue: '',
                                    key: 'Numero Toponimo',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label: 'Numero Toponimo',
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-2',
                                key: 'Coordinate Geografiche',
                                type: 'cube-block-table-type',
                                templateOptions: {
                                  grouptitle: 'Coordinate Geografiche',
                                },
                                fieldGroup: [
                                  {
                                    fieldGroup: [
                                      {
                                        key: 'ETRF2000/WGS84',
                                        type: 'radio',
                                        defaultValue: 'ETRF2000',
                                        className: 'sub-sec radio-sec ',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled,
                                              true
                                            ),
                                          key: 'ETRF2000/WGS84',
                                          options: [
                                            {
                                              value: 'ETRF2000',
                                              label: 'ETRF2000',
                                            },
                                            {
                                              value: 'WGS84',
                                              label: 'WGS84',
                                            },
                                          ],
                                          change: (field, e) => {
                                            bridgeInputTemplateComponent.syncSharedModelData(
                                              'coordinateGeografiche',
                                              field.formControl.value
                                            );
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    fieldGroup: [
                                      {
                                        key: 'Centro',
                                        wrappers: [
                                          'cube-coordinate-section-row-wrapper',
                                        ],
                                        templateOptions: {
                                          rowTitle: 'Centro',
                                        },
                                        fieldGroupClassName:
                                          'display-flex-column',
                                        fieldGroup: [
                                          {
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Quota s.l.m. [m]',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled,
                                                  true
                                                ),
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'centroQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            fieldGroupClassName:
                                              'display-flex grid-gap',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Longitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'centroLongitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Latitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'centroLatitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        key: 'Iniziale',
                                        wrappers: [
                                          'cube-coordinate-section-row-wrapper',
                                        ],
                                        templateOptions: {
                                          rowTitle: 'Iniziale',
                                        },
                                        fieldGroupClassName:
                                          'display-flex-column',
                                        fieldGroup: [
                                          {
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Quota s.l.m. [m]',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled,
                                                  true
                                                ),
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'inizialeQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            fieldGroupClassName:
                                              'display-flex grid-gap',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Longitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'inizialeLongitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Latitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'inizialeLatitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        key: 'Finale',
                                        wrappers: [
                                          'cube-coordinate-section-row-wrapper',
                                        ],
                                        templateOptions: {
                                          rowTitle: 'Finale',
                                        },
                                        fieldGroupClassName:
                                          'display-flex-column',
                                        fieldGroup: [
                                          {
                                            key: 'Quota slm m',
                                            type: 'input',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Quota s.l.m. [m]',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled,
                                                  true
                                                ),
                                              change: (field, e) => {
                                                bridgeInputTemplateComponent.syncSharedModelData(
                                                  'finaleQuota',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            fieldGroupClassName:
                                              'display-flex grid-gap',
                                            fieldGroup: [
                                              {
                                                className: 'flex-1',
                                                key: 'Longitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Longitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'finaleLongitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                              {
                                                className: 'flex-1',
                                                key: 'Latitudine',
                                                type: 'cube-number-type',
                                                defaultValue: '',
                                                templateOptions: {
                                                  label: 'Latitudine',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  onChange: (field, e) => {
                                                    bridgeInputTemplateComponent.syncSharedModelData(
                                                      'finaleLatitudine',
                                                      e.target.value
                                                    );
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap margin-top-20',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Ispezioni precedenti',
                            },
                          },
                          {
                            className: 'flex-2',
                            key: 'Numero',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Numero',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                            },
                            validators: {
                              validation: ['numberPattern'],
                            },
                          },
                          {
                            className: 'flex-2',
                            key: 'Data ultima ispezione',
                            type: 'cube-date-range-picker-type',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Data ultima ispezione ',
                              max: (() => {
                                const today = new Date();
                                const maxDate = new Date(today);
                                maxDate.setDate(today.getDate() + 7);

                                return maxDate;
                              })(),
                            },
                          },
                          {
                            className: 'flex-2',
                            key: 'Esito',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Esito',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap  border-bottom ',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 border-right padding-section',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'RISCHIO FRANA',
                            },
                          },
                          {
                            key: 'RISCHIO FRANA',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-3',
                            templateOptions: {
                              key: 'RISCHIO FRANA',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'Assente', label: 'Assente' },
                                { value: 'Presente', label: 'Presente' },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 border-right padding-section',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: { title: 'RISCHIO IDRAULICO' },
                          },
                          {
                            key: 'RISCHIO IDRAULICO',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-3',
                            templateOptions: {
                              key: 'RISCHIO IDRAULICO',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'Assente', label: 'Assente' },
                                { value: 'Presente', label: 'Presente' },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: { title: 'Informazioni generali' },
                  },
                  {
                    key: 'Informazioni generali',
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Proprietario',
                        templateOptions: {
                          label: 'Proprietario',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                        },
                      },
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Concessionario',
                        templateOptions: {
                          label: 'Concessionario',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                        },
                      },
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Ente vigilante',
                        templateOptions: {
                          label: 'Ente vigilante',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                        },
                      },
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Autorità distrettuale',
                        templateOptions: {
                          label: 'Autorità distrettuale ',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                        },
                      },
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Bacino idrografico',
                        templateOptions: {
                          label: 'Bacino idrografico',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    key: 'Contesto Geomorfologico',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Contesto Geomorfologico',
                        },
                      },
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: { title: 'Morfologia del sito' },
                      },
                      {
                        key: 'Morfologia del sito',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Cresta',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Cresta',
                                  name: 'gender',
                                  options: [{ value: true, label: 'Cresta' }],
                                },
                              },
                              {
                                key: 'Pendio dolce',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Pendio dolce',
                                  name: 'gender',
                                  options: [
                                    {
                                      value: true,
                                      label: 'Pendio dolce (0 - 10°)',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Pendio moderato',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Pendio moderato',
                                  name: 'gender',
                                  options: [
                                    {
                                      value: true,
                                      label: 'Pendio moderato (10° - 25°)',
                                    },
                                  ],
                                },
                              },
                              {
                                key: 'Pendio ripido',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Pendio ripido',
                                  name: 'gender',
                                  options: [
                                    {
                                      value: true,
                                      label: 'Pendio ripido (> 25°)',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Pianura',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Pianura',
                                  name: 'gender',
                                  options: [{ value: 1, label: 'Pianura' }],
                                },
                              },
                              {
                                key: 'Pianura alla base dei versanti',
                                type: 'checkbox',
                                defaultValue: false,
                                className: 'width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Pianura alla base dei versanti',
                                  name: 'gender',
                                  options: [
                                    {
                                      value: true,
                                      label: 'Pianura alla base dei versanti',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: { title: 'Unità fisiografica' },
                      },
                      {
                        key: 'Unità fisiografica',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Montuosa',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Montuosa',
                                  name: 'gender',
                                  options: [{ value: true, label: 'Montuosa' }],
                                },
                              },
                              {
                                key: 'Collinare',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Collinare',
                                  name: 'gender',
                                  options: [
                                    { value: true, label: 'Collinare' },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Pianura intermontana',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Pianura intermontana',
                                  name: 'gender',
                                  options: [
                                    {
                                      value: true,
                                      label: 'Pianura intermontana',
                                    },
                                  ],
                                },
                              },
                              {
                                key: 'Pianura bassa',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Pianura bassa',
                                  name: 'gender',
                                  options: [
                                    { value: true, label: 'Pianura bassa' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Confinamento alveo',
                        fieldGroup: [
                          {
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: { title: 'Confinamento alveo' },
                          },
                          {
                            key: 'Confinamento alveo radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              key: 'Confinamento alveo radio',
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              flex: 'true',
                              options: [
                                {
                                  value: 'Confinato',
                                  label: 'Confinato',
                                },
                                {
                                  value: 'Semiconfinato',
                                  label: 'Semiconfinato',
                                },
                                {
                                  value: 'Non confinato',
                                  label: 'Non confinato',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            type: 'textarea',
                            defaultValue: '',
                            key: 'Stralcio Cartografico Satellitare attuale e storico Foto Aeree Indicare Fonti e reperibilità vedi allegati',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label:
                                'Stralcio Cartografico. Satellitare attuale e storico. Foto Aeree (Indicare Fonti e reperibilità) (vedi allegati)',
                              theme: 'custom',
                              rows: 4,
                              grow: false,
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            type: 'textarea',
                            defaultValue: '',
                            key: 'Riprese fotografiche e eventuale sezione schematica illustrativa vedi allegati',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label:
                                'Riprese fotografiche e eventuale sezione schematica illustrativa (vedi allegati)',
                              theme: 'custom',
                              rows: 4,
                              grow: false,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Rischio frane e rischio idraulico da documentazione disponibile ',
                    },
                  },
                  {
                    key: 'Rischio frane e rischio idraulico da documentazione disponibile ',
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        type: 'textarea',
                        defaultValue: '',
                        key: 'Condizione di pericolosità rischio Cartografia PAI',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                          label:
                            'Condizione di pericolosità rischio Cartografia PAI',
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                      {
                        type: 'textarea',
                        defaultValue: '',
                        key: 'Altri documenti progetti PUC cartografie tecnico scientifico',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                          label:
                            'Altri documenti progetti PUC cartografie tecnico scientifico',
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                      {
                        type: 'textarea',
                        defaultValue: '',
                        key: 'Pericolosità PAI PSAI Frane',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                          label: 'Pericolosità PAI PSAI Frane',
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                      {
                        type: 'textarea',
                        defaultValue: '',
                        key: 'Pericolosità PAI PSAI Idraulico',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled
                            ),
                          label: 'Pericolosità PAI PSAI Idraulico',
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                    ],
                  },
                  {
                    key: 'Rischio frane',
                    fieldGroup: [
                      {
                        template: `<div class="sub-heading">
                              <span class="font-weight-bolder">Rischio frane</span>
                          </div>`,
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Area riconosciuta pericolosa (allegare riferimenti)',
                        },
                      },
                      {
                        key: 'Area riconosciuta pericolosa allegare riferimenti',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Fenomeno riconosciuto ma non ancora studiato',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Fenomeno riconosciuto ma non ancora studiato',
                                  name: 'gender',
                                  options: [
                                    {
                                      value:
                                        'Fenomeno riconosciuto ma non ancora studiato',
                                      label:
                                        'Fenomeno riconosciuto ma non ancora studiato',
                                    },
                                  ],
                                },
                              },
                              {
                                key: 'Fenomeno riconosciuto e studiato',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Fenomeno riconosciuto e studiato',
                                  name: 'gender',
                                  options: [
                                    {
                                      value: 'Fenomeno riconosciuto e studiato',
                                      label: 'Fenomeno riconosciuto e studiato',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Fenomeno modellato e oggetto di monitoraggio',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Fenomeno modellato e oggetto di monitoraggio',
                                  name: 'gender',
                                  options: [
                                    {
                                      value:
                                        'Fenomeno modellato e oggetto di monitoraggio',
                                      label:
                                        'Fenomeno modellato e oggetto di monitoraggio',
                                    },
                                  ],
                                },
                              },
                              {
                                key: 'Fenomeno oggetto di opere di mitigazione',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Fenomeno oggetto di opere di mitigazione',
                                  name: 'gender',
                                  options: [
                                    {
                                      value:
                                        'Fenomeno oggetto di opere di mitigazione',
                                      label:
                                        'Fenomeno oggetto di opere di mitigazione',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Contesto geologico',
                        },
                      },
                      {
                        key: 'Contesto geologico',
                        fieldGroupClassName: 'display-flex-column',
                        fieldGroup: [
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Formazioni',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Formazioni',
                            },
                          },
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Unità 1 ',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Unità 1 ',
                            },
                          },
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Unità 2',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Unità 2',
                            },
                          },
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Unità 3',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Unità 3',
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Tipologia di fenomeno',
                        },
                      },
                      {
                        key: 'Tipologia di fenomeno',
                        className: 'flex-2',
                        fieldGroupClassName: 'display-flex width-100',
                        fieldGroup: [
                          {
                            className: 'width-100',
                            fieldGroup: [
                              {
                                key: 'Tipologia di fenomeno - radio',
                                type: 'radio',
                                className:
                                  'display-flex justify-content-center border-right radio-sec',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Tipologia di fenomeno - radio',
                                  options: [
                                    {
                                      value: 'Accertato',
                                      label: 'Accertato',
                                    },
                                  ],
                                },
                              },
                              {
                                key: 'Accertato',
                                fieldGroup: [
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    fieldGroup: [
                                      {
                                        key: 'Crollo in roccia',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-top border-right border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Crollo in roccia',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label: 'Crollo in roccia',
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        key: 'Ribaltamento',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-right border-top border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Ribaltamento',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label: 'Ribaltamento',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    fieldGroup: [
                                      {
                                        key: 'Colate e valanghe detritiche',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-right border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Colate e valanghe detritiche',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label:
                                                'Colate e valanghe detritiche',
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        key: 'Colate viscose e traslative',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-right border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Colate viscose e traslative',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label:
                                                'Colate viscose e traslative',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'width-100',
                            fieldGroup: [
                              {
                                key: 'Tipologia di fenomeno - radio',
                                type: 'radio',
                                className:
                                  'display-flex justify-content-center radio-sec',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Tipologia di fenomeno - radio',
                                  options: [
                                    {
                                      value: 'Potenziale',
                                      label: 'Potenziale',
                                    },
                                  ],
                                },
                              },
                              {
                                key: 'Potenziale',
                                fieldGroup: [
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    fieldGroup: [
                                      {
                                        key: 'Scorrimento rotazionale',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-top border-right border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Scorrimento rotazionale',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label: 'Scorrimento rotazionale',
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        key: 'Scorrimento traslativo',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-top border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Scorrimento traslativo',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label: 'Scorrimento traslativo',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    fieldGroup: [
                                      {
                                        key: 'Complesso e composito',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-right border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Complesso e composito',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label: 'Complesso e composito',
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        key: 'Fenomeni gravitativi profondi',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'border-bottom width-100 radio-sec flex-1',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Fenomeni gravitativi profondi',
                                          name: 'gender',
                                          options: [
                                            {
                                              value: true,
                                              label:
                                                'Fenomeni gravitativi profondi',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Distribuzione di attività (se definibile dai dati in possesso)',
                        },
                      },
                      {
                        key: 'Distribuzione di attività se definibile dai dati in possesso',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Costante',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  key: 'Costante',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  name: 'gender',
                                  options: [{ value: true, label: 'Costante' }],
                                },
                              },
                              {
                                key: 'Retrogressivo',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  key: 'Retrogressivo',
                                  name: 'gender',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  options: [
                                    { value: true, label: 'Retrogressivo' },
                                  ],
                                },
                              },
                              {
                                key: 'In allargamento',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  key: 'In allargamento',
                                  name: 'gender',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  options: [
                                    { value: true, label: 'In allargamento' },
                                  ],
                                },
                              },
                              {
                                key: 'Avanzante',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  key: 'Avanzante',
                                  name: 'gender',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'Avanzante',
                                    },
                                  ],
                                },
                              },
                              {
                                key: 'In diminuzione',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  key: 'In diminuzione',
                                  name: 'gender',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  options: [
                                    { value: true, label: 'In diminuzione' },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                key: 'Confinato',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  key: 'Confinato',
                                  name: 'gender',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  options: [
                                    { value: true, label: 'Confinato' },
                                  ],
                                },
                              },
                              {
                                key: 'Multidirezionale',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'border-right border-bottom width-100 radio-sec flex-1',
                                templateOptions: {
                                  key: 'Multidirezionale',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  name: 'gender',
                                  options: [
                                    { value: true, label: 'Multidirezionale' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Uso suolo dell’area potenzialmente coinvolta (incidente sulla difficoltà di determinazione)',
                        },
                      },
                      {
                        key: 'Uso suolo dell’area potenzialmente coinvolta (incidente sulla difficoltà di determinazione)',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'Area urbanizzata',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 border-bottom radio-sec',
                                    templateOptions: {
                                      key: 'Area urbanizzata',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Area urbanizzata',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Area estrattiva',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'width-100 radio-sec border-bottom',
                                    templateOptions: {
                                      key: 'Area estrattiva',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Area estrattiva',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    fieldGroup: [
                                      {
                                        key: 'Incolto prato pascolo',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className:
                                          'width-100 radio-sec border-bottom flex-1',
                                        templateOptions: {
                                          key: 'Incolto prato pascolo',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'Incolto prato pascolo',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                className: 'flex-3',
                                fieldGroup: [
                                  {
                                    fieldGroup: [
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap ',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1 border-right',
                                            fieldGroup: [
                                              {
                                                key: 'Seminativo',
                                                type: 'checkbox',
                                                defaultValue: false,
                                                className:
                                                  'width-100 radio-sec border-bottom ',
                                                templateOptions: {
                                                  key: 'Seminativo',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled
                                                    ),
                                                  options: [
                                                    {
                                                      value: true,
                                                      label: 'Seminativo',
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1 border-right',
                                            fieldGroup: [
                                              {
                                                key: 'Colture',
                                                type: 'checkbox',
                                                defaultValue: false,
                                                className:
                                                  'width-100 radio-sec border-bottom flex-1',
                                                templateOptions: {
                                                  key: 'Colture',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled
                                                    ),
                                                  options: [
                                                    {
                                                      value: true,
                                                      label: 'Colture',
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1 border-right',
                                            fieldGroup: [
                                              {
                                                key: 'Vegetazione riparia',
                                                type: 'checkbox',
                                                defaultValue: false,
                                                className:
                                                  'width-100 radio-sec border-bottom flex-1',
                                                templateOptions: {
                                                  key: 'Vegetazione riparia',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled
                                                    ),
                                                  options: [
                                                    {
                                                      value: true,
                                                      label:
                                                        'Vegetazione riparia',
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1',
                                            fieldGroup: [
                                              {
                                                key: 'Rimboschimento',
                                                type: 'checkbox',
                                                defaultValue: false,
                                                className:
                                                  'width-100 radio-sec border-bottom flex-1',
                                                templateOptions: {
                                                  key: 'Rimboschimento',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled
                                                    ),
                                                  options: [
                                                    {
                                                      value: true,
                                                      label: 'Rimboschimento',
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap ',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1 border-right',
                                            fieldGroup: [
                                              {
                                                key: 'Bosco ceduo',
                                                type: 'checkbox',
                                                defaultValue: false,
                                                className:
                                                  'width-100 radio-sec border-bottom ',
                                                templateOptions: {
                                                  key: 'Bosco ceduo',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled
                                                    ),
                                                  options: [
                                                    {
                                                      value: true,
                                                      label: 'Bosco ceduo',
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1 border-right',
                                            fieldGroup: [
                                              {
                                                key: 'Bosco d alto fusto',
                                                type: 'checkbox',
                                                defaultValue: false,
                                                className:
                                                  'width-100 radio-sec border-bottom flex-1',
                                                templateOptions: {
                                                  key: 'Bosco d alto fusto',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled
                                                    ),
                                                  options: [
                                                    {
                                                      value: true,
                                                      label:
                                                        'Bosco d alto fusto',
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1 border-right',
                                            fieldGroup: [
                                              {
                                                key: 'Incolto nudo',
                                                type: 'checkbox',
                                                defaultValue: false,
                                                className:
                                                  'width-100 radio-sec border-bottom flex-1',
                                                templateOptions: {
                                                  key: 'Incolto nudo',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled
                                                    ),
                                                  options: [
                                                    {
                                                      value: true,
                                                      label: 'Incolto nudo',
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                          {
                                            className: 'flex-1',
                                            fieldGroup: [
                                              {
                                                key: 'Incolto macchia cespugliato',
                                                type: 'checkbox',
                                                defaultValue: false,
                                                className:
                                                  'width-100 radio-sec border-bottom flex-1',
                                                templateOptions: {
                                                  key: 'Incolto macchia cespugliato',
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled
                                                    ),
                                                  options: [
                                                    {
                                                      value: true,
                                                      label:
                                                        'Incolto macchia cespugliato',
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    fieldGroup: [
                                      {
                                        className: 'flex-1',
                                        fieldGroup: [
                                          {
                                            key: 'Altro',
                                            type: 'checkbox',
                                            defaultValue: false,
                                            className: 'width-100 radio-sec ',
                                            templateOptions: {
                                              key: 'Altro',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled
                                                ),
                                              options: [
                                                {
                                                  value: true,
                                                  label: 'Altro',
                                                },
                                              ],
                                              change: (field) => {
                                                if (
                                                  field.formControl.value !=
                                                  true
                                                ) {
                                                  field.formControl.parent
                                                    ?.get(['Altro Input'])
                                                    ?.setValue(null);
                                                  field.formControl.parent
                                                    ?.get(['Altro Input'])
                                                    ?.disable();
                                                } else {
                                                  field.formControl.parent
                                                    ?.get(['Altro Input'])
                                                    ?.enable();
                                                }
                                              },
                                              onReset: (field) => {
                                                if (
                                                  field.formControl.value !=
                                                  true
                                                ) {
                                                  field.formControl.parent
                                                    ?.get(['Altro Input'])
                                                    ?.setValue(null);
                                                  field.formControl.parent
                                                    ?.get(['Altro Input'])
                                                    ?.disable();
                                                } else {
                                                  field.formControl.parent
                                                    ?.get(['Altro Input'])
                                                    ?.enable();
                                                }
                                              },
                                            },
                                            hooks: {
                                              onInit: (field) => {
                                                setTimeout(() => {
                                                  if (
                                                    field.formControl.value !=
                                                    true
                                                  ) {
                                                    field.formControl.parent
                                                      ?.get(['Altro Input'])
                                                      ?.setValue(null);
                                                    field.formControl.parent
                                                      ?.get(['Altro Input'])
                                                      ?.disable();
                                                  } else {
                                                    const isFieldDisabled =
                                                      bridgeInputTemplateComponent.isFieldDisabled(
                                                        [
                                                          UserRole.hydraulicTechnician,
                                                        ],
                                                        isDisabled
                                                      );
                                                    if (!isFieldDisabled) {
                                                      field.formControl.parent
                                                        ?.get(['Altro Input'])
                                                        ?.enable();
                                                    }
                                                  }
                                                }, 0);
                                              },
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        className: 'flex-6',
                                        fieldGroup: [
                                          {
                                            className: 'sub-sec',
                                            type: 'input',
                                            defaultValue: '',
                                            key: 'Altro Input',
                                            templateOptions: {
                                              label: 'Altro',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled
                                                ),
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Morfometria frana',
                        },
                      },
                      {
                        key: 'Posizione instabilità sul versante',
                        fieldGroup: [
                          {
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Posizione instabilità sul versante',
                            },
                          },
                          {
                            key: 'Posizione instabilità sul versante - radio',
                            type: 'cube-radio-buttons-table-type',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Posizione instabilità sul versante - radio',
                              flex: 'true',
                              rowLength: 2,
                              columnLength: { 0: [0, 1], 1: [2, 3] },
                              options: [
                                {
                                  value: 'Alta',
                                  label: 'Alta ',
                                },
                                {
                                  value: 'Media',
                                  label: 'Media',
                                },
                                {
                                  value: 'Bassa',
                                  label: 'Bassa',
                                },
                                {
                                  value: 'Fondovalle',
                                  label: 'Fondovalle',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Dati generali (frane riconosciute / potenziali)',
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Quota orlo superiore zona distacco m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Quota orlo superiore zona distacco [m]',
                            },
                            validators: {
                              validation: ['numberPattern'],
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Quota ponte o viadotto m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Quota ponte o viadotto m',
                            },
                            validators: {
                              validation: ['numberPattern'],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Area totale m2',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Area totale [m2]',
                            },
                            validators: {
                              validation: ['numberPattern'],
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Profondità superficie di distacco m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Profondità superficie di distacco [m]',
                            },
                            validators: {
                              validation: ['numberPattern'],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Volume massa m3',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Volume massa [m3]',
                            },
                            validators: {
                              validation: ['numberPattern'],
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Parametro dello Stato di Attività per la quantificazione di PA (frane riconosciute / potenziali)',
                        },
                      },
                      {
                        key: 'Parametro dello Stato di Attività per la quantificazione di PA frane riconosciute / potenziali',
                        className: 'flex-2',
                        fieldGroupClassName: 'display-flex width-100',
                        fieldGroup: [
                          {
                            className: 'width-100',
                            fieldGroup: [
                              {
                                key: 'Parametro dello Stato di Attività per la quantificazione di PA frane riconosciute / potenziali - radio',
                                type: 'radio',
                                className:
                                  'display-flex justify-content-center border-right radio-sec',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Parametro dello Stato di Attività per la quantificazione di PA frane riconosciute / potenziali - radio',
                                  options: [
                                    {
                                      value: 'Frane riconosciute',
                                      label: 'Frane riconosciute',
                                    },
                                  ],
                                },
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column width-100',
                                fieldGroup: [
                                  {
                                    key: 'Attivo al momento del rilevo o con segni di movimento in atto',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'border-right width-100 radio-sec flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Attivo al momento del rilevo o con segni di movimento in atto',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Attivo al momento del rilevo o con segni di movimento in atto',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Sospesa attivo nell ultimo ciclo stagionale',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'border-right width-100 radio-sec flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Sospesa attivo nell ultimo ciclo stagionale',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Sospesa - attivo nell’ultimo ciclo stagionale',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Quiescente non attivo da più di un ciclo stagionale ma riattivabile',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'border-right width-100 radio-sec flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Quiescente non attivo da più di un ciclo stagionale ma riattivabile',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Quiescente - non attivo da più di un ciclo stagionale ma riattivabile',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Inattiva non attivo da diversi cicli stagionali',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'border-right width-100 radio-sec flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Inattiva non attivo da diversi cicli stagionali',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Inattiva - non attivo da diversi cicli stagionali',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Stabilizzata',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'border-right width-100 radio-sec flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Stabilizzata',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label: 'Stabilizzata',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'width-100',
                            fieldGroup: [
                              {
                                key: 'Parametro dello Stato di Attività per la quantificazione di PA frane riconosciute / potenziali - radio',
                                type: 'radio',
                                className:
                                  'display-flex justify-content-center radio-sec',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Parametro dello Stato di Attività per la quantificazione di PA frane riconosciute / potenziali - radio',
                                  options: [
                                    {
                                      value: 'Frane potenziali',
                                      label: 'Frane potenziali',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Parametro della magnitudo su base volumetrica in metri cubi PM (frane riconosciute / potenziali)',
                        },
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Parametro della magnitudo su base volumetrica in metri cubi PM frane riconosciute / potenziali - radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'remove-underline',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Parametro della magnitudo su base volumetrica in metri cubi PM frane riconosciute / potenziali - radio',
                              options: [
                                // INFO: Value updated to match the value in attention class excel file
                                {
                                  value: 'Estremamente grande',
                                  label: 'Estremamente/molto grande (> 106)',
                                },
                                {
                                  value: 'Grande',
                                  label: 'Grande (2,5 ∙ 105 - 106)',
                                },
                                {
                                  value: 'Media',
                                  label: 'Media (2,5 ∙ 105 - 104)',
                                },
                                {
                                  value: 'Piccola',
                                  label: 'Piccola (102 - 104)',
                                },
                                {
                                  value: 'Molto piccola',
                                  label: 'Molto piccola (< 5 ∙ 102)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Parametro della massima velocità attesa in funzione della tipologia di frana in atto o potenziale PV',
                        },
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Parametro della massima velocità attesa in funzione della tipologia di frana in atto o potenziale PV - radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'remove-underline',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Parametro della massima velocità attesa in funzione della tipologia di frana in atto o potenziale PV - radio',
                              options: [
                                // INFO: Value updated to match the value in attention class excel file
                                {
                                  value: 'Estremamente/molto rapida',
                                  label:
                                    'Estremamente/molto rapida (> 3 m/min)',
                                },
                                {
                                  value: 'Rapida',
                                  label: 'Rapida (3 m/min - 1,8 m/h)',
                                },
                                {
                                  value: 'Moderata',
                                  label: 'Moderata (1,8 m/h - 13 m/mese)',
                                },
                                {
                                  value: 'Lenta',
                                  label: 'Lenta (13 m/mese - 1,6 m/anno)',
                                },
                                {
                                  value: 'Estremamente lenta',
                                  label:
                                    'Estremamente/molto lenta (< 1,6 m /anno)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Estensione interferenza',
                        },
                      },
                      {
                        key: 'Estensione interferenza',
                        fieldGroup: [
                          {
                            type: 'cube-radio-buttons-table-type',
                            defaultValue: '',
                            key: 'Estensione interferenza',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Estensione interferenza',
                              flex: 'true',
                              rowLength: 2,
                              columnLength: { 0: [0, 1], 1: [2, 3] },
                              options: [
                                {
                                  value: 'Totale',
                                  label: 'Totale',
                                },
                                {
                                  value: 'Parziale spalle o pile',
                                  label: 'Parziale (spalle o pile)',
                                },
                                {
                                  value: 'Zona di approccio',
                                  label: 'Zona di approccio',
                                },
                                {
                                  value: 'Altro',
                                  label: 'Altro',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 input-line',
                                      key: 'Altro',
                                      type: 'input',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled
                                          ),
                                      },
                                    },
                                  ],
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value != 'Altro') {
                                  field.formControl.parent
                                    ?.get(['Altro'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value != 'Altro') {
                                  field.formControl.parent
                                    ?.get(['Altro'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro'])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value != 'Altro') {
                                    field.formControl.parent
                                      ?.get(['Altro'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Altro'])
                                      ?.disable();
                                  } else {
                                    const isFieldDisabled =
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled
                                      );

                                    if (!isFieldDisabled) {
                                      field.formControl.parent
                                        ?.get(['Altro'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Affidabilità complessiva della valutazione',
                        },
                      },
                      {
                        key: 'Affidabilità complessiva della valutazione',
                        fieldGroup: [
                          {
                            key: 'Affidabilità complessiva della valutazione - radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Affidabilità complessiva della valutazione - radio',
                              flex: 'true',
                              options: [
                                {
                                  value: 'Buona',
                                  label: 'Buona',
                                },
                                {
                                  value: 'Limitata',
                                  label: 'Limitata',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Rischio idraulico',
                    fieldGroup: [
                      {
                        template: `<div class="sub-heading">
                              <span class="font-weight-bolder">Rischio idraulico</span>
                          </div>`,
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Tipologia di fenomeno',
                        },
                      },
                      {
                        key: 'Tipologia di fenomeno',
                        className: 'flex-2',
                        fieldGroupClassName: 'display-flex width-100',
                        fieldGroup: [
                          {
                            className: 'width-100',
                            fieldGroup: [
                              {
                                key: 'Tipologia di fenomeno - radio',
                                type: 'radio',
                                className:
                                  'display-flex justify-content-center border-right radio-sec',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Rischio idraulico - Tipologia di fenomeno - radio',
                                  options: [
                                    {
                                      value: 'Accertato',
                                      label: 'Accertato',
                                    },
                                  ],
                                },
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column width-100',
                                fieldGroup: [
                                  {
                                    key: 'Sormonto o insufficienza di franco',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'border-right width-100 radio-sec flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Sormonto o insufficienza di franco',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Sormonto o insufficienza di franco',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Fenomeni di erosione localizzata e generalizzata',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className:
                                      'border-right width-100 radio-sec flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Fenomeni di erosione localizzata e generalizzata',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Fenomeni di erosione localizzata e generalizzata',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'width-100',
                            fieldGroup: [
                              {
                                key: 'Tipologia di fenomeno - radio',
                                type: 'radio',
                                className:
                                  'display-flex justify-content-center radio-sec',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Rischio idraulico - Tipologia di fenomeno - radio',
                                  options: [
                                    {
                                      value: 'Ipotizzato',
                                      label: 'Ipotizzato',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Area riconosciuta pericolosa (allegare riferimenti)',
                        },
                      },
                      {
                        key: 'Area riconosciuta pericolosa allegare riferimenti',
                        fieldGroup: [
                          {
                            key: 'Area riconosciuta pericolosa allegare riferimenti - radio',
                            type: 'cube-radio-buttons-table-type',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Area riconosciuta pericolosa allegare riferimenti - radio',
                              flex: 'true',
                              rowLength: 2,
                              columnLength: { 0: [0, 1], 1: [2, 3] },
                              options: [
                                {
                                  value:
                                    'Fenomeno riconosciuto ma non ancora studiato',
                                  label:
                                    'Fenomeno riconosciuto ma non ancora studiato',
                                },
                                {
                                  value: 'Fenomeno riconosciuto e studiato',
                                  label: 'Fenomeno riconosciuto e studiato',
                                },
                                {
                                  value:
                                    'Fenomeno modellato e oggetto di monitoraggio',
                                  label:
                                    'Fenomeno modellato e oggetto di monitoraggio',
                                },
                                {
                                  value:
                                    'Fenomeno oggetto di opere di mitigazione',
                                  label:
                                    'Fenomeno oggetto di opere di mitigazione',
                                },
                              ],
                            },
                          },
                          {
                            fieldGroup: [
                              {
                                type: 'textarea',
                                defaultValue: '',
                                key: 'Individuazione area secondo le cartografie tematiche delle Autorità di Distretto',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label:
                                    'Individuazione area secondo le cartografie tematiche delle Autorità di Distretto',
                                  theme: 'custom',
                                  rows: 4,
                                  grow: false,
                                },
                              },
                            ],
                          },
                          {
                            fieldGroup: [
                              {
                                type: 'textarea',
                                defaultValue: '',
                                key: 'Individuazione delle parti della struttura che interessano l alveo secondo le definizioni delle NTC 2018 e circolare',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label:
                                    'Individuazione delle parti della struttura che interessano l alveo secondo le definizioni delle NTC 2018 e circolare',
                                  theme: 'custom',
                                  rows: 4,
                                  grow: false,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Confinamento alveo',
                        },
                      },
                      {
                        key: 'Confinamento alveo',
                        fieldGroup: [
                          {
                            key: 'Confinamento alveo - radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Confinamento alveo - radio',
                              flex: 'true',
                              options: [
                                {
                                  value: 'Confinato',
                                  label: 'Confinato',
                                },
                                {
                                  value: 'Semiconfinato',
                                  label: 'Semiconfinato',
                                },
                                {
                                  value: 'Non confinato',
                                  label: 'Non confinato',
                                },
                              ],
                            },
                          },
                          {
                            key: 'A canale singolo',
                            fieldGroupClassName:
                              'display-flex width-100 remove-underline',
                            fieldGroup: [
                              {
                                type: 'radio',
                                className: 'remove-underline',
                                key: 'A canale singolo',
                                defaultValue: '',
                                templateOptions: {
                                  key: 'A canale singolo',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label: 'A canale singolo',
                                      value: 'A canale singolo',
                                    },
                                  ],
                                  change: (field) => {
                                    if (
                                      field.formControl.value !==
                                      'A canale singolo'
                                    ) {
                                      field.formControl.parent
                                        ?.get(['Rettilineo'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Rettilineo'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get(['Sinuoso'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Sinuoso'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get(['Meandriforme'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Meandriforme'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Rettilineo'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Sinuoso'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Meandriforme'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (
                                      field.formControl.value !==
                                      'A canale singolo'
                                    ) {
                                      field.formControl.parent
                                        ?.get(['Rettilineo'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Rettilineo'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get(['Sinuoso'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Sinuoso'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get(['Meandriforme'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Meandriforme'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Rettilineo'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Sinuoso'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Meandriforme'])
                                        ?.enable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !==
                                        'A canale singolo'
                                      ) {
                                        field.formControl.parent
                                          ?.get(['Rettilineo'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['Rettilineo'])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get(['Sinuoso'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['Sinuoso'])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get(['Meandriforme'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['Meandriforme'])
                                          ?.disable();
                                      } else {
                                        const isFieldDisabled =
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled
                                          );
                                        if (!isFieldDisabled) {
                                          field.formControl.parent
                                            ?.get(['Rettilineo'])
                                            ?.enable();
                                          field.formControl.parent
                                            ?.get(['Sinuoso'])
                                            ?.enable();
                                          field.formControl.parent
                                            ?.get(['Meandriforme'])
                                            ?.enable();
                                        }
                                      }
                                    }, 0);
                                  },
                                },
                              },
                              {
                                fieldGroup: [
                                  {
                                    key: 'Rettilineo',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Rettilineo',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label: 'Rettilineo',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Sinuoso',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Sinuoso',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label: 'Sinuoso',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Meandriforme',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Meandriforme',
                                      name: 'gender',
                                      options: [
                                        {
                                          value: true,
                                          label: 'Meandriforme',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            key: 'Intrecciato - radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Intrecciato - radio',
                              flex: 'true',
                              options: [
                                {
                                  value: 'Intrecciato',
                                  label: 'Intrecciato',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        template: `<div class="sub-title">
                              <span class="font-weight-bolder">Rischio di sormonto o insufficienza di franco</span>
                          </div>`,
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Tipologia di reticolo',
                        },
                      },
                      {
                        key: 'Tipologia di reticolo',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1 border-right',
                                fieldGroup: [
                                  {
                                    key: 'Reticolo principale',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'remove-underline',
                                    templateOptions: {
                                      key: 'Reticolo principale',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Reticolo principale',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    key: 'Quota del pelo libero scenario P3',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label:
                                        'Quota del pelo libero (scenario P3)',
                                    },
                                  },
                                  {
                                    key: 'Quota dell’intradosso dell’impalcato del manufatto - first',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label:
                                        'Quota dell’intradosso dell’impalcato del manufatto',
                                    },
                                  },
                                  {
                                    key: 'Franco idraulico FP3',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label: 'Franco idraulico, FP3',
                                    },
                                  },
                                  {
                                    key: 'Quota del pelo libero scenario P2 - first',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label:
                                        'Quota del pelo libero (scenario P2)',
                                    },
                                  },
                                  {
                                    key: 'Quota dell’intradosso dell’impalcato del manufatto - second',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label:
                                        'Quota dell’intradosso dell’impalcato del manufatto',
                                    },
                                  },
                                  {
                                    key: 'Franco idraulico FP2 - first',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label: 'Franco idraulico, FP2',
                                    },
                                  },
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    key: 'Presenza di argini',
                                    fieldGroup: [
                                      {
                                        className:
                                          'width-100 flex-2 margin-auto',
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title: 'Presenza di argini',
                                        },
                                      },
                                      {
                                        key: 'Presenza di argini',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec flex-2',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Presenza di argini',
                                          flex: true,
                                          options: [
                                            {
                                              value: 'SÌ',
                                              label: 'SÌ',
                                            },
                                            {
                                              value: 'NO',
                                              label: 'NO',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Quota della sommità arginale mintra le due - first',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label:
                                        'Quota della sommità arginale (mintra le due)',
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1 padding-left-40',
                                fieldGroup: [
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    fieldGroup: [
                                      {
                                        key: 'Reticolo secondario',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className: 'remove-underline flex-2',
                                        templateOptions: {
                                          key: 'Reticolo secondario',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label: 'Reticolo secondario',
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        key: 'Reticolo artificiale di scolo',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        className: 'remove-underline flex-2',
                                        templateOptions: {
                                          key: 'Reticolo artificiale di scolo',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          options: [
                                            {
                                              value: true,
                                              label:
                                                'Reticolo artificiale di scolo',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    key: 'Ricadente in area mappata ai sensi della direttiva alluvioni per esondazione di corsi d’acqua principali',
                                    fieldGroup: [
                                      {
                                        className:
                                          'width-100 flex-2 margin-auto',
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title:
                                            'Ricadente in area mappata ai sensi della direttiva alluvioni per esondazione di corsi d’acqua principali  ',
                                        },
                                      },
                                      {
                                        key: 'Ricadente in area mappata ai sensi della direttiva alluvioni per esondazione di corsi d’acqua principali',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec flex-2',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Ricadente in area mappata ai sensi della direttiva alluvioni per esondazione di corsi d’acqua principali',
                                          flex: true,
                                          options: [
                                            {
                                              value: 'SÌ',
                                              label: 'SÌ',
                                            },
                                            {
                                              value: 'NO',
                                              label: 'NO',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Quota del pelo libero scenario P2 - second',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label:
                                        'Quota del pelo libero (scenario P2)',
                                    },
                                  },
                                  {
                                    key: 'Franco idraulico FP2 - second',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label: 'Franco idraulico, FP2',
                                    },
                                  },
                                  {
                                    fieldGroupClassName: 'display-flex-wrap',
                                    key: 'Presenza di argini - radio',
                                    fieldGroup: [
                                      {
                                        className:
                                          'width-100 flex-2 margin-auto',
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title: 'Presenza di argini',
                                        },
                                      },
                                      {
                                        key: 'Presenza di argini - radio',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec flex-2',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled
                                            ),
                                          key: 'Presenza di argini - radio',
                                          flex: true,
                                          options: [
                                            {
                                              value: 'SÌ',
                                              label: 'SÌ',
                                            },
                                            {
                                              value: 'NO',
                                              label: 'NO',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Quota della sommità arginale mintra le due - second',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      label:
                                        'Quota della sommità arginale (mintra le due)',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            key: 'Mappa di pericolosità',
                            fieldGroup: [
                              {
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Mappa di pericolosità',
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  token: bridgeInputTemplateComponent.token,
                                  modelData: bridgeInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Tipologia di alveo',
                        fieldGroup: [
                          {
                            wrappers: ['cube-section-title-wrapper'],
                            templateOptions: {
                              title: 'Tipologia di alveo',
                            },
                          },
                          {
                            key: 'Tipologia di alveo - radio',
                            type: 'cube-radio-buttons-table-type',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Tipologia di alveo - radio',
                              flex: 'true',
                              rowLength: 2,
                              columnLength: { 0: [0, 1], 1: [2, 3] },
                              options: [
                                {
                                  value: 'In equilibrio',
                                  label: 'In equilibrio',
                                },
                                {
                                  value: 'In fase evolutiva',
                                  label: 'In fase evolutiva',
                                },
                                {
                                  value: 'Fondo fisso',
                                  label: 'Fondo fisso',
                                },
                                {
                                  value: 'Fondo mobile ',
                                  label: 'Fondo mobile ',
                                },
                              ],
                            },
                          },
                          {
                            template: `<div class="sub-title">
                                  <span class="font-weight-bolder">Fenomeni di erosione </span>
                              </div>`,
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'Larghezza complessiva dell’alveo inciso occupata dall’ingombro di pile e spalle wa,l',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label:
                                    'Larghezza complessiva dell’alveo inciso occupata dall’ingombro di pile e spalle (wa,l)',
                                },
                                validators: {
                                  validation: ['numberPattern'],
                                },
                              },
                              {
                                template: `<div class="input-text-align">m</div>`,
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'Larghezza complessiva dell’alveo inciso a monte del ponte wa',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label:
                                    'Larghezza complessiva dell’alveo inciso a monte del ponte (wa)',
                                },
                                validators: {
                                  validation: ['numberPattern'],
                                },
                              },
                              {
                                template: `<div class="input-text-align">m</div>`,
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'Larghezza complessiva delle golene occupata dai rilevati di accesso, dalle spalle e dalle pile wg,l',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label:
                                    'Larghezza complessiva delle golene occupata dai rilevati di accesso, dalle spalle e dalle pile (wg,l)',
                                },
                                validators: {
                                  validation: ['numberPattern'],
                                },
                              },
                              {
                                template: `<div class="input-text-align">m</div>`,
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'Larghezza complessiva delle golene a monte del ponte wg',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label:
                                    'Larghezza complessiva delle golene a monte del ponte (wg)',
                                },
                                validators: {
                                  validation: ['numberPattern'],
                                },
                              },
                              {
                                template: `<div class="input-text-align">m</div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Caratteristiche geometriche Pile',
                        fieldGroupClassName: 'display-flex-wrap gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                className: 'text-align-center',
                                wrappers: ['cube-section-title-wrapper'],
                                templateOptions: {
                                  title: 'Caratteristiche geometriche Pile',
                                },
                              },
                              {
                                key: 'Caratteristiche geometriche Pile',
                                fieldGroup: [
                                  {
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        key: 'Geometria sezione trasversale',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [
                                                UserRole.bmsInspectionManager,
                                                UserRole.bmsInspector,
                                              ],
                                              isDisabled
                                            ),
                                          label:
                                            'Geometria sezione trasversale:',
                                        },
                                      },
                                      {
                                        key: 'Dimensioni sezione trasversale',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [
                                                UserRole.bmsInspectionManager,
                                                UserRole.bmsInspector,
                                              ],
                                              isDisabled
                                            ),
                                          label:
                                            'Dimensioni sezione trasversale:',
                                        },
                                      },
                                      {
                                        key: 'Stato di conservazione giudizio sintetico',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [
                                                UserRole.bmsInspectionManager,
                                                UserRole.bmsInspector,
                                              ],
                                              isDisabled
                                            ),
                                          label:
                                            'Stato di conservazione(giudizio sintetico)',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            key: 'Caratteristiche geometriche Spalle',
                            className: 'flex-1 border-left padding-left-35',
                            fieldGroup: [
                              {
                                className: 'text-align-center',
                                wrappers: ['cube-section-title-wrapper'],
                                templateOptions: {
                                  title: 'Caratteristiche geometriche Spalle',
                                },
                              },
                              {
                                fieldGroup: [
                                  {
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        key: 'Geometria sezione trasversale',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [
                                                UserRole.bmsInspectionManager,
                                                UserRole.bmsInspector,
                                              ],
                                              isDisabled
                                            ),
                                          label:
                                            'Geometria sezione trasversale:',
                                        },
                                      },
                                      {
                                        key: 'Dimensioni sezione trasversale',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [
                                                UserRole.bmsInspectionManager,
                                                UserRole.bmsInspector,
                                              ],
                                              isDisabled
                                            ),
                                          label:
                                            'Dimensioni sezione trasversale:',
                                        },
                                      },
                                      {
                                        key: 'Stato di conservazione giudizio sintetico',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [
                                                UserRole.bmsInspectionManager,
                                                UserRole.bmsInspector,
                                              ],
                                              isDisabled
                                            ),
                                          label:
                                            'Stato di conservazione(giudizio sintetico)',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Caratteristiche Alveo',
                        },
                      },
                      {
                        key: 'Caratteristiche Alveo',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Natura del materiale d’alveo',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Natura del materiale d’alveo',
                            },
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            key: 'Sensibile accumulo di detriti trasportati dalla corrente',
                            fieldGroup: [
                              {
                                className: 'width-100 flex-2 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title:
                                    'Sensibile accumulo di detriti trasportati dalla corrente',
                                },
                              },
                              {
                                key: 'Sensibile accumulo di detriti trasportati dalla corrente',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-2',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  key: 'Sensibile accumulo di detriti trasportati dalla corrente',
                                  flex: true,
                                  options: [
                                    {
                                      value: 'SÌ',
                                      label: 'SÌ',
                                    },
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'Massima profondità di scavo ds',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label: 'Massima profondità di scavo (ds)',
                                },
                                validators: {
                                  validation: ['numberPattern'],
                                },
                              },
                              {
                                template: `<div class="input-text-align">m</div>`,
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'Profondità di posa del piano di fondazione rispetto all’alveo df',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label:
                                    'Profondità di posa del piano di fondazione rispetto all’alveo(df)',
                                },
                                validators: {
                                  validation: ['numberPattern'],
                                },
                              },
                              {
                                template: `<div class="input-text-align">m</div>`,
                              },
                              {
                                className: 'flex-1',
                                key: 'se da documentazione, indicare il numero di allegato',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled
                                    ),
                                  label:
                                    'se da documentazione, indicare il numero di allegato',
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-1',
                            key: 'Dimensioni del bacino idrografico kmq',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Dimensioni del bacino idrografico (kmq)',
                            },
                            validators: {
                              validation: ['numberPattern'],
                            },
                          },
                          {
                            fieldGroup: [
                              {
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title:
                                    'Indicare l’eventuale sussistenza delle seguenti condizioni:',
                                },
                              },
                              {
                                key: 'Indicare l’eventuale sussistenza delle seguenti condizioni',
                                fieldGroup: [
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Evidenza di accentuati fenomeni di deposizione di sedimenti, soprattutto se grossolani, o di fenomeni d’erosione d’alveo',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Evidenza di accentuati fenomeni di deposizione di sedimenti, soprattutto se grossolani, o di fenomeni d’erosione d’alveo',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Evidenza di accentuati fenomeni di deposizione di sedimenti, soprattutto se grossolani, o di fenomeni d’erosione d’alveo',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Evidenza di trasporto di materiale vegetale di notevole dimensione',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Evidenza di trasporto di materiale vegetale di notevole dimensione',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Evidenza di trasporto di materiale vegetale di notevole dimensione',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Dimensioni del bacino idrografico S < 100 kmq',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Dimensioni del bacino idrografico S < 100 kmq',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Dimensioni del bacino idrografico S < 100 kmq',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Evidenza di presenza di fondazioni superficiali delle pile e delle spalle del ponte',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Evidenza di presenza di fondazioni superficiali delle pile e delle spalle del ponte',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Evidenza di presenza di fondazioni superficiali delle pile e delle spalle del ponte',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Evidenza di presenza di fondazioni profonde delle pile e delle spalle del ponte',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Evidenza di presenza di fondazioni profonde delle pile e delle spalle del ponte',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Evidenza di presenza di fondazioni profonde delle pile e delle spalle del ponte',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Evidenza di fenomeni di abbassamento generalizzato dell’alveo a monte e a valle del ponte',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Evidenza di fenomeni di abbassamento generalizzato dell’alveo a monte e a valle del ponte',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Evidenza di fenomeni di abbassamento generalizzato dell’alveo a monte e a valle del ponte',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Ponte posizionato in tratto di alveo avente sensibile curvatura',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Ponte posizionato in tratto di alveo avente sensibile curvatura',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Ponte posizionato in tratto di alveo avente sensibile curvatura',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Presenza di accumuli di detriti o materiale flottante a monte della pila',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Presenza di accumuli di detriti o materiale flottante a monte della pila',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Presenza di accumuli di detriti o materiale flottante a monte della pila',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Tendenza dell’alveo alla divagazione planimetrica',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Tendenza dell’alveo alla divagazione planimetrica',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Tendenza dell’alveo alla divagazione planimetrica',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Evidenza di presenza di protezione al piede delle pile e delle spalle del ponte',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Evidenza di presenza di protezione al piede delle pile e delle spalle del ponte',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Evidenza di presenza di protezione al piede delle pile e delle spalle del ponte',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'checkbox',
                                    defaultValue: false,
                                    key: 'Presenza di una briglia di protezione immediatamente a valle del ponte',
                                    className:
                                      'border-top border-right border-bottom width-100 flex-1',
                                    templateOptions: {
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled
                                        ),
                                      key: 'Presenza di una briglia di protezione immediatamente a valle del ponte',
                                      options: [
                                        {
                                          value: true,
                                          label:
                                            'Presenza di una briglia di protezione immediatamente a valle del ponte',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Opere di arginatura, di alterazione delle portate liquide e solide',
                        },
                      },
                      {
                        key: 'Opere di arginatura di alterazione delle portate liquide e solide',
                        fieldGroup: [
                          {
                            key: 'Opere di arginatura di alterazione delle portate liquide e solide',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Opere di arginatura di alterazione delle portate liquide e solide',
                              flex: 'true',
                              options: [
                                {
                                  value: 'Presenti',
                                  label: 'Presenti',
                                },
                                {
                                  value: 'Assenti',
                                  label: 'Assenti',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Tipologia',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Tipologia',
                            },
                          },
                          {
                            key: 'Stato di conservazione giudizio sintetico',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label:
                                'Stato di conservazione (giudizio sintetico)',
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Misure/opere di laminazione e mitigazione (casse di espansione, aree inondabili, ecc.)',
                        },
                      },
                      {
                        key: 'Misure opere di laminazione e mitigazione casse di espansione, aree inondabili, ecc',
                        fieldGroup: [
                          {
                            key: 'Misure opere di laminazione e mitigazione casse di espansione, aree inondabili, ecc',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Misure opere di laminazione e mitigazione casse di espansione, aree inondabili, ecc',
                              flex: 'true',
                              options: [
                                {
                                  value: 'Presenti',
                                  label: 'Presenti',
                                },
                                {
                                  value: 'Assenti',
                                  label: 'Assenti',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Tipologia',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Tipologia',
                            },
                          },
                          {
                            key: 'Stato di conservazione giudizio sintetico',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label:
                                'Stato di conservazione (giudizio sintetico)',
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Sistemi di monitoraggio',
                        },
                      },
                      {
                        key: 'Sistemi di monitoraggio',
                        fieldGroup: [
                          {
                            key: 'Sistemi di monitoraggio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Sistemi di monitoraggio',
                              flex: 'true',
                              options: [
                                {
                                  value: 'Presenti',
                                  label: 'Presenti',
                                },
                                {
                                  value: 'Assenti',
                                  label: 'Assenti',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Tipologia',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Tipologia',
                            },
                          },
                          {
                            key: 'Stato di conservazione giudizio sintetico',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label:
                                'Stato di conservazione (giudizio sintetico)',
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Tipologia aree di possibile allagamento',
                        },
                      },
                      {
                        fieldGroup: [
                          {
                            type: 'textarea',
                            defaultValue: '',
                            key: 'Tipologia aree di possibile allagamento',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              label: 'Tipologia aree di possibile allagamento',
                              theme: 'custom',
                              rows: 4,
                              grow: false,
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Affidabilità complessiva della valutazione',
                        },
                      },
                      {
                        key: 'Affidabilità complessiva della valutazione',
                        fieldGroupClassName: 'display-flex-wrap border-bottom',
                        fieldGroup: [
                          {
                            key: 'Affidabilità complessiva della valutazione',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-3',
                            templateOptions: {
                              disabled:
                                bridgeInputTemplateComponent.isFieldDisabled(
                                  [UserRole.hydraulicTechnician],
                                  isDisabled
                                ),
                              key: 'Affidabilità complessiva della valutazione',
                              flex: true,
                              options: [
                                { value: 'Buona', label: 'Buona' },
                                { value: 'Limitata', label: 'Limitata' },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
                hideExpression: '!formState.l1Frane',
                hooks: {
                  onInit: (field) => {
                    if (!bridgeInputTemplateComponent.isShortcutView)
                      bridgeInputTemplateComponent.options.formState.l1Frane =
                        false;
                  },
                },
              },
            ],
          },
          {
            templateOptions: { label: 'Campate' },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: 'campatelist',
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: BridgeConstants.partNo,
                        headerClass: 'header-no-child',
                        field: BridgeConstants.partNo,
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 300,
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.l2;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.l2,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l1
                              ].campatelist,
                              ''
                            );
                          },
                          onDuplicate: (index) => {
                            bridgeInputTemplateComponent.openDuplicateCampataDialog(
                              index
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message:
                                    'Sei sicuro di eliminare la campata?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images under campata
                            const urlList = [
                              `${BridgeConstants.levels.spalle}list`,
                              `${BridgeConstants.levels.pile}list`,
                              `${BridgeConstants.levels.archi}list`,
                              `${BridgeConstants.levels.impalcati}list`,
                              `${BridgeConstants.levels.fileDiAppoggi}list`,
                              `${BridgeConstants.levels.giunti}list`,
                              `${BridgeConstants.levels.apparecchiSismici}list`,
                            ].reduce((imageArray, elementListName) => {
                              const elementList =
                                bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l1
                                ].campatelist[index]?.modelstructure?.[
                                  elementListName
                                ];

                              elementList?.forEach((element) => {
                                element.modelstructure[
                                  'Scheda Ispezione Ponti di Livello 1'
                                ][
                                  'Scheda Ispezione Ponti di Livello 1 List1'
                                ].forEach((defect) => {
                                  const key =
                                    defect['Codice difetto'] ===
                                    BridgeConstants.eventualiNote
                                      ? 'Note'
                                      : 'N° foto';
                                  if (defect[key]) {
                                    const imageUrls = Object.entries(
                                      defect[key]
                                    );
                                    imageArray = imageArray.concat(imageUrls);
                                  }
                                });
                              });

                              return imageArray;
                            }, []);

                            if (urlList.length)
                              bridgeInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l1
                            ].campatelist.splice(index, 1);
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l1
                            ].campatelist = [].concat(
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l1
                              ].campatelist
                            );
                            bridgeInputTemplateComponent.campateCount -= 1;
                            bridgeInputTemplateComponent.refreshFields();
                          },
                        },
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: BridgeConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: BridgeConstants.partNo,
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Campate
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  onClick: (e) => {
                    bridgeInputTemplateComponent.l1BackupData =
                      bridgeInputTemplateComponent.getDeepCopy(
                        bridgeInputTemplateComponent.model.l1
                      );
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l1
                    );
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.l2;
                  },
                },
              },
            ],
            expressionProperties: {
              'templateOptions.isHidden': () =>
                bridgeInputTemplateComponent.isShortcutView,
            },
          },
          {
            templateOptions: {
              label: BridgeConstants.elementiAccessori,
            },
            hooks: {
              onInit: (field) => {
                bridgeInputTemplateComponent.getElementiAccessoriDefects();
              },
            },
            fieldGroupClassName: 'elementi-accessori',
            fieldGroup: [
              {
                key: 'Scheda Ispezione Ponti di Livello 1',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Scheda Ispezione Ponti di Livello 1',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-6',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 header-size',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Elementi accessori (indicare solamente se il difetto è presente)',
                            },
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap grid-gap padding-top repeat-header',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'Strada di appartenenza',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Strada di appartenenza',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                className: 'flex-1',
                                key: 'Progressiva km',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Progressiva km',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName:
                              'display-flex-wrap grid-gap padding-top',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                key: 'InspectionDate',
                                type: 'cube-repeat-section-type',
                                templateOptions: {
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  /* // INFO: Tecnico syncing to defect level
                                  onAddRow: () => {
                                    bridgeInputTemplateComponent.syncRepeatSections(
                                      'censusDate'
                                    );
                                  },
                                  onDeleteRow: (index) => {
                                    bridgeInputTemplateComponent.syncRepeatSections(
                                      'censusDate',
                                      index
                                    );
                                  }, */
                                },
                                fieldArray: {
                                  fieldGroupClassName:
                                    'display-flex-wrap grid-gap',
                                  fieldGroup: [
                                    {
                                      className: 'flex-1',
                                      key: 'Tecnico rilevatore',
                                      type: 'input',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        /* // INFO: Tecnico syncing to defect level
                                        change: (field, e) => {
                                          bridgeInputTemplateComponent.syncSharedModelData(
                                            'censusDateTecnico',
                                            e.target.value,
                                            field.parent.key
                                          );
                                        }, */
                                      },
                                      expressionProperties: {
                                        'templateOptions.label':
                                          "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                                      },
                                    },
                                    {
                                      className: 'flex-1',
                                      key: 'Data ispezione',
                                      type: 'cube-date-range-picker-type',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        max: (() => {
                                          const today = new Date();
                                          const maxDate = new Date(today);
                                          maxDate.setDate(today.getDate() + 7);

                                          return maxDate;
                                        })(),
                                        /* // INFO: Tecnico syncing to defect level
                                        onClearDate: (field, value) => {
                                          bridgeInputTemplateComponent.syncSharedModelData(
                                            'censusDateData',
                                            value,
                                            field.parent.key
                                          );
                                        }, 
                                        onDateChange: (field, e) => {
                                          bridgeInputTemplateComponent.syncSharedModelData(
                                            'censusDateData',
                                            e,
                                            field.parent.key
                                          );
                                        }, */
                                      },
                                      expressionProperties: {
                                        'templateOptions.label':
                                          "'Data ispezione n.' + (+field.parent.key + 1)",
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'page-heading-image-only',
                        template: `<div class="defect-rina-logo">
                              <img src="assets/rina/RINA-dark.png">
                          </div>`,
                      },
                    ],
                  },
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1 List1',
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                fieldGroup: [
                                  {
                                    fieldGroup: [
                                      {
                                        key: 'N° corsie',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: 'N° corsie',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Larghezza carreggiata',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: 'Larghezza carreggiata',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Marciapiede',
                                        fieldGroupClassName:
                                          'display-flex border-all',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1 border-right',
                                            template: `<div class="left-align">Marciapiede</div>`,
                                          },
                                          {
                                            className: 'display-flex flex-2',
                                            fieldGroupClassName: 'display-flex',
                                            fieldGroup: [
                                              {
                                                className:
                                                  'display-flex flex-6',
                                                fieldGroupClassName:
                                                  'width-100',
                                                fieldGroup: [
                                                  {
                                                    className:
                                                      'text-align-center remove-underline',
                                                    key: 'Marciapiede - radio',
                                                    type: 'radio',
                                                    defaultValue: '',
                                                    templateOptions: {
                                                      key: 'Marciapiede - radio',
                                                      options: [
                                                        {
                                                          value: 'SI',
                                                          label: 'SI',
                                                        },
                                                      ],
                                                      change: (field) => {
                                                        if (
                                                          field.formControl
                                                            .value !== 'SI'
                                                        ) {
                                                          field.formControl.parent
                                                            ?.get([
                                                              'SI',
                                                              'SI - radio',
                                                            ])
                                                            ?.setValue(null);
                                                          field.formControl.parent
                                                            ?.get([
                                                              'SI',
                                                              'SI - radio',
                                                            ])
                                                            ?.disable();
                                                        } else {
                                                          field.formControl.parent
                                                            ?.get([
                                                              'SI',
                                                              'SI - radio',
                                                            ])
                                                            ?.enable();
                                                        }
                                                      },
                                                      onReset: (field) => {
                                                        if (
                                                          field.formControl
                                                            .value !== 'SI'
                                                        ) {
                                                          field.formControl.parent
                                                            ?.get([
                                                              'SI',
                                                              'SI - radio',
                                                            ])
                                                            ?.setValue(null);
                                                          field.formControl.parent
                                                            ?.get([
                                                              'SI',
                                                              'SI - radio',
                                                            ])
                                                            ?.disable();
                                                        } else {
                                                          field.formControl.parent
                                                            ?.get([
                                                              'SI',
                                                              'SI - radio',
                                                            ])
                                                            ?.enable();
                                                        }
                                                      },
                                                    },
                                                    hooks: {
                                                      onInit: (field) => {
                                                        setTimeout(() => {
                                                          if (
                                                            field.formControl
                                                              .value !== 'SI'
                                                          ) {
                                                            field.formControl.parent
                                                              ?.get([
                                                                'SI',
                                                                'SI - radio',
                                                              ])
                                                              ?.setValue(null);
                                                            field.formControl.parent
                                                              ?.get([
                                                                'SI',
                                                                'SI - radio',
                                                              ])
                                                              ?.disable();
                                                          } else {
                                                            const isFieldDisabled =
                                                              bridgeInputTemplateComponent.isFieldDisabled(
                                                                [
                                                                  UserRole.hydraulicTechnician,
                                                                ],
                                                                isDisabled,
                                                                true
                                                              );
                                                            if (
                                                              !isFieldDisabled
                                                            ) {
                                                              field.formControl.parent
                                                                ?.get([
                                                                  'SI',
                                                                  'SI - radio',
                                                                ])
                                                                ?.enable();
                                                            }
                                                          }
                                                        }, 0);
                                                      },
                                                    },
                                                  },
                                                  {
                                                    key: 'SI',
                                                    fieldGroup: [
                                                      {
                                                        key: 'SI - radio',
                                                        type: 'radio',
                                                        defaultValue: '',
                                                        className:
                                                          'remove-underline',
                                                        templateOptions: {
                                                          placeholder:
                                                            'Placeholder',
                                                          key: 'SI - radio',
                                                          flex: true,
                                                          disabled:
                                                            bridgeInputTemplateComponent.isFieldDisabled(
                                                              [
                                                                UserRole.hydraulicTechnician,
                                                              ],
                                                              isDisabled,
                                                              true
                                                            ),
                                                          options: [
                                                            {
                                                              value:
                                                                '1 (solo su un lato)',
                                                              label:
                                                                '1 (solo su un lato)',
                                                            },
                                                            {
                                                              value:
                                                                '2 (su entrambi i lati)',
                                                              label:
                                                                '2 (su entrambi i lati)',
                                                            },
                                                          ],
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                              {
                                                className:
                                                  'border-left remove-underline width-20',
                                                key: 'Marciapiede - radio',
                                                type: 'radio',
                                                defaultValue: '',
                                                templateOptions: {
                                                  disabled:
                                                    bridgeInputTemplateComponent.isFieldDisabled(
                                                      [
                                                        UserRole.hydraulicTechnician,
                                                      ],
                                                      isDisabled,
                                                      true
                                                    ),
                                                  key: 'Marciapiede - radio',
                                                  options: [
                                                    {
                                                      value: 'NO',
                                                      label: 'NO',
                                                    },
                                                  ],
                                                  change: (field) => {
                                                    if (
                                                      field.formControl
                                                        .value !== 'SI'
                                                    ) {
                                                      field.formControl.parent
                                                        ?.get([
                                                          'SI',
                                                          'SI - radio',
                                                        ])
                                                        ?.setValue(null);
                                                      field.formControl.parent
                                                        ?.get([
                                                          'SI',
                                                          'SI - radio',
                                                        ])
                                                        ?.disable();
                                                    } else {
                                                      field.formControl.parent
                                                        ?.get([
                                                          'SI',
                                                          'SI - radio',
                                                        ])
                                                        ?.enable();
                                                    }
                                                  },
                                                  onReset: (field) => {
                                                    if (
                                                      field.formControl
                                                        .value !== 'SI'
                                                    ) {
                                                      field.formControl.parent
                                                        ?.get([
                                                          'SI',
                                                          'SI - radio',
                                                        ])
                                                        ?.setValue(null);
                                                      field.formControl.parent
                                                        ?.get([
                                                          'SI',
                                                          'SI - radio',
                                                        ])
                                                        ?.disable();
                                                    } else {
                                                      field.formControl.parent
                                                        ?.get([
                                                          'SI',
                                                          'SI - radio',
                                                        ])
                                                        ?.enable();
                                                    }
                                                  },
                                                },
                                                hooks: {
                                                  onInit: (field) => {
                                                    setTimeout(() => {
                                                      if (
                                                        field.formControl
                                                          .value !== 'SI'
                                                      ) {
                                                        field.formControl.parent
                                                          ?.get([
                                                            'SI',
                                                            'SI - radio',
                                                          ])
                                                          ?.setValue(null);
                                                        field.formControl.parent
                                                          ?.get([
                                                            'SI',
                                                            'SI - radio',
                                                          ])
                                                          ?.disable();
                                                      } else {
                                                        const isFieldDisabled =
                                                          bridgeInputTemplateComponent.isFieldDisabled(
                                                            [
                                                              UserRole.hydraulicTechnician,
                                                            ],
                                                            isDisabled,
                                                            true
                                                          );
                                                        if (!isFieldDisabled) {
                                                          field.formControl.parent
                                                            ?.get([
                                                              'SI',
                                                              'SI - radio',
                                                            ])
                                                            ?.enable();
                                                        }
                                                      }
                                                    }, 0);
                                                  },
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        key: 'Larghezza marciapiede',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: 'Larghezza marciapiede',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                      {
                                        key: 'Altezza marciapiede',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: 'Altezza marciapiede',
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        validators: {
                                          validation: ['numberPattern'],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                key: 'BARRIERE LATERALI',
                                type: 'cube-caso-table-radio-buttons-type',
                                className: 'sub-sec radio-sec',
                                templateOptions: {
                                  groupName: 'BARRIERE LATERALI',
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                  options: [
                                    {
                                      value: 'CASO 1',
                                      label: 'CASO',
                                      text1: 'Marciapiede assente',
                                      text2: '1',
                                    },
                                    {
                                      value: 'CASO 2',
                                      label: 'CASO',
                                      text1: 'Marciapiede sormontabile',
                                      text2: '2',
                                    },
                                    {
                                      value: 'CASO 3',
                                      label: 'CASO',
                                      text1: 'Marciapiede non sormontabile',
                                      text2: '3',
                                    },
                                  ],
                                  change: () => {
                                    bridgeInputTemplateComponent.onChangeElementiAccessoriCasoOption();
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List2',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham flex-3',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            columnDefs: [
                              {
                                headerName: 'Elemento ispezionato',
                                headerClass: 'header-no-child',
                                field: 'Elemento ispezionato',
                                rowSpan: function (params) {
                                  switch (
                                    params.data?.['Elemento ispezionato']
                                  ) {
                                    case 'Stato della pavimentazione':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Dislivello rilevato impalcato'
                                        ? 3
                                        : 1;

                                    case 'Cordoli':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Assenti'
                                        ? 2
                                        : 1;

                                    case 'Convogliamento acque':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Assenti'
                                        ? 5
                                        : 1;

                                    case 'Marciapiedi':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Assenti'
                                        ? 2
                                        : 1;

                                    case 'Pali di illuminazione':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Assenti'
                                        ? 4
                                        : 1;

                                    case 'Sottoservizi':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Assenti'
                                        ? 2
                                        : 1;

                                    case 'Guardiavia danneggiati':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Lievemente'
                                        ? 2
                                        : 1;

                                    case 'Guardiavia ossidati':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Lievemente'
                                        ? 2
                                        : 1;

                                    case 'Guardiavia male ancorati':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === ''
                                        ? 3
                                        : 1;

                                    case 'Parapetto danneggiati':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Lievemente'
                                        ? 2
                                        : 1;

                                    case 'Parapetto ossidati':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Lievemente'
                                        ? 2
                                        : 1;

                                    case 'Parapetto male ancorati':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === ''
                                        ? 3
                                        : 1;

                                    default:
                                      return 1;
                                  }
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': (params) =>
                                    (params.value ===
                                      'Stato della pavimentazione' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Dislivello rilevato impalcato') ||
                                    (params.value === 'Cordoli' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Assenti') ||
                                    (params.value === 'Convogliamento acque' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Assenti') ||
                                    (params.value === 'Marciapiedi' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Assenti') ||
                                    (params.value === 'Pali di illuminazione' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Assenti') ||
                                    (params.value === 'Sottoservizi' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Assenti') ||
                                    (params.value ===
                                      'Guardiavia danneggiati' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Lievemente') ||
                                    (params.value === 'Guardiavia ossidati' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Lievemente') ||
                                    (params.value ===
                                      'Guardiavia male ancorati' &&
                                      params.data?.['Descrizione difetto'] ===
                                        '') ||
                                    (params.value === 'Parapetto danneggiati' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Lievemente') ||
                                    (params.value === 'Parapetto ossidati' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Lievemente') ||
                                    (params.value ===
                                      'Parapetto male ancorati' &&
                                      params.data?.['Descrizione difetto'] ===
                                        ''),
                                },
                                minWidth: 220,
                                flex: 1,
                              },
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                rowSpan: function (params) {
                                  switch (params.data?.['Codice difetto']) {
                                    case 'El.Acc_1':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Dislivello rilevato impalcato'
                                        ? 3
                                        : 1;

                                    case 'El.Acc_2':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Cordoli'
                                        ? 2
                                        : 1;

                                    case 'El.Acc_8':
                                    case 'El.Acc_11':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Assenti'
                                        ? 2
                                        : 1;

                                    case 'El.Acc_9':
                                      return params.data?.[
                                        'Descrizione difetto'
                                      ] === 'Mal ancorati'
                                        ? 2
                                        : 1;

                                    default:
                                      return 1;
                                  }
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': (params) =>
                                    (params.value === 'El.Acc_1' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Dislivello rilevato impalcato') ||
                                    (params.value === 'El.Acc_2' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Cordoli') ||
                                    ((params.value === 'El.Acc_8' ||
                                      params.value === 'El.Acc_11') &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Assenti') ||
                                    (params.value === 'El.Acc_9' &&
                                      params.data?.['Descrizione difetto'] ===
                                        'Mal ancorati'),
                                },
                                width: 125,
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 220,
                                flex: 1,
                              },
                              {
                                headerName: 'G',
                                headerClass: 'header-no-child',
                                field: 'G',
                                width: 50,
                              },
                              {
                                headerName: '',
                                headerClass: 'header-no-child',
                                field: 'checkbox',
                                width: 50,
                                onCellValueChanged: (params) => {
                                  switch (
                                    params.data?.['Elemento ispezionato']
                                  ) {
                                    case 'Cordoli':
                                      if (
                                        params.newValue &&
                                        params.data?.['Descrizione difetto'] ===
                                          'Assenti'
                                      ) {
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 1
                                        ].data.checkbox = false;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 1
                                            ],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      if (
                                        params.newValue &&
                                        params.data?.['Descrizione difetto'] ===
                                          'Degradati'
                                      ) {
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex - 1
                                        ].data.checkbox = false;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex - 1
                                            ],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      break;
                                    case 'Marciapiedi':
                                      if (
                                        params.newValue &&
                                        params.data?.['Descrizione difetto'] ===
                                          'Assenti'
                                      ) {
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 1
                                        ].data.checkbox = false;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 1
                                            ],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      if (
                                        params.newValue &&
                                        params.data?.['Descrizione difetto'] ===
                                          'Pavimetazione degradata'
                                      ) {
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex - 1
                                        ].data.checkbox = false;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex - 1
                                            ],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      break;
                                    case 'Sottoservizi':
                                      if (
                                        params.newValue &&
                                        params.data?.['Descrizione difetto'] ===
                                          'Assenti'
                                      ) {
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 1
                                        ].data.checkbox = false;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 1
                                            ],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      if (
                                        params.newValue &&
                                        params.data?.['Descrizione difetto'] ===
                                          'Mal ancorati'
                                      ) {
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex - 1
                                        ].data.checkbox = false;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex - 1
                                            ],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      break;
                                    case 'Pali di illuminazione':
                                      if (
                                        params.newValue &&
                                        params.data?.['Descrizione difetto'] ===
                                          'Assenti'
                                      ) {
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 1
                                        ].data.checkbox = false;
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 2
                                        ].data.checkbox = false;
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 3
                                        ].data.checkbox = false;
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 1
                                        ].selectable = false;
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 2
                                        ].selectable = false;
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 3
                                        ].selectable = false;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex
                                            ],
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 1
                                            ],
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 2
                                            ],
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 3
                                            ],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      if (
                                        !params.newValue &&
                                        params.data?.['Descrizione difetto'] ===
                                          'Assenti'
                                      ) {
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 1
                                        ].selectable = true;
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 2
                                        ].selectable = true;
                                        params.node.parent.allLeafChildren[
                                          params.node.childIndex + 3
                                        ].selectable = true;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex
                                            ],
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 1
                                            ],
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 2
                                            ],
                                            params.node?.parent.allLeafChildren[
                                              params.node.childIndex + 3
                                            ],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      if (
                                        params.node.parent.allLeafChildren[12]
                                          .data.checkbox &&
                                        params.newValue &&
                                        (params.data?.[
                                          'Descrizione difetto'
                                        ] === 'Mal ancorati' ||
                                          params.data?.[
                                            'Descrizione difetto'
                                          ] === 'Danneggiati' ||
                                          params.data?.[
                                            'Descrizione difetto'
                                          ] === 'Arrugginiti')
                                      ) {
                                        params.data.checkbox = false;
                                        params.api.refreshCells({
                                          rowNodes: [
                                            params.node?.parent
                                              .allLeafChildren[12],
                                            params.node?.parent
                                              .allLeafChildren[13],
                                            params.node?.parent
                                              .allLeafChildren[14],
                                            params.node?.parent
                                              .allLeafChildren[15],
                                          ],
                                          columns: [params.column],
                                        });
                                      }
                                      break;
                                  }
                                },
                                cellRenderer:
                                  defectTableCheckboxRendererComponent,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'N° foto',
                                headerClass: 'header-no-child',
                                field: 'N° foto',
                                width: 70,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.elementiAccessori,
                                casoType: 0,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1.5,
                                cellClass: ['remove-underline'],
                                cellRenderer: defectTableNoteRendererComponent,
                                cellRendererParams: {
                                  mode: DefectNoteMode.elementiAccessori,
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Elemento ispezionato',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'checkbox',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                key: 'Caso1',
                fieldGroup: [
                  {
                    fieldGroupClassName: 'caso-heading',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        template: `<div class="caso-number">Caso 1</div>`,
                      },
                      {
                        className: 'caso-name flex-2',
                        template: `<div>Marciapiede assente</div>`,
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        key: 'GUARDIAVIA',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham flex-3',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          gridOptions: {
                            columnDefs: [
                              {
                                headerClass: 'hide-header',
                                field: 'S No',
                                minWidth: 70,
                                maxWidth: 100,
                                flex: 0.25,
                                rowSpan: function (params) {
                                  return 13;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': 'value === 1',
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Guardiavia',
                                minWidth: 200,
                                flex: 1.25,
                                rowSpan: function (params) {
                                  return 13;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': "value === 'GUARDIAVIA'",
                                },
                              },
                              {
                                headerClass: 'border-left',
                                field: 'Codice difetto',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['align-items'],
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Marciapiede assente',
                                rowSpan: function (params) {
                                  switch (params.data['Marciapiede assente']) {
                                    case 'Tipologia':
                                      return 4;

                                    case 'Danneggiati':
                                    case 'Ossidati':
                                    case 'Elementi di ancoraggio':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                                cellClass: [
                                  'align-items-center',
                                  'display-flex',
                                ],
                                cellClassRules: {
                                  'cell-span':
                                    "value === 'Tipologia' || value === 'Danneggiati' || value === 'Ossidati' || value === 'Elementi di ancoraggio'",
                                },
                                minWidth: 150,
                                flex: 1,
                                colSpan: function (params) {
                                  switch (params.data['Marciapiede assente']) {
                                    case 'Assente':
                                    case 'Mal ancorati':
                                    case 'Altezza (cm)':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Sub Marciapiede assente',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['cell-span'],
                              },
                              {
                                headerName: 'G',
                                headerClass: 'border-left',
                                field: 'G',
                                width: 50,
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'checkbox',
                                minWidth: 50,
                                maxWidth: 200,
                                flex: 0.5,
                                cellRendererSelector: (params) => {
                                  if (
                                    params.data['Marciapiede assente'] ==
                                    'Altezza (cm)'
                                  )
                                    return null;

                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                colSpan: (params) => {
                                  return params.data['Marciapiede assente'] ==
                                    'Altezza (cm)'
                                    ? 2
                                    : 1;
                                },
                                cellClass: [
                                  'display-flex-wrap',
                                  'justify-content-center',
                                ],
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerClass: 'border-left',
                                field: 'N° foto',
                                minWidth: 100,
                                maxWidth: 200,
                                flex: 0.5,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.elementiAccessori,
                                casoType: 1,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'S No',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Guardiavia',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Marciapiede assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Sub Marciapiede assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              type: 'cube-number-type',
                              defaultValue: '',
                              key: 'checkbox',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                readonly: true,
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                hideExpression: () =>
                  bridgeInputTemplateComponent.model[BridgeConstants.levels.l1][
                    'Scheda Ispezione Ponti di Livello 1'
                  ]['Scheda Ispezione Ponti di Livello 1 List1'][
                    'BARRIERE LATERALI'
                  ] !== 'CASO 1',
              },
              {
                key: 'Caso2',
                fieldGroup: [
                  {
                    fieldGroupClassName: 'caso-heading',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        template: `<div class="caso-number">Caso 2</div>`,
                      },
                      {
                        className: 'caso-name flex-2',
                        template: `<div>Marciapiede sormontabile</div>`,
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        key: 'GUARDIAVIA',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham flex-3',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          gridOptions: {
                            rowHeight: 43,
                            columnDefs: [
                              {
                                headerClass: 'hide-header',
                                field: 'S No',
                                minWidth: 70,
                                maxWidth: 100,
                                flex: 0.25,
                                rowSpan: function (params) {
                                  return 15;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': 'value === 1',
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Marciapiede',
                                minWidth: 200,
                                flex: 1.25,
                                rowSpan: function (params) {
                                  return 15;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': "value === 'GUARDIAVIA'",
                                },
                              },
                              {
                                headerClass: 'border-left',
                                field: 'Codice difetto',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['align-items'],
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Assente',
                                rowSpan: function (params) {
                                  switch (params.data.Assente) {
                                    case 'Tipologia':
                                      return 4;

                                    case 'Danneggiati':
                                    case 'Ossidati':
                                    case 'Elementi di ancoraggio':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                                cellClass: [
                                  'align-items-center',
                                  'display-flex',
                                ],
                                cellClassRules: {
                                  'cell-span':
                                    "value === 'Tipologia' || value === 'Danneggiati' || value === 'Ossidati' || value === 'Elementi di ancoraggio'",
                                },
                                minWidth: 150,
                                flex: 1,
                                colSpan: function (params) {
                                  switch (params.data.Assente) {
                                    case 'Assente':
                                    case 'Mal ancorati':
                                    case 'Altezza (cm)':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'sub-assente',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['cell-span'],
                              },
                              {
                                headerName: 'G',
                                headerClass: 'border-left',
                                field: 'G',
                                width: 50,
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'checkbox',
                                minWidth: 50,
                                maxWidth: 200,
                                flex: 0.5,
                                cellRendererSelector: (params) => {
                                  if (params.data.Assente == 'Altezza (cm)')
                                    return null;

                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                colSpan: (params) => {
                                  return params.data.Assente == 'Altezza (cm)'
                                    ? 2
                                    : 1;
                                },
                                cellClass: [
                                  'display-flex-wrap',
                                  'justify-content-center',
                                ],
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerClass: 'border-left',
                                field: 'N° foto',
                                minWidth: 100,
                                maxWidth: 200,
                                flex: 0.5,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.elementiAccessori,
                                casoType: 2,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'S No',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Marciapiede',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'sub-assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              type: 'cube-number-type',
                              defaultValue: '',
                              key: 'checkbox',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                readonly: true,
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        key: 'PARAPETTO',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham flex-3',
                        templateOptions: {
                          hasModelData: true,
                          gridOptions: {
                            rowHeight: 43,
                            headerHeight: 0,
                            columnDefs: [
                              {
                                headerClass: 'hide-header',
                                field: 'S No',
                                minWidth: 70,
                                maxWidth: 100,
                                flex: 0.25,
                                rowSpan: function (params) {
                                  return 15;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': 'value === 2',
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Marciapiede',
                                minWidth: 200,
                                flex: 1.25,
                                rowSpan: function (params) {
                                  return 15;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span':
                                    "value === 'PARAPETTO(Guardiavia se 1 assente)'",
                                },
                              },
                              {
                                headerClass: 'border-left',
                                field: 'Codice difetto',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['align-items'],
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Assente',
                                rowSpan: function (params) {
                                  switch (params.data.Assente) {
                                    case 'Tipologia':
                                      return 4;

                                    case 'Danneggiati':
                                    case 'Ossidati':
                                    case 'Elementi di ancoraggio':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                                cellClass: [
                                  'align-items-center',
                                  'display-flex',
                                ],
                                cellClassRules: {
                                  'cell-span':
                                    "value === 'Tipologia' || value === 'Danneggiati' || value === 'Ossidati' || value === 'Elementi di ancoraggio'",
                                },
                                minWidth: 150,
                                flex: 1,
                                colSpan: function (params) {
                                  switch (params.data.Assente) {
                                    case 'Assente':
                                    case 'Mal ancora':
                                    case 'Altezza (cm)':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'sub-assente',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['cell-span'],
                              },
                              {
                                headerName: 'G',
                                headerClass: 'border-left',
                                field: 'G',
                                width: 50,
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'checkbox',
                                minWidth: 50,
                                maxWidth: 200,
                                flex: 0.5,
                                cellRendererSelector: (params) => {
                                  if (params.data.Assente == 'Altezza (cm)')
                                    return null;

                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                colSpan: (params) => {
                                  return params.data.Assente == 'Altezza (cm)'
                                    ? 2
                                    : 1;
                                },
                                cellClass: [
                                  'display-flex-wrap',
                                  'justify-content-center',
                                ],
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'N° foto',
                                minWidth: 100,
                                maxWidth: 200,
                                flex: 0.5,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.elementiAccessori,
                                casoType: 2,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'S No',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Marciapiede',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'sub-assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              type: 'cube-number-type',
                              defaultValue: '',
                              key: 'checkbox',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                readonly: true,
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                hideExpression: () =>
                  bridgeInputTemplateComponent.model[BridgeConstants.levels.l1][
                    'Scheda Ispezione Ponti di Livello 1'
                  ]['Scheda Ispezione Ponti di Livello 1 List1'][
                    'BARRIERE LATERALI'
                  ] !== 'CASO 2',
              },
              {
                key: 'Caso3',
                fieldGroup: [
                  {
                    fieldGroupClassName: 'caso-heading',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        template: `<div class="caso-number">Caso 3</div>`,
                      },
                      {
                        className: 'caso-name flex-2',
                        template: `<div>Marciapiede NON sormontabile</div>`,
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        key: 'GUARDIAVIA',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham flex-3',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          gridOptions: {
                            rowHeight: 43,
                            columnDefs: [
                              {
                                headerClass: 'hide-header',
                                field: 'S No',
                                minWidth: 70,
                                maxWidth: 100,
                                flex: 0.25,
                                rowSpan: function (params) {
                                  return 15;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': 'value === 1',
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Marciapiede',
                                minWidth: 200,
                                flex: 1.25,
                                rowSpan: function (params) {
                                  return 15;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': "value === 'GUARDIAVIA'",
                                },
                              },
                              {
                                headerClass: 'border-left',
                                field: 'Codice difetto',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['align-items'],
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Assente',
                                rowSpan: function (params) {
                                  switch (params.data.Assente) {
                                    case 'Tipologia':
                                      return 4;

                                    case 'Presente':
                                    case 'Danneggiati':
                                    case 'Ossidati':
                                    case 'Elementi di ancoraggio':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                                cellClass: [
                                  'align-items-center',
                                  'display-flex',
                                ],
                                cellClassRules: {
                                  'cell-span':
                                    "value === 'Presente'|| value === 'Tipologia' || value === 'Danneggiati' || value === 'Ossidati' || value === 'Elementi di ancoraggio'",
                                },
                                minWidth: 150,
                                flex: 1,
                                colSpan: function (params) {
                                  switch (params.data.Assente) {
                                    case 'Assente':
                                    case 'Mal ancorati':
                                    case 'Altezza (cm)':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'sub-assente',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['cell-span'],
                              },
                              {
                                headerName: 'G',
                                headerClass: 'border-left',
                                field: 'G',
                                width: 50,
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'checkbox',
                                minWidth: 50,
                                maxWidth: 200,
                                flex: 0.5,
                                cellRendererSelector: (params) => {
                                  if (params.data.Assente == 'Altezza (cm)')
                                    return null;

                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                colSpan: (params) => {
                                  return params.data.Assente == 'Altezza (cm)'
                                    ? 2
                                    : 1;
                                },
                                cellClass: [
                                  'display-flex-wrap',
                                  'justify-content-center',
                                ],
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerClass: 'border-left',
                                field: 'N° foto',
                                minWidth: 100,
                                maxWidth: 200,
                                flex: 0.5,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.elementiAccessori,
                                casoType: 3,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'S No',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Marciapiede',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'sub-assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              type: 'cube-number-type',
                              defaultValue: '',
                              key: 'checkbox',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                readonly: true,
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        key: 'PARAPETTO',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham flex-3',
                        templateOptions: {
                          hasModelData: true,
                          gridOptions: {
                            rowHeight: 43,
                            headerHeight: 0,
                            columnDefs: [
                              {
                                headerClass: 'hide-header',
                                field: 'S No',
                                minWidth: 70,
                                maxWidth: 100,
                                flex: 0.25,
                                rowSpan: function (params) {
                                  return 15;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span': 'value === 2',
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Marciapiede',
                                minWidth: 200,
                                flex: 1.25,
                                rowSpan: function (params) {
                                  return 15;
                                },
                                cellClass: ['align-items'],
                                cellClassRules: {
                                  'cell-span':
                                    "value === 'PARAPETTO(Guardiavia se 1 assente)'",
                                },
                              },
                              {
                                headerClass: 'border-left',
                                field: 'Codice difetto',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['align-items'],
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'Assente',
                                rowSpan: function (params) {
                                  switch (params.data.Assente) {
                                    case 'Tipologia':
                                      return 4;

                                    case 'Presente':
                                    case 'Danneggiati':
                                    case 'Ossidati':
                                    case 'Elementi di ancoraggio':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                                cellClass: [
                                  'align-items-center',
                                  'display-flex',
                                ],
                                cellClassRules: {
                                  'cell-span':
                                    "value === 'Tipologia' || value === 'Danneggiati' || value === 'Ossidati' || value === 'Elementi di ancoraggio'",
                                },
                                minWidth: 150,
                                flex: 1,
                                colSpan: function (params) {
                                  switch (params.data.Assente) {
                                    case 'Assente (se guardiavia non necessario)':
                                    case 'Mal ancorati':
                                    case 'Altezza (cm)':
                                      return 2;

                                    default:
                                      return 1;
                                  }
                                },
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'sub-assente',
                                minWidth: 150,
                                flex: 1,
                                cellClass: ['cell-span'],
                              },
                              {
                                headerName: 'G',
                                headerClass: 'border-left',
                                field: 'G',
                                width: 50,
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'checkbox',
                                minWidth: 50,
                                maxWidth: 200,
                                flex: 0.5,
                                cellRendererSelector: (params) => {
                                  if (params.data.Assente == 'Altezza (cm)')
                                    return null;

                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                colSpan: (params) => {
                                  return params.data.Assente == 'Altezza (cm)'
                                    ? 2
                                    : 1;
                                },
                                cellClass: [
                                  'display-flex-wrap',
                                  'justify-content-center',
                                ],
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerClass: 'hide-header',
                                field: 'N° foto',
                                minWidth: 100,
                                maxWidth: 200,
                                flex: 0.5,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.elementiAccessori,
                                casoType: 3,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'S No',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Marciapiede',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'sub-assente',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              type: 'cube-number-type',
                              defaultValue: '',
                              key: 'checkbox',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                readonly: true,
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                hideExpression: () =>
                  bridgeInputTemplateComponent.model[BridgeConstants.levels.l1][
                    'Scheda Ispezione Ponti di Livello 1'
                  ]['Scheda Ispezione Ponti di Livello 1 List1'][
                    'BARRIERE LATERALI'
                  ] !== 'CASO 3',
              },
            ],
            expressionProperties: {
              'templateOptions.isHidden': () =>
                bridgeInputTemplateComponent.isShortcutView,
            },
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.l1,
      },
      {
        key: BridgeConstants.levels.l2,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: { label: 'Campata' },
            fieldGroup: [
              {
                className: 'flex-1',
                key: BridgeConstants.partNo,
                type: 'select',
                templateOptions: {
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  required: true,
                  label: BridgeConstants.partNo,
                  options: [],
                  change: (field, e) => {
                    const campataNo =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo];
                    const isCampataNoAlreadyAdded =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l1
                      ].campatelist.some(
                        (obj) => obj[BridgeConstants.partNo] === campataNo
                      );

                    if (isCampataNoAlreadyAdded) {
                      // If campata number is already added
                      bridgeInputTemplateComponent.getConfirmation({
                        isCancelButtonVisible: false,
                        message:
                          'Campata number already added. Please choose another campata number.',
                        title: 'Info',
                      });

                      // Reverting campata number to previous number if it was already selected earlier
                      const elementsList = [].concat(
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l2
                        ].spallelist,
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l2
                        ].pilelist,
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l2
                        ].archilist,
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l2
                        ].impalcatilist,
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l2
                        ].filediappoggilist,
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l2
                        ].giuntilist,
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l2
                        ].apparecchisismicilist
                      );
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo] = elementsList.length
                        ? elementsList[0].modelstructure[
                            'Scheda Ispezione Ponti di Livello 1'
                          ][BridgeConstants.partNo]
                        : '';
                      bridgeInputTemplateComponent.refreshFields();

                      return;
                    }

                    // If campata number has not been added already

                    // Updating all defects under all elements
                    const elementListNames = [
                      `${BridgeConstants.levels.spalle}list`,
                      `${BridgeConstants.levels.pile}list`,
                      `${BridgeConstants.levels.archi}list`,
                      `${BridgeConstants.levels.impalcati}list`,
                      `${BridgeConstants.levels.fileDiAppoggi}list`,
                      `${BridgeConstants.levels.giunti}list`,
                      `${BridgeConstants.levels.apparecchiSismici}list`,
                    ];
                    for (const elementListName of elementListNames) {
                      const elementList =
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.l2
                        ][elementListName];

                      for (const element of elementList) {
                        const modelstructure =
                          element.modelstructure[
                            'Scheda Ispezione Ponti di Livello 1'
                          ];

                        // Campata No.
                        modelstructure[BridgeConstants.partNo] = campataNo;

                        // Sigla ID Elem
                        const siglaValue =
                          modelstructure['Sigla ID Elem'].split('-');
                        modelstructure[
                          'Sigla ID Elem'
                        ] = `C${campataNo}-${siglaValue[1]}-${siglaValue[2]}`;
                      }
                    }

                    /* // TODO to be removed if not needed
                    const defectLevelNames = [
                      BridgeConstants.defectElements.spalle,
                      BridgeConstants.defectElements.pile,
                      BridgeConstants.defectElements.archi,
                      BridgeConstants.defectElements.impalcati,
                      BridgeConstants.defectElements.giunti,
                      BridgeConstants.defectElements.apparecchiSismici,
                      BridgeConstants.defectElements.fileDiAppoggi,
                    ];
                    for (const defectLevelName of defectLevelNames) {
                      const defectLevel =
                        bridgeInputTemplateComponent.model[defectLevelName];
      
                      // Campata No
                      defectLevel[BridgeConstants.partNo] = campataNo;
      
                      // compata code
                      defectLevel['compata code'] = campataNo;
                    } */

                    bridgeInputTemplateComponent.refreshFields();
                  },
                },
                hooks: {
                  onInit: (field) => {
                    field.templateOptions.options =
                      bridgeInputTemplateComponent.campateNoList;
                  },
                },
                hideExpression: () =>
                  bridgeInputTemplateComponent.l2UpdateStatus,
              },
              {
                className: 'flex-1',
                key: BridgeConstants.partNo,
                type: 'input',
                templateOptions: {
                  readonly: true,
                  required: true,
                  label: BridgeConstants.partNo,
                },
                hideExpression: () =>
                  !bridgeInputTemplateComponent.l2UpdateStatus,
              },
            ],
          },
          {
            templateOptions: { label: BridgeConstants.defectElements.spalle },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: `${BridgeConstants.levels.spalle}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: `${BridgeConstants.defectElements.spalle} No`,
                        headerClass: 'header-no-child',
                        field: 'element no',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Direzione',
                        headerClass: 'header-no-child',
                        field: 'direzione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 90,
                      },
                      {
                        headerName: 'Localizazione',
                        headerClass: 'header-no-child',
                        field: 'localizazione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 2,
                        minWidth: 300,
                      },
                      {
                        headerName: 'Materiale',
                        headerClass: 'header-no-child',
                        field: 'materiale',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Unique Code',
                        headerClass: 'header-no-child',
                        field: 'uniquecode',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 0.75,
                        minWidth: 110,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.spalle;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.spalle,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].spallelist,
                              `${BridgeConstants.defectElements.spalle} No`
                            );
                          },
                          onDuplicate: (index) => {
                            bridgeInputTemplateComponent.duplicateDefect(
                              index,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].spallelist,
                              BridgeConstants.defectElements.spalle,
                              `${BridgeConstants.levels.spalle}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].spallelist[index].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const key =
                                defect['Codice difetto'] ===
                                BridgeConstants.eventualiNote
                                  ? 'Note'
                                  : 'N° foto';
                              if (defect[key]) {
                                const imageUrls = Object.entries(defect[key]);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              bridgeInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            bridgeInputTemplateComponent.updateUniqueCode(
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].spallelist[index],
                              `${BridgeConstants.levels.spalle}list`,
                              BridgeConstants.defectElements.spalle,
                              index
                            );
                          },
                        },
                        flex: 1,
                        minWidth: isDisabled ? 80 : 300,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'element no',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: `${BridgeConstants.defectElements.spalle} No`,
                        readonly: true,
                      },
                    },
                    {
                      key: 'localizazione',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Localizazione',
                        readonly: true,
                      },
                    },
                    {
                      key: 'materiale',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Materiale',
                        readonly: true,
                      },
                    },
                    {
                      key: 'uniquecode',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Unique Code',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Spalle
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  onClick: (e) => {
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l2
                    );
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.spalle
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Codice IOP'];
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.spalle
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      BridgeConstants.partNo
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo];
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.spalle;

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.spalle
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      'Strada di appartenenza'
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Strada di appartenenza'];

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.spalle
                    ]['Scheda Ispezione Ponti di Livello 1']['Progressiva km'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Progressiva km iniziale'];
                  },
                },
              },
            ],
          },
          {
            templateOptions: { label: BridgeConstants.defectElements.pile },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: `${BridgeConstants.levels.pile}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: `${BridgeConstants.defectElements.pile} No`,
                        headerClass: 'header-no-child',
                        field: 'element no',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Direzione',
                        headerClass: 'header-no-child',
                        field: 'direzione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 90,
                      },
                      {
                        headerName: 'Localizazione',
                        headerClass: 'header-no-child',
                        field: 'localizazione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 2,
                        minWidth: 300,
                      },
                      {
                        headerName: 'Materiale',
                        headerClass: 'header-no-child',
                        field: 'materiale',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Unique Code',
                        headerClass: 'header-no-child',
                        field: 'uniquecode',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 0.75,
                        minWidth: 110,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.pile;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.pile,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].pilelist,
                              `${BridgeConstants.defectElements.pile} No`
                            );
                          },
                          onDuplicate: (index) => {
                            bridgeInputTemplateComponent.duplicateDefect(
                              index,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].pilelist,
                              BridgeConstants.defectElements.pile,
                              `${BridgeConstants.levels.pile}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].pilelist[index].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const key =
                                defect['Codice difetto'] ===
                                BridgeConstants.eventualiNote
                                  ? 'Note'
                                  : 'N° foto';
                              if (defect[key]) {
                                const imageUrls = Object.entries(defect[key]);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              bridgeInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            bridgeInputTemplateComponent.updateUniqueCode(
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].pilelist[index],
                              `${BridgeConstants.levels.pile}list`,
                              BridgeConstants.defectElements.pile,
                              index
                            );
                          },
                        },
                        flex: 1,
                        minWidth: isDisabled ? 80 : 300,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'element no',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: `${BridgeConstants.defectElements.pile} No`,
                        readonly: true,
                      },
                    },
                    {
                      key: 'localizazione',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Localizazione',
                        readonly: true,
                      },
                    },
                    {
                      key: 'materiale',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Materiale',
                        readonly: true,
                      },
                    },
                    {
                      key: 'uniquecode',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Unique Code',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Pile
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  onClick: (e) => {
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l2
                    );
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.pile
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Codice IOP'];
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.pile
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      BridgeConstants.partNo
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo];
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.pile;

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.pile
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      'Strada di appartenenza'
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Strada di appartenenza'];

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.pile
                    ]['Scheda Ispezione Ponti di Livello 1']['Progressiva km'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Progressiva km iniziale'];
                  },
                },
              },
            ],
          },
          {
            templateOptions: { label: BridgeConstants.defectElements.archi },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: `${BridgeConstants.levels.archi}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: `${BridgeConstants.defectElements.archi} No`,
                        headerClass: 'header-no-child',
                        field: 'element no',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Direzione',
                        headerClass: 'header-no-child',
                        field: 'direzione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 90,
                      },
                      {
                        headerName: 'Localizazione',
                        headerClass: 'header-no-child',
                        field: 'localizazione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 2,
                        minWidth: 300,
                      },
                      {
                        headerName: 'Materiale',
                        headerClass: 'header-no-child',
                        field: 'materiale',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Unique Code',
                        headerClass: 'header-no-child',
                        field: 'uniquecode',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 0.75,
                        minWidth: 110,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.archi;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.archi,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].archilist,
                              `${BridgeConstants.defectElements.archi} No`
                            );
                          },
                          onDuplicate: (index) => {
                            bridgeInputTemplateComponent.duplicateDefect(
                              index,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].archilist,
                              BridgeConstants.defectElements.archi,
                              `${BridgeConstants.levels.archi}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].archilist[index].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const key =
                                defect['Codice difetto'] ===
                                BridgeConstants.eventualiNote
                                  ? 'Note'
                                  : 'N° foto';
                              if (defect[key]) {
                                const imageUrls = Object.entries(defect[key]);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              bridgeInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            bridgeInputTemplateComponent.updateUniqueCode(
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].archilist[index],
                              `${BridgeConstants.levels.archi}list`,
                              BridgeConstants.defectElements.archi,
                              index
                            );
                          },
                        },
                        flex: 1,
                        minWidth: isDisabled ? 80 : 300,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'element no',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: `${BridgeConstants.defectElements.archi} No`,
                        readonly: true,
                      },
                    },
                    {
                      key: 'localizazione',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Localizazione',
                        readonly: true,
                      },
                    },
                    {
                      key: 'materiale',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Materiale',
                        readonly: true,
                      },
                    },
                    {
                      key: 'uniquecode',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Unique Code',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Archi
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  onClick: (e) => {
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l2
                    );
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.archi
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Codice IOP'];
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.archi
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      BridgeConstants.partNo
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo];
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.archi;

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.archi
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      'Strada di appartenenza'
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Strada di appartenenza'];

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.archi
                    ]['Scheda Ispezione Ponti di Livello 1']['Progressiva km'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Progressiva km iniziale'];
                  },
                },
              },
            ],
          },
          {
            templateOptions: {
              label: BridgeConstants.defectElements.impalcati,
            },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: `${BridgeConstants.levels.impalcati}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: `${BridgeConstants.defectElements.impalcati} No`,
                        headerClass: 'header-no-child',
                        field: 'element no',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Direzione',
                        headerClass: 'header-no-child',
                        field: 'direzione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 90,
                      },
                      {
                        headerName: 'Localizazione',
                        headerClass: 'header-no-child',
                        field: 'localizazione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 2,
                        minWidth: 300,
                      },
                      {
                        headerName: 'Materiale',
                        headerClass: 'header-no-child',
                        field: 'materiale',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Unique Code',
                        headerClass: 'header-no-child',
                        field: 'uniquecode',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 0.75,
                        minWidth: 110,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.impalcati;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.impalcati,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].impalcatilist,
                              `${BridgeConstants.defectElements.impalcati} No`
                            );
                          },
                          onDuplicate: (index) => {
                            bridgeInputTemplateComponent.duplicateDefect(
                              index,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].impalcatilist,
                              BridgeConstants.defectElements.impalcati,
                              `${BridgeConstants.levels.impalcati}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].impalcatilist[index].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const key =
                                defect['Codice difetto'] ===
                                BridgeConstants.eventualiNote
                                  ? 'Note'
                                  : 'N° foto';
                              if (defect[key]) {
                                const imageUrls = Object.entries(defect[key]);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              bridgeInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            bridgeInputTemplateComponent.updateUniqueCode(
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].impalcatilist[index],
                              `${BridgeConstants.levels.impalcati}list`,
                              BridgeConstants.defectElements.impalcati,
                              index
                            );
                          },
                        },
                        flex: 1,
                        minWidth: isDisabled ? 80 : 300,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'element no',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: `${BridgeConstants.defectElements.impalcati} No`,
                        readonly: true,
                      },
                    },
                    {
                      key: 'localizazione',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Localizazione',
                        readonly: true,
                      },
                    },
                    {
                      key: 'materiale',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Materiale',
                        readonly: true,
                      },
                    },
                    {
                      key: 'uniquecode',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Unique Code',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Impalcati
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  onClick: (e) => {
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l2
                    );
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.impalcati
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Codice IOP'];
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.impalcati
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      BridgeConstants.partNo
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo];
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.impalcati;

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.impalcati
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      'Strada di appartenenza'
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Strada di appartenenza'];

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.impalcati
                    ]['Scheda Ispezione Ponti di Livello 1']['Progressiva km'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Progressiva km iniziale'];
                  },
                },
              },
            ],
          },
          {
            templateOptions: {
              label: BridgeConstants.defectElements.fileDiAppoggi,
            },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: `${BridgeConstants.levels.fileDiAppoggi}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: `${BridgeConstants.defectElements.fileDiAppoggi} No`,
                        headerClass: 'header-no-child',
                        field: 'element no',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Direzione',
                        headerClass: 'header-no-child',
                        field: 'direzione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 90,
                      },
                      {
                        headerName: 'Localizazione',
                        headerClass: 'header-no-child',
                        field: 'localizazione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 2,
                        minWidth: 300,
                      },
                      {
                        headerName: 'Materiale',
                        headerClass: 'header-no-child',
                        field: 'materiale',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Unique Code',
                        headerClass: 'header-no-child',
                        field: 'uniquecode',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 0.75,
                        minWidth: 110,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.fileDiAppoggi;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.fileDiAppoggi,
                              bridgeInputTemplateComponent.model.l2
                                .filediappoggilist,
                              `${BridgeConstants.defectElements.fileDiAppoggi} No`
                            );
                          },
                          onDuplicate: (index) => {
                            bridgeInputTemplateComponent.duplicateDefect(
                              index,
                              bridgeInputTemplateComponent.model.l2
                                .filediappoggilist,
                              BridgeConstants.defectElements.fileDiAppoggi,
                              `${BridgeConstants.levels.fileDiAppoggi}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].filediappoggilist[index].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const key =
                                defect['Codice difetto'] ===
                                BridgeConstants.eventualiNote
                                  ? 'Note'
                                  : 'N° foto';
                              if (defect[key]) {
                                const imageUrls = Object.entries(defect[key]);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              bridgeInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            bridgeInputTemplateComponent.updateUniqueCode(
                              bridgeInputTemplateComponent.model.l2
                                .filediappoggilist[index],
                              `${BridgeConstants.levels.fileDiAppoggi}list`,
                              BridgeConstants.defectElements.fileDiAppoggi,
                              index
                            );
                          },
                        },
                        flex: 1,
                        minWidth: isDisabled ? 80 : 300,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'element no',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: `${BridgeConstants.defectElements.fileDiAppoggi} No`,
                        readonly: true,
                      },
                    },
                    {
                      key: 'localizazione',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Localizazione',
                        readonly: true,
                      },
                    },
                    {
                      key: 'materiale',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Materiale',
                        readonly: true,
                      },
                    },
                    {
                      key: 'uniquecode',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Unique Code',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New File Di Appoggi
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  onClick: (e) => {
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l2
                    );
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.fileDiAppoggi
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Codice IOP'];
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.fileDiAppoggi
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      BridgeConstants.partNo
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo];
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.fileDiAppoggi;

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.fileDiAppoggi
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      'Strada di appartenenza'
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Strada di appartenenza'];

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.fileDiAppoggi
                    ]['Scheda Ispezione Ponti di Livello 1']['Progressiva km'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Progressiva km iniziale'];
                  },
                },
              },
            ],
          },
          {
            templateOptions: { label: BridgeConstants.defectElements.giunti },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: `${BridgeConstants.levels.giunti}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: `${BridgeConstants.defectElements.giunti} No`,
                        headerClass: 'header-no-child',
                        field: 'element no',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Direzione',
                        headerClass: 'header-no-child',
                        field: 'direzione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 90,
                      },
                      {
                        headerName: 'Localizazione',
                        headerClass: 'header-no-child',
                        field: 'localizazione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 2,
                        minWidth: 300,
                      },
                      {
                        headerName: 'Materiale',
                        headerClass: 'header-no-child',
                        field: 'materiale',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Unique Code',
                        headerClass: 'header-no-child',
                        field: 'uniquecode',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 0.75,
                        minWidth: 110,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.giunti;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.giunti,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].giuntilist,
                              `${BridgeConstants.defectElements.giunti} No`
                            );
                          },
                          onDuplicate: (index) => {
                            bridgeInputTemplateComponent.duplicateDefect(
                              index,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].giuntilist,
                              BridgeConstants.defectElements.giunti,
                              `${BridgeConstants.levels.giunti}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].giuntilist[index].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const key =
                                defect['Codice difetto'] ===
                                BridgeConstants.eventualiNote
                                  ? 'Note'
                                  : 'N° foto';
                              if (defect[key]) {
                                const imageUrls = Object.entries(defect[key]);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              bridgeInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            bridgeInputTemplateComponent.updateUniqueCode(
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].giuntilist[index],
                              `${BridgeConstants.levels.giunti}list`,
                              BridgeConstants.defectElements.giunti,
                              index
                            );
                          },
                        },
                        flex: 1,
                        minWidth: isDisabled ? 80 : 300,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'element no',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: `${BridgeConstants.defectElements.giunti} No`,
                        readonly: true,
                      },
                    },
                    {
                      key: 'localizazione',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Localizazione',
                        readonly: true,
                      },
                    },
                    {
                      key: 'materiale',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Materiale',
                        readonly: true,
                      },
                    },
                    {
                      key: 'uniquecode',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Unique Code',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Giunti
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  onClick: (e) => {
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l2
                    );
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.giunti
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Codice IOP'];
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.giunti
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      BridgeConstants.partNo
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo];
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.giunti;

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.giunti
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      'Strada di appartenenza'
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Strada di appartenenza'];

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.giunti
                    ]['Scheda Ispezione Ponti di Livello 1']['Progressiva km'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Progressiva km iniziale'];
                  },
                },
              },
            ],
          },
          {
            templateOptions: {
              label: BridgeConstants.defectElements.apparecchiSismici,
            },
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: `${BridgeConstants.levels.apparecchiSismici}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: `${BridgeConstants.defectElements.apparecchiSismici} No`,
                        headerClass: 'header-no-child',
                        field: 'element no',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Direzione',
                        headerClass: 'header-no-child',
                        field: 'direzione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 90,
                      },
                      {
                        headerName: 'Localizazione',
                        headerClass: 'header-no-child',
                        field: 'localizazione',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 2,
                        minWidth: 300,
                      },
                      {
                        headerName: 'Materiale',
                        headerClass: 'header-no-child',
                        field: 'materiale',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Unique Code',
                        headerClass: 'header-no-child',
                        field: 'uniquecode',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 0.75,
                        minWidth: 110,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.apparecchiSismici;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.apparecchiSismici,
                              bridgeInputTemplateComponent.model.l2
                                .apparecchisismicilist,
                              `${BridgeConstants.defectElements.apparecchiSismici} No`
                            );
                          },
                          onDuplicate: (index) => {
                            bridgeInputTemplateComponent.duplicateDefect(
                              index,
                              bridgeInputTemplateComponent.model.l2
                                .apparecchisismicilist,
                              BridgeConstants.defectElements.apparecchiSismici,
                              `${BridgeConstants.levels.apparecchiSismici}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].apparecchisismicilist[index].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const key =
                                defect['Codice difetto'] ===
                                BridgeConstants.eventualiNote
                                  ? 'Note'
                                  : 'N° foto';
                              if (defect[key]) {
                                const imageUrls = Object.entries(defect[key]);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              bridgeInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            bridgeInputTemplateComponent.updateUniqueCode(
                              bridgeInputTemplateComponent.model.l2
                                .apparecchisismicilist[index],
                              `${BridgeConstants.levels.apparecchiSismici}list`,
                              BridgeConstants.defectElements.apparecchiSismici,
                              index
                            );
                          },
                        },
                        flex: 1,
                        minWidth: isDisabled ? 80 : 300,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'element no',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: `${BridgeConstants.defectElements.apparecchiSismici} No`,
                        readonly: true,
                      },
                    },
                    {
                      key: 'localizazione',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Localizazione',
                        readonly: true,
                      },
                    },
                    {
                      key: 'materiale',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Materiale',
                        readonly: true,
                      },
                    },
                    {
                      key: 'uniquecode',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'Unique Code',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Apparecchi Sismici
                type: 'cube-button-type',
                className: 'remove-underline',
                defaultValue: true,
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  onClick: (e) => {
                    bridgeInputTemplateComponent.setImages(
                      BridgeConstants.levels.l2
                    );
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.apparecchiSismici
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Codice IOP'];
                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.apparecchiSismici
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      BridgeConstants.partNo
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l2
                      ][BridgeConstants.partNo];
                    bridgeInputTemplateComponent.model.activeLevel =
                      BridgeConstants.levels.apparecchiSismici;

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.apparecchiSismici
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      'Strada di appartenenza'
                    ] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Strada di appartenenza'];

                    bridgeInputTemplateComponent.model[
                      BridgeConstants.levels.apparecchiSismici
                    ]['Scheda Ispezione Ponti di Livello 1']['Progressiva km'] =
                      bridgeInputTemplateComponent.model[
                        BridgeConstants.levels.l0
                      ]['Progressiva km iniziale'];
                  },
                },
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.l2,
      },
      {
        key: BridgeConstants.levels.spalle,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: { label: BridgeConstants.defectElements.spalle },
            fieldGroup: [
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: `${BridgeConstants.defectElements.spalle} No`,
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: `${BridgeConstants.defectElements.spalle} No`,
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      min: 1,
                      onChange: async (field, e) => {
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.spalle
                        ]['Scheda Ispezione Ponti di Livello 1']['Element No'] =
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.spalle
                          ][`${BridgeConstants.defectElements.spalle} No`];
                        await bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.spalle,
                          `${BridgeConstants.defectElements.spalle} No`,
                          BridgeConstants.defectElements.spalle
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'direzione',
                    type: 'select',
                    templateOptions: {
                      label: 'Direzione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      options: bridgeInputTemplateComponent.direzioneList,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.spalle,
                          `${BridgeConstants.defectElements.spalle} No`,
                          BridgeConstants.defectElements.spalle
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'component',
                    type: 'select',
                    hooks: {
                      onInit: (field) => {
                        if (field.formControl.value) {
                          const value = field.formControl.value;
                          const component =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              ComponentMaterialMappingObj.Spalle.component,
                              value
                            );

                          bridgeInputTemplateComponent.setMaterials(
                            component,
                            field,
                            true
                          );
                        }
                      },
                    },
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Component',
                      options: ComponentMaterialMappingObj.Spalle.component,
                      change: (field, e) => {
                        const value = field.formControl.value;
                        const component =
                          bridgeInputTemplateComponent.getItemFromListByValue(
                            ComponentMaterialMappingObj.Spalle.component,
                            value
                          );

                        bridgeInputTemplateComponent.setMaterials(
                          component,
                          field,
                          false
                        );

                        if (component.materials.length === 0) {
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.spalle,
                            component.default_component_type
                          );
                        }

                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l2
                          ].componentCount
                        ) {
                          if (
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].componentCount[
                              `${BridgeConstants.defectElements.spalle} ${value}`
                            ]
                          ) {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.spalle
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ]['componentCount'][
                                `${BridgeConstants.defectElements.spalle} ${value}`
                              ] + 1;
                          } else {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.spalle
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo = 1;
                          }
                        } else {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.spalle
                          ][
                            'Scheda Ispezione Ponti di Livello 1'
                          ].componentNo = 1;
                        }
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.spalle,
                          `${BridgeConstants.defectElements.spalle} No`,
                          BridgeConstants.defectElements.spalle
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'materiale',
                    type: 'select',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Materiale',
                      options: [],
                      change: (field, e) => {
                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.spalle
                          ].component &&
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.spalle
                          ].materiale
                        ) {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.spalle
                          ]['Scheda Ispezione Ponti di Livello 1'].materiale =
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.spalle
                            ].materiale;

                          const value = field.formControl.value;
                          const material =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              field.templateOptions.options,
                              value
                            );
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.spalle,
                            material.component_type,
                            value
                          );

                          bridgeInputTemplateComponent.setUniqueCode(
                            BridgeConstants.levels.spalle,
                            `${BridgeConstants.defectElements.spalle} No`,
                            BridgeConstants.defectElements.spalle
                          );
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Ponti di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            fieldGroup: [
                              {
                                className: 'unique-code-sec',
                                key: 'uniquecode',
                                type: 'cube-unique-code-type',
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column grid-gap-extra',
                                fieldGroup: [
                                  {
                                    className: 'defect-field-label',
                                    key: BridgeConstants.partNo,
                                    type: 'cube-text-only-type',
                                    templateOptions: {
                                      pre: `${BridgeConstants.partNo}: `,
                                    },
                                  },
                                  {
                                    key: 'Localizzazione',
                                    type: 'cube-text-only-type',
                                    className: 'defect-field-label',
                                    templateOptions: {
                                      pre: 'Localizzazione: ',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            fieldGroupClassName:
                              'display-flex-column grid-gap-extra',
                            fieldGroup: [
                              {
                                className: 'defect-field-label',
                                key: 'Element No',
                                type: 'cube-text-only-type',
                                templateOptions: {
                                  pre: `${BridgeConstants.defectElements.spalle} No: `,
                                },
                              },
                              {
                                className: 'defect-field-label',
                                key: 'materiale',
                                type: 'cube-text-only-type',
                              },
                              {
                                key: 'Sigla ID Elem',
                                type: 'cube-text-only-type',
                                className: 'defect-field-label',
                                templateOptions: {
                                  pre: 'Sigla ID Elem.: ',
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !bridgeInputTemplateComponent.l3UpdateStatus
                                            ) {
                                              bridgeInputTemplateComponent.model[
                                                BridgeConstants.levels.spalle
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = bridgeInputTemplateComponent.getDeepCopy(
                                                bridgeInputTemplateComponent
                                                  .model[
                                                  BridgeConstants.levels.l1
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1'
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1 section'
                                                ]
                                              );
                                              bridgeInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex-wrap grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data ispezione',
                                              type: 'cube-date-range-picker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                                max: (() => {
                                                  const today = new Date();
                                                  const maxDate = new Date(
                                                    today
                                                  );
                                                  maxDate.setDate(
                                                    today.getDate() + 7
                                                  );

                                                  return maxDate;
                                                })(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className:
                                              'defect-field-label flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            onCellClicked: (e) => {
                              if (
                                e.colDef.field == 'Codice difetto' &&
                                !e.data.hasOwnProperty('id')
                              ) {
                                bridgeInputTemplateComponent.openNewDefectDialog(
                                  BridgeConstants.levels.spalle,
                                  'editDefect',
                                  e.rowIndex
                                );
                              }
                            },
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                minWidth: 100,
                                flex: 1,
                                colSpan: (params) => {
                                  switch (true) {
                                    case params.data.isFullRow:
                                      // Section Title
                                      return 16;

                                    case params.data.isQuarterRow:
                                      // Eventuali Note
                                      return 2;

                                    default:
                                      // Codice Difetto
                                      return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'text-align-left': (params) =>
                                    params.data.isFullRow,
                                  'cursor-pointer': (params) =>
                                    !params.data.hasOwnProperty('id') &&
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 225,
                                flex: 1.6,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: 'visto',
                                headerClass: 'visto header-no-child',
                                field: 'visto',
                                cellRendererSelector: (params) => {
                                  if (typeof params.value !== 'boolean') {
                                    // Eventuali Note
                                    return {
                                      component:
                                        'defectTableNoteRendererComponent',
                                      params: {
                                        mode: DefectNoteMode.bridgeEventualiNote,
                                      },
                                    };
                                  }

                                  // Visto Checkbox
                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                width: 40,
                                colSpan: (params) => {
                                  return params.data.isQuarterRow
                                    ? 10 // Eventuali Note
                                    : 1; // Visto Checkbox
                                },
                                cellClass: 'width-100',
                                cellClassRules: {
                                  'remove-underline': (params) =>
                                    typeof params.value !== 'boolean',
                                },
                              },
                              {
                                headerName: 'G',
                                headerClass: 'header-no-child',
                                field: 'G',
                                width: 40,
                              },
                              {
                                headerName: 'Estensione K1',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Estensione K1',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'Intensità K2',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Intensità K2',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'N° foto',
                                headerClass: 'header-no-child',
                                field: 'N° foto',
                                width: 70,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.spalle,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'PS',
                                headerClass: 'header-no-child',
                                field: 'PS',
                                width: 40,
                                cellRendererSelector: (params) => {
                                  if (params.data.G > 3) {
                                    // PS Checkbox
                                    return {
                                      component:
                                        'defectTableCheckboxRendererComponent',
                                      params: {},
                                    };
                                  }

                                  // No checkbox
                                  return null;
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'NA',
                                headerClass: 'header-no-child',
                                field: 'NA',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NA = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NR = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NR',
                                headerClass: 'header-no-child',
                                field: 'NR',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NR = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NP',
                                headerClass: 'header-no-child',
                                field: 'NP',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NP = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NR = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'Sup. Est. Dif. [m^2]',
                                headerClass: 'header-no-child',
                                field: 'Sup Est Dif',
                                minWidth: 100,
                                flex: 1,
                                cellClass: ['remove-underline'],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-underline'],
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.spalle,
                                cellRendererSelector: (params) => {
                                  if (params.data.isQuarterRow) {
                                    // Eventuali Note File Upload
                                    return {
                                      component:
                                        'defectTableFileUploadRendererComponent',
                                      params: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.images,
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        isEventualiNoteSection: true,
                                      },
                                    };
                                  }

                                  // Defect Note
                                  return {
                                    component:
                                      'defectTableNoteRendererComponent',
                                    params: {
                                      mode: DefectNoteMode.bridgeDefect,
                                    },
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableFileUploadRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'visto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            }, //
                            {
                              fieldGroup: [
                                {
                                  key: 'Estensione K1',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Intensità K2',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'PS',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NA',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NR',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NP',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'Sup Est Dif',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    // Add New Defect
                    type: 'cube-button-type',
                    defaultValue: true,
                    className: 'remove-underline',
                    templateOptions: {
                      text: 'Add New Defect',
                      color: 'primary',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      onClick: (e) => {
                        bridgeInputTemplateComponent.openNewDefectDialog(
                          BridgeConstants.levels.spalle,
                          'addDefect'
                        );
                      },
                    },
                  },
                ],
                hideExpression: `formState.${BridgeConstants.levels.spalle}`,
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.spalle,
      },
      {
        key: BridgeConstants.levels.pile,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: { label: BridgeConstants.defectElements.pile },
            fieldGroup: [
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: `${BridgeConstants.defectElements.pile} No`,
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: `${BridgeConstants.defectElements.pile} No`,
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      min: 1,
                      onChange: async (field, e) => {
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.pile
                        ]['Scheda Ispezione Ponti di Livello 1']['Element No'] =
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.pile
                          ][`${BridgeConstants.defectElements.pile} No`];
                        await bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.pile,
                          `${BridgeConstants.defectElements.pile} No`,
                          BridgeConstants.defectElements.pile
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'direzione',
                    type: 'select',
                    templateOptions: {
                      label: 'Direzione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      options: bridgeInputTemplateComponent.direzioneList,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.pile,
                          `${BridgeConstants.defectElements.pile} No`,
                          BridgeConstants.defectElements.pile
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'component',
                    type: 'select',
                    hooks: {
                      onInit: (field) => {
                        if (field.formControl.value) {
                          const value = field.formControl.value;
                          const component =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              ComponentMaterialMappingObj.Pile.component,
                              value
                            );

                          bridgeInputTemplateComponent.setMaterials(
                            component,
                            field,
                            true
                          );
                        }
                      },
                    },
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Component',
                      options: ComponentMaterialMappingObj.Pile.component,
                      change: (field, e) => {
                        const value = field.formControl.value;
                        const component =
                          bridgeInputTemplateComponent.getItemFromListByValue(
                            ComponentMaterialMappingObj.Pile.component,
                            value
                          );

                        bridgeInputTemplateComponent.setMaterials(
                          component,
                          field,
                          false
                        );

                        if (component.materials.length === 0) {
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.pile,
                            component.default_component_type
                          );
                        }

                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l2
                          ].componentCount
                        ) {
                          if (
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ]['componentCount'][
                              `${BridgeConstants.defectElements.pile} ${value}`
                            ]
                          ) {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.pile
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ]['componentCount'][
                                `${BridgeConstants.defectElements.pile} ${value}`
                              ] + 1;
                          } else {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.pile
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo = 1;
                          }
                        } else {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.pile
                          ][
                            'Scheda Ispezione Ponti di Livello 1'
                          ].componentNo = 1;
                        }
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.pile,
                          `${BridgeConstants.defectElements.pile} No`,
                          BridgeConstants.defectElements.pile
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'materiale',
                    type: 'select',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Materiale',
                      options: [],
                      change: (field, e) => {
                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.pile
                          ].component &&
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.pile
                          ].materiale
                        ) {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.pile
                          ]['Scheda Ispezione Ponti di Livello 1'].materiale =
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.pile
                            ].materiale;

                          const value = field.formControl.value;
                          const material =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              field.templateOptions.options,
                              value
                            );
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.pile,
                            material.component_type,
                            value
                          );

                          bridgeInputTemplateComponent.setUniqueCode(
                            BridgeConstants.levels.pile,
                            `${BridgeConstants.defectElements.pile} No`,
                            BridgeConstants.defectElements.pile
                          );
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Ponti di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            fieldGroup: [
                              {
                                className: 'unique-code-sec',
                                key: 'uniquecode',
                                type: 'cube-unique-code-type',
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column grid-gap-extra',
                                fieldGroup: [
                                  {
                                    className: 'defect-field-label',
                                    key: BridgeConstants.partNo,
                                    type: 'cube-text-only-type',
                                    templateOptions: {
                                      pre: `${BridgeConstants.partNo}: `,
                                    },
                                  },
                                  {
                                    key: 'Localizzazione',
                                    type: 'cube-text-only-type',
                                    className: 'defect-field-label',
                                    templateOptions: {
                                      pre: 'Localizzazione: ',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            fieldGroupClassName:
                              'display-flex-column grid-gap-extra',
                            fieldGroup: [
                              {
                                className: 'defect-field-label',
                                key: 'Element No',
                                type: 'cube-text-only-type',
                                templateOptions: {
                                  pre: `${BridgeConstants.defectElements.pile} No: `,
                                },
                              },
                              {
                                className: 'defect-field-label',
                                key: 'materiale',
                                type: 'cube-text-only-type',
                              },
                              {
                                key: 'Sigla ID Elem',
                                type: 'cube-text-only-type',
                                className: 'defect-field-label',
                                templateOptions: {
                                  pre: 'Sigla ID Elem.: ',
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !bridgeInputTemplateComponent.l3UpdateStatus
                                            ) {
                                              bridgeInputTemplateComponent.model[
                                                BridgeConstants.levels.pile
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = bridgeInputTemplateComponent.getDeepCopy(
                                                bridgeInputTemplateComponent
                                                  .model[
                                                  BridgeConstants.levels.l1
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1'
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1 section'
                                                ]
                                              );
                                              bridgeInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex-wrap grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data ispezione',
                                              type: 'cube-date-range-picker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                                max: (() => {
                                                  const today = new Date();
                                                  const maxDate = new Date(
                                                    today
                                                  );
                                                  maxDate.setDate(
                                                    today.getDate() + 7
                                                  );

                                                  return maxDate;
                                                })(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className:
                                              'defect-field-label flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            onCellClicked: (e) => {
                              if (
                                e.colDef.field == 'Codice difetto' &&
                                !e.data.hasOwnProperty('id')
                              ) {
                                bridgeInputTemplateComponent.openNewDefectDialog(
                                  BridgeConstants.levels.pile,
                                  'editDefect',
                                  e.rowIndex
                                );
                              }
                            },
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                minWidth: 100,
                                flex: 1,
                                colSpan: (params) => {
                                  switch (true) {
                                    case params.data.isFullRow:
                                      // Section Title
                                      return 16;

                                    case params.data.isQuarterRow:
                                      // Eventuali Note
                                      return 2;

                                    default:
                                      // Codice Difetto
                                      return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'text-align-left': (params) =>
                                    params.data.isFullRow,
                                  'cursor-pointer': (params) =>
                                    !params.data.hasOwnProperty('id') &&
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 225,
                                flex: 1.6,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: 'visto',
                                headerClass: 'visto header-no-child',
                                field: 'visto',
                                cellRendererSelector: (params) => {
                                  if (typeof params.value !== 'boolean') {
                                    // Eventuali Note
                                    return {
                                      component:
                                        'defectTableNoteRendererComponent',
                                      params: {
                                        mode: DefectNoteMode.bridgeEventualiNote,
                                      },
                                    };
                                  }

                                  // Visto Checkbox
                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                width: 40,
                                colSpan: (params) => {
                                  return params.data.isQuarterRow
                                    ? 10 // Eventuali Note
                                    : 1; // Visto Checkbox
                                },
                                cellClass: 'width-100',
                                cellClassRules: {
                                  'remove-underline': (params) =>
                                    typeof params.value !== 'boolean',
                                },
                              },
                              {
                                headerName: 'G',
                                headerClass: 'header-no-child',
                                field: 'G',
                                width: 40,
                              },
                              {
                                headerName: 'Estensione K1',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Estensione K1',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'Intensità K2',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Intensità K2',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'N° foto',
                                headerClass: 'header-no-child',
                                field: 'N° foto',
                                width: 70,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.pile,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'PS',
                                headerClass: 'header-no-child',
                                field: 'PS',
                                width: 40,
                                cellRendererSelector: (params) => {
                                  if (params.data.G > 3) {
                                    // PS Checkbox
                                    return {
                                      component:
                                        'defectTableCheckboxRendererComponent',
                                      params: {},
                                    };
                                  }

                                  // No checkbox
                                  return null;
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'NA',
                                headerClass: 'header-no-child',
                                field: 'NA',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NA = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NR = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NR',
                                headerClass: 'header-no-child',
                                field: 'NR',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NR = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NP',
                                headerClass: 'header-no-child',
                                field: 'NP',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NP = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NR = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'Sup. Est. Dif. [m^2]',
                                headerClass: 'header-no-child',
                                field: 'Sup Est Dif',
                                minWidth: 100,
                                flex: 1,
                                cellClass: ['remove-underline'],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-underline'],
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.pile,
                                cellRendererSelector: (params) => {
                                  if (params.data.isQuarterRow) {
                                    // Eventuali Note File Upload
                                    return {
                                      component:
                                        'defectTableFileUploadRendererComponent',
                                      params: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.images,
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        isEventualiNoteSection: true,
                                      },
                                    };
                                  }

                                  // Defect Note
                                  return {
                                    component:
                                      'defectTableNoteRendererComponent',
                                    params: {
                                      mode: DefectNoteMode.bridgeDefect,
                                    },
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableFileUploadRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'visto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              fieldGroup: [
                                {
                                  key: 'Estensione K1',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Intensità K2',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'PS',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NA',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NR',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NP',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'Sup Est Dif',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                      {
                        // Add New Defect
                        type: 'cube-button-type',
                        defaultValue: true,
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Add New Defect',
                          color: 'primary',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onClick: (e) => {
                            bridgeInputTemplateComponent.openNewDefectDialog(
                              BridgeConstants.levels.pile,
                              'addDefect'
                            );
                          },
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${BridgeConstants.levels.pile}`,
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.pile,
      },
      {
        key: BridgeConstants.levels.archi,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: { label: BridgeConstants.defectElements.archi },
            fieldGroup: [
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: `${BridgeConstants.defectElements.archi} No`,
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: `${BridgeConstants.defectElements.archi} No`,
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      min: 1,
                      onChange: async (field, e) => {
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.archi
                        ]['Scheda Ispezione Ponti di Livello 1']['Element No'] =
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.archi
                          ][`${BridgeConstants.defectElements.archi} No`];
                        await bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.archi,
                          `${BridgeConstants.defectElements.archi} No`,
                          BridgeConstants.defectElements.archi
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'direzione',
                    type: 'select',
                    templateOptions: {
                      label: 'Direzione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      options: bridgeInputTemplateComponent.direzioneList,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.archi,
                          `${BridgeConstants.defectElements.archi} No`,
                          BridgeConstants.defectElements.archi
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'component',
                    type: 'select',
                    hooks: {
                      onInit: (field) => {
                        if (field.formControl.value) {
                          const value = field.formControl.value;
                          const component =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              ComponentMaterialMappingObj.Archi.component,
                              value
                            );

                          bridgeInputTemplateComponent.setMaterials(
                            component,
                            field,
                            true
                          );
                        }
                      },
                    },
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Component',
                      options: ComponentMaterialMappingObj.Archi.component,
                      change: (field, e) => {
                        const value = field.formControl.value;
                        const component =
                          bridgeInputTemplateComponent.getItemFromListByValue(
                            ComponentMaterialMappingObj.Archi.component,
                            value
                          );

                        bridgeInputTemplateComponent.setMaterials(
                          component,
                          field,
                          false
                        );

                        if (component.materials.length === 0) {
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.archi,
                            component.default_component_type
                          );
                        }

                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l2
                          ].componentCount
                        ) {
                          if (
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].componentCount[
                              `${BridgeConstants.defectElements.archi} ${value}`
                            ]
                          ) {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.archi
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].componentCount[
                                `${BridgeConstants.defectElements.archi} ${value}`
                              ] + 1;
                          } else {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.archi
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo = 1;
                          }
                        } else {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.archi
                          ][
                            'Scheda Ispezione Ponti di Livello 1'
                          ].componentNo = 1;
                        }
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.archi,
                          `${BridgeConstants.defectElements.archi} No`,
                          BridgeConstants.defectElements.archi
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'materiale',
                    type: 'select',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Materiale',
                      options: [],
                      change: (field, e) => {
                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.archi
                          ].component &&
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.archi
                          ].materiale
                        ) {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.archi
                          ]['Scheda Ispezione Ponti di Livello 1'].materiale =
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.archi
                            ].materiale;

                          const value = field.formControl.value;
                          const material =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              field.templateOptions.options,
                              value
                            );
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.archi,
                            material.component_type,
                            value
                          );

                          bridgeInputTemplateComponent.setUniqueCode(
                            BridgeConstants.levels.archi,
                            `${BridgeConstants.defectElements.archi} No`,
                            BridgeConstants.defectElements.archi
                          );
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Ponti di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            fieldGroup: [
                              {
                                className: 'unique-code-sec',
                                key: 'uniquecode',
                                type: 'cube-unique-code-type',
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column grid-gap-extra',
                                fieldGroup: [
                                  {
                                    className: 'defect-field-label',
                                    key: BridgeConstants.partNo,
                                    type: 'cube-text-only-type',
                                    templateOptions: {
                                      pre: `${BridgeConstants.partNo}: `,
                                    },
                                  },
                                  {
                                    key: 'Localizzazione',
                                    type: 'cube-text-only-type',
                                    className: 'defect-field-label',
                                    templateOptions: {
                                      pre: 'Localizzazione: ',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            fieldGroupClassName:
                              'display-flex-column grid-gap-extra',
                            fieldGroup: [
                              {
                                className: 'defect-field-label',
                                key: 'Element No',
                                type: 'cube-text-only-type',
                                templateOptions: {
                                  pre: `${BridgeConstants.defectElements.archi} No: `,
                                },
                              },
                              {
                                className: 'defect-field-label',
                                key: 'materiale',
                                type: 'cube-text-only-type',
                              },
                              {
                                key: 'Sigla ID Elem',
                                type: 'cube-text-only-type',
                                className: 'defect-field-label',
                                templateOptions: {
                                  pre: 'Sigla ID Elem.: ',
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !bridgeInputTemplateComponent.l3UpdateStatus
                                            ) {
                                              bridgeInputTemplateComponent.model[
                                                BridgeConstants.levels.archi
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = bridgeInputTemplateComponent.getDeepCopy(
                                                bridgeInputTemplateComponent
                                                  .model[
                                                  BridgeConstants.levels.l1
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1'
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1 section'
                                                ]
                                              );
                                              bridgeInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex-wrap grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data ispezione',
                                              type: 'cube-date-range-picker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                                max: (() => {
                                                  const today = new Date();
                                                  const maxDate = new Date(
                                                    today
                                                  );
                                                  maxDate.setDate(
                                                    today.getDate() + 7
                                                  );

                                                  return maxDate;
                                                })(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className:
                                              'defect-field-label flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            onCellClicked: (e) => {
                              if (
                                e.colDef.field == 'Codice difetto' &&
                                !e.data.hasOwnProperty('id')
                              ) {
                                bridgeInputTemplateComponent.openNewDefectDialog(
                                  BridgeConstants.levels.archi,
                                  'editDefect',
                                  e.rowIndex
                                );
                              }
                            },
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                minWidth: 100,
                                flex: 1,
                                colSpan: (params) => {
                                  switch (true) {
                                    case params.data.isFullRow:
                                      // Section Title
                                      return 16;

                                    case params.data.isQuarterRow:
                                      // Eventuali Note
                                      return 2;

                                    default:
                                      // Codice Difetto
                                      return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'text-align-left': (params) =>
                                    params.data.isFullRow,
                                  'cursor-pointer': (params) =>
                                    !params.data.hasOwnProperty('id') &&
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 225,
                                flex: 1.6,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: 'visto',
                                headerClass: 'visto header-no-child',
                                field: 'visto',
                                cellRendererSelector: (params) => {
                                  if (typeof params.value !== 'boolean') {
                                    // Eventuali Note
                                    return {
                                      component:
                                        'defectTableNoteRendererComponent',
                                      params: {
                                        mode: DefectNoteMode.bridgeEventualiNote,
                                      },
                                    };
                                  }

                                  // Visto Checkbox
                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                width: 40,
                                colSpan: (params) => {
                                  return params.data.isQuarterRow
                                    ? 10 // Eventuali Note
                                    : 1; // Visto Checkbox
                                },
                                cellClass: 'width-100',
                                cellClassRules: {
                                  'remove-underline': (params) =>
                                    typeof params.value !== 'boolean',
                                },
                              },
                              {
                                headerName: 'G',
                                headerClass: 'header-no-child',
                                field: 'G',
                                width: 40,
                              },
                              {
                                headerName: 'Estensione K1',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Estensione K1',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'Intensità K2',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Intensità K2',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'N° foto',
                                headerClass: 'header-no-child',
                                field: 'N° foto',
                                width: 70,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.archi,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'PS',
                                headerClass: 'header-no-child',
                                field: 'PS',
                                width: 40,
                                cellRendererSelector: (params) => {
                                  if (params.data.G > 3) {
                                    // PS Checkbox
                                    return {
                                      component:
                                        'defectTableCheckboxRendererComponent',
                                      params: {},
                                    };
                                  }

                                  // No checkbox
                                  return null;
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'NA',
                                headerClass: 'header-no-child',
                                field: 'NA',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NA = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NR = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NR',
                                headerClass: 'header-no-child',
                                field: 'NR',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NR = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NP',
                                headerClass: 'header-no-child',
                                field: 'NP',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NP = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NR = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'Sup. Est. Dif. [m^2]',
                                headerClass: 'header-no-child',
                                field: 'Sup Est Dif',
                                minWidth: 100,
                                flex: 1,
                                cellClass: ['remove-underline'],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-underline'],
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.archi,
                                cellRendererSelector: (params) => {
                                  if (params.data.isQuarterRow) {
                                    // Eventuali Note File Upload
                                    return {
                                      component:
                                        'defectTableFileUploadRendererComponent',
                                      params: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.images,
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        isEventualiNoteSection: true,
                                      },
                                    };
                                  }

                                  // Defect Note
                                  return {
                                    component:
                                      'defectTableNoteRendererComponent',
                                    params: {
                                      mode: DefectNoteMode.bridgeDefect,
                                    },
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableFileUploadRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'visto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              fieldGroup: [
                                {
                                  key: 'Estensione K1',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Intensità K2',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'PS',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NA',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NR',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NP',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'Sup Est Dif',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                      {
                        // Add New Defect
                        type: 'cube-button-type',
                        defaultValue: true,
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Add New Defect',
                          color: 'primary',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onClick: (e) => {
                            bridgeInputTemplateComponent.openNewDefectDialog(
                              BridgeConstants.levels.archi,
                              'addDefect'
                            );
                          },
                        },
                        hide: bridgeInputTemplateComponent.isFieldDisabled(
                          [UserRole.hydraulicTechnician],
                          isDisabled,
                          true
                        ),
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${BridgeConstants.levels.archi}`,
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.archi,
      },
      {
        key: BridgeConstants.levels.impalcati,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: {
              label: BridgeConstants.defectElements.impalcati,
            },
            fieldGroup: [
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: `${BridgeConstants.defectElements.impalcati} No`,
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: `${BridgeConstants.defectElements.impalcati} No`,
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      min: 1,
                      onChange: async (field, e) => {
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.impalcati
                        ]['Scheda Ispezione Ponti di Livello 1']['Element No'] =
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.impalcati
                          ][`${BridgeConstants.defectElements.impalcati} No`];
                        await bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.impalcati,
                          `${BridgeConstants.defectElements.impalcati} No`,
                          BridgeConstants.defectElements.impalcati
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'direzione',
                    type: 'select',
                    templateOptions: {
                      label: 'Direzione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      options: bridgeInputTemplateComponent.direzioneList,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.impalcati,
                          `${BridgeConstants.defectElements.impalcati} No`,
                          BridgeConstants.defectElements.impalcati
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'component',
                    type: 'select',
                    hooks: {
                      onInit: (field) => {
                        if (field.formControl.value) {
                          const value = field.formControl.value;
                          const component =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              ComponentMaterialMappingObj.Impalcati.component,
                              value
                            );

                          bridgeInputTemplateComponent.setMaterials(
                            component,
                            field,
                            true
                          );
                        }
                      },
                    },
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Component',
                      options: ComponentMaterialMappingObj.Impalcati.component,
                      change: (field, e) => {
                        const value = field.formControl.value;
                        const component =
                          bridgeInputTemplateComponent.getItemFromListByValue(
                            ComponentMaterialMappingObj.Impalcati.component,
                            value
                          );

                        bridgeInputTemplateComponent.setMaterials(
                          component,
                          field,
                          false
                        );

                        if (component.materials.length === 0) {
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.impalcati,
                            component.default_component_type
                          );
                        }

                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l2
                          ].componentCount
                        ) {
                          if (
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].componentCount[
                              `${BridgeConstants.defectElements.impalcati} ${value}`
                            ]
                          ) {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.impalcati
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].componentCount[
                                `${BridgeConstants.defectElements.impalcati} ${value}`
                              ] + 1;
                          } else {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.impalcati
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo = 1;
                          }
                        } else {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.impalcati
                          ][
                            'Scheda Ispezione Ponti di Livello 1'
                          ].componentNo = 1;
                        }
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.impalcati,
                          `${BridgeConstants.defectElements.impalcati} No`,
                          BridgeConstants.defectElements.impalcati
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'materiale',
                    type: 'select',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Materiale',
                      options: [],
                      change: (field, e) => {
                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.impalcati
                          ].component &&
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.impalcati
                          ].materiale
                        ) {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.impalcati
                          ]['Scheda Ispezione Ponti di Livello 1'].materiale =
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.impalcati
                            ].materiale;

                          const value = field.formControl.value;
                          const material =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              field.templateOptions.options,
                              value
                            );
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.impalcati,
                            material.component_type,
                            value
                          );

                          bridgeInputTemplateComponent.setUniqueCode(
                            BridgeConstants.levels.impalcati,
                            `${BridgeConstants.defectElements.impalcati} No`,
                            BridgeConstants.defectElements.impalcati
                          );
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Ponti di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            fieldGroup: [
                              {
                                className: 'unique-code-sec',
                                key: 'uniquecode',
                                type: 'cube-unique-code-type',
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column grid-gap-extra',
                                fieldGroup: [
                                  {
                                    className: 'defect-field-label',
                                    key: BridgeConstants.partNo,
                                    type: 'cube-text-only-type',
                                    templateOptions: {
                                      pre: `${BridgeConstants.partNo}: `,
                                    },
                                  },
                                  {
                                    key: 'Localizzazione',
                                    type: 'cube-text-only-type',
                                    className: 'defect-field-label',
                                    templateOptions: {
                                      pre: 'Localizzazione: ',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            fieldGroupClassName:
                              'display-flex-column grid-gap-extra',
                            fieldGroup: [
                              {
                                className: 'defect-field-label',
                                key: 'Element No',
                                type: 'cube-text-only-type',
                                templateOptions: {
                                  pre: `${BridgeConstants.defectElements.impalcati} No: `,
                                },
                              },
                              {
                                className: 'defect-field-label',
                                key: 'materiale',
                                type: 'cube-text-only-type',
                              },
                              {
                                key: 'Sigla ID Elem',
                                type: 'cube-text-only-type',
                                className: 'defect-field-label',
                                templateOptions: {
                                  pre: 'Sigla ID Elem.: ',
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !bridgeInputTemplateComponent.l3UpdateStatus
                                            ) {
                                              bridgeInputTemplateComponent.model[
                                                BridgeConstants.levels.impalcati
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = bridgeInputTemplateComponent.getDeepCopy(
                                                bridgeInputTemplateComponent
                                                  .model[
                                                  BridgeConstants.levels.l1
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1'
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1 section'
                                                ]
                                              );
                                              bridgeInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex-wrap grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data ispezione',
                                              type: 'cube-date-range-picker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                                max: (() => {
                                                  const today = new Date();
                                                  const maxDate = new Date(
                                                    today
                                                  );
                                                  maxDate.setDate(
                                                    today.getDate() + 7
                                                  );

                                                  return maxDate;
                                                })(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className:
                                              'defect-field-label flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            onCellClicked: (e) => {
                              if (
                                e.colDef.field == 'Codice difetto' &&
                                !e.data.hasOwnProperty('id')
                              ) {
                                bridgeInputTemplateComponent.openNewDefectDialog(
                                  BridgeConstants.levels.impalcati,
                                  'editDefect',
                                  e.rowIndex
                                );
                              }
                            },
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                minWidth: 100,
                                flex: 1,
                                colSpan: (params) => {
                                  switch (true) {
                                    case params.data.isFullRow:
                                      // Section Title
                                      return 16;

                                    case params.data.isQuarterRow:
                                      // Eventuali Note
                                      return 2;

                                    default:
                                      // Codice Difetto
                                      return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'text-align-left': (params) =>
                                    params.data.isFullRow,
                                  'cursor-pointer': (params) =>
                                    !params.data.hasOwnProperty('id') &&
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 225,
                                flex: 1.6,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: 'visto',
                                headerClass: 'visto header-no-child',
                                field: 'visto',
                                cellRendererSelector: (params) => {
                                  if (typeof params.value !== 'boolean') {
                                    // Eventuali Note
                                    return {
                                      component:
                                        'defectTableNoteRendererComponent',
                                      params: {
                                        mode: DefectNoteMode.bridgeEventualiNote,
                                      },
                                    };
                                  }

                                  // Visto Checkbox
                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                width: 40,
                                colSpan: (params) => {
                                  return params.data.isQuarterRow
                                    ? 10 // Eventuali Note
                                    : 1; // Visto Checkbox
                                },
                                cellClass: 'width-100',
                                cellClassRules: {
                                  'remove-underline': (params) =>
                                    typeof params.value !== 'boolean',
                                },
                              },
                              {
                                headerName: 'G',
                                headerClass: 'header-no-child',
                                field: 'G',
                                width: 40,
                              },
                              {
                                headerName: 'Estensione K1',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Estensione K1',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'Intensità K2',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Intensità K2',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'N° foto',
                                headerClass: 'header-no-child',
                                field: 'N° foto',
                                width: 70,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.impalcati,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'PS',
                                headerClass: 'header-no-child',
                                field: 'PS',
                                width: 40,
                                cellRendererSelector: (params) => {
                                  if (params.data.G > 3) {
                                    // PS Checkbox
                                    return {
                                      component:
                                        'defectTableCheckboxRendererComponent',
                                      params: {},
                                    };
                                  }

                                  // No checkbox
                                  return null;
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'NA',
                                headerClass: 'header-no-child',
                                field: 'NA',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NA = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NR = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NR',
                                headerClass: 'header-no-child',
                                field: 'NR',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NR = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NP',
                                headerClass: 'header-no-child',
                                field: 'NP',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NP = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NR = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'Sup. Est. Dif. [m^2]',
                                headerClass: 'header-no-child',
                                field: 'Sup Est Dif',
                                minWidth: 100,
                                flex: 1,
                                cellClass: ['remove-underline'],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-underline'],
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.impalcati,
                                cellRendererSelector: (params) => {
                                  if (params.data.isQuarterRow) {
                                    // Eventuali Note File Upload
                                    return {
                                      component:
                                        'defectTableFileUploadRendererComponent',
                                      params: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.images,
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        isEventualiNoteSection: true,
                                      },
                                    };
                                  }

                                  // Defect Note
                                  return {
                                    component:
                                      'defectTableNoteRendererComponent',
                                    params: {
                                      mode: DefectNoteMode.bridgeDefect,
                                    },
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableFileUploadRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'visto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              fieldGroup: [
                                {
                                  key: 'Estensione K1',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Intensità K2',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'PS',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NA',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NR',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NP',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'Sup Est Dif',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                      {
                        // Add New Defect
                        type: 'cube-button-type',
                        defaultValue: true,
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Add New Defect',
                          color: 'primary',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onClick: (e) => {
                            bridgeInputTemplateComponent.openNewDefectDialog(
                              BridgeConstants.levels.impalcati,
                              'addDefect'
                            );
                          },
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${BridgeConstants.levels.impalcati}`,
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.impalcati,
      },
      {
        key: BridgeConstants.levels.fileDiAppoggi,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: {
              label: BridgeConstants.defectElements.fileDiAppoggi,
            },
            fieldGroup: [
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: `${BridgeConstants.defectElements.fileDiAppoggi} No`,
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: `${BridgeConstants.defectElements.fileDiAppoggi} No`,
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      min: 1,
                      onChange: async (field, e) => {
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.fileDiAppoggi
                        ]['Scheda Ispezione Ponti di Livello 1']['Element No'] =
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.fileDiAppoggi
                          ][
                            `${BridgeConstants.defectElements.fileDiAppoggi} No`
                          ];
                        await bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.fileDiAppoggi,
                          `${BridgeConstants.defectElements.fileDiAppoggi} No`,
                          BridgeConstants.defectElements.fileDiAppoggi
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'direzione',
                    type: 'select',
                    templateOptions: {
                      label: 'Direzione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      options: bridgeInputTemplateComponent.direzioneList,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.fileDiAppoggi,
                          `${BridgeConstants.defectElements.fileDiAppoggi} No`,
                          BridgeConstants.defectElements.fileDiAppoggi
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'component',
                    type: 'select',
                    hooks: {
                      onInit: (field) => {
                        if (field.formControl.value) {
                          const value = field.formControl.value;
                          const component =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              ComponentMaterialMappingObj[
                                BridgeConstants.defectElements.fileDiAppoggi
                              ].component,
                              value
                            );

                          bridgeInputTemplateComponent.setMaterials(
                            component,
                            field,
                            true
                          );
                        }
                      },
                    },
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Component',
                      options:
                        ComponentMaterialMappingObj[
                          BridgeConstants.defectElements.fileDiAppoggi
                        ].component,
                      change: (field, e) => {
                        const value = field.formControl.value;
                        const component =
                          bridgeInputTemplateComponent.getItemFromListByValue(
                            ComponentMaterialMappingObj[
                              BridgeConstants.defectElements.fileDiAppoggi
                            ].component,
                            value
                          );

                        bridgeInputTemplateComponent.setMaterials(
                          component,
                          field,
                          false
                        );

                        if (component.materials.length === 0) {
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.fileDiAppoggi,
                            component.default_component_type
                          );
                        }

                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l2
                          ].componentCount
                        ) {
                          if (
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].componentCount[
                              `${BridgeConstants.defectElements.fileDiAppoggi} ${value}`
                            ]
                          ) {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.fileDiAppoggi
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].componentCount[
                                `${BridgeConstants.defectElements.fileDiAppoggi} ${value}`
                              ] + 1;
                          } else {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.fileDiAppoggi
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo = 1;
                          }
                        } else {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.fileDiAppoggi
                          ][
                            'Scheda Ispezione Ponti di Livello 1'
                          ].componentNo = 1;
                        }
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.fileDiAppoggi,
                          `${BridgeConstants.defectElements.fileDiAppoggi} No`,
                          BridgeConstants.defectElements.fileDiAppoggi
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'materiale',
                    type: 'select',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Materiale',
                      options: [],
                      change: (field, e) => {
                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.fileDiAppoggi
                          ].component &&
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.fileDiAppoggi
                          ].materiale
                        ) {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.fileDiAppoggi
                          ]['Scheda Ispezione Ponti di Livello 1'].materiale =
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.fileDiAppoggi
                            ].materiale;

                          const value = field.formControl.value;
                          const material =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              field.templateOptions.options,
                              value
                            );
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.fileDiAppoggi,
                            material.component_type,
                            value
                          );

                          bridgeInputTemplateComponent.setUniqueCode(
                            BridgeConstants.levels.fileDiAppoggi,
                            `${BridgeConstants.defectElements.fileDiAppoggi} No`,
                            BridgeConstants.defectElements.fileDiAppoggi
                          );
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Ponti di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            fieldGroup: [
                              {
                                className: 'unique-code-sec',
                                key: 'uniquecode',
                                type: 'cube-unique-code-type',
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column grid-gap-extra',
                                fieldGroup: [
                                  {
                                    className: 'defect-field-label',
                                    key: BridgeConstants.partNo,
                                    type: 'cube-text-only-type',
                                    templateOptions: {
                                      pre: `${BridgeConstants.partNo}: `,
                                    },
                                  },
                                  {
                                    key: 'Localizzazione',
                                    type: 'cube-text-only-type',
                                    className: 'defect-field-label',
                                    templateOptions: {
                                      pre: 'Localizzazione: ',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            fieldGroupClassName:
                              'display-flex-column grid-gap-extra',
                            fieldGroup: [
                              {
                                className: 'defect-field-label',
                                key: 'Element No',
                                type: 'cube-text-only-type',
                                templateOptions: {
                                  pre: `${BridgeConstants.defectElements.fileDiAppoggi} No: `,
                                },
                              },
                              {
                                className: 'defect-field-label',
                                key: 'materiale',
                                type: 'cube-text-only-type',
                              },
                              {
                                key: 'Sigla ID Elem',
                                type: 'cube-text-only-type',
                                className: 'defect-field-label',
                                templateOptions: {
                                  pre: 'Sigla ID Elem.: ',
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !bridgeInputTemplateComponent.l3UpdateStatus
                                            ) {
                                              bridgeInputTemplateComponent.model[
                                                BridgeConstants.levels.fileDiAppoggi
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = bridgeInputTemplateComponent.getDeepCopy(
                                                bridgeInputTemplateComponent
                                                  .model[
                                                  BridgeConstants.levels.l1
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1'
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1 section'
                                                ]
                                              );
                                              bridgeInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex-wrap grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data ispezione',
                                              type: 'cube-date-range-picker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                                max: (() => {
                                                  const today = new Date();
                                                  const maxDate = new Date(
                                                    today
                                                  );
                                                  maxDate.setDate(
                                                    today.getDate() + 7
                                                  );

                                                  return maxDate;
                                                })(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className:
                                              'defect-field-label flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            onCellClicked: (e) => {
                              if (
                                e.colDef.field == 'Codice difetto' &&
                                !e.data.hasOwnProperty('id')
                              ) {
                                bridgeInputTemplateComponent.openNewDefectDialog(
                                  BridgeConstants.levels.fileDiAppoggi,
                                  'editDefect',
                                  e.rowIndex
                                );
                              }
                            },
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                minWidth: 100,
                                flex: 1,
                                colSpan: (params) => {
                                  switch (true) {
                                    case params.data.isFullRow:
                                      // Section Title
                                      return 16;

                                    case params.data.isQuarterRow:
                                      // Eventuali Note
                                      return 2;

                                    default:
                                      // Codice Difetto
                                      return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'text-align-left': (params) =>
                                    params.data.isFullRow,
                                  'cursor-pointer': (params) =>
                                    !params.data.hasOwnProperty('id') &&
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 225,
                                flex: 1.6,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: 'visto',
                                headerClass: 'visto header-no-child',
                                field: 'visto',
                                cellRendererSelector: (params) => {
                                  if (typeof params.value !== 'boolean') {
                                    // Eventuali Note
                                    return {
                                      component:
                                        'defectTableNoteRendererComponent',
                                      params: {
                                        mode: DefectNoteMode.bridgeEventualiNote,
                                      },
                                    };
                                  }

                                  // Visto Checkbox
                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                width: 40,
                                colSpan: (params) => {
                                  return params.data.isQuarterRow
                                    ? 10 // Eventuali Note
                                    : 1; // Visto Checkbox
                                },
                                cellClass: 'width-100',
                                cellClassRules: {
                                  'remove-underline': (params) =>
                                    typeof params.value !== 'boolean',
                                },
                              },
                              {
                                headerName: 'G',
                                headerClass: 'header-no-child',
                                field: 'G',
                                width: 40,
                              },
                              {
                                headerName: 'Estensione K1',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Estensione K1',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'Intensità K2',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Intensità K2',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'N° foto',
                                headerClass: 'header-no-child',
                                field: 'N° foto',
                                width: 70,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.fileDiAppoggi,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'PS',
                                headerClass: 'header-no-child',
                                field: 'PS',
                                width: 40,
                                cellRendererSelector: (params) => {
                                  if (params.data.G > 3) {
                                    // PS Checkbox
                                    return {
                                      component:
                                        'defectTableCheckboxRendererComponent',
                                      params: {},
                                    };
                                  }

                                  // No checkbox
                                  return null;
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'NA',
                                headerClass: 'header-no-child',
                                field: 'NA',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NA = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NR = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NR',
                                headerClass: 'header-no-child',
                                field: 'NR',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NR = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NP',
                                headerClass: 'header-no-child',
                                field: 'NP',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NP = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NR = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'Sup. Est. Dif. [m^2]',
                                headerClass: 'header-no-child',
                                field: 'Sup Est Dif',
                                minWidth: 100,
                                flex: 1,
                                cellClass: ['remove-underline'],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-underline'],
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.fileDiAppoggi,
                                cellRendererSelector: (params) => {
                                  if (params.data.isQuarterRow) {
                                    // Eventuali Note File Upload
                                    return {
                                      component:
                                        'defectTableFileUploadRendererComponent',
                                      params: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.images,
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        isEventualiNoteSection: true,
                                      },
                                    };
                                  }

                                  // Defect Note
                                  return {
                                    component:
                                      'defectTableNoteRendererComponent',
                                    params: {
                                      mode: DefectNoteMode.bridgeDefect,
                                    },
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableFileUploadRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'visto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              fieldGroup: [
                                {
                                  key: 'Estensione K1',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Intensità K2',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'PS',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NA',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NR',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NP',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'Sup Est Dif',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                      {
                        // Add New Defect
                        type: 'cube-button-type',
                        defaultValue: true,
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Add New Defect',
                          color: 'primary',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onClick: (e) => {
                            bridgeInputTemplateComponent.openNewDefectDialog(
                              BridgeConstants.levels.fileDiAppoggi,
                              'addDefect'
                            );
                          },
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${BridgeConstants.levels.fileDiAppoggi}`,
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.fileDiAppoggi,
      },
      {
        key: BridgeConstants.levels.giunti,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: { label: BridgeConstants.defectElements.giunti },
            fieldGroup: [
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: `${BridgeConstants.defectElements.giunti} No`,
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: `${BridgeConstants.defectElements.giunti} No`,
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      min: 1,
                      onChange: async (field, e) => {
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.giunti
                        ]['Scheda Ispezione Ponti di Livello 1']['Element No'] =
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.giunti
                          ][`${BridgeConstants.defectElements.giunti} No`];
                        await bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.giunti,
                          `${BridgeConstants.defectElements.giunti} No`,
                          BridgeConstants.defectElements.giunti
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'direzione',
                    type: 'select',
                    templateOptions: {
                      label: 'Direzione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      options: bridgeInputTemplateComponent.direzioneList,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.giunti,
                          `${BridgeConstants.defectElements.giunti} No`,
                          BridgeConstants.defectElements.giunti
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'component',
                    type: 'select',
                    hooks: {
                      onInit: (field) => {
                        if (field.formControl.value) {
                          const value = field.formControl.value;
                          const component =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              ComponentMaterialMappingObj.Giunti.component,
                              value
                            );

                          bridgeInputTemplateComponent.setMaterials(
                            component,
                            field,
                            true
                          );
                        }
                      },
                    },
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Component',
                      options: ComponentMaterialMappingObj.Giunti.component,
                      change: (field, e) => {
                        const value = field.formControl.value;
                        const component =
                          bridgeInputTemplateComponent.getItemFromListByValue(
                            ComponentMaterialMappingObj.Giunti.component,
                            value
                          );

                        bridgeInputTemplateComponent.setMaterials(
                          component,
                          field,
                          false
                        );

                        if (component.materials.length === 0) {
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.giunti,
                            component.default_component_type
                          );
                        }

                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l2
                          ].componentCount
                        ) {
                          if (
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].componentCount[
                              `${BridgeConstants.defectElements.giunti} ${value}`
                            ]
                          ) {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.giunti
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].componentCount[
                                `${BridgeConstants.defectElements.giunti} ${value}`
                              ] + 1;
                          } else {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.giunti
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo = 1;
                          }
                        } else {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.giunti
                          ][
                            'Scheda Ispezione Ponti di Livello 1'
                          ].componentNo = 1;
                        }
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.giunti,
                          `${BridgeConstants.defectElements.giunti} No`,
                          BridgeConstants.defectElements.giunti
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'materiale',
                    type: 'select',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Materiale',
                      options: [],
                      change: (field, e) => {
                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.giunti
                          ].component &&
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.giunti
                          ].materiale
                        ) {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.giunti
                          ]['Scheda Ispezione Ponti di Livello 1'].materiale =
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.giunti
                            ].materiale;

                          const value = field.formControl.value;
                          const material =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              field.templateOptions.options,
                              value
                            );
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.giunti,
                            material.component_type,
                            value
                          );

                          bridgeInputTemplateComponent.setUniqueCode(
                            BridgeConstants.levels.giunti,
                            `${BridgeConstants.defectElements.giunti} No`,
                            BridgeConstants.defectElements.giunti
                          );
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Ponti di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            fieldGroup: [
                              {
                                className: 'unique-code-sec',
                                key: 'uniquecode',
                                type: 'cube-unique-code-type',
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column grid-gap-extra',
                                fieldGroup: [
                                  {
                                    className: 'defect-field-label',
                                    key: BridgeConstants.partNo,
                                    type: 'cube-text-only-type',
                                    templateOptions: {
                                      pre: `${BridgeConstants.partNo}: `,
                                    },
                                  },
                                  {
                                    key: 'Localizzazione',
                                    type: 'cube-text-only-type',
                                    className: 'defect-field-label',
                                    templateOptions: {
                                      pre: 'Localizzazione: ',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            fieldGroupClassName:
                              'display-flex-column grid-gap-extra',
                            fieldGroup: [
                              {
                                className: 'defect-field-label',
                                key: 'Element No',
                                type: 'cube-text-only-type',
                                templateOptions: {
                                  pre: `${BridgeConstants.defectElements.giunti} No: `,
                                },
                              },
                              {
                                className: 'defect-field-label',
                                key: 'materiale',
                                type: 'cube-text-only-type',
                              },
                              {
                                key: 'Sigla ID Elem',
                                type: 'cube-text-only-type',
                                className: 'defect-field-label',
                                templateOptions: {
                                  pre: 'Sigla ID Elem.: ',
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !bridgeInputTemplateComponent.l3UpdateStatus
                                            ) {
                                              bridgeInputTemplateComponent.model[
                                                BridgeConstants.levels.giunti
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = bridgeInputTemplateComponent.getDeepCopy(
                                                bridgeInputTemplateComponent
                                                  .model[
                                                  BridgeConstants.levels.l1
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1'
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1 section'
                                                ]
                                              );
                                              bridgeInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex-wrap grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data ispezione',
                                              type: 'cube-date-range-picker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                                max: (() => {
                                                  const today = new Date();
                                                  const maxDate = new Date(
                                                    today
                                                  );
                                                  maxDate.setDate(
                                                    today.getDate() + 7
                                                  );

                                                  return maxDate;
                                                })(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className:
                                              'defect-field-label flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            onCellClicked: (e) => {
                              if (
                                e.colDef.field == 'Codice difetto' &&
                                !e.data.hasOwnProperty('id')
                              ) {
                                bridgeInputTemplateComponent.openNewDefectDialog(
                                  BridgeConstants.levels.giunti,
                                  'editDefect',
                                  e.rowIndex
                                );
                              }
                            },
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                minWidth: 100,
                                flex: 1,
                                colSpan: (params) => {
                                  switch (true) {
                                    case params.data.isFullRow:
                                      // Section Title
                                      return 16;

                                    case params.data.isQuarterRow:
                                      // Eventuali Note
                                      return 2;

                                    default:
                                      // Codice Difetto
                                      return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'text-align-left': (params) =>
                                    params.data.isFullRow,
                                  'cursor-pointer': (params) =>
                                    !params.data.hasOwnProperty('id') &&
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 225,
                                flex: 1.6,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: 'visto',
                                headerClass: 'visto header-no-child',
                                field: 'visto',
                                cellRendererSelector: (params) => {
                                  if (typeof params.value !== 'boolean') {
                                    // Eventuali Note
                                    return {
                                      component:
                                        'defectTableNoteRendererComponent',
                                      params: {
                                        mode: DefectNoteMode.bridgeEventualiNote,
                                      },
                                    };
                                  }

                                  // Visto Checkbox
                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                width: 40,
                                colSpan: (params) => {
                                  return params.data.isQuarterRow
                                    ? 10 // Eventuali Note
                                    : 1; // Visto Checkbox
                                },
                                cellClass: 'width-100',
                                cellClassRules: {
                                  'remove-underline': (params) =>
                                    typeof params.value !== 'boolean',
                                },
                              },
                              {
                                headerName: 'G',
                                headerClass: 'header-no-child',
                                field: 'G',
                                width: 40,
                              },
                              {
                                headerName: 'Estensione K1',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Estensione K1',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'Intensità K2',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Intensità K2',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'N° foto',
                                headerClass: 'header-no-child',
                                field: 'N° foto',
                                width: 70,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.giunti,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'PS',
                                headerClass: 'header-no-child',
                                field: 'PS',
                                width: 40,
                                cellRendererSelector: (params) => {
                                  if (params.data.G > 3) {
                                    // PS Checkbox
                                    return {
                                      component:
                                        'defectTableCheckboxRendererComponent',
                                      params: {},
                                    };
                                  }

                                  // No checkbox
                                  return null;
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'NA',
                                headerClass: 'header-no-child',
                                field: 'NA',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NA = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NR = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NR',
                                headerClass: 'header-no-child',
                                field: 'NR',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NR = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NP',
                                headerClass: 'header-no-child',
                                field: 'NP',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NP = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NR = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'Sup. Est. Dif. [m^2]',
                                headerClass: 'header-no-child',
                                field: 'Sup Est Dif',
                                minWidth: 100,
                                flex: 1,
                                cellClass: ['remove-underline'],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-underline'],
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements.giunti,
                                cellRendererSelector: (params) => {
                                  if (params.data.isQuarterRow) {
                                    // Eventuali Note File Upload
                                    return {
                                      component:
                                        'defectTableFileUploadRendererComponent',
                                      params: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.images,
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        isEventualiNoteSection: true,
                                      },
                                    };
                                  }

                                  // Defect Note
                                  return {
                                    component:
                                      'defectTableNoteRendererComponent',
                                    params: {
                                      mode: DefectNoteMode.bridgeDefect,
                                    },
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableFileUploadRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'visto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              fieldGroup: [
                                {
                                  key: 'Estensione K1',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Intensità K2',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'PS',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NA',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NR',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NP',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'Sup Est Dif',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                      {
                        // Add New Defect
                        type: 'cube-button-type',
                        defaultValue: true,
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Add New Defect',
                          color: 'primary',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onClick: (e) => {
                            bridgeInputTemplateComponent.openNewDefectDialog(
                              BridgeConstants.levels.giunti,
                              'addDefect'
                            );
                          },
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${BridgeConstants.levels.giunti}`,
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.giunti,
      },
      {
        key: BridgeConstants.levels.apparecchiSismici,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          {
            templateOptions: {
              label: BridgeConstants.defectElements.apparecchiSismici,
            },
            fieldGroup: [
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: `${BridgeConstants.defectElements.apparecchiSismici} No`,
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: `${BridgeConstants.defectElements.apparecchiSismici} No`,
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      min: 1,
                      onChange: async (field, e) => {
                        bridgeInputTemplateComponent.model[
                          BridgeConstants.levels.apparecchiSismici
                        ]['Scheda Ispezione Ponti di Livello 1']['Element No'] =
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.apparecchiSismici
                          ][
                            `${BridgeConstants.defectElements.apparecchiSismici} No`
                          ];
                        await bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.apparecchiSismici,
                          `${BridgeConstants.defectElements.apparecchiSismici} No`,
                          BridgeConstants.defectElements.apparecchiSismici
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'direzione',
                    type: 'select',
                    templateOptions: {
                      label: 'Direzione',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      options: bridgeInputTemplateComponent.direzioneList,
                      change: (field, e) => {
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.apparecchiSismici,
                          `${BridgeConstants.defectElements.apparecchiSismici} No`,
                          BridgeConstants.defectElements.apparecchiSismici
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'component',
                    type: 'select',
                    hooks: {
                      onInit: (field) => {
                        if (field.formControl.value) {
                          const value = field.formControl.value;
                          const component =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              ComponentMaterialMappingObj[
                                BridgeConstants.defectElements.apparecchiSismici
                              ].component,
                              value
                            );

                          bridgeInputTemplateComponent.setMaterials(
                            component,
                            field,
                            true
                          );
                        }
                      },
                    },
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Component',
                      options:
                        ComponentMaterialMappingObj[
                          BridgeConstants.defectElements.apparecchiSismici
                        ].component,
                      change: (field, e) => {
                        const value = field.formControl.value;
                        const component =
                          bridgeInputTemplateComponent.getItemFromListByValue(
                            ComponentMaterialMappingObj[
                              BridgeConstants.defectElements.apparecchiSismici
                            ].component,
                            value
                          );

                        bridgeInputTemplateComponent.setMaterials(
                          component,
                          field,
                          false
                        );

                        if (component.materials.length === 0) {
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.apparecchiSismici,
                            component.default_component_type
                          );
                        }

                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.l2
                          ].componentCount
                        ) {
                          if (
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.l2
                            ].componentCount[
                              `${BridgeConstants.defectElements.apparecchiSismici} ${value}`
                            ]
                          ) {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.apparecchiSismici
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l2
                              ].componentCount[
                                `${BridgeConstants.defectElements.apparecchiSismici} ${value}`
                              ] + 1;
                          } else {
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.apparecchiSismici
                            ][
                              'Scheda Ispezione Ponti di Livello 1'
                            ].componentNo = 1;
                          }
                        } else {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.apparecchiSismici
                          ][
                            'Scheda Ispezione Ponti di Livello 1'
                          ].componentNo = 1;
                        }
                        bridgeInputTemplateComponent.setUniqueCode(
                          BridgeConstants.levels.apparecchiSismici,
                          `${BridgeConstants.defectElements.apparecchiSismici} No`,
                          BridgeConstants.defectElements.apparecchiSismici
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'materiale',
                    type: 'select',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: 'Materiale',
                      options: [],
                      change: (field, e) => {
                        if (
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.apparecchiSismici
                          ].component &&
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.apparecchiSismici
                          ].materiale
                        ) {
                          bridgeInputTemplateComponent.model[
                            BridgeConstants.levels.apparecchiSismici
                          ]['Scheda Ispezione Ponti di Livello 1'].materiale =
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.apparecchiSismici
                            ].materiale;

                          const value = field.formControl.value;
                          const material =
                            bridgeInputTemplateComponent.getItemFromListByValue(
                              field.templateOptions.options,
                              value
                            );
                          bridgeInputTemplateComponent.getDefectsList(
                            BridgeConstants.levels.apparecchiSismici,
                            material.component_type,
                            value
                          );

                          bridgeInputTemplateComponent.setUniqueCode(
                            BridgeConstants.levels.apparecchiSismici,
                            `${BridgeConstants.defectElements.apparecchiSismici} No`,
                            BridgeConstants.defectElements.apparecchiSismici
                          );
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Ponti di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            fieldGroup: [
                              {
                                className: 'unique-code-sec',
                                key: 'uniquecode',
                                type: 'cube-unique-code-type',
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-column grid-gap-extra',
                                fieldGroup: [
                                  {
                                    className: 'defect-field-label',
                                    key: BridgeConstants.partNo,
                                    type: 'cube-text-only-type',
                                    templateOptions: {
                                      pre: `${BridgeConstants.partNo}: `,
                                    },
                                  },
                                  {
                                    key: 'Localizzazione',
                                    type: 'cube-text-only-type',
                                    className: 'defect-field-label',
                                    templateOptions: {
                                      pre: 'Localizzazione: ',
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            fieldGroupClassName:
                              'display-flex-column grid-gap-extra',
                            fieldGroup: [
                              {
                                className: 'defect-field-label',
                                key: 'Element No',
                                type: 'cube-text-only-type',
                                templateOptions: {
                                  pre: `${BridgeConstants.defectElements.apparecchiSismici} No: `,
                                },
                              },
                              {
                                className: 'defect-field-label',
                                key: 'materiale',
                                type: 'cube-text-only-type',
                              },
                              {
                                key: 'Sigla ID Elem',
                                type: 'cube-text-only-type',
                                className: 'defect-field-label',
                                templateOptions: {
                                  pre: 'Sigla ID Elem.: ',
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        bridgeInputTemplateComponent.isFieldDisabled(
                                          [UserRole.hydraulicTechnician],
                                          isDisabled,
                                          true
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex-wrap',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            bridgeInputTemplateComponent.isFieldDisabled(
                                              [UserRole.hydraulicTechnician],
                                              isDisabled,
                                              true
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !bridgeInputTemplateComponent.l3UpdateStatus
                                            ) {
                                              bridgeInputTemplateComponent.model[
                                                BridgeConstants.levels.apparecchiSismici
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = bridgeInputTemplateComponent.getDeepCopy(
                                                bridgeInputTemplateComponent
                                                  .model[
                                                  BridgeConstants.levels.l1
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1'
                                                ][
                                                  'Schede descrittive di ispezione ponti di Livello 1 section'
                                                ]
                                              );
                                              bridgeInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex-wrap grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data ispezione',
                                              type: 'cube-date-range-picker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  bridgeInputTemplateComponent.isFieldDisabled(
                                                    [
                                                      UserRole.hydraulicTechnician,
                                                    ],
                                                    isDisabled,
                                                    true
                                                  ),
                                                max: (() => {
                                                  const today = new Date();
                                                  const maxDate = new Date(
                                                    today
                                                  );
                                                  maxDate.setDate(
                                                    today.getDate() + 7
                                                  );

                                                  return maxDate;
                                                })(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex-wrap grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                bridgeInputTemplateComponent.isFieldDisabled(
                                                  [
                                                    UserRole.hydraulicTechnician,
                                                  ],
                                                  isDisabled,
                                                  true
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className:
                                              'defect-field-label flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            onCellClicked: (e) => {
                              if (
                                e.colDef.field == 'Codice difetto' &&
                                !e.data.hasOwnProperty('id')
                              ) {
                                bridgeInputTemplateComponent.openNewDefectDialog(
                                  BridgeConstants.levels.apparecchiSismici,
                                  'editDefect',
                                  e.rowIndex
                                );
                              }
                            },
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                minWidth: 100,
                                flex: 1,
                                colSpan: (params) => {
                                  switch (true) {
                                    case params.data.isFullRow:
                                      // Section Title
                                      return 16;

                                    case params.data.isQuarterRow:
                                      // Eventuali Note
                                      return 2;

                                    default:
                                      // Codice Difetto
                                      return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'text-align-left': (params) =>
                                    params.data.isFullRow,
                                  'cursor-pointer': (params) =>
                                    !params.data.hasOwnProperty('id') &&
                                    !bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 225,
                                flex: 1.6,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: 'visto',
                                headerClass: 'visto header-no-child',
                                field: 'visto',
                                cellRendererSelector: (params) => {
                                  if (typeof params.value !== 'boolean') {
                                    // Eventuali Note
                                    return {
                                      component:
                                        'defectTableNoteRendererComponent',
                                      params: {
                                        mode: DefectNoteMode.bridgeEventualiNote,
                                      },
                                    };
                                  }

                                  // Visto Checkbox
                                  return {
                                    component:
                                      'defectTableCheckboxRendererComponent',
                                    params: {},
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                width: 40,
                                colSpan: (params) => {
                                  return params.data.isQuarterRow
                                    ? 10 // Eventuali Note
                                    : 1; // Visto Checkbox
                                },
                                cellClass: 'width-100',
                                cellClassRules: {
                                  'remove-underline': (params) =>
                                    typeof params.value !== 'boolean',
                                },
                              },
                              {
                                headerName: 'G',
                                headerClass: 'header-no-child',
                                field: 'G',
                                width: 40,
                              },
                              {
                                headerName: 'Estensione K1',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Estensione K1',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'Intensità K2',
                                children: [
                                  {
                                    headerClass: 'custom-radio-table',
                                    headerComponent:
                                      defectTableHeaderRendererComponent,
                                    headerComponentParams: {
                                      title: ['0.2', '0.5', '1'],
                                      sortable: false,
                                    },
                                    field: 'Intensità K2',
                                    cellRenderer:
                                      defectTableRadioButtonsRendererComponent,
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                    width: 160,
                                  },
                                ],
                              },
                              {
                                headerName: 'N° foto',
                                headerClass: 'header-no-child',
                                field: 'N° foto',
                                width: 70,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellRenderer:
                                  defectTableFileUploadRendererComponent,
                                cellRendererParams: {
                                  acceptedFileTypes:
                                    environment.file_upload.accepted_file_types
                                      .images,
                                  disabled:
                                    bridgeInputTemplateComponent.isFieldDisabled(
                                      [UserRole.hydraulicTechnician],
                                      isDisabled,
                                      true
                                    ),
                                },
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements
                                    .apparecchiSismici,
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'PS',
                                headerClass: 'header-no-child',
                                field: 'PS',
                                width: 40,
                                cellRendererSelector: (params) => {
                                  if (params.data.G > 3) {
                                    // PS Checkbox
                                    return {
                                      component:
                                        'defectTableCheckboxRendererComponent',
                                      params: {},
                                    };
                                  }

                                  // No checkbox
                                  return null;
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                              {
                                headerName: 'NA',
                                headerClass: 'header-no-child',
                                field: 'NA',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NA = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NR = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NR',
                                headerClass: 'header-no-child',
                                field: 'NR',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NR = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NP = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'NP',
                                headerClass: 'header-no-child',
                                field: 'NP',
                                width: 40,
                                cellRenderer:
                                  'defectTableCheckboxRendererComponent',
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                                onCellValueChanged: (params) => {
                                  if (
                                    params.oldValue !== params.newValue &&
                                    (params.data['Intensità K2'] != null ||
                                      params.data['Estensione K1'] != null)
                                  ) {
                                    params.data.NP = false;
                                    params.api.refreshCells({
                                      rowNodes: [params.node],
                                      columns: [params.column],
                                    });
                                    bridgeInputTemplateComponent.getConfirmation(
                                      {
                                        isCancelButtonVisible: false,
                                        message:
                                          'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                        title: 'Info',
                                      }
                                    );
                                  }
                                  params.data.NA = false;
                                  params.data.NR = false;
                                  params.api.refreshCells({
                                    rowNodes: [params.node],
                                  });
                                },
                              },
                              {
                                headerName: 'Sup. Est. Dif. [m^2]',
                                headerClass: 'header-no-child',
                                field: 'Sup Est Dif',
                                minWidth: 100,
                                flex: 1,
                                cellClass: ['remove-underline'],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-underline'],
                                modelData: bridgeInputTemplateComponent.model,
                                token: bridgeInputTemplateComponent.token,
                                componentType:
                                  BridgeConstants.defectElements
                                    .apparecchiSismici,
                                cellRendererSelector: (params) => {
                                  if (params.data.isQuarterRow) {
                                    // Eventuali Note File Upload
                                    return {
                                      component:
                                        'defectTableFileUploadRendererComponent',
                                      params: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.images,
                                        disabled:
                                          bridgeInputTemplateComponent.isFieldDisabled(
                                            [UserRole.hydraulicTechnician],
                                            isDisabled,
                                            true
                                          ),
                                        isEventualiNoteSection: true,
                                      },
                                    };
                                  }

                                  // Defect Note
                                  return {
                                    component:
                                      'defectTableNoteRendererComponent',
                                    params: {
                                      mode: DefectNoteMode.bridgeDefect,
                                    },
                                  };
                                },
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableFileUploadRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'visto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'G',
                            },
                            {
                              fieldGroup: [
                                {
                                  key: 'Estensione K1',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Intensità K2',
                                  templateOptions: {
                                    disabled:
                                      bridgeInputTemplateComponent.isFieldDisabled(
                                        [UserRole.hydraulicTechnician],
                                        isDisabled,
                                        true
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'N° foto',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'PS',
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NA',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NR',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'NP',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'input',
                              defaultValue: '',
                              key: 'Sup Est Dif',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  bridgeInputTemplateComponent.isFieldDisabled(
                                    [UserRole.hydraulicTechnician],
                                    isDisabled,
                                    true
                                  ),
                              },
                            },
                          ],
                        },
                      },
                      {
                        // Add New Defect
                        type: 'cube-button-type',
                        defaultValue: true,
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Add New Defect',
                          color: 'primary',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onClick: (e) => {
                            bridgeInputTemplateComponent.openNewDefectDialog(
                              BridgeConstants.levels.apparecchiSismici,
                              'addDefect'
                            );
                          },
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${BridgeConstants.levels.apparecchiSismici}`,
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.apparecchiSismici,
      },
      // INFO: Level 2
      {
        key: BridgeConstants.levels.level2,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          // INFO: Level 2 Tab
          {
            key: 'campateCdaStatus',
            templateOptions: { label: 'Level 2' },
            fieldGroupClassName: 'display-flex-column grid-gap',
            fieldGroup: [
              // INFO: Opera CdA Status Section
              {
                fieldGroup: [
                  // INFO: Technico rilevatore
                  {
                    className: 'flex-2',
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-top-2',
                        key: 'Tecnico rilevatore',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          required: true,
                          label: 'Tecnico rilevatore',
                        },
                      },
                      // INFO: Data di ispezione
                      {
                        className: 'flex-1',
                        key: 'Data di ispezione',
                        type: 'cube-date-range-picker-type',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: 'Data di ispezione',
                          required: true,
                          max: (() => {
                            const today = new Date();
                            const maxDate = new Date(today);
                            maxDate.setDate(today.getDate() + 7);

                            return maxDate;
                          })(),
                        },
                      },
                    ],
                  },
                  // INFO: Tipologia Opera
                  {
                    fieldGroupClassName:
                      'display-flex-wrap border-left border-top border-right',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 border-right padding-left-10 light-grey',
                        fieldGroupClassName:
                          'display-flex align-items-center grid-gap',
                        fieldGroup: [
                          {
                            className: 'font-weight-bolder',
                            template: `<p>Tipologia Opera <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `TIPO 1: Opera già inserita in un sistema di sorveglianza conforme alla Circolare n° 6736/61/AI del 1967 della
                              quale è sufficientemente noto lo stato di conservazione e l’evoluzione attesa dei difetti.
                              TIPO 2: Opera di nuova costruzione oppure opera già in esercizio da diversi anni, ma per la quale non sono
                              state effettuate le ispezioni periodiche
                              di cui alla Circolare n° 6736/61/AI del 1967 di cui non sia noto lo stato conservativo/manutentivo, il
                              progredire dei difetti presenti (curva
                              di degrado reale)`,
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'flex-3 remove-underline custom-select-field padding-left-10',
                        key: 'Tipologia Opera',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'Tipo 1', label: 'Tipo 1' },
                            { value: 'Tipo 2', label: 'Tipo 2' },
                          ],
                        },
                      },
                    ],
                  },
                  // INFO: Classe Di Attenzione Dell'Opera
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 border-right padding-left-10 light-grey font-weight-bolder',
                        template: `<p>Classe Di Attenzione Dell'Opera</p>`,
                      },
                      {
                        key: "classeDiAttenzioneDell'Opera",
                        type: 'select',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: CdAStatus.alta,
                              label: CdAStatus.alta,
                            },
                            {
                              value: CdAStatus.medioAlta,
                              label: CdAStatus.medioAlta,
                            },
                            {
                              value: CdAStatus.media,
                              label: CdAStatus.media,
                            },
                            {
                              value: CdAStatus.medioBassa,
                              label: CdAStatus.medioBassa,
                            },
                            {
                              value: CdAStatus.bassa,
                              label: CdAStatus.bassa,
                            },
                            { value: CdAState.na, label: CdAState.na },
                          ],
                          change: (field, e) => {
                            const calculatedCdAValue =
                              field.parent.formControl.get([
                                'calculatedCdAValue',
                              ])?.value;

                            if (calculatedCdAValue !== e.value) {
                              field.parent.formControl
                                .get(['rilevamento'])
                                ?.setValue('Modified');
                            } else {
                              field.parent.formControl
                                .get(['rilevamento'])
                                ?.setValue('Calculated');
                            }
                          },
                        },
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `flex-3 remove-underline custom-select-field attention-class padding-left-10 ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  // INFO: Rilevamento
                  {
                    fieldGroupClassName:
                      'display-flex-wrap border-left border-bottom border-right margin-bottom-30',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 border-right padding-left-10 light-grey font-weight-bolder',
                        template: `<p>Rilevamento</p>`,
                      },
                      {
                        className:
                          'flex-3 display-flex align-items-center padding-left-10',
                        key: 'rilevamento',
                        type: 'cube-text-only-type',
                        templateOptions: {
                          customClass: 'padding-left-8',
                        },
                      },
                    ],
                  },
                ],
              },
              // INFO: Campate Table
              {
                key: 'campateList',
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: false,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    noRowsMessage: 'No campate added in Level 1',
                    defaultColDef: {
                      flex: 1,
                      minWidth: 130,
                    },
                    columnDefs: [
                      {
                        headerName: 'Nb. Campate',
                        headerClass: 'header-no-child',
                        field: BridgeConstants.partNo,
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        cellClass: (params) => {
                          const l2Campata = params.data;
                          const isCampataPresentInL1 =
                            bridgeInputTemplateComponent.isCampataPresentInL1(
                              l2Campata[BridgeConstants.partNo]
                            );

                          return !isCampataPresentInL1 ? 'disable-row' : '';
                        },
                      },
                      {
                        headerName:
                          BridgeConstants.level2RiskSheets
                            .strutturaleEFondazionale,
                        headerClass: 'header-no-child',
                        field:
                          BridgeConstants.level2RiskSheets
                            .strutturaleEFondazionale,
                        tooltipValueGetter: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellRenderer: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellStyle: { textAlign: 'center' },
                        cellClass: (params) => {
                          const l2Campata = params.data;
                          const isCampataPresentInL1 =
                            bridgeInputTemplateComponent.isCampataPresentInL1(
                              l2Campata[BridgeConstants.partNo]
                            );

                          if (!isCampataPresentInL1) return 'disable-row';

                          return params.value !== ''
                            ? `attention-class ${params.value
                                ?.replace(/\s/g, '')
                                .toLowerCase()}`
                            : '';
                        },
                      },
                      {
                        headerName: BridgeConstants.level2RiskSheets.sismica,
                        headerClass: 'header-no-child',
                        field: BridgeConstants.level2RiskSheets.sismica,
                        tooltipValueGetter: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellRenderer: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellStyle: { textAlign: 'center' },
                        cellClass: (params) => {
                          const l2Campata = params.data;
                          const isCampataPresentInL1 =
                            bridgeInputTemplateComponent.isCampataPresentInL1(
                              l2Campata[BridgeConstants.partNo]
                            );

                          if (!isCampataPresentInL1) return 'disable-row';

                          return params.value !== ''
                            ? `attention-class ${params.value
                                ?.replace(/\s/g, '')
                                .toLowerCase()}`
                            : '';
                        },
                      },
                      {
                        headerName: BridgeConstants.level2RiskSheets.frane,
                        headerClass: 'header-no-child',
                        field: BridgeConstants.level2RiskSheets.frane,
                        tooltipValueGetter: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellRenderer: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellStyle: { textAlign: 'center' },
                        cellClass: (params) => {
                          const l2Campata = params.data;
                          const isCampataPresentInL1 =
                            bridgeInputTemplateComponent.isCampataPresentInL1(
                              l2Campata[BridgeConstants.partNo]
                            );

                          if (!isCampataPresentInL1) return 'disable-row';

                          return params.value !== ''
                            ? `attention-class ${params.value
                                ?.replace(/\s/g, '')
                                .toLowerCase()}`
                            : '';
                        },
                      },
                      {
                        headerName: BridgeConstants.level2RiskSheets.idraulica,
                        headerClass: 'header-no-child',
                        field: BridgeConstants.level2RiskSheets.idraulica,
                        tooltipValueGetter: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellRenderer: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellStyle: { textAlign: 'center' },
                        cellClass: (params) => {
                          const l2Campata = params.data;
                          const isCampataPresentInL1 =
                            bridgeInputTemplateComponent.isCampataPresentInL1(
                              l2Campata[BridgeConstants.partNo]
                            );

                          if (!isCampataPresentInL1) return 'disable-row';

                          return params.value !== ''
                            ? `attention-class ${params.value
                                ?.replace(/\s/g, '')
                                .toLowerCase()}`
                            : '';
                        },
                      },
                      {
                        headerName: 'CdA',
                        headerClass: 'header-no-child',
                        field: 'CdA',
                        tooltipValueGetter: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellRenderer: (params) =>
                          params.value === '' ? CdAState.na : params.value,
                        cellStyle: { textAlign: 'center' },
                        cellClass: (params) => {
                          const l2Campata = params.data;
                          const isCampataPresentInL1 =
                            bridgeInputTemplateComponent.isCampataPresentInL1(
                              l2Campata[BridgeConstants.partNo]
                            );

                          if (!isCampataPresentInL1) return 'disable-row';

                          return params.value !== ''
                            ? `attention-class ${params.value
                                ?.replace(/\s/g, '')
                                .toLowerCase()}`
                            : '';
                        },
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          onEdit: async (index, rowData) => {
                            const l2Campata = rowData;

                            // Checking if campata has been added in L1
                            const isCampataPresentInL1 =
                              bridgeInputTemplateComponent.isCampataPresentInL1(
                                l2Campata[BridgeConstants.partNo]
                              );

                            // If campata has not been added in L1
                            if (!isCampataPresentInL1) {
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Ok',
                                  isCancelButtonVisible: false,
                                  message: `Please add campate in L1 first`,
                                  title: 'Info',
                                }
                              );

                              return;
                            }

                            // Checking if all required fields have been filled in this level
                            const isFormInvalid =
                              bridgeInputTemplateComponent.form.get([
                                'level2',
                                'campateCdaStatus',
                              ]).invalid;

                            // If all required fields have not been filled in this level
                            if (isFormInvalid) {
                              await bridgeInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Ok',
                                  isCancelButtonVisible: false,
                                  message: `Please fill all required fields`,
                                  title: 'Info',
                                }
                              );

                              return;
                            }

                            bridgeInputTemplateComponent.model.activeLevel =
                              BridgeConstants.levels.level2Campata;
                            bridgeInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              BridgeConstants.levels.level2Campata,
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.level2
                              ].campateCdaStatus.campateList,
                              ''
                            );
                          },
                        },
                        cellClass: (params) => {
                          const l2Campata = params.data;
                          const isCampataPresentInL1 =
                            bridgeInputTemplateComponent.isCampataPresentInL1(
                              l2Campata[BridgeConstants.partNo]
                            );

                          if (!isCampataPresentInL1) return 'disable-row';
                        },
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: BridgeConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: BridgeConstants.partNo,
                        readonly: true,
                      },
                    },
                    {
                      key: BridgeConstants.level2RiskSheets
                        .strutturaleEFondazionale,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label:
                          BridgeConstants.level2RiskSheets
                            .strutturaleEFondazionale,
                        readonly: true,
                      },
                    },
                    {
                      key: BridgeConstants.level2RiskSheets.sismica,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: BridgeConstants.level2RiskSheets.sismica,
                        readonly: true,
                      },
                    },
                    {
                      key: BridgeConstants.level2RiskSheets.frane,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: BridgeConstants.level2RiskSheets.frane,
                        readonly: true,
                      },
                    },
                    {
                      key: BridgeConstants.level2RiskSheets.idraulica,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: BridgeConstants.level2RiskSheets.idraulica,
                        readonly: true,
                      },
                    },
                    {
                      key: 'CdA',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'CdA',
                        readonly: true,
                      },
                    },
                    {
                      key: BridgeConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: BridgeConstants.partNo,
                        readonly: true,
                      },
                    },
                  ],
                },
              },
            ],
          },
          // INFO: Media Tab
          {
            key: 'Media',
            templateOptions: { label: 'Media' },
            fieldGroup: [
              // INFO: Cover Image
              {
                className: 'width-100 flex-1 input-line',
                key: 'Cover Image',
                type: 'cube-file-upload-type',
                defaultValue: '',
                templateOptions: {
                  acceptedFileTypes:
                    environment.file_upload.accepted_file_types
                      .imagesWithoutTiff,
                  label: 'Cover Image',
                  disabled: bridgeInputTemplateComponent.isFieldDisabled(
                    [UserRole.hydraulicTechnician],
                    isDisabled,
                    true
                  ),
                  token: bridgeInputTemplateComponent.token,
                  modelData: bridgeInputTemplateComponent.model,
                },
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.level2,
      },
      // INFO: Level 2 > Campata Level
      {
        key: BridgeConstants.levels.level2Campata,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: bridgeInputTemplateComponent.devMode, // TEMP: For development
        },
        fieldGroup: [
          // INFO: Campata Tab
          {
            key: 'Campata',
            templateOptions: { label: 'Campata' },
            fieldGroup: [
              // INFO: Campata No.
              {
                className: 'flex-1',
                key: BridgeConstants.partNo,
                type: 'input',
                templateOptions: {
                  readonly: true,
                  required: true,
                  label: BridgeConstants.partNo,
                },
              },
            ],
          },
          // INFO: Strutturale e Fondazionale Tab
          {
            key: BridgeConstants.level2RiskSheets.strutturaleEFondazionale,
            templateOptions: {
              label: BridgeConstants.level2RiskSheets.strutturaleEFondazionale,
            },
            fieldGroup: [
              // INFO: Top section
              {
                fieldGroup: [
                  // INFO: Shortcut Links
                  {
                    className: 'flex-2',
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      // INFO: Schede di censimento di Livello 0
                      {
                        className: 'flex-1 padding-left-10 padding-top-10',
                        template: `<p>Schede di censimento di Livello 0</p>`,
                      },
                      {
                        type: 'cube-button-type',
                        className: 'flex-1 remove-underline',
                        templateOptions: {
                          text: 'Vai alla scheda',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.onClickGotoLevel(
                              BridgeConstants.levels.l0
                            );
                          },
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                      // INFO: Scheda di ispezione di Livello 1 descrittiva
                      {
                        className: 'flex-1 padding-left-10 padding-top-10',
                        template: `<p>Scheda di ispezione di Livello 1 descrittiva</p>`,
                      },
                      {
                        type: 'cube-button-type',
                        className: 'flex-1 remove-underline',
                        templateOptions: {
                          text: 'Vai alla scheda',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.onClickGotoLevel(
                              BridgeConstants.levels.l1,
                              'descrizione'
                            );
                          },
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      // INFO: Calculate cdA button
                      {
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Calculate CdA',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.getCampataAttentionClass();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const pericolosita =
                              model['Stima del livello di pericolosita'];
                            const vulnerabilita =
                              model['Stima del livello di vulnerabilita'];
                            const esposizione =
                              model['Stima del livello di esposizione'];

                            const isAllRequiredFieldsFilled =
                              bridgeInputTemplateComponent.hasValue(
                                pericolosita['Classificazione strada']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                pericolosita[
                                  'Traffico Medio Giornaliero Veicoli commerciali'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Difettosita relativa']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Presenza di PS']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Anno di costruzione']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Norma di progetazione']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Schema statico']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Materiale']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Luce massima campata m']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['N° di campate']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                esposizione['Traffico medio giornaliero']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                esposizione['Luce media della campata']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                esposizione['Alternative stradali']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                esposizione['Tipologia Ente scavalcato']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                esposizione['Transporto di merci pericolose']
                              );

                            const isRiskSheetFilled =
                              field.parent.formControl.valid &&
                              isAllRequiredFieldsFilled;

                            model.isRiskSheetFilled = isRiskSheetFilled;

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isRiskSheetFilled || isDisabled,
                              true
                            );
                          },
                        },
                      },
                      // Copy strutturale e fondazionale sheet to all campata
                      {
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Copy',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.copyRiskSheets(
                              BridgeConstants.level2RiskSheets
                                .strutturaleEFondazionale
                            );
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isRiskSheetFilled =
                              field.parent.formControl.valid;

                            const campateListLength =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ].opereparzialilist?.[0]?.modelstructure
                                ?.campatelist?.length;

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isRiskSheetFilled ||
                                isDisabled ||
                                campateListLength <= 1,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // INFO: Stima del livello di pericolosità
              {
                key: 'Stima del livello di pericolosita',
                fieldGroup: [
                  // INFO: Title
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DEL LIVELLO DI PERICOLOSITÀ STRUTTURALE E FONDAZIONALE',
                    },
                  },
                  // INFO: Fields
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Classificazione strada <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `Con la dicitura “Carichi di progetto previsti dalle Norme Tecniche”, utilizzata per la definizione della Classe A, si fa riferimento 
                              ai soli carichi da traffico e si intende l’assenza di limitazioni di carico sulla strada che interessa il ponte. Qualora esista una 
                              limitazione di carico solo su una corsia, si consideri la classe peggiore risultante dalla Tabella 4.2 delle Linee guida corrispondente alla Classe A.`,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Classificazione strada',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: '-', label: '-' },
                            { value: 'Classe A', label: 'Classe A' },
                            { value: 'Classe B', label: 'Classe B' },
                            { value: 'Classe C', label: 'Classe C' },
                            { value: 'Classe D', label: 'Classe D' },
                            { value: 'Classe E', label: 'Classe E' },
                          ],
                          change: (field, e) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'Classe A':
                                associatedText =
                                  'Carichi di progetto previsti dalle Norme Tecniche';
                                break;

                              case 'Classe B':
                                associatedText =
                                  'Limitazione di carico a 44 t (≈ 73% dei carichi di progetto previsti dalle Norme Tecniche)';
                                break;

                              case 'Classe C':
                                associatedText =
                                  'Limitazione di carico a 26 t (≈ 43% dei carichi di progetto previsti dalle Norme Tecniche)';
                                break;

                              case 'Classe D':
                                associatedText =
                                  'Limitazione di carico a 8,0 t (≈ 13% dei carichi di progetto previsti dalle Norme Tecniche)';
                                break;

                              case 'Classe E':
                                associatedText =
                                  'Limitazione di carico a 3,5 t (≈ 6% dei carichi di progetto previsti dalle Norme Tecniche)';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get([
                                'Stima del livello di pericolosita calculated',
                              ])
                              ?.setValue(associatedText);

                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'Classe A':
                                associatedText =
                                  'Carichi di progetto previsti dalle Norme Tecniche';
                                break;

                              case 'Classe B':
                                associatedText =
                                  'Limitazione di carico a 44 t (≈ 73% dei carichi di progetto previsti dalle Norme Tecniche)';
                                break;

                              case 'Classe C':
                                associatedText =
                                  'Limitazione di carico a 26 t (≈ 43% dei carichi di progetto previsti dalle Norme Tecniche)';
                                break;

                              case 'Classe D':
                                associatedText =
                                  'Limitazione di carico a 8,0 t (≈ 13% dei carichi di progetto previsti dalle Norme Tecniche)';
                                break;

                              case 'Classe E':
                                associatedText =
                                  'Limitazione di carico a 3,5 t (≈ 6% dei carichi di progetto previsti dalle Norme Tecniche)';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get([
                                'Stima del livello di pericolosita calculated',
                              ])
                              ?.setValue(associatedText);
                          },
                        },
                      },
                    ],
                  },
                  {
                    className: 'remove-underline cursor-auto',
                    key: 'Stima del livello di pericolosita calculated',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: '',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Traffico Medio Giornaliero - Veicoli commerciali(per singola corsia di marcia)<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `I.O. 4.2.1.3 - In mancanza di dati utili per la determinazione della frequenza di transito di veicoli commerciali per singola corsia di 
                              marcia, nelle more di acquisizione degli stessi, si può operare per analogia con dati disponibili riferiti a strade di eguale ordine e 
                              ambito territoriale. In tal caso, possono essere utili analisi statistiche riferite all’ambito provinciale e/o regionale atte a correlare a 
                              ciascuna categoria di strada (definita ai sensi del Codice della Strada – D.L. n.285 del 30/04/1992 e ss.mm.ii.) il valore di frequenza del 
                              transito di veicoli commerciali per singola corsia di marcia. Qualora siano disponibili registrazioni del traffico riferite all’intera 
                              carreggiata, ovvero al traffico totale in entrambe le direzioni, può adottarsi la condizione più gravosa, assumendo il dato totale. In 
                              qualsiasi caso, nel caso in cui il gestore abbia a disposizione i dati relativi alle misurazioni di traffico, la valutazione del  valore del 
                              TGM deve essere fatta su un periodo di osservazione e misura significativo e rappresentativo dei flussi di traffico  che impegnano la 
                              strada dove la struttura è situata.`,
                            },
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                return bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ]['Rete stradale']?.[
                                  'Traffico Medio Giornaliero - Veicoli commerciali'
                                ]?.[
                                  'veicoli/giorno per singola corsia di marcia'
                                ];
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Traffico Medio Giornaliero Veicoli commerciali',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          required: true,
                          label: '',
                          options: [
                            {
                              value: '≥ 700 veicoli/giorno',
                              label: '≥ 700 veicoli/giorno',
                            },
                            {
                              value: '300 < veicoli /giorno < 700',
                              label: '300 < veicoli /giorno < 700',
                            },
                            {
                              value: '≤ 300 veicoli/giorno',
                              label: '≤ 300 veicoli/giorno',
                            },
                            {
                              value: '-',
                              label: '-',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI PERICOLOSITA'</p>`,
                      },
                      {
                        key: 'Classe di pericolosita',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // Stima del livello di vulnerabilita
              {
                key: 'Stima del livello di vulnerabilita',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: `STIMA DEL LIVELLO DI VULNERABILITÀ STRUTTURALE E FONDAZIONALE`,
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Difettosità media<span class="required-marker"> *</span></p>`,
                        //The label has been changed to "Difettosità media" for CR-12760, with no key changes required.
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Difettosita relativa',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          required: true,
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Presenza di PS<span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Presenza di PS',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'SI',
                              label: 'SI',
                            },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Livello di Difettosità</p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Livello di difettosita',
                        type: 'select',
                        templateOptions: {
                          label: '',
                          options: [
                            {
                              value: 'ALTO',
                              label: 'ALTO',
                            },
                            {
                              value: 'MEDIO-ALTO',
                              label: 'MEDIO-ALTO',
                            },
                            {
                              value: 'MEDIO',
                              label: 'MEDIO',
                            },
                            {
                              value: 'MEDIO-BASSO',
                              label: 'MEDIO-BASSO',
                            },
                            {
                              value: 'BASSO',
                              label: 'BASSO',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.level2Campata
                            ][
                              BridgeConstants.level2RiskSheets.strutturaleEFondazionale
                            ].isUserModifiedLivelloDiDifettosita = true;
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isLivelloDiDifettositaValueChanged =
                              bridgeInputTemplateComponent.isLivelloDiDifettositaValueChanged(
                                field.formControl.value
                              );

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isLivelloDiDifettositaValueChanged || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Anno di costruzione<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `Nel caso in cui si abbia evidenza di interventi manutentivi, opportunamente documentati, che abbiano limitato in maniera significativa i fenomeni di degrado, riconducendo lo stato di conservazione dell’opera nella pratica alle sue condizioni iniziali, occorre fare riferimento all’anno dell’ultimo intervento di manutenzione effettuato.`,
                            },
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                const ultimazioneCostruzioneCollaudo =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ]['Informazioni generali']?.[
                                    'Anno di costruzione / ristrutturazione'
                                  ]?.['Ultimazione costruzione collaudo']?.year;

                                const eventualiInterventiSostanziali =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ]['Informazioni generali']?.[
                                    'Anno di costruzione / ristrutturazione'
                                  ]?.['Eventuali interventi sostanziali']?.year;

                                return [
                                  ultimazioneCostruzioneCollaudo,
                                  eventualiInterventiSostanziali,
                                ]
                                  .filter((value) => value)
                                  .join(' ; ');
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Anno di costruzione',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: '≤ 1945',
                              label: '≤ 1945',
                            },
                            {
                              value: '1945 -1980',
                              label: '1945 -1980',
                            },
                            {
                              value: '≥ 1980',
                              label: '≥ 1980',
                            },
                            {
                              value: '-',
                              label: '-',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Norma di progettazione<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `I.O. 4.2.2.4 -Nel caso in cui la categoria di intervento sia stata di adeguamento (interventi atti ad aumentare la sicurezza strutturale 
                              preesistente conseguendo i livelli di sicurezza fissati al § 8.4.3 delle NTC2018), l’opera è da considerarsi di Classe C. Nel caso di 
                              interventi di miglioramento e riparazione locale occorre valutare se tali interventi hanno influito sulla capacità globale dell’opera 
                              (§ 8.4 delle NTC2018); in mancanza di specifica documentazione che attesti il livello di sicurezza raggiunto, si dovrebbe far riferimento 
                              alla classe riferita all’anno di progettazione. Nel caso siano state eseguite valutazioni di sicurezza con esito positivo nei confronti dei carichi da traffico in accordo con le norme pubblicate dal 2005 in poi, l’opera è da considerarsi di Classe C.`,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Norma di progetazione',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: '-',
                              label: '-',
                            },
                            {
                              value: 'Classe A',
                              label: 'Classe A',
                            },
                            {
                              value: 'Classe B',
                              label: 'Classe B',
                            },
                            {
                              value: 'Classe C',
                              label: 'Classe C',
                            },
                          ],
                          change: (field, e) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'Classe A':
                                associatedText =
                                  'ponti di I categoria progettati con norme pubblicate antecedentemente al 1952; ponti di II categoria progettati con norme pubblicate antecedentemente al 1990.';
                                break;

                              case 'Classe B':
                                associatedText =
                                  'ponti di I categoria progettati con norme pubblicate dal 1952 al 1990, inclusi, per luci inferiori ai 10 m e con norme dal 1952 al 2005, inclusi, per luci superiori ai 10 m; ponti di II categoria progettati con le norme pubblicate nel 1990';
                                break;

                              case 'Classe C':
                                associatedText =
                                  'ponti di I e II categoria progettati con norme pubblicate dal 2005, incluso, ad oggi per luci inferiori ai 10 m e con norme dal 2008, incluso, ad oggi per luci superiori ai 10 m.';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get([
                                'Stima del livello di vulnerabilita calculated',
                              ])
                              ?.setValue(associatedText);

                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'Classe A':
                                associatedText =
                                  'ponti di I categoria progettati con norme pubblicate antecedentemente al 1952; ponti di II categoria progettati con norme pubblicate antecedentemente al 1990.';
                                break;

                              case 'Classe B':
                                associatedText =
                                  'ponti di I categoria progettati con norme pubblicate dal 1952 al 1990, inclusi, per luci inferiori ai 10 m e con norme dal 1952 al 2005, inclusi, per luci superiori ai 10 m; ponti di II categoria progettati con le norme pubblicate nel 1990';
                                break;

                              case 'Classe C':
                                associatedText =
                                  'ponti di I e II categoria progettati con norme pubblicate dal 2005, incluso, ad oggi per luci inferiori ai 10 m e con norme dal 2008, incluso, ad oggi per luci superiori ai 10 m.';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get([
                                'Stima del livello di vulnerabilita calculated',
                              ])
                              ?.setValue(associatedText);
                          },
                        },
                      },
                    ],
                  },
                  {
                    className: 'remove-underline cursor-auto',
                    key: 'Stima del livello di vulnerabilita calculated',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: '',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Schema Statico<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `I.O. 4.2.2.6 - In caso di schemi statici non contemplati o non riconducibili a quelli della Tabella 4.6 (es. ponti strallati o ponti sospesi) la 
                              scelta della classe di vulnerabilità in funzione di schema statico, luce e materiale è demandata al valutatore, che la motiva 
                              in modo esauriente e adeguato.`,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Schema statico',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'Travate appoggiate',
                              label: 'Travate appoggiate',
                            },
                            {
                              value: 'Travate continue/Telaio',
                              label: 'Travate continue/Telaio',
                            },
                            {
                              value: 'Arco massiccio',
                              label: 'Arco massiccio',
                            },
                            {
                              value: 'Arco sottile',
                              label: 'Arco sottile',
                            },
                            {
                              value:
                                'Travate Gerber/Ponti a stampella con travi tampone',
                              label:
                                'Travate Gerber/Ponti a stampella con travi tampone',
                            },
                            {
                              value: 'Soletta appoggiata',
                              label: 'Soletta appoggiata',
                            },
                            {
                              value: 'Soletta incastrata',
                              label: 'Soletta incastrata',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Materiale<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `I.O. 4.2.2.6 - In presenza di strutture composte calcestruzzo armato - muratura (es. ponti a travata in c.a. con pile e/o spalle in muratura) per la definizione della classe di vulnerabilità si
                              considera il materiale di impalcato.`,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Materiale',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'C.a.',
                              label: 'C.a.',
                            },
                            {
                              value: 'C.a.p.',
                              label: 'C.a.p.',
                            },
                            {
                              value: 'Acciaio',
                              label: 'Acciaio',
                            },
                            {
                              value: 'Metallo',
                              label: 'Metallo',
                            },
                            {
                              value: 'Legno',
                              label: 'Legno',
                            },
                            {
                              value: 'Misto',
                              label: 'Misto',
                            },
                            {
                              value: 'Muratura',
                              label: 'Muratura',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Luce massima campata [m]<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                return bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ]?.['opereparzialilist']?.[0]?.[
                                  'modelstructure'
                                ]?.['Rilievo caratteristiche geometriche']?.[
                                  'Luce massima campata m'
                                ];
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Luce massima campata m',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          required: true,
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>N° di campate<span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'N° di campate',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          required: true,
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI VULNERABILITA'</p>`,
                      },
                      {
                        key: 'Classe di vulnerabilita',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // Stima del livello di esposizione
              {
                key: 'Stima del livello di esposizione',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DEL LIVELLO DI ESPOSIZIONE STRUTTURALE E FONDAZIONALE',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Traffico Medio Giornaliero<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `I.O. 4.2.3.1 - Qualora il dato TGM non fosse disponibile per la rete stradale in esame, si può far riferimento al dato TGM noto per tratte 
                              limitrofe e/o caratterizzate da simili caratteristiche.`,
                            },
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                return bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ]['Rete stradale']?.[
                                  'Traffico Medio Giornaliero'
                                ];
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Traffico medio giornaliero',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: '≥ 25000 veicoli/giorno',
                              label: '≥ 25000 veicoli/giorno',
                            },
                            {
                              value: '1000 < veicoli/giorno ≤ 25000',
                              label: '1000 < veicoli/giorno ≤ 25000',
                            },
                            {
                              value: '≤ 10000 veicoli/giorno',
                              label: '≤ 10000 veicoli/giorno',
                            },
                            {
                              value: '-',
                              label: '-',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Luce media della campata<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                return bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ]?.['opereparzialilist']?.[0]?.[
                                  'modelstructure'
                                ]?.['Rilievo caratteristiche geometriche']?.[
                                  'Luce media campata m'
                                ];
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Luce media della campata',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          required: true,
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                        expressionProperties: {
                          'model.Luce media della campata calculated': (
                            model
                          ) => {
                            let associatedText = '';
                            const value = model['Luce media della campata'];

                            if (value <= 20) {
                              associatedText = 'Piccola luce';
                            } else if (value > 20 && value <= 50) {
                              associatedText = 'Media luce';
                            } else if (value > 50) {
                              associatedText = 'Grande luce';
                            } else {
                              associatedText = '';
                            }

                            return associatedText;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      // Empty field for design purpose
                      {
                        className: 'flex-1',
                      },
                      {
                        className: 'flex-1 remove-underline cursor-auto',
                        key: 'Luce media della campata calculated',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Alternative stradali<span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Alternative stradali',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'SI',
                              label: 'SI',
                            },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Tipologia Ente scavalcato<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                return bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ]?.['opereparzialilist']?.[0]?.[
                                  'modelstructure'
                                ]?.[
                                  'Indicare, ai fini della classificazione'
                                ]?.[
                                  'Indicare, ai fini della classificazione - radio'
                                ];
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        key: 'Tipologia Ente scavalcato',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: CdAStatus.alta,
                              label: CdAStatus.alta,
                            },
                            {
                              value: CdAStatus.media,
                              label: CdAStatus.media,
                            },
                            {
                              value: CdAStatus.bassa,
                              label: CdAStatus.bassa,
                            },
                          ],
                          change: (field, e) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case CdAStatus.alta:
                                associatedText =
                                  'Ente scavalcato il cui uso preveda affollamenti significativi e/o con funzioni pubbliche e sociali essenziali e/o la cui interruzione provochi situazioni di emergenza e/o enti di elevato valore naturalistico, economico e sociale (Ferrovia, zona edificata/antropizzata, strade a viabilità primaria, etc.)';
                                break;

                              case CdAStatus.media:
                                associatedText =
                                  'Ente scavalcato il cui uso preveda normali affollamenti, senza funzioni pubbliche e sociali essenziali, la cui interruzione non provochi situazioni di emergenza e/o enti con limitato valore naturalistico, economico e sociale (strade a viabilità secondaria, corsi d’acqua, laghi, specchi d’acqua marini, etc.)';
                                break;

                              case CdAStatus.bassa:
                                associatedText =
                                  'Ente scavalcato con presenza occasionale di persone e privi di valore naturalistico, economico e sociale (discontinuità naturali, depressioni del terreno, etc.)';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get([
                                'Stima del livello di esposizione calculated',
                              ])
                              ?.setValue(associatedText);

                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case CdAStatus.alta:
                                associatedText =
                                  'Ente scavalcato il cui uso preveda affollamenti significativi e/o con funzioni pubbliche e sociali essenziali e/o la cui interruzione provochi situazioni di emergenza e/o enti di elevato valore naturalistico, economico e sociale (Ferrovia, zona edificata/antropizzata, strade a viabilità primaria, etc.)';
                                break;

                              case CdAStatus.media:
                                associatedText =
                                  'Ente scavalcato il cui uso preveda normali affollamenti, senza funzioni pubbliche e sociali essenziali, la cui interruzione non provochi situazioni di emergenza e/o enti con limitato valore naturalistico, economico e sociale (strade a viabilità secondaria, corsi d’acqua, laghi, specchi d’acqua marini, etc.)';
                                break;

                              case CdAStatus.bassa:
                                associatedText =
                                  'Ente scavalcato con presenza occasionale di persone e privi di valore naturalistico, economico e sociale (discontinuità naturali, depressioni del terreno, etc.)';
                                break;
                            }

                            field.formControl.parent
                              ?.get([
                                'Stima del livello di esposizione calculated',
                              ])
                              ?.setValue(associatedText);
                          },
                        },
                      },
                    ],
                  },
                  {
                    className: 'remove-underline cursor-auto',
                    key: 'Stima del livello di esposizione calculated',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: '',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Trasporto di merci pericolose<span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Transporto di merci pericolose',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'SI',
                              label: 'SI',
                            },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                            {
                              value: '-',
                              label: '-',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI ESPOSIZIONE</p>`,
                      },
                      {
                        key: 'Classe di esposizione',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // Stima della classe di attenzione strutturale e fondazionale
              {
                key: 'Stima della classe di attenzione strutturale e fondazionale',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DELLA CLASSE DI ATTENZIONE STRUTTURALE E FONDAZIONALE',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI ATTENZIONE</p>`,
                      },
                      {
                        key: 'Classe di attenzione',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // INFO: Sismica Tab
          {
            key: BridgeConstants.level2RiskSheets.sismica,
            templateOptions: {
              label: BridgeConstants.level2RiskSheets.sismica,
            },
            fieldGroup: [
              // top section
              {
                fieldGroup: [
                  {
                    className: 'flex-2',
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10 padding-top-10',
                        template: `<p>Schede di censimento di Livello 0</p>`,
                      },
                      {
                        // Vai alla scheda
                        type: 'cube-button-type',
                        className: 'flex-1 remove-underline',
                        templateOptions: {
                          text: 'Vai alla scheda',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.onClickGotoLevel(
                              BridgeConstants.levels.l0
                            );
                          },
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                      {
                        className: 'flex-1 padding-left-10 padding-top-10',
                        template: `<p>Scheda di ispezione di Livello 1 descrittiva</p>`,
                      },
                      {
                        // Vai alla scheda
                        type: 'cube-button-type',
                        className: 'flex-1 remove-underline',
                        templateOptions: {
                          text: 'Vai alla scheda',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.onClickGotoLevel(
                              BridgeConstants.levels.l1,
                              'descrizione'
                            );
                          },
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      // Calculate cdA
                      {
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Calculate CdA',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.getCampataAttentionClass();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const esposizione =
                              model['Stima del livello di esposizione sismica'];
                            const pericolosita =
                              model[
                                'Valutazione del livello di pericolosità sismica'
                              ];
                            const vulnerabilita =
                              model[
                                'Stima del livello di vulnerabilità sismica'
                              ];

                            const isAllRequiredFieldsFilled =
                              bridgeInputTemplateComponent.hasValue(
                                esposizione["Strategità dell'opera"]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                pericolosita?.[
                                  'Accelerazione di picco al suolo'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                pericolosita['Categoria topografica']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                pericolosita['Categoria sottosuolo']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Materiale']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Campate']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Schema statico']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.['Luce massima campata m']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Criteri di progettazione']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Elementi di vulnerabilità sismica'
                                ]
                              );

                            const isRiskSheetFilled =
                              field.parent.formControl.valid &&
                              isAllRequiredFieldsFilled;

                            model.isRiskSheetFilled = isRiskSheetFilled;

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isRiskSheetFilled || isDisabled,
                              true
                            );
                          },
                        },
                      },
                      // Copy sismica sheet to all campata
                      {
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Copy',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.copyRiskSheets(
                              BridgeConstants.level2RiskSheets.sismica
                            );
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isRiskSheetFilled =
                              field.parent.formControl.valid;

                            const campateListLength =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ].opereparzialilist?.[0]?.modelstructure
                                ?.campatelist?.length;

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isRiskSheetFilled ||
                                isDisabled ||
                                campateListLength <= 1,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // VALUTAZIONE DEL LIVELLO DI PERICOLOSITÀ SISMICA
              {
                key: 'Valutazione del livello di pericolosità sismica',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: `STIMA DEL LIVELLO DI PERICOLOSITÀ SISMICA`,
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Accelerazione di picco al suolo <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Accelerazione di picco al suolo',
                        type: 'input',
                        required: true,
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Categoria topografica <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Categoria topografica',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          required: true,
                          label: '',
                          options: [
                            {
                              value: '-',
                              label: '-',
                            },
                            {
                              value: 'T1',
                              label: 'T1',
                            },
                            {
                              value: 'T2',
                              label: 'T2',
                            },
                            {
                              value: 'T3',
                              label: 'T3',
                            },
                            {
                              value: 'T4',
                              label: 'T4',
                            },
                          ],
                          change: (field, e) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'T1':
                                associatedText =
                                  'Superficie pianeggiante, pendii e rilievi isolati con inclinazione media i ≤ 15°';
                                break;

                              case 'T2':
                                associatedText =
                                  'Pendii con inclinazione media i > 15°';
                                break;

                              case 'T3':
                                associatedText =
                                  'Rilievi con larghezza in cresta molto minore che alla base e inclinazione media  15°≤ i ≤ 30°';
                                break;

                              case 'T4':
                                associatedText =
                                  'Rilievi con larghezza in cresta molto minore che alla base e inclinazione media  i > 30°';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get(['Categoria topografica calculated'])
                              ?.setValue(associatedText);

                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'T1':
                                associatedText =
                                  'Superficie pianeggiante, pendii e rilievi isolati con inclinazione media i ≤ 15°';
                                break;

                              case 'T2':
                                associatedText =
                                  'Pendii con inclinazione media i > 15°';
                                break;

                              case 'T3':
                                associatedText =
                                  'Rilievi con larghezza in cresta molto minore che alla base e inclinazione media  15°≤ i ≤ 30°';
                                break;

                              case 'T4':
                                associatedText =
                                  'Rilievi con larghezza in cresta molto minore che alla base e inclinazione media  i > 30°';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get(['Categoria topografica calculated'])
                              ?.setValue(associatedText);
                          },
                        },
                      },
                    ],
                  },
                  {
                    className: 'remove-underline cursor-auto',
                    key: 'Categoria topografica calculated',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: '',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Categoria sottosuolo <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Categoria sottosuolo',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          required: true,
                          label: '',
                          options: [
                            {
                              value: '-',
                              label: '-',
                            },
                            {
                              value: 'A',
                              label: 'A',
                            },
                            {
                              value: 'B',
                              label: 'B',
                            },
                            {
                              value: 'C',
                              label: 'C',
                            },
                            {
                              value: 'D',
                              label: 'D',
                            },
                            {
                              value: 'E',
                              label: 'E',
                            },
                          ],
                          change: (field, e) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'A':
                                associatedText =
                                  'Ammassi rocciosi affioranti o terreni molto rigidi caratterizzati da valori di velocità delle onde di taglio superiori a 800 m/s, eventualmente comprendenti in superficie terreni di caratteristiche meccaniche più scadenti con spessore massimo pari a 3 m.';
                                break;

                              case 'B':
                                associatedText =
                                  'Rocce tenere e depositi di terreni a grana grossa molto addensati o terreni a grana fina molto consistenti, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 360 m/s e 800 m/s';
                                break;

                              case 'C':
                                associatedText =
                                  'Depositi di terreni a grana grossa mediamente addensati o terreni a grana fina mediamente consistenti con profondità del substrato superiori a 30 m, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 180 m/s e 360 m/s.';
                                break;

                              case 'D':
                                associatedText =
                                  'Depositi di terreni a grana grossa scarsamente addensati o di terreni a grana fina scarsamente consistenti, con profondità del substrato superiori a 30 m, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 100 e 180 m/s';
                                break;

                              case 'E':
                                associatedText =
                                  'Terreni con caratteristiche e valori di velocità equivalente riconducibili a quelle definite per le categorie C o D, con profondità del substrato non superiore a 30 m.';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get(['Categoria sottosuolo calculated'])
                              ?.setValue(associatedText);

                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'A':
                                associatedText =
                                  'Ammassi rocciosi affioranti o terreni molto rigidi caratterizzati da valori di velocità delle onde di taglio superiori a 800 m/s, eventualmente comprendenti in superficie terreni di caratteristiche meccaniche più scadenti con spessore massimo pari a 3 m.';
                                break;

                              case 'B':
                                associatedText =
                                  'Rocce tenere e depositi di terreni a grana grossa molto addensati o terreni a grana fina molto consistenti, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 360 m/s e 800 m/s';
                                break;

                              case 'C':
                                associatedText =
                                  'Depositi di terreni a grana grossa mediamente addensati o terreni a grana fina mediamente consistenti con profondità del substrato superiori a 30 m, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 180 m/s e 360 m/s.';
                                break;

                              case 'D':
                                associatedText =
                                  'Depositi di terreni a grana grossa scarsamente addensati o di terreni a grana fina scarsamente consistenti, con profondità del substrato superiori a 30 m, caratterizzati da un miglioramento delle proprietà meccaniche con la profondità e da valori di velocità equivalente compresi tra 100 e 180 m/s';
                                break;

                              case 'E':
                                associatedText =
                                  'Terreni con caratteristiche e valori di velocità equivalente riconducibili a quelle definite per le categorie C o D, con profondità del substrato non superiore a 30 m.';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get(['Categoria sottosuolo calculated'])
                              ?.setValue(associatedText);
                          },
                        },
                      },
                    ],
                  },
                  {
                    className: 'remove-underline cursor-auto',
                    key: 'Categoria sottosuolo calculated',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: '',
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled,
                        true
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                        template: `<p>CLASSE DI PERICOLOSITA'</p>`,
                      },
                      {
                        key: 'Classe di Pericolosita',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DEL LIVELLO DI VULNERABILITÀ SISMICA
              {
                key: 'Stima del livello di vulnerabilità sismica',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'STIMA DEL LIVELLO DI VULNERABILITÀ SISMICA',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Materiale <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `I.O 4.3.3.1 - Nel caso di tipologie strutturali che prevedono la presenza di diversi materiali, come ad esempio i ponti in sistema misto 
                              acciaio-calcestruzzo, si procede in analogia con le considerazioni fatte per uno dei due materiali ritenuto, dal valutatore, prevalente 
                              nella determinazione del comportamento strutturale sismico`,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Materiale',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'c.a.',
                              label: 'c.a.',
                            },
                            {
                              value: 'c.a.p.',
                              label: 'c.a.p.',
                            },
                            {
                              value: 'muratura',
                              label: 'muratura',
                            },
                            {
                              value: 'acciaio',
                              label: 'acciaio',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Luce massima campata [m] <span class="required-marker"> *</p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                return bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ]?.['opereparzialilist']?.[0]?.[
                                  'modelstructure'
                                ]?.['Rilievo caratteristiche geometriche']?.[
                                  'Luce massima campata m'
                                ];
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Luce massima campata m',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          required: true,
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                        expressionProperties: {
                          'model.Luce massima campata m select': (model) => {
                            let associatedText = '';
                            const value = model['Luce massima campata m'];

                            if (value <= 20) {
                              associatedText = 'L medio-piccola';
                            } else if (value > 20) {
                              associatedText = 'L elevata';
                            } else {
                              associatedText = '';
                            }

                            return associatedText;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Luce massima campata m select',
                        type: 'select',
                        defaultValue: 'C.a',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'L medio-piccola',
                              label: 'L medio-piccola',
                            },
                            {
                              value: 'L elevata',
                              label: 'L elevata',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Campate <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Campate',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'Singola campata',
                              label: 'Singola campata',
                            },
                            {
                              value: 'Multi-campata',
                              label: 'Multi-campata',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Schema statico <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `
        Qualora il ponte abbia campate con diverso schema statico (ad esempio, campate centrali ad arco e campate di riva con travate appoggiate), si consideri la classe di vulnerabilità più gravosa tra quelle associate ai due differenti schemi statici
        `,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Schema statico',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'Schema isostatico',
                              label: 'Schema isostatico',
                            },
                            {
                              value: 'Schema iperstatico',
                              label: 'Schema iperstatico',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Elementi di vulnerabilità sismica <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Elementi di vulnerabilità sismica',
                        type: 'input',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Criteri di progettazione <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `I.O. 4.3.3.2 - Con riferimento alla normativa di progettazione, possono considerarsi realizzati secondo criteri di progettazione 
                              antisismica i ponti realizzati in accordo con le Normative adottate a partire dal 2003. Possono altresì considerarsi realizzati secondo 
                              criteri di progettazione antisismica anche quelle strutture realizzate in data antecedente al 2003, per le quali si dispongano elaborati 
                              progettuali che diano contezza di tale assunzione.`,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Criteri di progettazione',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'SISMICI',
                              label: 'SISMICI',
                            },
                            {
                              value: 'NON SISMICI',
                              label: 'NON SISMICI',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Livello di difettosità</p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Livello di difettosità',
                        type: 'select',
                        templateOptions: {
                          label: '',
                          options: [
                            {
                              value: 'ALTO',
                              label: 'ALTO',
                            },
                            {
                              value: 'MEDIO-ALTO',
                              label: 'MEDIO-ALTO',
                            },
                            {
                              value: 'MEDIO',
                              label: 'MEDIO',
                            },
                            {
                              value: 'MEDIO-BASSO',
                              label: 'MEDIO-BASSO',
                            },
                            {
                              value: 'BASSO',
                              label: 'BASSO',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                            bridgeInputTemplateComponent.model[
                              BridgeConstants.levels.level2Campata
                            ][
                              BridgeConstants.level2RiskSheets.sismica
                            ].isUserModifiedLivelloDiDifettosita = true;
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isLivelloDiDifettositaValueChanged =
                              bridgeInputTemplateComponent.isLivelloDiDifettositaValueChanged(
                                field.formControl.value
                              );

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isLivelloDiDifettositaValueChanged || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                        template: `<p>CLASSE DI VULNERABILITA'</p>`,
                      },
                      {
                        key: 'Classe di vulnerabilita',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DEL LIVELLO DI ESPOSIZIONE SISMICA
              {
                key: 'Stima del livello di esposizione sismica',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'STIMA DEL LIVELLO DI ESPOSIZIONE SISMICA',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Strategicità dell'opera <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: "Strategità dell'opera",
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'Strategica',
                              label: 'Strategica',
                            },
                            {
                              value: 'NON Strategica',
                              label: 'NON Strategica',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                        template: `<p>CLASSE DI ESPOSIZIONE</p>`,
                      },
                      {
                        key: 'Classe di esposizione',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DELLA CLASSE DI ATTENZIONE SISMICA A LIVELLO TERRITORIALE
              {
                key: 'Stima della classe di attenzione sismica a livello territoriale',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DELLA CLASSE DI ATTENZIONE SISMICA A LIVELLO TERRITORIALE',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                        template: `<p>CLASSE DI ATTENZIONE</p>`,
                      },
                      {
                        key: 'Classe di attenzione',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // INFO: Frane Tab
          {
            key: BridgeConstants.level2RiskSheets.frane,
            templateOptions: { label: BridgeConstants.level2RiskSheets.frane },
            fieldGroup: [
              // top section
              {
                fieldGroup: [
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'padding-left-10 padding-top-10',
                        template: `<p>Schede di ispezione ponti di Livello 1 - Fenomeni di frana e fenomeni idraulici`,
                      },
                      {
                        // Vai alla scheda
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Vai alla scheda',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.onClickGotoLevel(
                              BridgeConstants.levels.l1,
                              'frane'
                            );
                          },
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        // Calculate cdA
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Calculate CdA',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.getCampataAttentionClass();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const suscettibilita =
                              model[
                                'Stima Del Livello Di Suscettibilità Legato Al Rischio Frane'
                              ];
                            const vulnerabilita =
                              model[
                                'Stima Del Livello Di Vulnerabilità Legato Al Rischio Frane'
                              ];

                            const isNegligible =
                              model?.['Fenomeno trascurabile'] === 'SI';

                            const isAllRequiredFieldsFilled =
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.['Instabilità del versante']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.[
                                  'Affidabilità della valutazione'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.['Misure di mitigazione']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita['Stato di attività']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita[
                                  'Magnitudo attesa su base volumetrica in m3'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita['Massima velocità attesa']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.['Estensione Interferenza']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita[
                                  'Presenza di fondazioni superficiali o comunque NON progettate per resistere alle azioni orizzontali'
                                ]
                              );

                            const isRiskSheetFilled =
                              (field.parent.formControl.valid &&
                                isAllRequiredFieldsFilled) ||
                              isNegligible;

                            model.isRiskSheetFilled = isRiskSheetFilled;

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isRiskSheetFilled || isDisabled,
                              true
                            );
                          },
                        },
                      },
                      {
                        // Copy frane sheet to all campata
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Copy',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.copyRiskSheets(
                              BridgeConstants.level2RiskSheets.frane
                            );
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              model?.['Fenomeno trascurabile'] === 'SI';

                            const isRiskSheetFilled =
                              field.parent.formControl.valid || isNegligible;

                            const campateListLength =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ].opereparzialilist?.[0]?.modelstructure
                                ?.campatelist?.length;

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isRiskSheetFilled ||
                                isDisabled ||
                                campateListLength <= 1,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Fenomeno trascurabile<span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Fenomeno trascurabile',
                        type: 'select',
                        defaultValue: 'NO',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            {
                              value: 'SI',
                              label: 'SI',
                            },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                          change: (field, e) => {
                            const defaultSheetValue = {
                              ...bridgeInputTemplateComponent.getFraneOrIdraulicaRiskSheetDefaultValues(
                                BridgeConstants.level2RiskSheets.frane
                              ),
                              'Fenomeno trascurabile': e.value,
                            };
                            field.parent.formControl.reset(defaultSheetValue);
                            bridgeInputTemplateComponent.refreshFields();
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DEL LIVELLO DI SUSCETTIBILITÀ LEGATO AL RISCHIO FRANE
              {
                key: 'Stima Del Livello Di Suscettibilità Legato Al Rischio Frane',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DEL LIVELLO DI SUSCETTIBILITÀ LEGATO AL RISCHIO FRANE',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Instabilità del versante <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Instabilità del versante',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: '-', label: '-' },
                            {
                              value: 'Frane riconosciute',
                              label: 'Frane riconosciute',
                            },
                            {
                              value: 'Frane potenziali',
                              label: 'Frane potenziali',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Stato di attività<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `I.O. 4.4.2.6 - Le frane riconosciute come sospese o quiescienti rientrano nella classe delle frane attive`,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Stato di attività',
                        type: 'select',
                        templateOptions: {
                          required: true,
                          label: '',
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.frane]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                          'templateOptions.options': (
                            model,
                            formState,
                            field
                          ) => {
                            const instabilitaDelVersante =
                              model['Instabilità del versante'];
                            switch (instabilitaDelVersante) {
                              case 'Frane riconosciute':
                                return [
                                  {
                                    value: 'Attiva',
                                    label: 'Attiva',
                                  },
                                  {
                                    value: 'Inattiva',
                                    label: 'Inattiva',
                                  },
                                  {
                                    value: 'Stabilizzata',
                                    label: 'Stabilizzata',
                                  },
                                  {
                                    value: '-',
                                    label: '-',
                                  },
                                ];

                              case 'Frane potenziali':
                                return [
                                  {
                                    value: 'Altamente critica',
                                    label: 'Altamente critica',
                                  },
                                  {
                                    value: 'Critica',
                                    label: 'Critica',
                                  },
                                  {
                                    value: 'Scarsamente critica',
                                    label: 'Scarsamente critica',
                                  },
                                  {
                                    value: '-',
                                    label: '-',
                                  },
                                ];

                              case '-':
                                return [
                                  {
                                    value: 'Altamente critica',
                                    label: 'Altamente critica',
                                  },
                                  {
                                    value: 'Critica',
                                    label: 'Critica',
                                  },
                                  {
                                    value: 'Scarsamente critica',
                                    label: 'Scarsamente critica',
                                  },
                                  {
                                    value: 'Attiva',
                                    label: 'Attiva',
                                  },
                                  {
                                    value: 'Inattiva',
                                    label: 'Inattiva',
                                  },
                                  {
                                    value: 'Stabilizzata',
                                    label: 'Stabilizzata',
                                  },
                                  {
                                    value: '-',
                                    label: '-',
                                  },
                                ];

                              default:
                                return [];
                            }
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Massima velocità attesa<span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Massima velocità attesa',
                        type: 'select',
                        templateOptions: {
                          required: true,
                          label: '',
                          options: [
                            {
                              value: 'Estremamente/molto rapida',
                              label: 'Estremamente/molto rapida',
                            },
                            {
                              value: 'Rapida',
                              label: 'Rapida',
                            },
                            {
                              value: 'Moderata',
                              label: 'Moderata',
                            },
                            {
                              value: 'Lenta',
                              label: 'Lenta',
                            },
                            {
                              value: 'Estremamente lenta',
                              label: 'Estremamente lenta',
                            },
                            {
                              value: '-',
                              label: '-',
                            },
                          ],
                          change: (field, e) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'Estremamente/molto rapida':
                                associatedText = '> 3 m/min';
                                break;

                              case 'Rapida':
                                associatedText = '3m/min - 1,8 m/h';
                                break;

                              case 'Moderata':
                                associatedText = '1,8 m/h - 13 m/mese';
                                break;

                              case 'Lenta':
                                associatedText = '13 m/mese - 1,6 m/anno';
                                break;

                              case 'Estremamente lenta':
                                associatedText = '< 1,6 m/anno';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get(['Massima velocità attesa value'])
                              ?.setValue(associatedText);

                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.frane]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'Estremamente/molto rapida':
                                associatedText = '> 3 m/min';
                                break;

                              case 'Rapida':
                                associatedText = '3m/min - 1,8 m/h';
                                break;

                              case 'Moderata':
                                associatedText = '1,8 m/h - 13 m/mese';
                                break;

                              case 'Lenta':
                                associatedText = '13 m/mese - 1,6 m/anno';
                                break;

                              case 'Estremamente lenta':
                                associatedText = '< 1,6 m/anno';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get(['Massima velocità attesa value'])
                              ?.setValue(associatedText);
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: ``,
                      },
                      {
                        className: 'flex-1',
                        key: 'Massima velocità attesa value',
                        type: 'input',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Magnitudo attesa su base volumetrica in m3<span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Magnitudo attesa su base volumetrica in m3',
                        type: 'select',
                        templateOptions: {
                          required: true,
                          label: '',
                          options: [
                            {
                              value: 'Estremamente grande',
                              label: 'Estremamente grande',
                            },
                            {
                              value: 'Grande',
                              label: 'Grande',
                            },
                            {
                              value: 'Media',
                              label: 'Media',
                            },
                            {
                              value: 'Piccola',
                              label: 'Piccola',
                            },
                            {
                              value: 'Molto Piccola',
                              label: 'Molto Piccola',
                            },
                            {
                              value: '-',
                              label: '-',
                            },
                          ],
                          change: (field, e) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'Estremamente grande':
                                associatedText = '> 106';
                                break;

                              case 'Grande':
                                associatedText = '2,5 x 105 - 106';
                                break;

                              case 'Media':
                                associatedText = '2,5 x 104 - 105';
                                break;

                              case 'Piccola':
                                associatedText = '102 - 104';
                                break;

                              case 'Molto Piccola':
                                associatedText = '< 102';
                                break;

                              default:
                                associatedText = '-';
                            }

                            field.formControl.parent
                              ?.get([
                                'Magnitudo attesa su base volumetrica in m3 value',
                              ])
                              ?.setValue(associatedText);

                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.frane]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            let associatedText;

                            switch (field.formControl.value) {
                              case 'Estremamente grande':
                                associatedText = '> 106';
                                break;

                              case 'Grande':
                                associatedText = '2,5 x 105 - 106';
                                break;

                              case 'Media':
                                associatedText = '2,5 x 104 - 105';
                                break;

                              case 'Piccola':
                                associatedText = '102 - 104';
                                break;

                              case 'Molto Piccola':
                                associatedText = '< 102';
                                break;
                            }

                            field.formControl.parent
                              ?.get([
                                'Magnitudo attesa su base volumetrica in m3 value',
                              ])
                              ?.setValue(associatedText);
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: ``,
                      },
                      {
                        className: 'flex-1',
                        key: 'Magnitudo attesa su base volumetrica in m3 value',
                        type: 'input',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Affidabilità della valutazione<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `IO 4.4.2.8: Nel caso di frane potenziali è opportuno considerare il livello di conoscenza limitato.`,
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Affidabilità della valutazione',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          required: true,
                          label: '',
                          options: [
                            {
                              value: 'Limitata',
                              label: 'Limitata',
                            },
                            {
                              value: 'Buona',
                              label: 'Buona',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Misure di mitigazione<span class="required-marker"> *</span></p>`,
                          },
                          // {
                          //   type: 'cube-custom-tooltip',
                          //   templateOptions: {
                          //     icon: 'search',
                          //     toolTipMessage: `If "Area riconosciuta pericolosa (allegare riferimenti)" is valued as "Fenomeno riconosciuto ma non ancora studiato", insert ASSENTI.
                          //     If "Area riconosciuta pericolosa (allegare riferimenti)" is valued as "Fenomeno riconosciuto e studiato", insert ASSENTI.
                          //     If "Area riconosciuta pericolosa (allegare riferimenti)" is valued as "Fenomeno modellato e oggetto di monitoraggio", insert MONITORATO.
                          //     If "Area riconosciuta pericolosa (allegare riferimenti)" is valued as "Fenomeno oggetto di opere di mitigazione", insert STABILIZZATO.`,
                          //   },
                          // },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                const areaRiconosciuta =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ]?.['opereparzialilist'][0]?.[
                                    'modelstructure'
                                  ]?.['Rischio frane']?.[
                                    'Area riconosciuta pericolosa allegare riferimenti'
                                  ];

                                if (areaRiconosciuta) {
                                  return Object.keys(areaRiconosciuta).find(
                                    (key) => areaRiconosciuta[key] === true
                                  );
                                }
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Misure di mitigazione',
                        type: 'select',
                        templateOptions: {
                          required: true,
                          label: '',
                          options: [
                            {
                              value:
                                'Fenomeno riconosciuto ma non ancora studiato',
                              label:
                                'Fenomeno riconosciuto ma non ancora studiato',
                            },
                            {
                              value: 'Fenomeno riconosciuto e studiato',
                              label: 'Fenomeno riconosciuto e studiato',
                            },
                            {
                              value:
                                'Fenomeno modellato e oggetto di monitoraggio',
                              label:
                                'Fenomeno modellato e oggetto di monitoraggio',
                            },
                            {
                              value: 'Fenomeno oggetto di opere di mitigazione',
                              label: 'Fenomeno oggetto di opere di mitigazione',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.frane]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                        template: `<p>CLASSE DI SUSCETTIBILITA'</p>`,
                      },
                      {
                        key: 'Classe Di Suscettibilita',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DEL LIVELLO DI VULNERABILITÀ LEGATO AL RISCHIO FRANE
              {
                key: 'Stima Del Livello Di Vulnerabilità Legato Al Rischio Frane',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DEL LIVELLO DI VULNERABILITÀ LEGATO AL RISCHIO FRANE',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Estensione Interferenza<span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                return bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ]?.['opereparzialilist'][0]?.[
                                  'modelstructure'
                                ]?.['Rischio frane']?.[
                                  'Estensione interferenza'
                                ]['Estensione interferenza'];
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Estensione Interferenza',
                        type: 'select',
                        templateOptions: {
                          required: true,
                          label: '',
                          options: [
                            {
                              value: 'Totale',
                              label: 'Totale',
                            },
                            {
                              value: 'Parziale',
                              label: 'Parziale',
                            },
                            {
                              value: 'Zona approccio',
                              label: 'Zona approccio',
                            },
                            {
                              value: '-',
                              label: '-',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.frane]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Presenza di fondazioni superficiali o comunque NON progettate per resistere alle azioni orizzontali<span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Presenza di fondazioni superficiali o comunque NON progettate per resistere alle azioni orizzontali',
                        type: 'select',
                        templateOptions: {
                          required: true,
                          label: '',
                          options: [
                            {
                              value: 'SI',
                              label: 'SI',
                            },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.frane]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                        template: `<p>CLASSE DI VULNERABILITA'</p>`,
                      },
                      {
                        key: 'Classe Di Vulnerabilita',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DEL LIVELLO DI ESPOSIZIONE LEGATO AL RISCHIO FRANE
              {
                key: 'Stima Del Livello Di Esposizione Legato Al Rischio Frane',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DEL LIVELLO DI ESPOSIZIONE LEGATO AL RISCHIO FRANE',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                        template: `<p>CLASSE DI ESPOSIZIONE</p>`,
                      },
                      {
                        key: 'Classe Di Esposizione Rischio Frane',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DELLA CLASSE DI ATTENZIONE FRANE A LIVELLO TERRITORIALE
              {
                key: 'Stima Della Classe Di Attenzione Frane A Livello Territoriale',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DELLA CLASSE DI ATTENZIONE FRANE A LIVELLO TERRITORIALE',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                        template: `<p>CLASSE DI ATTENZIONE</p>`,
                      },
                      {
                        key: 'Classe Di Attenzione',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // INFO: Idraulica Tab
          {
            key: BridgeConstants.level2RiskSheets.idraulica,
            templateOptions: {
              label: BridgeConstants.level2RiskSheets.idraulica,
            },
            fieldGroup: [
              // Top section
              {
                fieldGroup: [
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10 padding-top-10',
                        template: `<p>Schede di ispezione ponti di Livello 1 - Fenomeni di frana e fenomeni idraulici</p>`,
                      },
                      {
                        // Vai alla scheda
                        type: 'cube-button-type',
                        className: 'flex-2 remove-underline',
                        templateOptions: {
                          text: 'Vai alla scheda',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.onClickGotoLevel(
                              BridgeConstants.levels.l1,
                              'frane'
                            );
                          },
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        // Calculate cdA
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Calculate CdA',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.getCampataAttentionClass();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const suscettibilita =
                              model[
                                'Stima della suscettibilita legeta al rischio idraulico'
                              ];
                            const vulnerabilita =
                              model[
                                'Stima della vulnerabilita legeta al rischio idraulico'
                              ];

                            const isNegligible =
                              model?.['Fenomeno trascurabile'] === 'SI';

                            const isAllRequiredFieldsFilled =
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.['Corso d acqua']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita['Franco idraulico atteso']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.[
                                  'wa l Larghezza alveo a monte del ponte'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.[
                                  'wa Larghezza complessiva alveo in corrispondenza del ponte'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.[
                                  'Wg Larghezza complessiva delle golene a monte del ponte'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.[
                                  'Wg l Larghezza complessiva delle golene occupata dai rilevati di accesso'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita['a Diametro pila']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.[
                                  'ds Massima profondità di scavo'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                suscettibilita?.[
                                  'df Profondità di posa del piano di fondazione rispetto all alveo'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Dimensioni del bacino idrografico km2'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita['Fenomeni di deposizione']
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita[
                                  'Transporto di materiale vegetale'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Evidenza di presenza di fondazioni superficiali delle pile e delle spalle del ponte'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Evidenza di fenomeni di abbassamento generalizzato dell alveo a monte e a valle del ponte 1'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Ponte posizionato in tratto di alveo avente sensibile curvatura'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Evidenza di fenomeni di abbassamento generalizzato dell alveo a monte e a valle del ponte 2'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Presenza di accumuli di detriti o materiale flottante a monte della pila'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Tendenza dell alveo alla divagazione planimetrica'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Evidenza di presenza di protezione al piede delle pile e delle spalle del ponte'
                                ]
                              ) &&
                              bridgeInputTemplateComponent.hasValue(
                                vulnerabilita?.[
                                  'Presenza di una briglia di protezione immediatamente a valle del ponte'
                                ]
                              );

                            const isRiskSheetFilled =
                              (field.parent.formControl.valid &&
                                isAllRequiredFieldsFilled) ||
                              isNegligible;

                            model.isRiskSheetFilled = isRiskSheetFilled;

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isRiskSheetFilled || isDisabled,
                              true
                            );
                          },
                        },
                      },
                      {
                        // Copy idraulica sheet to all campata
                        type: 'cube-button-type',
                        className: 'remove-underline',
                        templateOptions: {
                          text: 'Copy',
                          color: 'primary',
                          onClick: (e) => {
                            bridgeInputTemplateComponent.copyRiskSheets(
                              BridgeConstants.level2RiskSheets.idraulica
                            );
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              model?.['Fenomeno trascurabile'] === 'SI';

                            const isRiskSheetFilled =
                              field.parent.formControl.valid || isNegligible;

                            const campateListLength =
                              bridgeInputTemplateComponent.model[
                                BridgeConstants.levels.l0
                              ].opereparzialilist?.[0]?.modelstructure
                                ?.campatelist?.length;

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              !isRiskSheetFilled ||
                                isDisabled ||
                                campateListLength <= 1,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex align-items-center',
                    fieldGroup: [
                      {
                        className: 'padding-left-10 padding-right-10',
                        template: `<p>Fenomeno trascurabile <span class="required-marker"> *</span></p>`,
                      },
                      {
                        type: 'cube-custom-tooltip',
                        templateOptions: {
                          icon: 'search',
                          toolTipMessage: `I.O. 4.5.1 - Si può ritenere che il corso d’acqua non interferisca con la 
                          struttura se il ponte presenta contemporaneamente le seguenti caratteristiche: 
                          - luce > 25 m riferita alla luce libera della sezione idraulica dell’attraversamento, misurata in direzione ortogonale al 
                          deflusso della corrente; 
                          - distanza tra il fondo dell’alveo e la quota minima dell’intradosso dell’impalcato > 15 m; 
                          - presenza di fondazioni profonde; 
                          - assenza di notizie di fenomeni erosivi e di scalzamento.`,
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-1 padding-left-10',
                    key: 'Fenomeno trascurabile',
                    type: 'select',
                    defaultValue: 'NO',
                    templateOptions: {
                      disabled: bridgeInputTemplateComponent.isFieldDisabled(
                        [UserRole.hydraulicTechnician],
                        isDisabled,
                        true
                      ),
                      label: '',
                      required: true,
                      options: [
                        { value: 'SI', label: 'SI' },
                        { value: 'NO', label: 'NO' },
                      ],
                      change: (field, e) => {
                        const defaultSheetValue = {
                          ...bridgeInputTemplateComponent.getFraneOrIdraulicaRiskSheetDefaultValues(
                            BridgeConstants.level2RiskSheets.idraulica
                          ),
                          'Fenomeno trascurabile': e.value,
                        };
                        field.parent.formControl.reset(defaultSheetValue);
                        bridgeInputTemplateComponent.refreshFields();
                        bridgeInputTemplateComponent.setIsLevel2DataChanged();
                      },
                    },
                  },
                ],
              },
              // STIMA DELLA SUSCETTIBILITA' LEGATA AL RISCHIO IDRAULICO
              {
                key: 'Stima della suscettibilita legeta al rischio idraulico',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: `STIMA DELLA SUSCETTIBILITÀ LEGATA AL RISCHIO IDRAULICO`,
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Corso d'acqua <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Corso d acqua',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'principale', label: 'principale' },
                            { value: 'secondario', label: 'secondario' },
                            { value: '-', label: '-' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Franco idraulico atteso <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                const tipologiaDiReticolo =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ]?.['opereparzialilist'][0]?.[
                                    'modelstructure'
                                  ]?.['Rischio idraulico']?.[
                                    'Tipologia di reticolo'
                                  ];
                                if (
                                  tipologiaDiReticolo?.['Reticolo principale']
                                ) {
                                  if (
                                    tipologiaDiReticolo?.[
                                      'Franco idraulico FP2 - first'
                                    ] &&
                                    tipologiaDiReticolo?.[
                                      'Franco idraulico FP3'
                                    ]
                                  )
                                    return `
                                      ${tipologiaDiReticolo?.['Franco idraulico FP2 - first']};
                                      ${tipologiaDiReticolo?.['Franco idraulico FP3']}
                                    `;
                                  else
                                    return `
                                      ${tipologiaDiReticolo?.['Franco idraulico FP2 - first']}${tipologiaDiReticolo?.['Franco idraulico FP3']}
                                    `;
                                } else if (
                                  tipologiaDiReticolo?.[
                                    'Reticolo secondario'
                                  ] ||
                                  tipologiaDiReticolo?.[
                                    'Reticolo artificiale di scolo'
                                  ]
                                ) {
                                  return tipologiaDiReticolo?.[
                                    'Franco idraulico FP2 - second'
                                  ];
                                }
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Franco idraulico atteso',
                        type: 'select',
                        templateOptions: {
                          required: true,
                          label: '',
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.idraulica]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                          'templateOptions.options': (
                            model,
                            formState,
                            field
                          ) => {
                            const corsodAcqua = model['Corso d acqua'];
                            switch (corsodAcqua) {
                              case 'principale':
                                return [
                                  {
                                    value: 'FP2 ≤ 0.80 m e FP3 ≤ 1.50 m',
                                    label: 'FP2 ≤ 0.80 m e FP3 ≤ 1.50 m',
                                  },
                                  {
                                    value: 'FP2 ≤ 0.80 m e FP3 > 1.50 m',
                                    label: 'FP2 ≤ 0.80 m e FP3 > 1.50 m',
                                  },
                                  {
                                    value: '0.80 m < FP2 ≤ 1.00 m',
                                    label: '0.80 m < FP2 ≤ 1.00 m',
                                  },
                                  {
                                    value: '1.00 m < FP2 < 1.50 m',
                                    label: '1.00 m < FP2 < 1.50 m',
                                  },
                                  {
                                    value: 'FP2 ≥ 1.50 m',
                                    label: 'FP2 ≥ 1.50 m',
                                  },
                                  {
                                    value: '-',
                                    label: '-',
                                  },
                                ];

                              case 'secondario':
                                return [
                                  {
                                    value: 'F < 0.80 m',
                                    label: 'F < 0.80 m',
                                  },
                                  {
                                    value: '0.80 m < F < 1.00 m',
                                    label: '0.80 m < F < 1.00 m',
                                  },
                                  {
                                    value: '1.00 m < F ≤ 1.20 m',
                                    label: '1.00 m < F ≤ 1.20 m',
                                  },
                                  {
                                    value: '1.20 m < F ≤ 1.50 m',
                                    label: '1.20 m < F ≤ 1.50 m',
                                  },
                                  {
                                    value: 'F > 1.50 m',
                                    label: 'F > 1.50 m',
                                  },
                                  {
                                    value: '-',
                                    label: '-',
                                  },
                                ];
                              default:
                                return [];
                            }
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI SUSCETTIBILITÀ PER IL FENOMENO DI SORMONTO</p>`,
                      },
                      {
                        key: 'Classe di suscettibilita per il fenomeno di sormonto',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>(Wa,l) Larghezza alveo a monte del ponte <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'wa l Larghezza alveo a monte del ponte',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          required: true,
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>(Wa) Larghezza complessiva alveo in corrispondenza del ponte <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'wa Larghezza complessiva alveo in corrispondenza del ponte',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          required: true,
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>(Ca) Fattore di restringimento</p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'ca Fattore di restringimento',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>(Wg,l) Larghezza complessiva delle golene occupata dai rilevati di accesso <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Wg l Larghezza complessiva delle golene occupata dai rilevati di accesso',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          required: true,
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>(Wg) Larghezza complessiva delle golene a monte del ponte <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Wg Larghezza complessiva delle golene a monte del ponte',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          required: true,
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>(Cg) Fattore restringimento delle aree golenali</p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Cg fattore restringimento delle aree golenali',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI SUSCETTIBILITÀ PER IL FENOMENO DI EROSIONE GENERALIZZATA</p>`,
                      },
                      {
                        key: 'Classe di suscettibilita per il fenomeno di erosione generalizzata',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>(a) Diametro Pila <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `il valore di a si assume pari al doppio dell’aggetto della spalla.
                              Per pile non circolari, nel caso in cui la direzione del filone principale della corrente sia obliqua rispetto all’asse longitudinale della pila (angolo di attacco diverso da zero),
                              si assume per a la larghezza della proiezione della pila sul piano trasversale alla direzione della corrente.`,
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'width-100 input-line flex-1 padding-left-10',
                        key: 'a Diametro pila',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          required: true,
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.idraulica]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>(ds) Massima profondità di scavo <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'ds Massima profondità di scavo',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>(df) Profondità di posa del piano di fondazione rispetto all'alveo <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'search',
                              toolTipMessage: `La valutazione di quest’ultima può essere fatta sulla base della documentazione di progetto dell’opera, se disponibile, o di indicazioni specifiche derivanti da evidenze di 
                              campo; nel caso molto frequente di mancanza di entrambi, si può assumere in prima approssimazione una profondità di riferimento df pari 2.00 m, risultante dai valori medi 
                              normalmente adottati nella pratica costruttiva per fondazioni superficiali.`,
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'width-100 input-line flex-1 padding-left-10',
                        key: 'df Profondità di posa del piano di fondazione rispetto all alveo',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          required: true,
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>IEL</p>`,
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'IEL',
                        type: 'input',
                        templateOptions: {
                          label: '',
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                        },
                        validators: {
                          validation: ['numberPattern'],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI SUSCETTIBILITÀ PER IL FENOMENO DI EROSIONE LOCALIZZATA</p>`,
                      },
                      {
                        key: 'Classe di suscettibilita per il fenomeno di erosione localizzata',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DELLA VULNERABILITÀ LEGATA AL RISCHIO IDRAULICO
              {
                key: 'Stima della vulnerabilita legeta al rischio idraulico',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: `STIMA DELLA VULNERABILITÀ LEGATA AL RISCHIO IDRAULICO`,
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Dimensioni del bacino idrografico [km2] <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                return bridgeInputTemplateComponent.model[
                                  BridgeConstants.levels.l0
                                ]?.['opereparzialilist']?.[0]?.[
                                  'modelstructure'
                                ]?.['Rischio idraulico']?.[
                                  'Caratteristiche Alveo'
                                ]?.['Dimensioni del bacino idrografico kmq'];
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'width-100 input-line flex-1',
                        key: 'Dimensioni del bacino idrografico km2',
                        type: 'select',
                        templateOptions: {
                          label: '',
                          required: true,
                          options: [
                            {
                              value: '<100',
                              label: '<100',
                            },
                            {
                              value: '100-499',
                              label: '100-499',
                            },
                            {
                              value: '≥500',
                              label: '≥500',
                            },
                            {
                              value: '-',
                              label: '-',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.idraulica]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Fenomeni di deposizione <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                const indicareEventualeObject =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ]?.['opereparzialilist'][0]?.[
                                    'modelstructure'
                                  ]?.['Rischio idraulico']?.[
                                    'Caratteristiche Alveo'
                                  ]?.[
                                    'Indicare l’eventuale sussistenza delle seguenti condizioni'
                                  ];

                                if (indicareEventualeObject) {
                                  return Object.keys(indicareEventualeObject)
                                    .filter(
                                      (key) =>
                                        indicareEventualeObject[key] === true
                                    )
                                    .join('\n');
                                }
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Fenomeni di deposizione',
                        type: 'select',
                        templateOptions: {
                          label: '',
                          required: true,
                          options: [
                            {
                              value:
                                'Evidenza di accentuati fenomeni di deposizione di sedimenti, soprattutto se grossolani, o di fenomeni d’erosione d’alveo',
                              label:
                                'Evidenza di accentuati fenomeni di deposizione di sedimenti, soprattutto se grossolani, o di fenomeni d’erosione d’alveo',
                            },
                            {
                              value:
                                'Evidenza di significativi fenomeni di deposizione di sedimenti o di d’erosione d’alveo',
                              label:
                                'Evidenza di significativi fenomeni di deposizione di sedimenti o di d’erosione d’alveo',
                            },
                            {
                              value:
                                'Assenza di evidenza di significativi fenomeni di deposizione o di erosione d’alveo',
                              label:
                                'Assenza di evidenza di significativi fenomeni di deposizione o di erosione d’alveo',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.idraulica]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex align-items-center',
                        fieldGroup: [
                          {
                            className: 'padding-left-10 padding-right-10',
                            template: `<p>Trasporto di materiale vegetale <span class="required-marker"> *</span></p>`,
                          },
                          {
                            type: 'cube-custom-tooltip',
                            templateOptions: {
                              icon: 'info',
                            },
                            expressionProperties: {
                              'templateOptions.toolTipMessage': (
                                model,
                                formState,
                                field
                              ) => {
                                const indicareEventualeObject =
                                  bridgeInputTemplateComponent.model[
                                    BridgeConstants.levels.l0
                                  ]?.['opereparzialilist'][0]?.[
                                    'modelstructure'
                                  ]?.['Rischio idraulico']?.[
                                    'Caratteristiche Alveo'
                                  ]?.[
                                    'Indicare l’eventuale sussistenza delle seguenti condizioni'
                                  ];

                                if (indicareEventualeObject) {
                                  return Object.keys(indicareEventualeObject)
                                    .filter(
                                      (key) =>
                                        indicareEventualeObject[key] === true
                                    )
                                    .join('\n');
                                }
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        key: 'Transporto di materiale vegetale',
                        type: 'select',
                        templateOptions: {
                          label: '',
                          required: true,
                          options: [
                            {
                              value:
                                'Evidenza di trasporto di materiale vegetale di notevole dimensione',
                              label:
                                'Evidenza di trasporto di materiale vegetale di notevole dimensione',
                            },
                            {
                              value:
                                'Evidenza di significativo trasporto di materiale vegetale di notevole dimensione',
                              label:
                                'Evidenza di significativo trasporto di materiale vegetale di notevole dimensione',
                            },
                            {
                              value:
                                'Assenza di evidenza di trasporto di materiale vegetale di notevole dimensione',
                              label:
                                'Assenza di evidenza di trasporto di materiale vegetale di notevole dimensione',
                            },
                          ],
                          change: () => {
                            bridgeInputTemplateComponent.setIsLevel2DataChanged();
                          },
                        },
                        expressionProperties: {
                          'templateOptions.disabled': (
                            model,
                            formState,
                            field
                          ) => {
                            const isNegligible =
                              bridgeInputTemplateComponent.model?.[
                                BridgeConstants.levels.level2Campata
                              ]?.[BridgeConstants.level2RiskSheets.idraulica]?.[
                                'Fenomeno trascurabile'
                              ] === 'SI';

                            return bridgeInputTemplateComponent.isFieldDisabled(
                              [UserRole.hydraulicTechnician],
                              isNegligible || isDisabled,
                              true
                            );
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI VULNERABILITÀ PER IL FENOMENO DI SORMONTO</p>`,
                      },
                      {
                        key: 'Classe di vulnerabilita per il fenomeno di sormonto',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Evidenza di presenza di fondazioni superficiali delle pile e delle spalle del ponte <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Evidenza di presenza di fondazioni superficiali delle pile e delle spalle del ponte',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Evidenza di fenomeni di abbassamento generalizzato dell’alveo a monte e a valle del ponte <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Evidenza di fenomeni di abbassamento generalizzato dell alveo a monte e a valle del ponte 1',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Ponte posizionato in tratto di alveo avente sensibile curvatura <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Ponte posizionato in tratto di alveo avente sensibile curvatura',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI VULNERABILITÀ PER IL FENOMENO DI EROSIONE GENERALIZZATA</p>`,
                      },
                      {
                        key: 'Classe di vulnerabilita per il fenomeno di erosione generalizzata',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Evidenza di fenomeni di abbassamento generalizzato dell’alveo a monte e a valle del ponte <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Evidenza di fenomeni di abbassamento generalizzato dell alveo a monte e a valle del ponte 2',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Presenza di accumuli di detriti o materiale flottante a monte della pila <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Presenza di accumuli di detriti o materiale flottante a monte della pila',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Tendenza dell’alveo alla divagazione planimetrica <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Tendenza dell alveo alla divagazione planimetrica',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Evidenza di presenza di protezione al piede delle pile e delle spalle del ponte <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Evidenza di presenza di protezione al piede delle pile e delle spalle del ponte',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        template: `<p>Presenza di una briglia di protezione immediatamente a valle del ponte <span class="required-marker"> *</span></p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Presenza di una briglia di protezione immediatamente a valle del ponte',
                        type: 'select',
                        templateOptions: {
                          disabled:
                            bridgeInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled,
                              true
                            ),
                          label: '',
                          required: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI VULNERABILITÀ PER IL FENOMENO DI EROSIONE LOCALIZZATA</p>`,
                      },
                      {
                        key: 'Classe di vulnerabilita per il fenomeno di erosione localizzata',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DELL’ESPOSIZIONE LEGATA AL RISCHIO IDRAULICO
              {
                key: 'Stima dell espezione legata al rischio idraulico',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DELL’ESPOSIZIONE LEGATA AL RISCHIO IDRAULICO',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI ESPOSIZIONE</p>`,
                      },
                      {
                        key: 'Classe di esposizione',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              // STIMA DELLA CLASSE DI ATTENZIONE RISCHIO IDRAULICO
              {
                key: 'Stima della classe di attenzione rischio idraulico',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'STIMA DELLA CLASSE DI ATTENZIONE AL RISCHIO IDRAULICO',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI ATTENZIONE RISCHIO SORMONTO</p>`,
                      },
                      {
                        key: 'Classe di attenzione rischio sormonto',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI ATTENZIONE RISCHIO EROSIONE GENERALIZZATA</p>`,
                      },
                      {
                        key: 'Classe di attenzione rischio erosione generalizzata',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI ATTENZIONE RISCHIO EROSIONE LOCALIZZATA</p>`,
                      },
                      {
                        key: 'Classe di attenzione rischio erosione localizzata',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI ATTENZIONE RISCHIO EROSIONE</p>`,
                      },
                      {
                        key: 'Classe di attenzione rischio erosione',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap border-all',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 padding-left-10 border-right font-weight-bolder font-size-14',
                        template: `<p>CLASSE DI ATTENZIONE RISCHIO IDRAULICO</p>`,
                      },
                      {
                        key: 'Classe di attenzione rischio idraulico',
                        type: 'cube-text-only-type',
                        expressionProperties: {
                          className: (model, formState, field) => {
                            const cdaClass =
                              bridgeInputTemplateComponent.getCdaClassName(
                                field.formControl.value
                              );

                            return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                              cdaClass ?? ''
                            }`;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // INFO: Globale Tab
          {
            key: 'Globale',
            templateOptions: { label: 'Globale' },
            fieldGroup: [
              // Calculate Section
              {
                // Calculate cdA
                type: 'cube-button-type',
                className: 'remove-underline',
                templateOptions: {
                  text: 'Calculate CdA',
                  color: 'primary',
                  onClick: (e) => {
                    bridgeInputTemplateComponent.getCampataAttentionClass();
                  },
                },
                expressionProperties: {
                  'templateOptions.disabled': (model, formState, field) => {
                    const level2Campata =
                      bridgeInputTemplateComponent.model.level2Campata;

                    const isGlobaleRiskSheetFilled =
                      level2Campata[
                        BridgeConstants.level2RiskSheets
                          .strutturaleEFondazionale
                      ]?.isRiskSheetFilled &&
                      level2Campata[BridgeConstants.level2RiskSheets.sismica]
                        ?.isRiskSheetFilled &&
                      level2Campata[BridgeConstants.level2RiskSheets.frane]
                        ?.isRiskSheetFilled &&
                      level2Campata[BridgeConstants.level2RiskSheets.idraulica]
                        ?.isRiskSheetFilled;

                    return bridgeInputTemplateComponent.isFieldDisabled(
                      [UserRole.hydraulicTechnician],
                      !isGlobaleRiskSheetFilled || isDisabled,
                      true
                    );
                  },
                },
              },
              // Attention class table
              {
                fieldGroupClassName: 'display-flex-wrap border-all',
                fieldGroup: [
                  {
                    className:
                      'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                    template: `<p>CLASSE DI ATTENZIONE STRUTTURALE-FONDAZIONALE</p>`,
                  },
                  {
                    key: 'Classe Di Attenzione Strutturale Fondazionale',
                    type: 'cube-text-only-type',
                    expressionProperties: {
                      className: (model, formState, field) => {
                        const cdaClass =
                          bridgeInputTemplateComponent.getCdaClassName(
                            field.formControl.value
                          );

                        return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                          cdaClass ?? ''
                        }`;
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName:
                  'display-flex-wrap border-right border-left border-bottom',
                fieldGroup: [
                  {
                    className:
                      'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                    template: `<p>CLASSE DI ATTENZIONE SISMICA</p>`,
                  },
                  {
                    key: 'Classe Di Attenzione Sismica',
                    type: 'cube-text-only-type',
                    expressionProperties: {
                      className: (model, formState, field) => {
                        const cdaClass =
                          bridgeInputTemplateComponent.getCdaClassName(
                            field.formControl.value
                          );

                        return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                          cdaClass ?? ''
                        }`;
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName:
                  'display-flex-wrap border-right border-left border-bottom',
                fieldGroup: [
                  {
                    className:
                      'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                    template: `<p>CLASSE DI ATTENZIONE FRANE</p>`,
                  },
                  {
                    key: 'Classe Di Attenzione Frane',
                    type: 'cube-text-only-type',
                    expressionProperties: {
                      className: (model, formState, field) => {
                        const cdaClass =
                          bridgeInputTemplateComponent.getCdaClassName(
                            field.formControl.value
                          );

                        return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                          cdaClass ?? ''
                        }`;
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName:
                  'display-flex-wrap border-right border-left',
                fieldGroup: [
                  {
                    className:
                      'flex-1 padding-left-10 border-right font-size-14 font-weight-bolder',
                    template: `<p>CLASSE DI ATTENZIONE IDRAULICA</p>`,
                  },
                  {
                    key: 'Classe Di Attenzione Idraulica',
                    type: 'cube-text-only-type',
                    expressionProperties: {
                      className: (model, formState, field) => {
                        const cdaClass =
                          bridgeInputTemplateComponent.getCdaClassName(
                            field.formControl.value
                          );

                        return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                          cdaClass ?? ''
                        }`;
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap border-all',
                fieldGroup: [
                  {
                    className:
                      'flex-1 padding-left-10 border-right font-color-red font-size-14 font-weight-bolder',
                    template: `<p>CLASSE DI ATTENZIONE GLOBALE DELLA CAMPATA</p>`,
                  },
                  {
                    key: 'Classe Di Attenzione Globale Della Campata',
                    type: 'cube-text-only-type',
                    expressionProperties: {
                      className: (model, formState, field) => {
                        const cdaClass =
                          bridgeInputTemplateComponent.getCdaClassName(
                            field.formControl.value
                          );

                        return `width-100 input-line flex-1 text-align-center padding-top-10 attention-class ${
                          cdaClass ?? ''
                        }`;
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
        hideExpression: () =>
          bridgeInputTemplateComponent.model.activeLevel !==
          BridgeConstants.levels.level2Campata,
      },
    ],
  };
}
